import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";

import CreateClient from "../../AppComponents/Commercial/CreateClient";
import CreateSeller from "./../../AppComponents/Commercial/CreateSeller";
import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
import CreateShippingData from "./Components/CreateShippingData";
import OrderDataConfirm from "../../AppComponents/Orders/OrderDataConfirm";
import OrderContext from '../../Contexts/OrderContext'
import CreateFreemiumSettings from "./Components/CreateFreemiumSettings";

import { preventWheel } from "../../Utilities/General/PreventWheel";
import validator from "../../Utilities/General/Validator";
import OrderService from "../../Services/Licenses/OrderService";
import StockOrderService from '../../Services/Stock/StockOrderService'
import PaymentService from '../../Services/Licenses/PaymentService'
import ClientService from "../../Services/Licenses/ClientService";
import SaleProductService from "../../Services/Stock/SaleProductService";
import { getUsername, isAuth, isCustomerSuccess } from '../../Utilities/Auth/AuthPermissions';
import { showModal, hideModal, toggleAccordion } from "../../Utilities/General/BootstrapApi";
import { setPreAssembledStock } from "../../Utilities/Orders/SetPreAssembledStock";
import UsoundPaymentsService from "../../Services/UsoundPayments/UsoundPaymentsService";
import { setFreemiumTags } from "../../Utilities/Orders/SetFreemiumTags";
import { checkBoneHeads } from "../../Utilities/Orders/CheckBoneHeads";

import Check from "../../Assets/Check";
import Warning from "../../Assets/Warning";

export default function CreateOrder() {

    const {
        orders,
        setOrders
    } = useContext(OrderContext)

    const [sellers, setSellers] = useState([])
    const [typePhone, setTypePhone] = useState([])
    const [phoneProviders, setPhoneProviders] = useState([])
    const [phoneContacts, setPhoneContacts] = useState([])
    const [emailContacts, setEmailContacts] = useState([])
    const [articles, setArticles] = useState([])
    const [saleProducts, setSaleProducts] = useState([])
    const [clients, setClients] = useState([])
    const [clientShippingData, setClientShippingData] = useState([])
    const [clientBillingData, setClientBillingData] = useState([])
    const [typeIdClient, setTypeIdClient] = useState([])
    const [countries, setCountries] = useState([])
    const [currency, setCurrency] = useState([])
    const [office, setOffice] = useState([])
    const [stockOrders, setStockOrders] = useState([])
    const [customers, setCustomers] = useState([])
    const [stock, setStock] = useState([])
    const [paymentServiceProducts, setPaymentServiceProducts] = useState([])
    const [order, setOrder] = useState({
        advancepayment: {
            full_payment: false
        },
        paymentdetail_set: [],
        orderstatus_set: [],
        ordershippingdata: {},
        orderbillingdata: {}
    })
    const [typeOrderStatus, setTypeOrderStatus] = useState([])
    const [uSoundPaymentOrder, setUsoundPaymentOrder] = useState({
        orderdetail_set: []
    })
    const [paymentDetail, setPaymentDetail] = useState({})
    const [shippingData, setShippingData] = useState({})
    const [phoneContact, setPhoneContact] = useState({})
    const [newSeller, setNewSeller] = useState({})
    const [newSellerType, setNewSellerType] = useState('')
    const [newClient, setNewClient] = useState({});
    const [newClientBillingData, setNewClientBillingData] = useState({});
    const [newEmailContact, setNewEmailContact] = useState({})
    const [newPhoneContact, setNewPhoneContact] = useState({})

    const [, forceUpdate] = useState()
    const [fullPayment, setFullPayment] = useState(false)
    const [location, setLocation] = useState({});
    const [locationConfirmed, setLocationConfirmed] = useState(false)
    const [locationFound, setLocationFound] = useState(false)
    const [mapCenter, setMapCenter] = useState({
        lat: -24.1851575,
        lng: -80
    });
    const [clientSelected, setClientSelected] = useState(null)
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

    const autocompleteRef = useRef()
    const phoneContactRef = useRef()

    const navigate = useNavigate()

    const val = validator.rules

    // GENERAL
    useEffect(() => {
        if (isAuth()) {
            ClientService.getTypePhone().then(data => setTypePhone(data))
            ClientService.getPhoneProvider().then(data => setPhoneProviders(data))
            ClientService.getPhoneContacts().then(data => setPhoneContacts(data))
            ClientService.getEmailContacts().then(data => setEmailContacts(data))
            ClientService.getClients().then(data => setClients(data?.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })))
            ClientService.getClientShippingData().then(data => setClientShippingData(data))
            ClientService.getClientBillingData().then(data => setClientBillingData(data))
            ClientService.getTypeIdClient().then(data => setTypeIdClient(data))
            PaymentService.getSellers().then(data => setSellers(data))
            PaymentService.getArticles().then(data => setArticles(data?.sort((a, b) => {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })))
            PaymentService.getCountries().then(data => setCountries(data?.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })))
            PaymentService.getCurrencies().then(data => setCurrency(data))
            PaymentService.getOffice().then(data => setOffice(data))
            OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
            SaleProductService.getSaleProducts().then(data => setSaleProducts(data?.sort((a, b) => {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })))
            StockOrderService.getStockOrders().then(data => setStockOrders(data))
            UsoundPaymentsService.getProducts().then(data => setPaymentServiceProducts(data))
            UsoundPaymentsService.getCustomers().then(data => setCustomers(data))
        }
    }, [])

    useEffect(() => {
        setOrder({
            ...order,
            advancepayment: {
                ...order.advancepayment,
                difference_installments: 1
            }
        })
    }, []);

    const handleChange = e => {
        setOrder({
            ...order,
            [e.target.name]: e.target.value
        })
        if (isCustomerSuccess()) {
            let [usoundSeller] = sellers.filter(item => item.name === 'uSound')
            setOrder({
                ...order,
                [e.target.name]: e.target.value,
                amount: 0,
                currency: 'USD',
                closer: usoundSeller.id,
                hunter: usoundSeller.id,
                sweeper: usoundSeller.id,
                advancepayment: {
                    ...order.advancepayment,
                    method: 'Efectivo',
                    advancepayment_amount: 0,
                    advancepayment_currency: 'USD',
                    difference_installments_amount: 0,
                    difference_installments_method: 'Efectivo'
                }
            })
        }
    }

    useEffect(() => {
        handleClientSection()
        handleProductSection()
        handlePaymentSection()
        handleShippingSection()
        handleVendorsSection()
    }, [order, shippingData, locationConfirmed]);

    const handleClientSection = () => {
        let v = val
        let check = document.getElementById('check-client');
        let warning = document.getElementById('warning-client');
        if (v.fieldValid('client')) {
            check.classList.remove('display-none');
            warning.classList.add('display-none');
            return true;
        } else {
            check.classList.add('display-none');
            warning.classList.remove('display-none');
            return false;
        }
    }

    // PRODUCTOS
    useEffect(() => {
        if (stockOrders !== undefined && saleProducts !== undefined) {
            setStock(setPreAssembledStock(stockOrders, saleProducts))
        }
    }, [stockOrders, saleProducts])

    const handleChangeCount = (e, spSku) => {
        setPaymentDetail({
            article: articles.filter(art => art.sku === spSku)[0].id,
            count: parseInt(e.target.value),
            rewarded: e.target.checked
        })
    }

    const handleChangeRewarded = (e, spSku) => {
        articles?.map(art => {
            order.paymentdetail_set.map(pd => {
                if (art.id === pd.article && art.sku === spSku) {
                    pd.rewarded = e.target.checked
                }
            })
        })
        checkRewarded()
    }

    const checkRewarded = () => {
        let amount = document.getElementById('orderAmountInput')
        let currency = document.getElementById('orderCurrencyInput')
        let allRewarded = true
        for (let i = 0; i < order.paymentdetail_set.length; i++) {
            if (!order.paymentdetail_set[i].rewarded) {
                allRewarded = false
            }
        }
    }

    useEffect(() => {
        if (Object.keys(paymentDetail).length > 0) {
            let flag = false
            for (let i = 0; i < order.paymentdetail_set.length; i++) {
                if (order.paymentdetail_set[i].article === paymentDetail.article) {
                    flag = true
                }
            }
            if (flag) {
                // si flag esta en true, es porque el producto ya esta en el array
                // filtro los productos con article distinto y le agrego el nuevo objeto con la nueva cantidad
                setOrder({
                    ...order,
                    paymentdetail_set: [
                        ...order.paymentdetail_set.filter(item => item.article !== paymentDetail.article),
                        paymentDetail
                    ]
                })
            } else {
                // si flag esta en false, es porque el producto no esta en el array
                // simplemente lo agrego
                setOrder({
                    ...order,
                    paymentdetail_set: [
                        ...order.paymentdetail_set,
                        paymentDetail
                    ]
                })
            }
        }
    }, [paymentDetail])

    const handleProductSection = () => {
        let v = val;
        let check = document.getElementById('check-product');
        let warning = document.getElementById('warning-product');
        let flag1 = false
        let flag2 = false
        for (let i = 0; i < order.paymentdetail_set.length; i++) {
            if (order.paymentdetail_set[i].count > 0 || !isNaN(order.paymentdetail_set[i].count)) {
                flag1 = true
            }
        }
        for (let i = 0; i < saleProducts.length; i++) {
            if (document.getElementById('count' + i).value > 0) {
                flag2 = true
            }
        }
        if ((v.fieldValid('amount') && v.fieldValid('currency') || isCustomerSuccess()) &&
            v.fieldValid('note') && flag1 && flag2) {
            check.classList.remove('display-none');
            warning.classList.add('display-none');
            return true;
        } else {
            check.classList.add('display-none');
            warning.classList.remove('display-none');
            return false;
        }
    }

    // PAGO
    let diff_installments = []
    for (let i = 1; i <= 18; i++) {
        diff_installments.push(i)
    }

    const toggleFullPayment = e => {
        if (e.target.value === 'anticipo') {
            setFullPayment(false)
        } else {
            setFullPayment(true)
        }
    }

    const handleChangePayment = e => {
        setOrder({
            ...order,
            advancepayment: {
                ...order.advancepayment,
                advancepayment_currency: order.currency,
                difference_installments_currency: order.currency,
                [e.target.name]: e.target.value
            }
        })
        if (e.target.name === 'full_payment') {
            if (e.target.value === 'anticipo') {
                setOrder({
                    ...order,
                    advancepayment: {
                        ...order.advancepayment,
                        full_payment: false
                    }
                })
            } else {
                setOrder({
                    ...order,
                    advancepayment: {
                        ...order.advancepayment,
                        full_payment: true
                    }
                })
            }
        }
    }

    const handlePaymentSection = () => {
        var v = val
        var check = document.getElementById('check-payment');
        var warning = document.getElementById('warning-payment');
        if (
            ((order.advancepayment.full_payment && v.fieldValid('method')) ||
                (v.fieldValid('method') && v.fieldValid('advancepayment_amount') &&
                    v.fieldValid('difference_installments_method') &&
                    parseInt(order.advancepayment.advancepayment_amount) < parseInt(order.amount)))
            || isCustomerSuccess()
        ) {
            check.classList.remove('display-none')
            warning.classList.add('display-none')
            return true;
        } else {
            check.classList.add('display-none')
            warning.classList.remove('display-none')
            return false;
        }
    }

    // DATOS DE ENVIO
    const handleShippingSection = () => {
        let v = val
        let check = document.getElementById('check-shipping');
        let warning = document.getElementById('warning-shipping');
        if (
            v.fieldValid('address_province') && v.fieldValid('address_city') && v.fieldValid('address_address') &&
            v.fieldValid('address_number') && v.fieldValid('address_postal_code') && v.fieldValid('address_floor_apartment') && v.fieldValid('address_apartment') &&
            v.fieldValid('id_recipient') && v.fieldValid('name_recipient') && v.fieldValid('country_code') &&
            v.fieldValid('area_code') && v.fieldValid('number') && v.fieldValid('observation') && v.fieldValid('note') && locationConfirmed
        ) {
            check.classList.remove('display-none');
            warning.classList.add('display-none');
            return true;
        } else {
            check.classList.add('display-none');
            warning.classList.remove('display-none');
            return false;
        }
    }

    const handleAutocomplete = address => {
        let country = document.getElementById('address_countryInput')
        let province = document.getElementById('address_provinceInput')
        let city = document.getElementById('address_cityInput')
        let postal_code = document.getElementById('address_postal_codeInput')
        let add_add = document.getElementById('address_addressInput')
        let number = document.getElementById('address_numberInput')
        let floor = document.getElementById('address_floor_apartmentInput')
        let apartment = document.getElementById('address_apartmentInput')
        if (address === 'new') {
            country.value = ''
            province.value = ''
            city.value = ''
            postal_code.value = ''
            add_add.value = ''
            number.value = ''
            floor.value = ''
            apartment.value = ''
        } else {
            let [csd] = clientShippingData.filter(item => item.address === address && item.client === parseInt(order.new_client))
            let [currentOffice] = office.filter(item => item.label === address)
            country.value = csd !== undefined ? csd.address_country : currentOffice.address_country
            province.value = csd !== undefined ? csd.address_province : currentOffice.address_province
            city.value = csd !== undefined ? csd.address_city : currentOffice.address_city
            postal_code.value = csd !== undefined ? csd.address_postal_code : currentOffice.address_postal_code
            add_add.value = csd !== undefined ? csd.address_address : currentOffice.address_address
            number.value = csd !== undefined ? csd.address_number : currentOffice.address_number
            floor.value = csd !== undefined ? csd.address_floor_apartment : currentOffice.address_floor_apartment
            apartment.value = csd !== undefined ? csd.address_apartment : currentOffice.address_apartment
            setShippingData({
                ...shippingData,
                client: csd !== undefined ? csd.client : order.new_client,
                address_country: csd !== undefined ? csd.address_country : currentOffice.address_country,
                address_province: csd !== undefined ? csd.address_province : currentOffice.address_province,
                address_city: csd !== undefined ? csd.address_city : currentOffice.address_city,
                address_postal_code: csd !== undefined ? csd.address_postal_code : currentOffice.address_postal_code,
                address_address: csd !== undefined ? csd.address_address : currentOffice.address_address,
                address_number: csd !== undefined ? csd.address_number : currentOffice.address_number,
                address_floor_apartment: csd !== undefined ? csd.address_floor_apartment : currentOffice.address_floor_apartment,
                address_apartment: csd !== undefined ? csd.address_apartment : currentOffice.address_apartment
            })
        }
    }

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setMapCenter(latLng)
                setLocation(latLng)
                findLocation()
            })
            .catch(error => console.error('Error', error));
    }

    const findLocation = () => {
        if (!locationFound) {
            setLocationFound(true)
        }
    }

    const confirmLocation = () => {
        setLocationConfirmed(!locationConfirmed)
    }

    const handleVendorsSection = () => {
        let v = val;
        let check = document.getElementById('check-vendors');
        let warning = document.getElementById('warning-vendors');
        if ((v.fieldValid('sweeper') && v.fieldValid('hunter') && v.fieldValid('closer')) || isCustomerSuccess()) {
            check.classList.remove('display-none');
            warning.classList.add('display-none');
            return true;
        } else {
            check.classList.add('display-none');
            warning.classList.remove('display-none');
            return false;
        }
    }

    // FINAL
    const preSubmit = () => {

        if (
            !handleClientSection() ||
            !handleProductSection() ||
            !handlePaymentSection() ||
            !handleShippingSection() ||
            !handleVendorsSection()
        ) {
            val.showMessages()
            forceUpdate(1)
            return
        }

        // PRODUCTOS
        // verifico que no haya cantidades en 0
        order.paymentdetail_set = order.paymentdetail_set.filter(item => item.count > 0)

        // si es reposicion, siempre van a ser productos bonificados
        if (isCustomerSuccess()) {
            order.paymentdetail_set.map(pd => {
                pd.rewarded = true
            })
        }

        // PAGO
        if (!order.advancepayment.full_payment) {
            order.advancepayment.difference_installments_amount = (
                (parseInt(order.amount) - parseInt(order.advancepayment.advancepayment_amount)) /
                parseInt(order.advancepayment.difference_installments)
            ).toFixed(2)
        } else {
            setOrder({
                ...order,
                advancepayment: {
                    ...order.advancepayment,
                    advancepayment_amount: order.amount,
                    advancepayment_currency: order.currency,
                    difference_installments: 1,
                    difference_installments_amount: 0,
                    difference_installments_method: order.advancepayment.method
                }
            })
        }

        // DATOS DE ENVIO
        // verificar receptor
        let [client] = clients.filter(c => c.id === order.new_client)
        if (shippingData.name_recipient === undefined) {
            shippingData.name_recipient = client.name
        }
        if (shippingData.id_recipient === undefined) {
            shippingData.id_recipient = client.id_client
        }

        // setear direccion y ubicacion en datos de envio
        setShippingData({
            ...shippingData,
            address: shippingData.address_address + ' ' + shippingData.address_number,
            latitude: location.lat.toFixed(8),
            longitude: location.lng.toFixed(8)
        })

        // verificar montos
        if (order.amount.length > 10) {
            toast.warning('Los montos no pueden tener más de 10 dígitos', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })
            return
        }

        // modal de confirmacion
        let [freemiumArticle] = articles.filter(art => art.sku === "NBK_AUD_FREM_0001")
        let freemiumsInOrder = order.paymentdetail_set.filter(pd => pd.article === freemiumArticle.id)
        if (freemiumsInOrder.length > 0) {
            let currentCountry = countries.find(country => country.id === parseInt(shippingData.address_country))?.name
            // if (currentCountry === 'Argentina' ||
            //     currentCountry === 'Bolivia' ||
            //     currentCountry === 'Brasil' ||
            //     currentCountry === 'Chile' ||
            //     currentCountry === 'Colombia' ||
            //     currentCountry === 'Ecuador' ||
            //     currentCountry === 'Perú') {
            showModal('freemiumSettings')
            // } else {
            //     toast.error(`El Audiómetro Freemium no está disponible para clientes de ${currentCountry}. Disponible en Argentina, Bolivia, Brasil, Chile, Colombia, Ecuador y Perú.`, {
            //         position: 'bottom-left',
            //         hideProgressBar: true,
            //         className: 'error-toast'
            //     })
            // }
        } else {
            showModal('orderDataConfirm')
        }
    }

    const handleSubmit = () => {
        const { result, difference } = checkBoneHeads(order.paymentdetail_set,
            uSoundPaymentOrder.orderdetail_set,
            paymentServiceProducts,
            articles)
        if (!result) {
            let bonehead = articles.find(art => art.sku === 'NBK_VINCH_OSEA_0001')
            order.paymentdetail_set.push({
                article: bonehead.id,
                count: difference,
                rewarded: true,
                tag: null
            })
        }

        // agrego los tags a los freemiums
        order.paymentdetail_set = setFreemiumTags(
            order.paymentdetail_set,
            articles,
            saleProducts
        )

        if (autocompleteRef.current.value === 'new' || autocompleteRef.current.value.length === 0) {
            // guardar nuevos datos de envio de cliente
            ClientService.saveNewClientShippingData(shippingData)
                .then(data => {
                    console.log(data)
                })
        } else {
            // actualizar datos de envio de cliente
            let [csd] = clientShippingData.filter(item => item.client === shippingData.client && item.address === shippingData.address)
            if (csd !== undefined) {
                ClientService.updateClientShippingData(csd.id, shippingData)
                    .then(data => {
                        console.log(data)
                    })
            }
        }

        if (phoneContactRef.current.value === 'new') {
            // guardar nuevo telefono
            ClientService.saveNewPhoneContact(phoneContact)
                .then(data => {
                    console.log(data)
                })
            // shippingData.phone_contact = `+${phoneContact.country_code} ${phoneContact.area_code} ${phoneContact.number}`
        }

        OrderService.saveNewOrder(order)
            .then(data => {
                // setear el estado "creada" o "reposicion"
                let [currentStatus] = typeOrderStatus.filter(tos => isCustomerSuccess() ? tos.name === 'replacement' : tos.name === 'created')
                let newOrderStatus = {
                    order: data.id,
                    type_order_status: currentStatus.id,
                    user_name: getUsername()
                }
                OrderService.saveNewOrderStatus(newOrderStatus)
                    .then(newOSResponse => {
                        data.orderstatus_set = [...data.orderstatus_set, newOSResponse]
                        // actualizar contexto de ordenes
                        setOrders([
                            ...orders,
                            data
                        ])
                        // mandar mail de orden creada
                        OrderService.sendMailOrderStatus(newOSResponse.order,
                            isCustomerSuccess() ? 'replacement' : 'created',
                            'creada'
                        )
                    })

                // setear la orden segun el id de la respuesta
                shippingData.order = data.id

                // si es reposicion, guardar datos de facturacion de la orden
                if (isCustomerSuccess()) {
                    OrderService.saveNewOrderBillingData(saveOBD())
                }

                // guardar servicios freemium
                if (uSoundPaymentOrder.orderdetail_set.length > 0) {
                    uSoundPaymentOrder.order_id = data.id
                    UsoundPaymentsService.saveNewOrder(uSoundPaymentOrder)
                        .then(data => {
                            console.log(data)
                        })
                }

                // guardar datos de envio de la orden
                OrderService.saveNewOrderShippingData(shippingData)
                    .then(() => {

                        toast.success('Orden creada correctamente', {
                            position: 'bottom-left',
                            hideProgressBar: true,
                            className: 'success-toast'
                        })
                        hideModal('orderDataConfirm')
                        setTimeout(() => {
                            navigate('/ordenes-venta')
                        }, 2000);
                    })
            })
    }

    const saveOBD = () => {
        let [currentClientBillingData] = clientBillingData.filter(cbd => cbd.client === parseInt(order.new_client))
        let newOrderBillingData = {
            vat_number: currentClientBillingData.vat_number,
            name: currentClientBillingData.name,
            iva: currentClientBillingData.iva,
            type: currentClientBillingData.type,
            person: currentClientBillingData.person,
            address_province: shippingData.address_province,
            address_city: shippingData.address_city,
            address_locality: shippingData.address_locality,
            address_address: shippingData.address_address,
            address_number: shippingData.address_number,
            address_floor_apartment: shippingData.address_floor_apartment,
            address_apartment: shippingData.address_apartment,
            address: shippingData.address,
            address_postal_code: shippingData.address_postal_code,
            amount: order.amount,
            amount_usd: order.amount_usd,
            carnet_id: currentClientBillingData.carnet_id,
            address_country: shippingData.address_country,
            order: shippingData.order,
            currency: currency.filter(cur => cur.name === 'USD')[0].id
        }
        return newOrderBillingData
    }

    return (

        <>

            <PrincipalBar />

            <div className="container-fluid px-5">

                <div className="d-flex flex-wrap justify-content between mt-5">
                    <h2 className="text-info col-12 col-sm-11">
                        Registro de orden de {isCustomerSuccess() ? "reposición" : "venta"}
                    </h2>
                    <div className=" col-12 col-sm-1 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn border border-info text-info"
                            onClick={() => navigate('/ordenes-venta')}>
                            VOLVER
                        </button>
                    </div>
                </div>

                <div id="accordion">

                    <div className="mt-4" id="item-client">
                        <h2 onClick={() => toggleAccordion('client')}>
                            <button id="client-button"
                                className="bg-order-client w-100 d-flex align-items-center p-3 border-0"
                                type="button"
                            >
                                <h4 className="text-white mb-0">Cliente</h4>
                                <span className="ms-3 pb-2" id="check-client"><Check /></span>
                                <span className="ms-3 pb-2" id="warning-client"><Warning /></span>
                            </button>
                        </h2>
                        <div id="collapse-client" className="collapse show shadow-sm rounded">
                            <div className="p-2">
                                <div className="col-lg-7 p-2 d-flex flex-wrap">
                                    <select id="clientInput" name="new_client" className="form-select"
                                        onChange={e => {
                                            handleChange(e)
                                            setClientSelected(true)
                                        }}>
                                        <option value="">Seleccione</option>
                                        {clients?.map((client, idx) => {
                                            return <option key={idx} value={client.id}>{client.name}</option>
                                        })}
                                    </select>
                                    {
                                        val.message(
                                            'client',
                                            order.new_client,
                                            'required',
                                            { className: 'text-danger' }
                                        )
                                    }
                                    <p
                                        className="cursor-pointer text-primary d-block mt-3 ms-3"
                                        onClick={() => showModal('newClient')}
                                    >
                                        + Agregar nuevo
                                    </p>
                                    <div className="modal fade" id="newClient" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h3 className="modal-title text-info" id="staticBackdropLabel">Nuevo cliente</h3>
                                                </div>
                                                <div className="modal-body">
                                                    <CreateClient
                                                        hideModal={hideModal}
                                                        countries={countries}
                                                        typeIdClient={typeIdClient}
                                                        val={val}
                                                        newClient={newClient}
                                                        setNewClient={setNewClient}
                                                        newClientBillingData={newClientBillingData}
                                                        setNewClientBillingData={setNewClientBillingData}
                                                        forceUpdate={forceUpdate}
                                                        newPhoneContact={newPhoneContact}
                                                        setNewPhoneContact={setNewPhoneContact}
                                                        newEmailContact={newEmailContact}
                                                        setNewEmailContact={setNewEmailContact}
                                                        clients={clients}
                                                        setClients={setClients}
                                                        order={order}
                                                        setOrder={setOrder}
                                                        phoneContacts={phoneContacts}
                                                        setPhoneContacts={setPhoneContacts}
                                                        clientBillingData={clientBillingData}
                                                        setClientBillingData={setClientBillingData}
                                                        setLocationConfirmed={setLocationConfirmed}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4" id="item-product">
                        <h2 onClick={() => toggleAccordion('product')}>
                            <button id="client-button"
                                className="bg-order-product w-100 d-flex p-3 border-0 align-items-center"
                                type="button"
                            >
                                <h4 className="text-white">Productos</h4>
                                <span className="ms-3 pb-2" id="check-product"><Check /></span>
                                <span className="ms-3 pb-2" id="warning-product"><Warning /></span>
                            </button>
                        </h2>
                        <div id="collapse-product" className="collapse shadow-sm rounded">
                            <div className="p-2">
                                <div className="px-4 pt-3 md-overflow-scroll">
                                    <table className="table table-hover text-center">
                                        <thead>
                                            <tr>
                                                <th className="col-4 ps-0">Detalle</th>
                                                <th className="col-2 text-center">Cantidad</th>
                                                {!isCustomerSuccess() && <th className="col-3 text-center">Bonificado</th>}
                                                <th className="col-3 text-center">Stock de pre-armados</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {saleProducts?.map((sp, spIndex) => {
                                                return (
                                                    <tr key={sp.id}>
                                                        <td>
                                                            {sp.alias === null ? sp.name : sp.alias}
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={'count' + spIndex}
                                                                name="count"
                                                                type="number"
                                                                min={0}
                                                                step={1}
                                                                className="form-control"
                                                                defaultValue={0}
                                                                onChange={e => handleChangeCount(e, sp.sku)}
                                                                onWheel={() => preventWheel('count' + spIndex)}
                                                            />
                                                        </td>
                                                        {!isCustomerSuccess() &&
                                                            <td>
                                                                <input
                                                                    id={'rewarded' + spIndex}
                                                                    name="rewarded"
                                                                    type="checkbox"
                                                                    onChange={e => handleChangeRewarded(e, sp.sku)}
                                                                />
                                                            </td>
                                                        }
                                                        <td>
                                                            {stock?.map(st => {
                                                                if ((sp.alias !== null ? sp.alias : sp.name) === st.sale_product) {
                                                                    return (
                                                                        <span
                                                                            key={sp.id}
                                                                            className="bg-success p-1 rounded text-white"
                                                                        >
                                                                            {st.stock}
                                                                        </span>
                                                                    )
                                                                }
                                                            })}
                                                            {!JSON.stringify(stock).includes((sp.alias !== null ? sp.alias : sp.name)) &&
                                                                <span className="bg-danger p-1 rounded text-white">
                                                                    0
                                                                </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={isCustomerSuccess() ? "d-none" : "row d-flex flex-wrap justify-content-end p-2"}>
                                    <div className="form-group d-flex align-items-center col-sm-3">
                                        <label htmlFor="amount" className="me-2 fs-5"><strong>Total</strong></label>
                                        <input
                                            id="orderAmountInput"
                                            type="number"
                                            min="0"
                                            max="99999999"
                                            step="0.01"
                                            name="amount"
                                            className="form-control me-1"
                                            onChange={e => handleChange(e)}
                                        />
                                        {
                                            val.message(
                                                'amount',
                                                order.amount,
                                                'required',
                                                { className: 'text-danger' }
                                            )
                                        }
                                    </div>
                                    <div className="form-group col-sm-2 mt-2 mt-sm-0">
                                        <select
                                            id="orderCurrencyInput"
                                            name="currency"
                                            className="form-select"
                                            onChange={e => handleChange(e)}
                                        >
                                            <option value="">Seleccione</option>
                                            {currency?.map(curr => {
                                                return <option key={curr.id}>{curr.name}</option>
                                            })}
                                        </select>
                                        {
                                            val.message('currency',
                                                order.currency,
                                                'required',
                                                { className: 'text-danger' }
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div className="form-group">
                                        <label htmlFor="note">Observaciones</label>
                                        <textarea
                                            name="note"
                                            className="form-control textarea"
                                            onChange={e => handleChange(e)}
                                        ></textarea>
                                        {
                                            val.message(
                                                'note',
                                                order.note,
                                                'regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü]|max:250',
                                                { className: 'text-danger' })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={isCustomerSuccess() ? "d-none" : "mt-4"} id="item-payment">
                        <h2 onClick={() => toggleAccordion('payment')}>
                            <button id="payment-button"
                                className="bg-order-payment w-100 d-flex p-3 border-0 align-items-center"
                                type="button"
                            >
                                <h4 className="text-white">Pago</h4>
                                <span className="ms-3 pb-2" id="check-payment"><Check /></span>
                                <span className="ms-3 pb-2" id="warning-payment"><Warning /></span>
                            </button>
                        </h2>
                        <div id="collapse-payment" className="collapse shadow-sm rounded">
                            <div className="p-4 pt-1">
                                <div className="md-overflow-scroll">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="col-2 text-align-center">Forma</th>
                                                <th className="col-3 text-align-center">Método de pago</th>
                                                <th className="col-1 text-align-center">Cuotas</th>
                                                <th className="col-2 text-align-center">Importe Total</th>
                                                <th className="col-1 text-align-center">Moneda</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="pt-2 ps-0 pe-3">
                                                    <select name="full_payment" className="form-select"
                                                        onChange={e => {
                                                            toggleFullPayment(e)
                                                            handleChangePayment(e)
                                                        }}>
                                                        <option value="anticipo">Anticipo</option>
                                                        <option value="total">Total</option>
                                                    </select>
                                                </td>
                                                <td className="pt-2 px-1">
                                                    <select name="method" className="form-select" onChange={e => handleChangePayment(e)}>
                                                        <option value="">Seleccione</option>
                                                        <option value="Efectivo">Efectivo</option>
                                                        <option value="Stripe">Stripe</option>
                                                        <option value="rebill">rebill</option>
                                                        <option value="dLocal">dLocal</option>
                                                        <option value="Mercado Pago">Mercado Pago</option>
                                                        <option value="Transferencia Bancaria">Transferencia Bancaria</option>
                                                        <option value="Cheque">Cheque</option>
                                                        <option value="PayPal">PayPal</option>
                                                        <option value="Western Union/MoneyGram/Argenper">Western Union/MoneyGram/Argenper</option>
                                                        <option value="Mercado Pago Ahora 12/18">Mercado Pago Ahora 12/18</option>
                                                        <option value="Tienda Nube Ahora 12/18">Tienda Nube Ahora 12/18</option>
                                                    </select>
                                                    {
                                                        val.message(
                                                            'method',
                                                            order.advancepayment.method,
                                                            'required',
                                                            { className: 'text-danger' }
                                                        )
                                                    }
                                                </td>
                                                <td className="pt-2 px-1">
                                                    <div className="rounded p-1 text-center">
                                                        1
                                                    </div>
                                                </td>
                                                <td className="pt-2 px-1">
                                                    {!fullPayment ?
                                                        <>
                                                            <input
                                                                type="text"
                                                                name="advancepayment_amount"
                                                                className="form-control"
                                                                defaultValue={order.amount}
                                                                onChange={e => handleChangePayment(e)}
                                                            />
                                                            {
                                                                val.message(
                                                                    'advancepayment_amount',
                                                                    order.advancepayment.advancepayment_amount,
                                                                    'required|regex:[0-9.]|not_regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü-]',
                                                                    { className: 'text-danger' }
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <input
                                                            type="text"
                                                            name="advancepayment_amount"
                                                            className="form-control"
                                                            value={order.amount}
                                                            onChange={e => handleChangePayment(e)}
                                                            disabled
                                                        />
                                                    }
                                                </td>
                                                <td className="pt-2 pe-0 ps-1">
                                                    <input
                                                        type="text"
                                                        name="advancepayment_currency"
                                                        className="form-control"
                                                        defaultValue={order.currency}
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            {!fullPayment &&
                                                <tr>
                                                    <td className="pt-2 ps-0 pe-3">
                                                        <div className="rounded text-align-center p-1">
                                                            Diferencia
                                                        </div>
                                                    </td>
                                                    <td className="pt-2 px-1">
                                                        <select name="difference_installments_method" className="form-select"
                                                            onChange={e => handleChangePayment(e)}>
                                                            <option value="">Seleccione</option>
                                                            <option value="Efectivo">Efectivo</option>
                                                            <option value="Stripe">Stripe</option>
                                                            <option value="rebill">rebill</option>
                                                            <option value="dLocal">dLocal</option>
                                                            <option value="Mercado Pago">Mercado Pago</option>
                                                            <option value="Transferencia Bancaria">Transferencia Bancaria</option>
                                                            <option value="Cheque">Cheque</option>
                                                            <option value="PayPal">PayPal</option>
                                                            <option value="Western Union/MoneyGram/Argenper">Western Union/MoneyGram/Argenper</option>
                                                            <option value="Mercado Pago Ahora 12/18">Mercado Pago Ahora 12/18</option>
                                                            <option value="Tienda Nube Ahora 12/18">Tienda Nube Ahora 12/18</option>
                                                        </select>
                                                        {
                                                            val.message('difference_installments_method',
                                                                order.advancepayment.difference_installments_method,
                                                                'required',
                                                                { className: 'text-danger' }
                                                            )
                                                        }
                                                    </td>
                                                    <td className="pt-2 px-1">
                                                        <select id="diff_instInput" name="difference_installments" className="form-select"
                                                            onChange={e => handleChangePayment(e)}>
                                                            {Array.from({ length: 18 }).map((inst, idx) => {
                                                                return <option key={idx} value={idx + 1}>{idx + 1}</option>
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td className="pt-2 px-1">
                                                        <input
                                                            id="difference_installments_amountInput"
                                                            type="text"
                                                            name="difference_installments_amount"
                                                            className="form-control"
                                                            value={
                                                                isNaN(((parseInt(order.amount) - parseInt(order.advancepayment.advancepayment_amount)) /
                                                                    parseInt(order.advancepayment.difference_installments)).toFixed(2)) ?
                                                                    'No válido' :
                                                                    ((parseInt(order.amount) - parseInt(order.advancepayment.advancepayment_amount)) /
                                                                        parseInt(order.advancepayment.difference_installments)).toFixed(2)
                                                            }
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className="pt-2 pe-0 ps-1">
                                                        <input
                                                            type="text"
                                                            name="difference_installments_currency"
                                                            className="form-control"
                                                            defaultValue={order.currency}
                                                            disabled
                                                        />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4" id="item-shipping">
                        <h2 onClick={() => toggleAccordion('shipping')}>
                            <button id="shipping-button"
                                className="bg-order-shipping w-100 d-flex p-3 border-0 align-items-center"
                                type="button"
                            >
                                <h4 className="text-white">Datos de envío</h4>
                                <span className="ms-3 pb-2" id="check-shipping"><Check /></span>
                                <span className="ms-3 pb-2" id="warning-shipping"><Warning /></span>
                            </button>
                        </h2>
                        <div id="collapse-shipping" className="collapse shadow-sm rounded">
                            <div className="p-4">
                                <CreateShippingData
                                    typePhone={typePhone}
                                    phoneProviders={phoneProviders}
                                    phoneContacts={phoneContacts}
                                    clients={clients}
                                    countries={countries}
                                    order={order}
                                    clientShippingData={clientShippingData}
                                    shippingData={shippingData}
                                    setShippingData={setShippingData}
                                    phoneContact={phoneContact}
                                    setPhoneContact={setPhoneContact}
                                    autocompleteRef={autocompleteRef}
                                    handleAutocomplete={handleAutocomplete}
                                    locationConfirmed={locationConfirmed}
                                    locationFound={locationFound}
                                    val={val}
                                    handleShippingSection={handleShippingSection}
                                    mapCenter={mapCenter}
                                    handleSelect={handleSelect}
                                    confirmLocation={confirmLocation}
                                    clientSelected={clientSelected}
                                    phoneContactRef={phoneContactRef}
                                    office={office}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={isCustomerSuccess() ? "d-none" : "my-4"} id="item-vendors">
                        <h2 onClick={() => toggleAccordion('vendors')}>
                            <button id="vendor-button"
                                className="bg-order-vendors w-100 d-flex p-3 border-0 align-items-center"
                                type="button"
                            >
                                <h4 className="text-white">Vendedores</h4>
                                <span className="ms-3 pb-2" id="check-vendors"><Check /></span>
                                <span className="ms-3 pb-2" id="warning-vendors"><Warning /></span>
                            </button>
                        </h2>
                        <div id="collapse-vendors" className="collapse shadow-sm rounded">
                            <div className="p-4">
                                <div className="d-flex">
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="sweeper">Cazador 1</label>
                                        <select id="sweeperInput" name="sweeper" className="form-select mt-1"
                                            onChange={e => handleChange(e)}>
                                            <option value="">Seleccione</option>
                                            {sellers?.filter(seller => seller.enabled).map((seller, idx) => {
                                                return <option key={idx} value={seller.id}>{seller.name}</option>
                                            })}
                                        </select>
                                        {
                                            val.message(
                                                'sweeper',
                                                order.sweeper,
                                                'required',
                                                { className: 'text-danger' }
                                            )
                                        }
                                        <p
                                            className="cursor-pointer text-primary d-block mt-1 ms-3"
                                            onClick={() => {
                                                showModal('newSeller')
                                                setNewSellerType('sweeper')
                                            }}
                                        >
                                            + Agregar nuevo
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="hunter">Cazador 2</label>
                                        <select id="hunterInput" name="hunter" className="form-select mt-1"
                                            onChange={e => handleChange(e)}>
                                            <option value="">Seleccione</option>
                                            {sellers?.filter(seller => seller.enabled).map((seller, idx) => {
                                                return <option key={idx} value={seller.id}>{seller.name}</option>
                                            })}
                                        </select>
                                        {
                                            val.message(
                                                'hunter',
                                                order.hunter,
                                                'required',
                                                { className: 'text-danger' }
                                            )
                                        }
                                        <p
                                            className="cursor-pointer text-primary d-block mt-1 ms-3"
                                            onClick={() => {
                                                showModal('newSeller')
                                                setNewSellerType('hunter')
                                            }}
                                        >
                                            + Agregar nuevo
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="closer">Closer</label>
                                        <select id="closerInput" name="closer" className="form-select mt-1"
                                            onChange={e => handleChange(e)}>
                                            <option value="">Seleccione</option>
                                            {sellers?.filter(seller => seller.enabled).map((seller, idx) => {
                                                return <option key={idx} value={seller.id}>{seller.name}</option>
                                            })}
                                        </select>
                                        {
                                            val.message(
                                                'closer',
                                                order.closer,
                                                'required',
                                                { className: 'text-danger' }
                                            )
                                        }
                                        <p
                                            className="cursor-pointer text-primary d-block mt-1 ms-3"
                                            onClick={() => {
                                                showModal('newSeller')
                                                setNewSellerType('closer')
                                            }}
                                        >
                                            + Agregar nuevo
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="newSeller" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title text-info" id="staticBackdropLabel">Nuevo vendedor</h3>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                        hideModal('newSeller')
                                        val.hideMessages()
                                        forceUpdate(2)
                                        document.getElementById('sellerForm').reset()
                                    }}></button>
                                </div>
                                <div className="modal-body">
                                    <CreateSeller
                                        newSeller={newSeller}
                                        setNewSeller={setNewSeller}
                                        val={val}
                                        order={order}
                                        setOrder={setOrder}
                                        sellers={sellers}
                                        setSellers={setSellers}
                                        newSellerType={newSellerType}
                                        forceUpdate={forceUpdate}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => {
                                        hideModal('newSeller')
                                        val.hideMessages()
                                        forceUpdate(2)
                                        document.getElementById('sellerForm').reset()
                                    }}>
                                        CERRAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="d-flex flex-wrap mt-3 mb-5 justify-content-end">
                    <button type="button"
                        className="btn border border-info text-info col-12 col-md-3"
                        onClick={() => {
                            navigate('/ordenes-venta')
                        }}>
                        VOLVER
                    </button>
                    <button type="button"
                        className="btn btn-info text-white col-12 col-md-3 ms-md-3 mt-2 mt-md-0"
                        onClick={preSubmit}>
                        GUARDAR
                    </button>
                </div>

                <CreateFreemiumSettings
                    order={order}
                    freemiumArticle={articles?.filter(art => art.sku === "NBK_AUD_FREM_0001")[0] || []}
                    clients={clients}
                    countries={countries}
                    uSoundPaymentOrder={uSoundPaymentOrder}
                    setUsoundPaymentOrder={setUsoundPaymentOrder}
                    paymentServiceProducts={paymentServiceProducts}
                    articles={articles}
                    shippingData={shippingData}
                />

                <OrderDataConfirm
                    order={order}
                    emails={emailContacts}
                    clients={clients}
                    sellers={sellers}
                    countries={countries}
                    saleProducts={saleProducts}
                    articles={articles}
                    hideModal={hideModal}
                    handleSubmit={handleSubmit}
                    loadingChangeStatus={loadingChangeStatus}
                    setLoadingChangeStatus={setLoadingChangeStatus}
                    stockOrders={stockOrders}
                    toOrderStatus="created or replacement"
                    uSoundPaymentOrder={uSoundPaymentOrder}
                    paymentServiceProducts={paymentServiceProducts}
                    fromCreateOrder="true"
                    customers={customers}
                    shippingData={shippingData}
                />

            </div>

        </>

    )
}