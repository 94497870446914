import { useEffect, useState } from "react";

import ClientService from '../../Services/Licenses/ClientService'

export default function useClientBillingData() {
    const [cbd, setCbd] = useState([])

    useEffect(() => {
        ClientService.getClientBillingData()
            .then(data => setCbd(data))
    }, [])

    return [cbd, setCbd]
}