import React, { useState, useEffect, useMemo, useRef } from 'react';
import { format } from "date-fns";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import TableInventory from './Components/TableInventory';
import ButtonBack from '../../AppComponents/Commons/ButtonBack';
import Loader from "../../AppComponents/Commons/Loader";
import CreateMovement from './Components/CreateMovement';
import CreateInputProduct from './Components/CreateInputProduct';
import CreateSaleProduct from './Components/CreateSaleProduct';
import { isAdmin, isAuth, isManufacturer } from '../../Utilities/Auth/AuthPermissions';
import InfoSaleProduct from './Components/InfoSaleProduct';
import EditInputProduct from './Components/EditInputProduct';
import EditSaleProduct from './Components/EditSaleProduct';
import AdminManufacturer from './Components/AdminManufacturer';
import AdminSupplier from './Components/AdminSupplier';
import AdminItems from './Components/AdminItems';

import validator from "../../Utilities/General/Validator";
import { showModal, hideModal, showTooltip, hideTooltip } from '../../Utilities/General/BootstrapApi';
import SupplierService from '../../Services/Stock/SupplierService';
import ManufacturerService from '../../Services/Stock/ManufacturerService';
import InputProductService from '../../Services/Stock/InputProductService';
import PaymentService from '../../Services/Licenses/PaymentService'
import SaleProductService from '../../Services/Stock/SaleProductService';
import MovementService from '../../Services/Stock/MovementService';
import { setPreAssembledStock } from "../../Utilities/Orders/SetPreAssembledStock";
import StockOrderService from '../../Services/Stock/StockOrderService';
import { toggleDisabled } from '../../Utilities/General/HandleClick';

import Edit from '../../Assets/Edit'
import InputProductLicenseList from './Components/InputProductLicenseList';

export default function Inventory() {

    const [suppliers, setSuppliers] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [typesInputProduct, setTypesInputProduct] = useState([]);
    const [inputProducts, setInputProducts] = useState([]);
    const [inputProductsStock, setInputProductsStock] = useState([]);
    const [articles, setArticles] = useState([]);
    const [saleProducts, setSaleProducts] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [saleProductsStock, setSaleProductsStock] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsStock, setItemsStock] = useState([]);
    const [movements, setMovements] = useState([]);
    const [movementsToDisplay, setMovementsToDisplay] = useState([]);
    const [originalMovementsToDisplay, setOriginalMovementsToDisplay] = useState([]);
    const [stockOrders, setStockOrders] = useState([])
    const [stock, setStock] = useState([])

    const [newSupplier, setNewSupplier] = useState({});
    const [newManufacturer, setNewManufacturer] = useState({});
    const [newTypeInputProduct, setNewTypeInputProduct] = useState({});
    const [newInputProduct, setNewInputProduct] = useState({});
    const [newRequirement, setNewRequirement] = useState({});
    const [newSaleProduct, setNewSaleProduct] = useState({});
    const [newItems, setNewItems] = useState([{}]);
    const [newMovement, setNewMovement] = useState({});

    const [loading, setLoading] = useState(true);
    const [willCreateManufacturer, setWillCreateManufacturer] = useState(false);
    const [willCreateTypeInputProduct, setWillCreateTypeInputProduct] = useState(false);
    const [notAllowed, setNotAllowed] = useState(false);
    const [is_serializable, setIs_serializable] = useState(false);
    const [choiceArticle, setChoiceArticle] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [loadingMovement, setLoadingMovement] = useState(true);
    const [workOn, setWorkOn] = useState(null);
    const [editArticle, setEditArticle] = useState(null)
    const [fail, setFail] = useState(false)

    const val = useRef(validator.rules);
    const [, forceUpdate] = useState();

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuth()) {
            getData()
        }
    }, []);

    const getData = () => {
        SupplierService.getSuppliers()
            .then(data => setSuppliers(data?.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })))
        ManufacturerService.getManufacturers()
            .then(data => setManufacturers(data?.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })))
        InputProductService.getTypesInputProduct()
            .then(data => setTypesInputProduct(data?.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })))
        SaleProductService.getSaleProducts().then(data => setSaleProducts(data?.sort((a, b) => b.id - a.id)))
        MovementService.getItems().then(data => setItems(data))
        MovementService.getMovements().then(data => {
            setMovements(data?.sort((a, b) => b.id - a.id))
            setLoadingMovement(false)
            setLoading(false)
        })
        MovementService.getItemsStock().then(data => setItemsStock(data))
        PaymentService.getArticles().then(data => setArticles(data))
        StockOrderService.getStockOrders().then(data => setStockOrders(data))
    }

    useEffect(() => {
        if (stockOrders !== undefined && saleProducts !== undefined) {
            setStock(setPreAssembledStock(stockOrders, saleProducts))
        }
    }, [stockOrders, saleProducts])

    const saveNewSupplier = flag => {
        if (flag === 'create') {
            toggleDisabled('submit_supplier')
            SupplierService.saveNewSupplier(newSupplier)
                .then(data => {
                    setNewSupplier({});
                    setSuppliers([...suppliers, data])
                    document.getElementById('newSupplierForm').reset()
                    toggleDisabled('submit_supplier')
                    toast.success('Proveedor creado correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                })
        }
        if (flag === 'edit') {
            SupplierService.updateSupplier(newSupplier.id, newSupplier)
                .then(data => {
                    setNewSupplier({});
                    setSuppliers([
                        ...suppliers.filter(item => item.id !== data.id),
                        data
                    ])
                    setWorkOn(null)
                })
        }
    }

    const saveNewManufacturer = flag => {
        if (flag === 'create') {
            toggleDisabled('submit_manufacturer')
            ManufacturerService.saveNewManufacturer(newManufacturer)
                .then(data => {
                    setWillCreateManufacturer(false);
                    setNewManufacturer({});
                    document.getElementById('newManufacturerForm').reset()
                    setManufacturers([...manufacturers, data])
                    toggleDisabled('submit_manufacturer')
                    toast.success('Fabricante creado correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                })
        }
        if (flag === 'edit') {
            ManufacturerService.updateManufacturer(workOn, newManufacturer)
                .then(data => {
                    setWillCreateManufacturer(false);
                    setNewManufacturer({});
                    document.getElementById('newManufacturerForm').reset()
                    setManufacturers([
                        ...manufacturers.filter(item => item.id !== data.id),
                        data
                    ])
                    setWorkOn(null)
                })
        }
    }

    const saveNewTypeInputProduct = () => {
        InputProductService.saveNewTypeInputProduct(newTypeInputProduct)
            .then(data => {
                setWillCreateTypeInputProduct(false);
                setNewTypeInputProduct({});
                setTypesInputProduct([...typesInputProduct, data]);
                toast.success('Tipo de artículo creado correctamente', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
            })
    }

    const saveNewInputProduct = (e, newInputProduct) => {
        e.preventDefault();
        InputProductService.saveNewInputProduct(newInputProduct)
            .then(res => {
                if (res !== undefined && res.status !== 400) {
                    setWillCreateTypeInputProduct(false)
                    setWillCreateManufacturer(false)
                    setNewInputProduct({});
                    setNewTypeInputProduct({})
                    setNewManufacturer({})
                    document.getElementById('newInputProductForm').reset()
                    setInputProducts([...inputProducts, res.data])
                    hideModal('newInputProduct')
                    toast.success('Artículo creado correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                }
            })
    }

    const saveUpdatedInputProduct = () => {
        InputProductService.updateInputProduct(newInputProduct.id, newInputProduct)
            .then(res => {
                if (res !== undefined && res.status !== 400) {
                    setWillCreateTypeInputProduct(false)
                    setWillCreateManufacturer(false)
                    setNewTypeInputProduct({})
                    setNewManufacturer({})
                    setWorkOn(null)
                    setInputProducts([
                        ...inputProducts.filter(item => item.id !== res.data.id),
                        res.data
                    ])
                    toast.success('Artículo editado correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                    hideModal('editInputProduct')
                }
            })
    }

    useEffect(() => {
        if (isAuth()) {
            if (inputProducts?.length > 0) {
                InputProductService.getInputProductStock()
                    .then(data => {
                        setInputProductsStock(data?.sort((a, b) => b.id - a.id));
                    })
            }
        }
    }, [inputProducts])

    // cuando cambie movements, incluir las propiedades del objeto padre en cada elemento del movementdetail_set
    useEffect(() => {
        setOriginalMovementsToDisplay(processMovements(movements))
        setMovementsToDisplay(processMovements(movements))
        setLoading(false)
    }, [movements])

    const processMovements = movements => {
        let display = []
        movements?.forEach(mov => {
            mov.movementdetail_set.forEach(md => {
                display.push({
                    ...md,
                    movement: mov.id,
                    date: mov.timestamp,
                    user_name: mov.user_name,
                    type: mov.type,
                    observation: mov.observation
                })
            })
        })
        return display.sort((a, b) => b.movement - a.movement)
    }

    const saveNewSaleProduct = (e, newSaleProduct) => {
        e.preventDefault();
        SaleProductService.saveNewSaleProduct(newSaleProduct)
            .then(res => {
                if (res !== undefined && res.status !== 400) {
                    setSaleProducts([...saleProducts, res.data])
                    PaymentService.saveNewArticle({
                        name: newSaleProduct.name,
                        sku: newSaleProduct.sku,
                        audiometer: newSaleProduct.name.includes('Audiómetro') ? true : false
                    })
                        .then(data => {
                            setArticles([...articles, data])
                            setNewSaleProduct({});
                            setRequirements([])
                            document.getElementById('newSaleProductForm').reset()
                            hideModal('newSaleProduct')
                            toast.success('Producto creado correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                        })
                }
            })
    }

    const saveUpdatedSaleProduct = () => {
        SaleProductService.updateSaleProduct(newSaleProduct.id, newSaleProduct)
            .then(res => {
                if (res !== undefined && res.status !== 400) {
                    setSaleProducts([
                        ...saleProducts.filter(item => item.id !== newSaleProduct.id),
                        res.data
                    ])
                    PaymentService.updateArticle(editArticle.id, {
                        name: newSaleProduct.name,
                        sku: newSaleProduct.sku,
                        audiometer: newSaleProduct.name.includes('Audiómetro') ? true : false
                    })
                        .then(data => {
                            setArticles([
                                ...articles.filter(item => item.id !== editArticle.id),
                                data
                            ])
                            setNewSaleProduct({});
                            setRequirements([])
                            setWorkOn(null)
                            document.getElementById('editSaleProductForm').reset()
                            hideModal('editSaleProduct')
                            toast.success('Producto editado correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                        })
                }
            })
    }

    useEffect(() => {
        if (saleProducts?.length > 0) {
            SaleProductService.getSaleProductStock()
                .then(data => {
                    setSaleProductsStock(data.sort((a, b) => b.id - a.id));
                    setLoading(false)

                })
        }
    }, [saleProducts, movements])

    useEffect(() => {
        if (isAuth()) {
            InputProductService.getInputProducts()
                .then(data => {
                    setInputProducts(data);
                })
            MovementService.getItemsStock()
                .then(data => {
                    setItemsStock(data)
                })
        }
    }, [movements])

    const columnsInputProductsStock = useMemo(
        () => [
            {
                Header: 'SKU',
                accessor: 'sku'
            },
            {
                Header: 'Nombre',
                accessor: 'name'
            },
            {
                Header: 'Tipo',
                accessor: data => {
                    let value
                    typesInputProduct.map(tip => {
                        if (tip.id === data.type_input_product) {
                            value = tip.name
                        }
                    })
                    return value
                }
            },
            {
                Header: 'Stock',
                accessor: inputProduct => {
                    return {
                        id: inputProduct.id,
                        name: inputProduct.name,
                        stock: inputProduct.stock,
                        is_serializable: inputProduct.is_serializable
                    }
                },
                Cell: ({ value }) => {
                    if (!value.is_serializable) {
                        return (
                            <span
                                className={
                                    value.stock > 0 ?
                                        "bg-success p-1 rounded text-white" :
                                        "bg-danger p-1 rounded text-white"
                                }>
                                {value.stock}
                            </span>
                        )
                    }
                    return (
                        <span
                            id={'stockButton' + value.id}
                            onMouseEnter={() => showTooltip('stockButton' + value.id, `Ver n° de serie de ${value.name}`)}
                            onMouseLeave={() => hideTooltip('stockButton' + value.id)}
                            onClick={() => {
                                setWorkOn(value)
                                showModal('inputProductLicenseList')
                            }}
                            className={
                                value.stock > 0 ?
                                    "bg-success p-1 rounded text-white only-cursor-pointer" :
                                    "bg-danger p-1 rounded text-white only-cursor-pointer"
                            }>
                            {value.stock}
                        </span>
                    )
                }
            },
            {
                Header: 'Acción',
                accessor: data => (
                    <span
                        id={'editInputProduct' + data.id}
                        className='actions'
                        onMouseEnter={() => showTooltip('editInputProduct' + data.id, 'Editar artículo')}
                        onMouseLeave={() => hideTooltip('editInputProduct' + data.id)}
                        onClick={() => {
                            setWorkOn(data.id)
                            showModal('editInputProduct')
                        }}>
                        <Edit />
                    </span>
                )
            }
        ],
        [manufacturers, typesInputProduct, inputProductsStock]
    );

    const columnsSaleProducts = useMemo(
        () => [
            {
                Header: 'SKU',
                accessor: 'sku',
                Cell: ({ value }) => {
                    return <span
                        className="cursor-pointer"
                        onClick={() => {
                            showModal('infoSaleProduct')
                            setWorkOn(value)
                        }}>
                        {value}
                    </span>
                }
            },
            {
                Header: 'Nombre',
                accessor: sp => sp.alias !== null ? sp.alias : sp.name,
            },
            {
                Header: 'Stock',
                accessor: sp => {
                    if (sp.stock > 0) {
                        return (
                            <span className="bg-success p-1 rounded text-white">
                                {sp.stock}
                            </span>
                        )
                    }
                    return (
                        <span className="bg-danger p-1 rounded text-white">
                            {sp.stock}
                        </span>
                    )
                }
            },
            {
                Header: 'Acción',
                accessor: data => (
                    <span
                        id={'editSaleProduct' + data.id}
                        className='actions'
                        onMouseEnter={() => showTooltip('editSaleProduct' + data.id, 'Editar producto')}
                        onMouseLeave={() => hideTooltip('editSaleProduct' + data.id)}
                        onClick={() => {
                            setWorkOn(data.id)
                            showModal('editSaleProduct')
                        }}>
                        <Edit />
                    </span>
                )
            }
        ],
        [manufacturers, typesInputProduct, saleProductsStock, stock]
    );

    const columnsMovements = useMemo(
        () => [
            {
                Header: 'Movimiento N°',
                accessor: 'movement'
            },
            {
                Header: 'Artículo',
                accessor: movement => movement.item_input_product_name
            },
            {
                Header: 'Cantidad',
                accessor: 'quantity'
            },
            {
                Header: 'Serie/lote',
                disableSortBy: true,
                accessor: movement => movement.item_input_product_serial_number
            },
            {
                Header: 'Proveedor',
                accessor: movement => movement.item_supplier_name
            },
            {
                Header: 'Fabricante',
                accessor: movement => movement.item_input_product_manufacturer_name
            },
            {
                Header: 'Tipo',
                accessor: 'type'
            },
            {
                Header: 'Fecha',
                accessor: 'date',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: 'Autor',
                accessor: 'user_name'
            },
            {
                Header: 'Observaciones',
                disableSortBy: true,
                accessor: 'observation'
            }
        ],
        []
    );

    return (
        <>

            <PrincipalBar />

            <div className="d-flex flex-wrap align-items-center justify-content-between m-3">
                <div className='d-flex flex-wrap justify-content-start col-lg-10'>
                    <button
                        type="button"
                        className='btn btn-info inventoryMenu col-12 col-md-2'
                        onClick={() => showModal('manufacturers')}
                    >
                        Admin. Fabricantes
                    </button>
                    <button
                        type="button"
                        className='btn btn-info inventoryMenu my-2 my-md-0 mx-md-2 col-12 col-md-2'
                        onClick={() => showModal('suppliers')}
                    >
                        Admin. Proveedores
                    </button>
                    <button
                        type="button"
                        className='btn btn-info inventoryMenu mb-2 mb-md-0 me-md-2 col-12 col-md-2'
                        onClick={() => showModal('items')}
                    >
                        N° serie / lote
                    </button>
                    <button
                        type="button"
                        className='btn btn-info inventoryMenu col-12 col-md-2'
                        onClick={() => navigate('/ordenes-trabajo')}
                    >
                        Órdenes de Trabajo
                    </button>
                </div>
                <div className='mt-2 mt-lg-0 col-lg-2 d-flex justify-content-end'>
                    <ButtonBack destination="/inicio" text="VOLVER" />
                </div>
            </div>

            <div className="d-flex flex-wrap justify-content-between mx-3 mb-md-4">
                <div className="col-12 col-xl-6 rounded shadow mb-4 mb-md-0">
                    <div className="d-flex flex-wrap align-items-center justify-content-start m-3">
                        <h3 className="text-info">Artículos</h3>
                        {(isAdmin() || isManufacturer()) &&
                            <button className="btn btn-info btn-sm text-white ms-md-2 col-12 col-md-2"
                                onClick={() => {
                                    showModal('newInputProduct')
                                    setNewInputProduct({})
                                }}>
                                + Nuevo
                            </button>
                        }
                    </div>

                    <div className="mx-3 mb-5">
                        {loading ?
                            <Loader /> :
                            inputProductsStock?.length === 0 ?
                                <div className="text-center">
                                    <strong>No hay artículos para mostrar.</strong>
                                </div> :
                                <TableInventory
                                    columns={columnsInputProductsStock}
                                    data={inputProductsStock || []}
                                    hideFilter={'true'}
                                    hideButtons={'true'}
                                    articleSettings={'true'}
                                    pageSize={5}
                                />
                        }
                    </div>
                </div>

                <div className="col-12 col-xl-6 rounded shadow mt-5 mt-xl-0">
                    <div className="d-flex flex-wrap align-items-center justify-content-start m-3">
                        <h3 className="text-info">Productos</h3>
                        {(isAdmin() || isManufacturer()) &&
                            <button className="btn btn-info btn-sm text-white ms-md-2 col-12 col-md-2"
                                onClick={() => {
                                    showModal('newSaleProduct')
                                    setNewSaleProduct({})
                                }}>
                                + Nuevo
                            </button>
                        }
                    </div>

                    <div className="mx-3 mb-5">
                        {loading ?
                            <Loader /> :
                            saleProductsStock.length === 0 ?
                                <div className="text-center">
                                    <strong>No hay productos para mostrar.</strong>
                                </div> :
                                <TableInventory
                                    columns={columnsSaleProducts}
                                    data={saleProductsStock || []}
                                    hideFilter={'true'}
                                    hideButtons={'true'}
                                    articleSettings={'true'}
                                    pageSize={5}
                                />
                        }
                    </div>
                </div>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-start m-3 mt-5 mt-xl-3">
                <h3 className="text-info">Movimientos</h3>
                {(isAdmin() || isManufacturer()) &&
                    <button
                        className={
                            `btn
                                ${document.getElementById('newMovementSection')?.classList.contains('d-none') ?
                                'btn-info text-white' : 'btn-secondary bg-transparent text-secondary'}
                                btn-sm mb-2 mb-md-0 col-12 col-md-1 mx-md-2`}
                        onClick={() => {
                            setNewItems([{}]);
                            setNewMovement({});
                            setFail(false)
                            document.getElementById('newMovementForm').reset()
                            document.getElementById('newMovementSection')?.classList.toggle('d-none')
                        }}
                    >
                        {document.getElementById('newMovementSection')?.classList.contains('d-none') ?
                            '+ Nuevo' :
                            'Cancelar'
                        }
                    </button>
                }
            </div>

            <CreateMovement
                inputProducts={inputProducts}
                suppliers={suppliers}
                setNewMovement={setNewMovement}
                newMovement={newMovement}
                newItems={newItems}
                setNewItems={setNewItems}
                val={val}
                forceUpdate={forceUpdate}
                inputProductsStock={inputProductsStock?.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                    return 0
                })}
                setInputProductsStock={setInputProductsStock}
                items={itemsStock}
                is_serializable={is_serializable}
                setIs_serializable={setIs_serializable}
                choiceArticle={choiceArticle}
                setChoiceArticle={setChoiceArticle}
                disableButton={disableButton}
                setDisableButton={setDisableButton}
                setFail={setFail}
                fail={fail}
                setLoadingMovement={setLoadingMovement}
                setItems={setItems}
                setMovements={setMovements}
                movements={movements}
                itemsStock={itemsStock}
            />

            {loadingMovement ?
                <Loader /> :
                movements?.length === 0 ?
                    <div className="mx-3 mb-5 text-center">
                        <strong>No hay movimientos registrados.</strong>
                    </div> :
                    <div className="mx-3 mb-5">
                        <TableInventory
                            columns={columnsMovements}
                            data={movementsToDisplay || []}
                            hideFilter="true"
                            movementsFilter="true"
                            originalMovementsToDisplay={originalMovementsToDisplay || []}
                            setMovementsToDisplay={setMovementsToDisplay}
                            suppliers={suppliers}
                            manufacturers={manufacturers}
                            // processMovements={processMovements}
                            // movements={movements}
                            items={items}
                            inputProducts={inputProducts}
                        />
                    </div>
            }

            <CreateInputProduct
                willCreateManufacturer={willCreateManufacturer}
                setWillCreateManufacturer={setWillCreateManufacturer}
                willCreateTypeInputProduct={willCreateTypeInputProduct}
                setWillCreateTypeInputProduct={setWillCreateTypeInputProduct}
                manufacturers={manufacturers}
                setNewManufacturer={setNewManufacturer}
                saveNewManufacturer={saveNewManufacturer}
                newManufacturer={newManufacturer}
                newInputProduct={newInputProduct}
                setNewInputProduct={setNewInputProduct}
                handleSubmit={saveNewInputProduct}
                typesInputProduct={typesInputProduct}
                newTypeInputProduct={newTypeInputProduct}
                setNewTypeInputProduct={setNewTypeInputProduct}
                saveNewTypeInputProduct={saveNewTypeInputProduct}
                val={val}
                forceUpdate={forceUpdate}
            />

            <EditInputProduct
                inputProducts={inputProducts}
                workOn={workOn}
                newInputProduct={newInputProduct}
                setNewInputProduct={setNewInputProduct}
                val={val}
                willCreateManufacturer={willCreateManufacturer}
                setWillCreateManufacturer={setWillCreateManufacturer}
                saveNewManufacturer={saveNewManufacturer}
                newManufacturer={newManufacturer}
                setNewManufacturer={setNewManufacturer}
                forceUpdate={forceUpdate}
                manufacturers={manufacturers}
                willCreateTypeInputProduct={willCreateTypeInputProduct}
                setWillCreateTypeInputProduct={setWillCreateTypeInputProduct}
                saveNewTypeInputProduct={saveNewTypeInputProduct}
                newTypeInputProduct={newTypeInputProduct}
                setNewTypeInputProduct={setNewTypeInputProduct}
                typesInputProduct={typesInputProduct}
                handleSubmit={saveUpdatedInputProduct}
            />

            <InputProductLicenseList
                itemsStock={itemsStock}
                inputProduct={inputProducts?.filter(ip => ip.id === workOn?.id)[0] || []}
            />

            <CreateSaleProduct
                val={val}
                newSaleProduct={newSaleProduct}
                setNewSaleProduct={setNewSaleProduct}
                handleSubmit={saveNewSaleProduct}
                typesInputProduct={typesInputProduct}
                requirements={requirements}
                setRequirements={setRequirements}
                forceUpdate={forceUpdate}
                notAllowed={notAllowed}
                setNotAllowed={setNotAllowed}
            />

            <EditSaleProduct
                saleProducts={saleProducts}
                workOn={workOn}
                val={val}
                typesInputProduct={typesInputProduct}
                newRequirement={newRequirement}
                setNewRequirement={setNewRequirement}
                newSaleProduct={newSaleProduct}
                setNewSaleProduct={setNewSaleProduct}
                requirements={requirements}
                setRequirements={setRequirements}
                notAllowed={notAllowed}
                setNotAllowed={setNotAllowed}
                forceUpdate={forceUpdate}
                handleSubmit={saveUpdatedSaleProduct}
                articles={articles}
                setEditArticle={setEditArticle}
            />

            <InfoSaleProduct
                workOn={workOn}
                saleProducts={saleProducts || []}
                typeInputProduct={typesInputProduct}
            />

            <AdminManufacturer
                manufacturers={manufacturers}
                newManufacturer={newManufacturer}
                setNewManufacturer={setNewManufacturer}
                val={val}
                forceUpdate={forceUpdate}
                handleSubmit={saveNewManufacturer}
                workOn={workOn}
                setWorkOn={setWorkOn}
            />

            <AdminSupplier
                suppliers={suppliers}
                newSupplier={newSupplier}
                setNewSupplier={setNewSupplier}
                val={val}
                forceUpdate={forceUpdate}
                handleSubmit={saveNewSupplier}
                workOn={workOn}
                setWorkOn={setWorkOn}
            />

            <AdminItems
                inputProducts={inputProducts}
                items={items}
            />

        </>
    )

}