import Flag from "react-world-flags"

import Loader from "../../../AppComponents/Commons/Loader"

import { hideModal, toggleAccordion } from "../../../Utilities/General/BootstrapApi"

export default function InputProductsByDistCenter({
    distCenters,
    countries,
    preAssembled
}) {

    const setStock = preAssembledInDistCenter => {
        let ipInDc = []
        const movementdetails = preAssembledInDistCenter
            .map(item => item.movementdetail_set)
            .flat(1)

        movementdetails.forEach(md => {
            let detail = movementdetails.filter(item => item.item_input_product_name === md.item_input_product_name)
            let parents = preAssembled.filter(item => item.movementdetail_set.some(item => item.item_input_product_name === md.item_input_product_name))
            let ip_found = ipInDc.some(item => item.article === md.item_input_product_name)
            if (ipInDc.length === 0 || !ip_found) {
                ipInDc.push({
                    article: md.item_input_product_name,
                    count: 1,
                    sku: md.item_input_product_sku,
                    detail,
                    parents
                })
            } else {
                ipInDc = [
                    ...ipInDc.filter(item => item.article !== md.item_input_product_name),
                    {
                        article: md.item_input_product_name,
                        count: ipInDc.find(item => item.article === md.item_input_product_name).count + 1,
                        sku: md.item_input_product_sku,
                        detail,
                        parents
                    }
                ]
            }
        })

        return ipInDc
    }

    return (
        <div className="modal fade" id="ipBydc">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info">
                            Explorar distribuidores
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => hideModal('ipBydc')}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div id="distCentersAccordion">
                            {preAssembled?.length === 0 ?
                                <div>
                                    <Loader />
                                    <strong className="d-block text-center">Cargando...</strong>
                                </div> :
                                <>
                                    {distCenters?.map(dc => {
                                        return (
                                            <div key={dc.id}>
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="distCenterAccordionButton"
                                                        type="button"
                                                        onClick={() => toggleAccordion(dc.name, 'distCentersAccordion')}
                                                    >
                                                        {dc.name} - {countries.find(c => c.iso_code === dc.country_iso_origin).name} <Flag code={dc.country_iso_origin} width="20" className="ms-1" />
                                                    </button>
                                                </h2>
                                                <div id={"collapse-" + dc.name} className="accordion-collapse collapse">
                                                    <div className="accordion-body">
                                                        <table className="table text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>Artículo</th>
                                                                    <th>SKU</th>
                                                                    <th>Stock</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {preAssembled.filter(pa => pa.distribution_center === dc.id).length === 0 ?
                                                                    <tr>
                                                                        <td colSpan={3} className="fw-bold text-center">
                                                                            No hay artículos en este distribuidor.
                                                                        </td>
                                                                    </tr> :
                                                                    <>
                                                                        {setStock(preAssembled.filter(pa => pa.distribution_center === dc.id))
                                                                            .map(item => {
                                                                                return (
                                                                                    <tr key={item.article}>
                                                                                        <td>{item.article}</td>
                                                                                        <td>{item.sku}</td>
                                                                                        <td>{item.count}</td>
                                                                                        <td className="detailColumn" id={"detailParent" + item.article + dc.id}>
                                                                                            <div className="accordion-header">
                                                                                                <span
                                                                                                    className="cursor-pointer"
                                                                                                    onClick={() => toggleAccordion(item.article + dc.id, 'detailParent' + item.article + dc.id)}
                                                                                                >
                                                                                                    Detalle
                                                                                                </span>
                                                                                            </div>
                                                                                            <div id={"collapse-" + item.article + dc.id} className="accordion-collapse collapse">
                                                                                                <table className="table text-center accordion-body">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>N° serie / lote</th>
                                                                                                            <th>Asignado a</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {item.detail.map(det => {
                                                                                                            const parent = item.parents.find(p => p.movementdetail_set.some(md => md.id === det.id))
                                                                                                            return (
                                                                                                                <tr key={det.id}>
                                                                                                                    <td>{det.item_input_product_serial_number}</td>
                                                                                                                    <td>
                                                                                                                        <ul className="list-group">
                                                                                                                            <li className="list-group-item">
                                                                                                                                <strong>SKU: </strong>{parent.sale_product_sku}
                                                                                                                            </li>
                                                                                                                            <li className="list-group-item">
                                                                                                                                <strong>N° serie: </strong>{parent.serial_number}
                                                                                                                            </li>
                                                                                                                            <li className="list-group-item">
                                                                                                                                <strong>Licencia: </strong>{parent.code !== null ? parent.code : 'Sin licencia'}
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <div
                                                                                                    className="cursor-pointer"
                                                                                                    onClick={() => toggleAccordion(item.article + dc.id, 'detailParent' + item.article + dc.id)}
                                                                                                >
                                                                                                    &#9650;
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                    </>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => hideModal('ipBydc')}
                        >
                            CERRAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}