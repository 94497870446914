import React, { useContext, useEffect, useState } from "react";
import { FiCopy } from 'react-icons/fi'
import { hideModal } from "../../Utilities/General/BootstrapApi"
import { copy } from '../../Utilities/General/Clipboard'
import StockOrderService from '../../Services/Stock/StockOrderService'
import Loader from '../../AppComponents/Commons/Loader';

export default function ShowPreassembledInputProducts({
    saleProductItem
}) {
    const [saleProductItemMovementsList, setSaleProductItemMovementsList] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true)
        StockOrderService.getSaleProductItemMovementsListById(saleProductItem?.id).then(data => {
            setSaleProductItemMovementsList(data)
            setLoading(false)
        })
    }, [saleProductItem]);
    // const product = saleProducts.find(sp => sp.id === preassembled.sale_product)?.alias ||
    //     saleProducts.find(sp => sp.id === preassembled.sale_product)?.name

    return (
        <div className="modal fade" id="showPAip">
            <div className="modal-dialog modal-xl border border-dark rounded shadow">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-info">
                            {/* Ver insumos asignados de {product} */}
                            Insumos Asignados
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => hideModal('showPAip')}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {loading ?
                            <div className='mt-5'>
                                <Loader />
                                <strong className='d-block text-center'>Cargando...</strong>
                            </div> 
                        :
                            <>
                                <h6 className="text-info">Producto pre-armado</h6>
                                <table className="table table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>N° de serie</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {saleProductItemMovementsList?.sale_product_sku}
                                                <FiCopy
                                                    id="paSkuTT"
                                                    className="ms-2 only-cursor-pointer"
                                                    onClick={() => copy('paSkuTT', saleProductItemMovementsList?.sale_product_sku)}
                                                />
                                            </td>
                                            <td>
                                                {saleProductItemMovementsList?.serial_number}
                                                <FiCopy
                                                    id="paSerialTT"
                                                    className="ms-2 only-cursor-pointer"
                                                    onClick={() => copy('paSerialTT', saleProductItemMovementsList?.serial_number)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="text-info mt-4">Insumos</h6>
                                <table className="table table-hover text-center">
                                    <thead>
                                        <th>Nombre</th>
                                        <th>SKU</th>
                                        <th>N° de serie / lote</th>
                                        <th>Proveedor</th>
                                        <th>Fabricante</th>
                                    </thead>
                                    <tbody>
                                        {saleProductItemMovementsList?.movementdetail_set.map(md => {
                                            return (
                                                <tr key={md.id}>
                                                    <td>
                                                        {md.item_input_product_name}
                                                        <FiCopy
                                                            id={"paNameTT" + md.id}
                                                            className="ms-2 only-cursor-pointer"
                                                            onClick={() => copy('paNameTT' + md.id, md.item_input_product_name)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {md.item_input_product_sku}
                                                        <FiCopy
                                                            id={"paIpSkuTT" + md.id}
                                                            className="ms-2 only-cursor-pointer"
                                                            onClick={() => copy('paIpSkuTT' + md.id, md.item_input_product_sku)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {md.item_input_product_serial_number}
                                                        <FiCopy
                                                            id={"paIpSerialTT" + md.id}
                                                            className="ms-2 only-cursor-pointer"
                                                            onClick={() => copy('paIpSerialTT' + md.id, md.item_input_product_serial_number)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {md.item_supplier_name}
                                                        <FiCopy
                                                            id={"paSupplierTT" + md.id}
                                                            className="ms-2 only-cursor-pointer"
                                                            onClick={() => copy('paSupplierTT' + md.id, md.item_supplier_name)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {md.item_input_product_manufacturer_name}
                                                        <FiCopy
                                                            id={"paManufacturerTT" + md.id}
                                                            className="ms-2 only-cursor-pointer"
                                                            onClick={() => copy('paManufacturerTT' + md.id, md.item_input_product_manufacturer_name)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => hideModal('showPAip')}
                        >
                            CERRAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}