import React, { useMemo } from 'react';

import { hideModal } from '../../../Utilities/General/BootstrapApi';
import TableInventory from './TableInventory';

export default function InputProductLicenseList({
    itemsStock,
    inputProduct
}) {

    const ipLicenseStock = useMemo(
        () => [
            {
                Header: ' ',
                accessor: 'serial_number'
            }
        ],
        []
    )

    return (
        <div className="modal fade" id="inputProductLicenseList" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info" id="staticBackdropLabel">
                            Números de serie de {inputProduct?.name}
                        </h3>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                            hideModal('inputProductLicenseList')
                        }}></button>
                    </div>
                    <div className="modal-body">
                        <TableInventory
                            columns={ipLicenseStock}
                            data={itemsStock?.filter(is => is.input_product === inputProduct?.id && is.stock > 0) || []}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            hideModal('inputProductLicenseList')
                        }}>
                            CERRAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}