import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

function GoogleMaps({
  locationFound,
  locationConfirmed,
  confirmLocation,
  mapCenter,
  google
}) {

  return (
    <div className='googleMaps'>
      <div className="mb-3 d-flex flex-wrap justify-content-around">
        {
          locationFound ?
            !locationConfirmed ?
              <button
                type="button"
                className="btn btn-success text-white d-block mx-2 col-sm-6 col-lg-3 my-1"
                onClick={() => confirmLocation()}>
                CONFIRMAR UBICACIÓN
              </button>
              :
              <button
                type="button"
                className="btn btn-danger text-white d-block mx-2 col-sm-6 col-lg-3 my-1"
                onClick={() => confirmLocation()}>
                CAMBIAR UBICACIÓN
              </button>
            :
            <button
              type="button"
              className="btn btn-success text-white d-block mx-2 col-sm-6 col-lg-3 my-1"
              disabled
            >
              CONFIRMAR UBICACIÓN
            </button>
        }
      </div>

      <Map
        google={google}
        initialCenter={{
          lat: !isNaN(mapCenter.lat) ? mapCenter.lat : 0,
          lng: !isNaN(mapCenter.lng) ? mapCenter.lng : 0
        }}
        center={{
          lat: !isNaN(mapCenter.lat) ? mapCenter.lat : 0,
          lng: !isNaN(mapCenter.lng) ? mapCenter.lng : 0
        }}
      >
        <Marker
          position={{
            lat: mapCenter.lat,
            lng: mapCenter.lng
          }}
        />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDCbntfVs_WfIFKMCnOKnrWDHzkhtAgXOY",
})(GoogleMaps);
