import React from 'react';

export default function Watch() {

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_973" data-name="Grupo 973" transform="translate(-601 -378)">
          <g id="Grupo_751" data-name="Grupo 751" transform="translate(601 378)">
            <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#00b0ff" />
          </g>
          <g id="ver" transform="translate(606.37 303.556)">
            <g id="Grupo_966" data-name="Grupo 966" transform="translate(0 82.176)">
              <g id="Grupo_965" data-name="Grupo 965" transform="translate(0 0)">
                <path id="Trazado_591" data-name="Trazado 591" d="M13.1,86c-.721-.9-3.3-3.826-6.475-3.826S.876,85.1.155,86a.708.708,0,0,0,0,.883c.721.9,3.3,3.826,6.475,3.826s5.754-2.929,6.475-3.826A.708.708,0,0,0,13.1,86ZM6.63,89.534a3.09,3.09,0,1,1,3.09-3.09A3.09,3.09,0,0,1,6.63,89.534Z" transform="translate(0 -82.176)" fill="#fff" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );

}
