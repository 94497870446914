import { setDistCentersStock } from "./SetDistCentersStock"

export const checkDcStockByCountry = (order,
    distCenters, stockOrders, countries) => {

    let iso = countries.find(c => c.id === order?.ordershippingdata.address_country)?.iso_code
    let dcStock = setDistCentersStock(distCenters, stockOrders)
    let counter = 0

    distCenters
        .filter(dc => dc.country_iso_origin === iso)
        .forEach(dc => {
            dcStock.forEach(dcs => {
                if (dc.id === dcs.dist) {
                    counter = counter + dcs.stock.length
                }
            })
        })

    return {
        result: counter > 0 ? true : false,
        country: countries.find(c => c.id === order?.ordershippingdata.address_country)?.name
    }
}