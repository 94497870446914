import React, { useMemo, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { toast } from 'react-toastify';

import Loader from '../../../AppComponents/Commons/Loader';

import { getPerson, isAdmin, isCustomerSuccess, isManufacturer } from '../../../Utilities/Auth/AuthPermissions';

export default function PreAssembledOrderAvailable({
    saleProducts,
    stockOrdersAvailable,
    setWorkOnParent,
    workOnStockOrder,
    setWorkOnStockOrder,
    typesInputProduct,
    items,
    itemsStock,
    newMovement,
    setNewMovement,
    saveNewMovement,
    inputProducts,
    saleProductItemMovementsList
}) {

    const [searchString, setSearchString] = useState(null)
    const [currentFilter, setCurrentFilter] = useState(null)

    let allReqs = []
    if (workOnStockOrder !== null) {
        let [saleProduct] = saleProducts.filter(sp => sp.id === workOnStockOrder.sale_product)
        for (let i = 0; i < saleProduct.saleproductrequirement_set.length; i++) {
            for (let j = 0; j < saleProduct.saleproductrequirement_set[i].quantity; j++) {
                allReqs.push(saleProduct.saleproductrequirement_set[i])
            }
        }
    }

    const handleChange = (index, e, tip) => {
        setSearchString(null)
        if (e.target.value === 'search') {
            handleSearch(index)
            return
        }
        setNewMovement({
            ...newMovement,
            type: 'egreso',
            user_name: getPerson(),
            observation: 'Egreso por asignación.',
            movementdetail_set: [
                ...newMovement.movementdetail_set.filter(
                    elem => elem.index !== index),
                {
                    quantity: -1,
                    item: parseInt(e.target.value),
                    type_input_product: tip,
                    sale_product_item: workOnStockOrder.id,
                    index: index
                }
            ]
        })
    }

    const handleSearch = index => {
        let inputs = document.getElementsByClassName('searchInputs')
        for (let i = 0; i < inputs.length; i++) {
            let input = document.getElementById('searchInput' + i)
            if (i === index) {
                input.classList.remove('d-none')
                input.focus()
            } else {
                input.classList.add('d-none')
            }
        }
    }

    useEffect(() => {
        let duplicated = false
        let counter
        for (let i = 0; i < newMovement.movementdetail_set.length; i++) {
            counter = 0
            let [currentItem] = items.filter(item => item.id === newMovement.movementdetail_set[i].item)
            let [currentInputProduct] = inputProducts.filter(ip => ip.id === currentItem?.input_product)
            for (let j = 0; j < newMovement.movementdetail_set.length; j++) {
                if (newMovement.movementdetail_set[i].item === newMovement.movementdetail_set[j].item) {
                    counter++
                }
            }
            if (counter > 1 && currentInputProduct.is_serializable) {
                duplicated = true
            }
        }
        if (duplicated) {
            document.getElementById('duplicatedMessage')?.classList.remove('d-none')
        } else {
            document.getElementById('duplicatedMessage')?.classList.add('d-none')
        }
    }, [newMovement])

    const checkAssigned = () => {
        // comprobar que todos los items esten asignados
        let allAssigned = true
        if (newMovement.movementdetail_set.length === 0) {
            allAssigned = false
        }
        for (let i = 0; i < newMovement.movementdetail_set.length; i++) {
            if (isNaN(newMovement.movementdetail_set[i].item) ||
                newMovement.movementdetail_set.length < allReqs.length) {
                allAssigned = false
            }
        }
        if (!allAssigned) {
            toast.error('Existen items sin asignar', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
        }
        return allAssigned
    }

    const checkStock = () => {
        // comprobar que haya stock suficiente en todos los items
        let enoughStock = true

        const count = {};
        for (const element of newMovement.movementdetail_set) {
            if (count[element.item]) {
                count[element.item] += 1;
            } else {
                count[element.item] = 1;
            }
        }

        let itemsOccurences = []
        Object.keys(count).forEach(key => {
            itemsOccurences.push({
                id: parseInt(key)
            })
        })
        for (let i = 0; i < itemsOccurences.length; i++) {
            itemsOccurences[i].count = Object.values(count)[i]
        }

        itemsOccurences.forEach(occ => {
            let [currentItemStock] = itemsStock.filter(is => is.id === occ.id)
            let [currentInputProduct] = inputProducts.filter(ip => ip.id === currentItemStock.input_product)
            if (occ.count > currentItemStock.stock) {
                if (!currentInputProduct.is_serializable) {
                    toast.error('Stock insuficiente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                    })
                }
                enoughStock = false
            }
        })

        return enoughStock
    }


    return (
        <>
            <div className='border border-muted rounded md-overflow-scroll'>
                <table className='table table-hover text-center'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Productos</th>
                            <th>Nros. de serie</th>
                            <th>Licencia</th>
                            <th>Creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {saleProductItemMovementsList?.length === 0 ?
                            <tr>
                                <td colSpan={5}>
                                    <Loader />
                                    <strong className="d-block text-center">Cargando...</strong>
                                </td>
                            </tr> :
                            <>
                                {stockOrdersAvailable?.map(so => {
                                    return (
                                        <tr key={so.id}>
                                            <td>
                                                {so.id}
                                            </td>
                                            <td>
                                                {so.ordersaleproductitem_set.sort((a, b) => {
                                                    if (a.sale_product_item.sale_product_sku > b.sale_product_item.sale_product_sku) return 1
                                                    if (a.sale_product_item.sale_product_sku < b.sale_product_item.sale_product_sku) return -1
                                                    return 0
                                                }).map(ospi => {
                                                    return saleProducts.map(sp => {
                                                        if (ospi.sale_product_item.sale_product === sp.id &&
                                                            ospi.sale_product_item.status === 'created') {
                                                            return (
                                                                <span key={sp.id} className='d-block'>
                                                                    {sp.alias !== null ? sp.alias : sp.name}
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                })}
                                            </td>
                                            <td>
                                                {so.ordersaleproductitem_set.map(ospi => {
                                                    if (ospi.sale_product_item.status === 'created') {
                                                        if (isCustomerSuccess()) return (
                                                            <span key={ospi.id} className="d-block">
                                                                {ospi.sale_product_item.serial_number}
                                                            </span>
                                                        )
                                                        return (
                                                            <span key={ospi.id} className={`d-block ${ospi.sale_product_item.serial_number === workOnStockOrder?.serial_number ? 'bg-info rounded text-white' : 'cursor-pointer'}`}
                                                                onClick={() => {
                                                                    let form = document.getElementById('assignementForm')
                                                                    if (form !== null) {
                                                                        form.reset()
                                                                    }
                                                                    setNewMovement({
                                                                        movementdetail_set: []
                                                                    })
                                                                    setWorkOnParent(so)
                                                                    setWorkOnStockOrder(ospi.sale_product_item)
                                                                }}>
                                                                {ospi.sale_product_item.serial_number}
                                                            </span>
                                                        )
                                                    }
                                                })}
                                            </td>
                                            <td>
                                                {so.ordersaleproductitem_set.map(ospi => {
                                                    if (ospi.sale_product_item.status === 'created') {
                                                        return (
                                                            <span key={ospi.id} className='d-block'>
                                                                {ospi.sale_product_item.code}
                                                            </span>
                                                        )
                                                    }
                                                })}
                                            </td>
                                            <td>
                                                {format(new Date(so.timestamp), "dd/MM/yyyy HH:mm")}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {(isManufacturer() || isAdmin()) &&
                <div className='border border-muted rounded my-5 md-overflow-scroll'>
                    {workOnStockOrder !== null ?
                        <div className='col-12 col-lg-6 py-3 mx-auto'>
                            <h5 className='text-info text-center'>
                                Licencia {workOnStockOrder.serial_number} - {saleProducts.filter(sp => sp.id === workOnStockOrder.sale_product)[0].alias !== null ?
                                    saleProducts.filter(sp => sp.id === workOnStockOrder.sale_product)[0].alias : saleProducts.filter(sp => sp.id === workOnStockOrder.sale_product)[0].name}
                            </h5>
                            <div className='mt-3'>
                                <form id='assignementForm'>
                                    <table className='table table-hover text-center'>
                                        <thead>
                                            <tr>
                                                <th>Item n°</th>
                                                <th>Tipo</th>
                                                <th>Asignar n° serie / lote</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allReqs.map(
                                                (req, reqIndex) => (
                                                    <tr key={reqIndex}>
                                                        <td>{reqIndex + 1}</td>
                                                        <td>
                                                            {typesInputProduct.filter(tip => tip.id === req.type_input_product)[0].name}
                                                        </td>
                                                        <td>
                                                            <select
                                                                id={'select' + reqIndex}
                                                                className='form-select selectInput'
                                                                onFocus={() => {
                                                                    setCurrentFilter(reqIndex)
                                                                }}
                                                                onChange={e => handleChange(reqIndex, e, typesInputProduct.filter(tip => tip.id === req.type_input_product)[0].id)}
                                                            >
                                                                <option value="">Seleccione</option>
                                                                <option value="search">Buscar</option>
                                                                {items.sort((a, b) => a.id - b.id).map(item => {
                                                                    let display = `${item.serial_number} - ${inputProducts.filter(ip => ip.id === item.input_product)[0].name}`
                                                                    return inputProducts.map(ip => {
                                                                        if (
                                                                            item.input_product === ip.id &&
                                                                            ip.type_input_product === req.type_input_product &&
                                                                            itemsStock.filter(is => is.id === item.id)[0].stock > 0 &&
                                                                            (
                                                                                searchString === null
                                                                                ||
                                                                                (display.toLowerCase().includes(searchString?.toLowerCase()) || currentFilter !== reqIndex)
                                                                            )
                                                                        ) {
                                                                            return (
                                                                                <option key={item.id} value={item.id}>
                                                                                    {display}
                                                                                </option>
                                                                            )
                                                                        }
                                                                    })
                                                                })}
                                                            </select>
                                                            <input
                                                                id={'searchInput' + reqIndex}
                                                                type='text'
                                                                className='form-control mt-1 d-none searchInputs'
                                                                placeholder='Escriba aquí para buscar...'
                                                                onChange={e => {
                                                                    setSearchString(e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </form>
                                <small id='duplicatedMessage' className='text-danger d-none'>
                                    * No se puede asignar dos veces un mismo artículo serializable.
                                </small>
                            </div>
                            <div className='text-center mt-3'>
                                <button
                                    type='button'
                                    className='btn btn-info bg-white text-info white-custom-button'
                                    onClick={() => {
                                        setWorkOnStockOrder(null)
                                        setNewMovement({
                                            movementdetail_set: []
                                        })
                                    }}
                                >
                                    CANCELAR
                                </button>
                                <button
                                    id='assignement_btn'
                                    type='button'
                                    className='btn btn-info text-white ms-2'
                                    onClick={() => {
                                        if (!checkAssigned()) {
                                            return
                                        }
                                        if (!checkStock()) {
                                            return
                                        }
                                        setSearchString(null)
                                        if (allReqs.length === 1) {
                                            saveNewMovement(true)
                                            return
                                        }
                                        saveNewMovement()
                                    }}
                                >
                                    GUARDAR
                                </button>
                            </div>
                        </div> :
                        <>
                            {saleProductItemMovementsList?.length > 0 &&
                                <strong className='d-block my-4 text-center'>
                                    Haga click en un número de serie para asignar insumos
                                </strong>
                            }
                        </>
                    }
                </div>
            }
        </>
    )
}