import React from 'react';

export default function BillingCard() {

    return (
        <div>
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path style={{
                    fill: '#1de3b0'
                }} d="M24,23.51a.86.86,0,0,1-.85.49H.86a1.22,1.22,0,0,1-.33,0,.72.72,0,0,1,.15-1.41h.49c.2,0,.26-.07.24-.25a1.08,1.08,0,0,1,0-.18V15c0-.62.26-.88.89-.88H4.8a.77.77,0,0,1,.89.89V22.2c0,.36,0,.36.36.36s.47,0,.71,0,.31-.08.28-.3a1.47,1.47,0,0,1,0-.21V12.2a1.17,1.17,0,0,1,.07-.49.67.67,0,0,1,.66-.44h2.85a.7.7,0,0,1,.7.68V22.23c0,.33,0,.33.32.33h.86c.14,0,.21,0,.2-.19a1.47,1.47,0,0,1,0-.21V9.35c0-.66.26-.92.92-.92H16c.7,0,1,.27,1,1V22.2c0,.36,0,.36.35.36h.8c.14,0,.2-.06.2-.2a1.76,1.76,0,0,1,0-.23V6.63a2.29,2.29,0,0,1,0-.37.72.72,0,0,1,.73-.64c.93,0,1.87,0,2.81,0a.72.72,0,0,1,.73.8V22.24c0,.32,0,.32.3.32h.24A.79.79,0,0,1,24,23Zm-4.21-8.69v4.9c0,.85,0,1.71,0,2.57,0,.2.05.29.26.27s.49,0,.73,0,.36,0,.36-.38V7.32c0-.17-.05-.25-.23-.24h-.79c-.32,0-.33,0-.33.32Zm-4.28,1.41v-6c0-.33,0-.33-.35-.33h-.63c-.37,0-.37,0-.37.37v12c0,.19.05.27.25.26h.75c.35,0,.35,0,.35-.34ZM9.86,17.66c0-1.56,0-3.11,0-4.66,0-.22-.06-.31-.29-.3s-.5,0-.75,0-.31,0-.31.32v9.2c0,.33,0,.33.31.33h.73c.31,0,.31,0,.31-.32Zm-5.64,1.4c0-1.06,0-2.13,0-3.2,0-.23-.06-.33-.3-.31s-.49,0-.73,0-.33,0-.33.33v5.71c0,.25,0,.5,0,.75s.06.22.22.22a8,8,0,0,1,.85,0c.22,0,.29-.06.29-.29C4.22,21.2,4.22,20.13,4.22,19.06Z" />
                <path style={{
                    fill: '#1de3b0'
                }} d="M22.12,0a.84.84,0,0,1,.49.88c0,.87,0,1.74,0,2.62a.75.75,0,0,1-.69.75.72.72,0,0,1-.75-.67c0-.18,0-.36,0-.54V2.5a.45.45,0,0,0-.25.16,27.28,27.28,0,0,1-6.23,3.76,41.55,41.55,0,0,1-10.1,3c-1.21.2-2.43.36-3.66.5A.74.74,0,1,1,.72,8.43l.52,0A43,43,0,0,0,14.05,5.1a25.84,25.84,0,0,0,5.72-3.41L20,1.52s.06,0,.07-.1h-.85c-.47,0-.73-.18-.82-.57s.08-.64.49-.85Z" />
            </svg>
        </div>
    );

}
