import React from "react";

export default function CreateTypeInputProduct({
    setWillCreateTypeInputProduct,
    cancelButton,
    handleSubmit,
    newTypeInputProduct,
    setNewTypeInputProduct,
    val,
    forceUpdate
}) {

    const handleChange = e => {
        setNewTypeInputProduct({
            ...newTypeInputProduct,
            [e.target.name]: e.target.value
        })
    }

    return (

        <>

            {newTypeInputProduct !== undefined &&

                <form id="newTypeForm" className="border border-muted rounded m-3">
                    <div className="text-center bg-info p-1">
                        <p className="text-white mb-1">Nuevo tipo de artículo</p>
                    </div>
                    <div className="p-3">
                        <div className="form-group mb-2">
                            <label htmlFor="nameType">Nombre</label>
                            <input type="text" name="name" className="form-control" onChange={e => handleChange(e)} />
                            {val.current.message('nameType', newTypeInputProduct.name, 'required|max:200|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&/()=<>+-]', { className: 'text-danger' })}
                        </div>
                        <div className="d-flex justify-content-around">
                            <button type="button" className="btn btn-info btn-sm text-white"
                                onClick={() => {
                                    if (!val.current.fieldValid('nameType')) {
                                        val.current.showMessages()
                                        forceUpdate(1)
                                    } else {
                                        handleSubmit()
                                    }
                                }}>
                                GUARDAR
                            </button>
                            {cancelButton &&
                                <input type="button" value="CANCELAR" className="btn btn-info btn-sm text-info bg-transparent"
                                    onClick={() => {
                                        setWillCreateTypeInputProduct(false)
                                    }}
                                />
                            }
                        </div>
                    </div>
                </form>

            }

        </>

    )

}