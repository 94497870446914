import { useMemo, useState } from "react";

import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
import Loader from "../../AppComponents/Commons/Loader";
import FirstTitle from "../../AppComponents/Commons/FirstTitle";
import TableBackPaginated from "../../AppComponents/Orders/TableBackPaginated";
import ButtonBack from '../../AppComponents/Commons/ButtonBack'
import useLicenses from '../../Hooks/CS/useLicenses'
import ViewLicense from "./Components/ViewLicense";

import { showModal } from "../../Utilities/General/BootstrapApi";

export default function CSPanel() {

    const {
        licenses,
        setLicenses,
        getLicenseData,
        licenseData,
        setLicenseData
    } = useLicenses()

    const [serial, setSerial] = useState(null)

    const handleClick = serial => {
        const workOn = licenses.find(lic => lic.serial_number === serial)
        if (workOn.uniqueid === null) {
            setLicenseData(null)
        } else {
            setSerial(workOn)
            getLicenseData(workOn.uniqueid)
            showModal('viewLicenseModal')
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Número de serie',
                accessor: 'serial_number',
                Cell: ({ value }) => {
                    let workOn = licenses.find(lic => lic.serial_number === value).uniqueid
                    if (workOn === null) return value
                    return (
                        <span
                            className="cursor-pointer"
                            onClick={() => handleClick(value)}
                        >
                            {value}
                        </span>
                    )
                }
            },
            {
                Header: 'Cliente',
                accessor: data => data.client_name !== undefined ? data.client_name : ''
            },
            {
                Header: 'Código de activación',
                accessor: 'code_code'
            }
        ],
        [licenses]
    )

    return (

        <>

            <PrincipalBar />

            <div className="mx-3 mb-5">

                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
                    <FirstTitle text="Panel Customer Success" />
                    <ButtonBack destination="/inicio" text="VOLVER" />
                </div>

                {licenses.length === 0 ?
                    <div className='mt-5'>
                        <Loader />
                        <strong className='d-block text-center'>Cargando...</strong>
                    </div> :
                    <TableBackPaginated
                        columns={columns}
                        data={licenses}
                        setData={setLicenses}
                        fromCSPanel="true"
                    />
                }
            </div>

            <ViewLicense
                serial={serial}
                setSerial={setSerial}
                licenseData={licenseData}
            />

        </>

    )
}