import React, { useState, useEffect, useMemo, useContext, useCallback, useRef } from 'react';
import { FcApproval, FcFullTrash, FcUndo } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { format } from 'date-fns'
import swal from 'sweetalert';
import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import TableBackPaginated from './Components/TableBackPaginated';
import Loader from '../../AppComponents/Commons/Loader';
import FirstTitle from '../../AppComponents/Commons/FirstTitle';

import OrderService from '../../Services/Licenses/OrderService';
import { hideTooltip, showTooltip, hideModal, showModal } from '../../Utilities/General/BootstrapApi';
import PaymentInstallmentContext from '../../Contexts/PaymentInstallmentContext';

export default function ProofPayment() {
    const {
        paymentsInstallments,
        setPaymentsInstallments,
        setNextPage,
        setPreviousPage,
        setTotalRegisters
    } = useContext(PaymentInstallmentContext)
    useEffect(() => {
        console.log(paymentsInstallments);
    }, [paymentsInstallments]);
    const [loading, setLoading] = useState(true);
    const [newFile, setNewFile] = useState()
        const [initialState, setInitialState] = useState({
        sortBy: [
            {
                id: 'id',
                desc: true
            }
        ]
    })

    useEffect(() => {
        OrderService.getPaymentInstallment({
            ordering: '-id',
            limit: 10
        }).then(data => {
            if (!data) return
            setTotalRegisters(data.count)
            setNextPage(data.next)
            setPreviousPage(data.previous)
            setPaymentsInstallments(data.results)
            setLoading(false)
        })
    }, [])

    const deleteProofOfPaymentUrl = paymentInstallmentId => {
        swal({
          title: "¿Borrar el comprobante de pago?",
          icon: 'warning',
          dangerMode: true,
          buttons: ['Cancelar', 'Confirmar']
        }).then(confirm => {
          if (confirm) {
            let [payment_installment] = paymentsInstallments.filter(item => item.id === paymentInstallmentId)
            payment_installment.proof_of_payment_url = null
            payment_installment.payment_date = null
            payment_installment.payment_method = null
            OrderService.updatePaymentInstallmentData(paymentInstallmentId, payment_installment)
              .then(data => {
                setPaymentsInstallments([
                  ...paymentsInstallments.filter(item => item.id !== payment_installment.id),
                  {
                    ...data
                  }
                ])
                toast.success(`Comprobante de pago eliminado correctamente`, {
                  position: 'bottom-left',
                  hideProgressBar: true,
                  className: 'success-toast'
                })
              })
          }
        })
      }

    const handleFile = (e, id) => {
        const [file] = e.target.files
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const maxSize = 10 * 1024 * 1024; // 2MB

        if (file.size > maxSize) {
            toast.error('El archivo es demasiado grande. El tamaño máximo permitido es de 10MB.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            });
            return; // Detiene la ejecución si el archivo es demasiado grande
        }
        Object.defineProperties(file, {
            name: {
                value: `comprobante-de-pago-${id}.` + fileExtension,
            }
        })
        setNewFile(file)
        handleFileSelects(id)
    }

    const handleFileSelects = (id) => {
        console.log(paymentsInstallments)
        console.log("dentro de funcion " + id)
        paymentsInstallments.sort((a, b) => b.id - a.id).forEach(paymentInstallment => {
            console.log("dentro de foreach " + id)
            if (paymentInstallment.id === id) {
                console.log("dentro de if " + id)
                document.getElementById('proofOfPaymentSection' + id).classList.remove('d-none')
            } else {
                let input = document.getElementById('fileInput' + paymentInstallment.id)
                if (input !== null) if (input.value !== '') input.value = ''
                document.getElementById('proofOfPaymentSection' + paymentInstallment.id)?.classList.add('d-none')
            }
        })
    };

    const saveFile = id => {
        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
        const type = newFile.type.split('/')[1]
        const payment_date = document.getElementById('dateInput' + id).value
        const payment_method= document.getElementById('paymentMethodInput' + id).value
        console.log(newFile.type)
        if (!allowedExtensions.includes(type)) {
            toast.error('Formato incorrecto. La extensión del archivo debe ser .pdf .jpg . jpeg o png', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
            return
        }
        console.log(payment_date)
        if (payment_date === 'dd/mm/aaaa') {
            toast.error('Debe seleccionar una fecha de pago', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
            return
        }
        if (payment_method === '') {
            toast.error('Debe seleccionar un método de pago', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
            return
        }


        toast.warning(`Enviando archivo. Esto puede tardar un momento...`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'warning-toast',
            autoClose: false
        })

        OrderService.saveProofOfPayment(newFile)
            .then(() => {
                let payment_installment = paymentsInstallments.find(pi => pi.id === id)

                if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                    payment_installment.proof_of_payment_url = `https://ordenes-compra.s3.amazonaws.com/proof-of-payment/${newFile.name}`
                } else {
                    payment_installment.proof_of_payment_url = `https://ordenes-compra.s3.amazonaws.com/proof-of-payment-testing/${newFile.name}`
                }
                payment_installment.payment_date = payment_date
                payment_installment.payment_method = payment_method
                OrderService.updatePaymentInstallmentData(id, payment_installment)
                    .then(data => {
                        setPaymentsInstallments([
                            ...paymentsInstallments.filter(item => item.id !== data.id),
                            {
                                ...data
                            }
                        ])
                        toast.dismiss()
                        toast.success(`Comprobante de pago guardado correctamente`, {
                            position: 'bottom-left',
                            hideProgressBar: true,
                            className: 'success-toast'
                        })
                        paymentsInstallments.sort((a, b) => b.id - a.id).forEach(paymentInstallment => {
                            let input = document.getElementById('fileInput' + paymentInstallment.id)
                            if (input !== null) if (input.value !== '') input.value = ''
                            document.getElementById('proofOfPaymentSection' + paymentInstallment.id)?.classList.add('d-none')
                        })

                    })
            }).catch(() => document.getElementById('proofOfPaymentSection' + id).classList.remove('d-none'))
        //document.getElementById('proofOfPaymentSection' + paymentInstallment.id).classList.add('d-none')
    }


    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "id",
                Cell: ({ value }) => {
                    return (
                        <div className="cursor-pointer">
                            <span>{value}</span>
                        </div>
                    )
                }
            },
            {
                Header: "Orden",
                accessor: "order",
                Cell: ({ value }) => {
                    return (
                        <div className="cursor-pointer"
                            id={'viewOrderDiv' + value}
                            onMouseEnter={() => showTooltip('viewOrderDiv' + value, `Ver orden #${value}`)}
                            onMouseLeave={() => hideTooltip('viewOrderDiv' + value)}
                            onClick={() => {}}
                        >
                            <span>{value}</span>
                        </div>
                    )
                }
            },
            {
                Header: "Número de cuota",
                disableSortBy: true,
                accessor: "number"
            },
            {
                Header: 'Monto',
                disableSortBy: true,
                accessor: paymentInstallment => {
                    return paymentInstallment.amount + ' ' + paymentInstallment.currency
                }
            },
            {
                Header: "Fecha de expiración",
                disableSortBy: true,
                accessor: paymentInstallment => {
                    return format(new Date(paymentInstallment.expiration_date), "dd/MM/yyyy")
                }
                
            },
            {
                Header: 'Comprobante de pago',
                disableSortBy: true,
                accessor: paymentInstallment => {
                  if (paymentInstallment.proof_of_payment_url !== null && paymentInstallment.proof_of_payment_url !== '') {
                    return (
                      <button
                        type='button'
                        onClick={() => window.open(paymentInstallment.proof_of_payment_url, '_blank')}
                        className="btn btn-sm text-white downloadButtons"
                      >
                        Descargar
                      </button>
                    )
                  }
                  return (
                    <div className='d-flex align-items-center flex-column w-50 mx-auto'>
                      <div className='d-flex align-items-center flex-column justify-content-center fs-4 ms-2 w-100'>
                        <input id={'fileInput' + paymentInstallment.id} type='file' accept='.jpg,.jpeg,.png,.pdf' className='form-control' onChange={e => handleFile(e, paymentInstallment.id)} />
                      </div>
                      <div
                        id={'proofOfPaymentSection' + paymentInstallment.id}
                        className='d-flex align-items-center flex-column justify-content-center fs-4 ms-2 w-100 d-none'
                      >
                        <div className='d-flex align-items-center justify-content-end w-100 mt-1' style={{marginLeft: 'auto'}} >
                            <label htmlFor={'dateInput' + paymentInstallment.id} style={{width: '100%', fontSize: '1rem', fontWeight: '400', lineHeight: '1.5', color: '#212529'}}>Fecha de Pago:</label>
                            <input id={'dateInput' + paymentInstallment.id} className='form-control' type="date" />
                        </div>
                        <div className='d-flex align-items-center justify-content-end w-100 mt-1'>
                            <select id={'paymentMethodInput' + paymentInstallment.id} className='form-control'>
                                <option value="">Seleccionar Método de Pago</option>
                                <option value="Efectivo">Efectivo</option>
                                <option value="dLocal">dLocal</option>
                                <option value="rebill">rebill</option>
                                <option value="Stripe">Stripe</option>
                                <option value="Mercado Pago">Mercado Pago</option>
                                <option value="Mercado Pago Ahora 12/18">Mercado Pago Ahora 12/18</option>
                                <option value="Transferencia Bancaria">Transferencia Bancaria</option>
                                <option value="Cheque">Cheque</option>
                                <option value="PayPal">PayPal</option>
                                <option value="Tienda Nube Ahora 12/18">Tienda Nube Ahora 12/18</option>
                                <option value="Western Union/MoneyGram/Argenper">Western Union/MoneyGram/Argenper</option>
                            </select>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span
                            id={'approveButton' + paymentInstallment.id}
                            className='actions'
                            onMouseEnter={() => showTooltip('approveButton' + paymentInstallment.id, 'Guardar')}
                            onMouseLeave={() => hideTooltip('approveButton' + paymentInstallment.id)}
                            >
                                <FcApproval onClick={() => {
                                    saveFile(paymentInstallment.id)
                                }} />
                            </span>
                            <span
                            id={'undoButton' + paymentInstallment.id}
                            className='actions'
                            onMouseEnter={() => showTooltip('undoButton' + paymentInstallment.id, 'Deshacer')}
                            onMouseLeave={() => hideTooltip('undoButton' + paymentInstallment.id)}
                            >
                                <FcUndo onClick={() => {
                                    document.getElementById('fileInput' + paymentInstallment.id).value = ''
                                    document.getElementById('proofOfPaymentSection' + paymentInstallment.id).classList.add('d-none')
                                }} />
                            </span> 
                        </div>
                      </div>
                    </div>
                  )
                }
            },
            {
                Header: 'Acciones',
                disableSortBy: true,
                accessor: paymentInstallment => {
                  return (
                    <div className='d-flex'>
                        {(paymentInstallment.proof_of_payment_url !== null && paymentInstallment.proof_of_payment_url !== '') &&
                            <span
                                id={'deleteProofOfPaymentButton' + paymentInstallment.id}
                                className='d-flex align-items-center actions pt-1 fs-3'
                                onMouseEnter={() => showTooltip('deleteProofOfPaymentButton' + paymentInstallment.id, 'Borrar comprobante de pago ' + paymentInstallment.id)}
                                onMouseLeave={() => hideTooltip('deleteProofOfPaymentButton' + paymentInstallment.id)}
                                onClick={() => deleteProofOfPaymentUrl(paymentInstallment.id)}
                            >
                                <FcFullTrash />
                            </span>
                        }
                    </div>
                  )
                }
            },
        ],
        [newFile, paymentsInstallments]
    );

    
    return (
        <>            
            <PrincipalBar />

            <div className="mx-3 mb-5">
                <FirstTitle text="Comprobantes de Pago" />
                {loading ?
                    <div className='mt-5'>
                        <Loader />
                        <strong className='d-block text-center'>Cargando...</strong>
                    </div> :
                    <TableBackPaginated
                        columns={columns}
                        initialState={initialState}
                    />
                }
            </div>

        </>
    )

}