import { useState } from "react"
import { toast } from "react-toastify"

import StockOrderService from "../../../Services/Stock/StockOrderService"
import { isAdmin, isManufacturer } from "../../../Utilities/Auth/AuthPermissions"
import { hideModal } from "../../../Utilities/General/BootstrapApi"

export default function AdminDistCenters({
    saleProductItemMovementsList,
    setSaleProductItemMovementsList,
    distCenters,
    workOn,
    saleProducts,
    currentSP,
    setStockOrders
}) {

    const [updateDC, setUpdateDC] = useState({})

    const handleChange = (spiml, e) => {
        setUpdateDC({
            ...spiml,
            distribution_center: parseInt(e.target.value)
        })
    }

    const handleSubmit = () => {
        StockOrderService.updateSaleProductItem(updateDC)
            .then(updated => {
                StockOrderService.getStockOrders()
                    .then(data => setStockOrders(data))
                StockOrderService.getSaleProductItemMovementsList()
                    .then(data => setSaleProductItemMovementsList(data))
                toast.success(`El ${currentSP} n° ${updated.serial_number} se movió al distribuidor ${distCenters.find(dc => dc.id === updated.distribution_center).name}.`, {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
            })
    }

    return (
        <div className="modal fade" id="adminDistCenters">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info">
                            Pre-armados en {distCenters?.find(dc => dc.id === workOn)?.name} - {distCenters?.find(dc => dc.id === workOn)?.country_iso_origin}
                        </h3>
                        <span type="button" className="btn-close" aria-label="Close"
                            onClick={() => hideModal('adminDistCenters')}
                        ></span>
                    </div>
                    <div className="modal-body">
                        <table className="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>N° serie</th>
                                    <th>Insumos</th>
                                    <th>{isManufacturer() ? 'Cambiar distribuidor' : 'Distribuidor'}</th>
                                    {isManufacturer() && <th>Acción</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {saleProductItemMovementsList?.filter(spiml => spiml.sale_product === saleProducts.find(sp => (sp.alias !== null ? sp.alias : sp.name) === currentSP)?.id)
                                    .sort((a, b) => {
                                        let first = parseInt(a.serial_number.split('_')[a.serial_number.split('_').length - 1])
                                        let second = parseInt(b.serial_number.split('_')[b.serial_number.split('_').length - 1])
                                        if (first > second) return 1
                                        if (first < second) return -1
                                        return 0
                                    })
                                    .map(spiml => {
                                        return (
                                            <tr key={spiml.id}>
                                                <td>{currentSP}</td>
                                                <td>{spiml.serial_number}</td>
                                                <td>
                                                    <table className="table table-success">
                                                        <tbody>
                                                            {spiml.movementdetail_set.map(md => {
                                                                return (
                                                                    <tr key={md.id}>
                                                                        <td>{md.item_input_product_name}</td>
                                                                        <td>{md.item_input_product_serial_number}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    {(isManufacturer() || isAdmin()) ?
                                                        <select
                                                            defaultValue={spiml.distribution_center}
                                                            className="form-select"
                                                            onChange={e => handleChange(spiml, e)}
                                                        >
                                                            {distCenters
                                                                .map(dc => {
                                                                    return (
                                                                        <option key={dc.id} value={dc.id}>{dc.name}</option>
                                                                    )
                                                                })}
                                                        </select> :
                                                        <>
                                                            {distCenters.find(dc => dc.id === spiml.distribution_center).name}
                                                        </>
                                                    }
                                                </td>
                                                {(isManufacturer() || isAdmin()) &&
                                                    <td>
                                                        <button
                                                            id={'submitNewDist' + spiml.id}
                                                            className={`btn btn-info text-white ${(updateDC.distribution_center === workOn
                                                                || updateDC.distribution_center === undefined
                                                                || updateDC.id !== spiml.id) && 'd-none'}`}
                                                            onClick={() => handleSubmit()}
                                                        >
                                                            Guardar
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <span type="button" className="btn btn-secondary"
                            onClick={() => hideModal('adminDistCenters')}
                        >CERRAR</span>
                    </div>
                </div>
            </div>
        </div>
    )
}