import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import PreAssembledStock from './Components/PreAssembledStock';
import useDistCenters from '../../Hooks/Stock/useDistCenters';
import InputProductsByDistCenter from './Components/InputProductsByDistCenter';
import PaymentService from '../../Services/Licenses/PaymentService'
import SaleProductService from '../../Services/Stock/SaleProductService'
import StockOrderService from '../../Services/Stock/StockOrderService'
import { getToken, getUsername, isAdmin, isAuth, isCustomerSuccess, isManufacturer } from '../../Utilities/Auth/AuthPermissions';
import { hideTooltip, showTooltip, hideModal, showModal } from '../../Utilities/General/BootstrapApi';
import { toggleDisabled } from '../../Utilities/General/HandleClick'

export default function DistributionCenters() {

    const [countries, setCountries] = useState([]);
    const [stockOrders, setStockOrders] = useState([])
    const [saleProducts, setSaleProducts] = useState([]);
    const [saleProductItemMovementsList, setSaleProductItemMovementsList] = useState([]);

    const [workOn, setWorkOn] = useState(null);
    const [loading, setLoading] = useState(true);

    let navigate = useNavigate()
    let params = useParams()

    const distCenters = useDistCenters()


    useEffect(() => {
        PaymentService.getCountries().then(data => setCountries(data))
        SaleProductService.getSaleProducts().then(data => setSaleProducts(data))
        StockOrderService.getStockOrders().then(data => {
            setStockOrders(data)
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        if (isAuth()) {
            StockOrderService.getSaleProductItemMovementsListByStatus('inputs products assigned').then(data => setSaleProductItemMovementsList(data))
        }
    }, [stockOrders])

    return (
        <>

            <PrincipalBar />

{/*             <AudiometerConverter
                data={saleProductItemMovementsList?.filter(spiml =>
                    spiml.status === 'inputs products assigned' && spiml.sale_product_sku === 'NBK_AUD_DIAG_0001') || []}
                setStockOrders={setStockOrders}
            /> */}

            <InputProductsByDistCenter
                distCenters={distCenters}
                countries={countries}
                preAssembled={saleProductItemMovementsList}
            />

            <div id='workOrdersHeader' className='d-flex flex-wrap mx-3 mt-5 p-3'>
                <div className='col-12 col-lg-6 md-overflow-scroll'>
                    <PreAssembledStock
                        stockOrders={stockOrders}
                        saleProducts={saleProducts}
                        saleProductItemMovementsList={saleProductItemMovementsList}
                        setSaleProductItemMovementsList={setSaleProductItemMovementsList}
                        workOn={workOn}
                        setWorkOn={setWorkOn}
                        setStockOrders={setStockOrders}
                    />
                </div>
                {!loading &&
                    <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center mt-4 mt-lg-0">
                        <button
                            type='button'
                            className={`py-2 col-12 col-sm-8 col-md-6 ${isCustomerSuccess() && 'mb-3'}`}
                            onClick={() => showModal('ipBydc')}
                        >
                            Explorar distribuidores
                        </button>
                        {(isManufacturer() || isAdmin()) &&
                            // <>
                            //     <button
                            //         type='button'
                            //         className="my-3 py-2 col-12 col-sm-8 col-md-6"
                            //         onClick={() => showModal('audiometerConverter')}
                            //     >
                            //         Convertir audiómetro
                            //     </button>
                            //     <button
                            //         type='button'
                            //         className="mb-3 py-2 col-12 col-sm-8 col-md-6"
                            //         onClick={() => navigate('/inventario')}
                            //     >
                            //         Inventario
                            //     </button>
                            // </>
                            <button
                                type='button'
                                className="my-3 py-2 col-12 col-sm-8 col-md-6"
                                onClick={() => navigate('/inventario')}
                            >
                                Inventario
                            </button>
                        }
                        <button
                            type='button'
                            className="py-2 col-12 col-sm-8 col-md-6"
                            onClick={() => navigate('/inicio')}
                        >
                            Volver
                        </button>
                    </div>
                }
            </div>

        </>
    )

}