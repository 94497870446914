export const generateSerialNumber = (items, newItems) => {
    let initValue
    // todos los items del articulo actual ordenados por id
    let allItems = items.filter(i => i.input_product === parseInt(newItems[0].input_product)).sort((a, b) => parseInt(b.serial_number) - parseInt(a.serial_number))
    if (allItems.length === 0) {
        // generar el primer numero de serie 
        initValue = 0
    } else {
        // agarrar el ultimo serial number
        initValue = parseInt(allItems[0].serial_number)
    }
    // asignar al serial number de cada item el init value incrementado en 1
    // paso el numero a string, calculo la longitud y 
    // pre concateno la diferencia entre la longitud y 5 (cantidad de digitos del serial number)
    for (let i = 0; i < newItems.length; i++) {
        initValue = initValue + 1
        let withZeros = ''
        for (let j = initValue.toString().length; j < 5; j++) {
            withZeros += '0'
        }
        newItems[i].serial_number = withZeros + initValue
    }
}