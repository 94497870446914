import React from "react";

export default function Accept() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Grupo_1062" data-name="Grupo 1062" transform="translate(-472 -378)">
                    <g id="Grupo_1061" data-name="Grupo 1061" transform="translate(-129)">
                        <g id="Grupo_751" data-name="Grupo 751" transform="translate(601 378)">
                            <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#69e2ff" />
                        </g>
                    </g>
                    <path id="Trazado_642" data-name="Trazado 642" d="M2388.784,1508.816l4.387,3.76,6.894-7.312" transform="translate(-1910.425 -1118.92)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </g>
            </svg>
        </div>
    )
}