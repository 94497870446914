export const setFreemiumTags = (paymentdetail, articles, saleProducts) => {
    let [freemProduct] = saleProducts.filter(sp => sp.sku === 'NBK_AUD_FREM_0001')
    let [freemArticle] = articles.filter(art => art.sku === freemProduct?.sku)
    let pd = [...paymentdetail.filter(item => item.article !== freemArticle?.id)]
    let count = paymentdetail.filter(item => item.article === freemArticle?.id)[0]?.count
    let rewarded = paymentdetail.filter(item => item.article === freemArticle?.id)[0]?.rewarded
    for (let i = 0; i < count; i++) {
        pd.push({
            article: freemArticle.id,
            count: 1,
            rewarded,
            tag: '#' + (i + 1)
        })
    }
    return pd
}