import * as bootstrap from 'bootstrap'

export const showModal = id => {
    let element = document.getElementById(id)
    let modal = new bootstrap.Modal(element, {
        backdrop: 'static',
        keyboard: false
    })
    modal.show()
}

export const hideModal = id => {
    let element = document.getElementById(id)
    let modal = bootstrap.Modal.getInstance(element)
    modal.hide()
}

export const showTooltip = (
    id,
    title = '',
    html = false,
    trigger = 'hover focus'
) => {
    let element = document.getElementById(id)
    let tooltipInstance = bootstrap.Tooltip.getInstance(element)
    if (tooltipInstance === null) {
        let tooltip = new bootstrap.Tooltip(element, {
            title,
            html,
            trigger
        })
        tooltip.show()
    }
}

export const hideTooltip = id => {
    let element = document.getElementById(id)
    let tooltip = bootstrap.Tooltip.getInstance(element)
    tooltip?.hide()
}

export const toggleAccordion = (section, parent = 'accordion') => {
    let element = document.getElementById('collapse-' + section)
    let coll = new bootstrap.Collapse(element, {
        parent: document.getElementById(parent)
    })
    coll.toggle()
}

export const collapseBar = () => {
    let element = document.getElementById('collapsableBar')
    let coll = new bootstrap.Collapse(element)
    coll.toggle()
}