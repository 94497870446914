import { Route, Routes } from "react-router-dom";
import { useState } from 'react';
import Login from './Commons/Login';
import Home from '../Pages/Home/Home'
import CreateOrder from '../Pages/CreateOrder/CreateOrder';
import SaleOrders from '../Pages/SaleOrders/SaleOrders';
import EditOrder from '../Pages/EditOrder/EditOrder';
import Clients from '../Pages/Clients/Clients';
import Error from '../Pages/Error/Error';
import Inventory from '../Pages/Inventory/Inventory';
import ViewCalibrations from '../Pages/ViewCalibrations/ViewCalibrations';
import CalibrationBuilds from '../Pages/CalibrationBuilds/CalibrationBuilds';
import WorkOrders from '../Pages/WorkOrders/WorkOrders';
import PreOrders from '../Pages/PreOrders/PreOrders';
import DistributionCenters from '../Pages/DistributionCenters/DistributionCenters';
import Billing from '../Pages/Billing/Billing';
import CSPanel from "../Pages/CSPanel/CSPanel";
import ProofPayment from "../Pages/ProofPayment/ProofPayment";
import PaymentInstallmentContext from "../Contexts/PaymentInstallmentContext";

export default function Router() {
  const [paymentsInstallments, setPaymentsInstallments] = useState([]);
  const [nextPage, setNextPage] = useState(null)
  const [totalRegisters, setTotalRegisters] = useState(0)
  const [previousPage, setPreviousPage] = useState(null)
  const [pageSize, setPageSize] = useState(10)

  const valuePaymentInstallmentContext = {
    paymentsInstallments,
    setPaymentsInstallments,
    nextPage,
    setNextPage,
    previousPage,
    setPreviousPage,
    totalRegisters,
    setTotalRegisters,
    pageSize,
    setPageSize
  }



  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/clientes" element={<Clients />} />
      <Route exact path="/inicio" element={<Home />} />
      <Route exact path="/ordenes-venta" element={<SaleOrders />} />
      <Route exact path="/ordenes-venta/:id" element={<SaleOrders />} />
      <Route exact path="/comprobantes-de-pago" element={
        <PaymentInstallmentContext.Provider value={valuePaymentInstallmentContext}>
          <ProofPayment />
        </PaymentInstallmentContext.Provider>
      } />
      <Route exact path="/ordenes-trabajo" element={<WorkOrders />} />
      <Route exact path="/ordenes-trabajo/:id" element={<WorkOrders />} />
      <Route exact path="/ordenes-prearmado" element={<PreOrders />} />
      <Route exact path="/centros-de-distribucion" element={<DistributionCenters />} />
      <Route exact path="/nueva-orden-venta" element={<CreateOrder />} />
      <Route exact path="/editar-orden/:id" element={<EditOrder />} />
      <Route exact path="/inventario" element={<Inventory />} />
      <Route exact path="/archivos-calibracion" element={<CalibrationBuilds />} />
      <Route exact path="/calibraciones/:id" element={<ViewCalibrations />} />
      <Route exact path="/facturacion" element={<Billing />} />
      <Route exact path="/facturacion/:id" element={<Billing />} />
      <Route exact path="/panel-cs" element={<CSPanel />} />
      <Route exact path='*' element={<Error />} />
    </Routes>
  )
};
