import React from 'react';

import CreateSupplier from './CreateSupplier';

import { hideModal } from '../../../Utilities/General/BootstrapApi';

import Edit from '../../../Assets/Edit';

export default function AdminSupplier({
    suppliers,
    newSupplier,
    setNewSupplier,
    val,
    forceUpdate,
    handleSubmit,
    workOn,
    setWorkOn
}) {

    const handleChange = e => {
        setNewSupplier({
            ...newSupplier,
            [e.target.name]: e.target.value
        });
    }

    return (
        <>
            <div className="modal fade" id="suppliers" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h3 className="modal-title text-info" id="staticBackdropLabel">Administrar Proveedores</h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                hideModal('suppliers')
                                setNewSupplier({})
                                setWorkOn(null)
                                document.getElementById('newSupplierForm').reset()
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>CUIT/CUIL/RUT</th>
                                        <th>Observaciones</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {suppliers?.map(sup => (
                                        <tr key={sup.id}>
                                            {workOn === sup.id ?
                                                <td className='border-0'>
                                                    <input
                                                        type='text'
                                                        name='name'
                                                        className='form-control'
                                                        defaultValue={sup.name}
                                                        onChange={e => handleChange(e)}
                                                    />
                                                    {val.current.message('nameSupEdit',
                                                        newSupplier.name,
                                                        'required|max:200|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&=<>+]',
                                                        { className: 'text-danger' })}
                                                    <small id='supNameWarning' className='text-danger d-none'>
                                                        * El dato ingresado no es válido
                                                    </small>
                                                </td> :
                                                <td className='border-0'>
                                                    {sup.name}
                                                </td>
                                            }
                                            {workOn === sup.id ?
                                                <td className='border-0'>
                                                    <input
                                                        type='text'
                                                        name='vat'
                                                        className='form-control'
                                                        defaultValue={sup.vat}
                                                        onChange={e => handleChange(e)}
                                                    />
                                                    {val.current.message('vatEdit',
                                                        newSupplier.vat,
                                                        'required|max:200|alpha_num_space',
                                                        { className: 'text-danger' })}
                                                    <small id='supVatWarning' className='text-danger d-none'>
                                                        * El dato ingresado no es válido
                                                    </small>
                                                </td> :
                                                <td className='border-0'>
                                                    {sup.vat}
                                                </td>
                                            }
                                            {
                                                workOn === sup.id ?
                                                    <td className='border-0'>
                                                        <input
                                                            type='text'
                                                            name='observation'
                                                            className='form-control'
                                                            defaultValue={sup.observation}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                        {val.current.message('observationEdit',
                                                            newSupplier.observation,
                                                            'max:500',
                                                            { className: 'text-danger' })}
                                                        <small id='supObsWarning' className='text-danger d-none'>
                                                            * El dato ingresado no es válido
                                                        </small>

                                                    </td> :
                                                    <td className='border-0'>
                                                        {sup.observation}
                                                    </td>
                                            }
                                            <td className="border-0">
                                                {workOn === sup.id ?
                                                    <>
                                                        <button
                                                            type='button'
                                                            className='btn btn-info bg-white text-info'
                                                            onClick={() => {
                                                                if (!val.current.fieldValid('nameSupEdit')) {
                                                                    document.getElementById('supNameWarning').classList.remove('d-none')
                                                                    return
                                                                }
                                                                if (!val.current.fieldValid('vatEdit')) {
                                                                    document.getElementById('supVatWarning').classList.remove('d-none')
                                                                    return
                                                                }
                                                                if (!val.current.fieldValid('observationEdit')) {
                                                                    document.getElementById('supObsWarning').classList.remove('d-none')
                                                                    return
                                                                }
                                                                document.getElementById('supNameWarning').classList.add('d-none')
                                                                document.getElementById('supVatWarning').classList.add('d-none')
                                                                document.getElementById('supObsWarning').classList.add('d-none')
                                                                handleSubmit('edit')
                                                            }}
                                                        >
                                                            Guardar
                                                        </button>
                                                        <button
                                                            type='button'
                                                            className='btn btn-info bg-white text-info ms-2'
                                                            onClick={() => {
                                                                setWorkOn(null)
                                                                document.getElementById('supNameWarning').classList.add('d-none')
                                                                document.getElementById('supVatWarning').classList.add('d-none')
                                                                document.getElementById('supObsWarning').classList.add('d-none')
                                                            }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                    </> :
                                                    <span className='actions' onClick={() => {
                                                        let [editItem] = suppliers.filter(item => item.id === sup.id)
                                                        setNewSupplier(editItem)
                                                        setWorkOn(sup.id)
                                                    }}>
                                                        <Edit />
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-1 mb-3 ms-5'>
                            <h4 className='text-info'>Nuevo</h4>
                            <CreateSupplier
                                handleSubmit={handleSubmit}
                                newSupplier={newSupplier}
                                setNewSupplier={setNewSupplier}
                                val={val}
                                forceUpdate={forceUpdate}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                hideModal('suppliers')
                                setNewSupplier({})
                                setWorkOn(null)
                                document.getElementById('newSupplierForm').reset()
                            }}>CERRAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}