import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import { isAdmin, isCalibrator } from '../../../Utilities/Auth/AuthPermissions';

export default function TableEmissionData({
    columns,
    data,
    length,
    filteredRows,
    built,
    showModal,
    dontShowButton,
    pageSizeProp,
    emissionType
}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: emissionType === 'pure tone' ? 'frequency' : 'id',
                    desc: true
                }
            ]
        }
    }, useSortBy);

    const [pageSize, setPageSize] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [pageOptions, setPageOptions] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const [canNextPage, setCanNextPage] = useState(true);

    useEffect(() => {
        setPageSize(pageSizeProp);
    }, [])

    useEffect(() => {
        setPageCount(parseInt(Math.ceil(length / pageSize)));
    }, [pageSize])

    useEffect(() => {
        var i = 0;
        var indexes = [];
        while (i < pageCount) {
            indexes.push(i);
            i++
        }
        setPageOptions(indexes);
    }, [pageCount])

    const gotoPage = idx => {
        setPageIndex(idx);
    }

    const previousPage = () => {
        setPageIndex(pageIndex - 1);
    }

    const nextPage = () => {
        setPageIndex(pageIndex + 1);
    }

    useEffect(() => {
        if (pageIndex === 0) {
            setCanPreviousPage(false);
            setCanNextPage(true);
        }
        if (pageIndex === (pageCount - 1)) {
            setCanNextPage(false);
            setCanPreviousPage(true);
        }
        if (pageIndex > 0 && pageIndex < (pageCount - 1)) {
            setCanNextPage(true);
            setCanPreviousPage(true);
        }
        toggleDisplay();
    }, [pageIndex, pageOptions])

    const toggleDisplay = () => {
        var i = 0;
        while (i < length) {
            var item = document.getElementById('emDataRow' + i);
            if (i >= (pageIndex * pageSize) && i < ((pageIndex + 1) * pageSize)) {
                item.classList.remove('d-none');
            } else {
                item.classList.add('d-none');
            }
            i++
        }
    }

    return (
        <>
            <div className="text-center lg-overflow-scroll">
                <table {...getTableProps()} className={parseInt(length) === 0 ? "table" : "table table-hover"}   >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        <span className="me-1">
                                            {column.isSorted ? (column.isSortedDesc ? "  ↓" : "  ↑") : ""}
                                        </span>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}
                                    id={'emDataRow' + row.index}
                                >
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === 'Min gain' ||
                                            cell.column.Header === 'Max gain') {
                                            return (
                                                <td
                                                    className='oldGainColumns'
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        }
                                        if (cell.column.Header === 'Min gain new' ||
                                            cell.column.Header === 'Max gain new') {
                                            return (
                                                <td
                                                    className='newGainColumns'
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        }
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        {parseInt(length) === 0 && !filteredRows &&
                            <tr>
                                <td colSpan="9" className="p-3">
                                    <strong className="text-muted">
                                        No hay resultados que mostrar.
                                    </strong>
                                </td>
                            </tr>
                        }
                        {parseInt(length) === 0 && filteredRows &&
                            <tr>
                                {built ?
                                    <td colSpan="9" className={dontShowButton ? 'p-4 pb-0' : 'p-4'}>
                                        <strong className="text-muted">
                                            No se han encontrado resultados.
                                        </strong><br />
                                    </td> :
                                    <td colSpan="9" className={dontShowButton ? 'p-4 pb-0' : 'p-4'}>
                                        <strong className="text-muted">
                                            No se han encontrado resultados.<br />
                                            {!dontShowButton && (isCalibrator() || isAdmin()) &&
                                                <>
                                                    ¡Registre una nueva calibración!
                                                </>
                                            }
                                        </strong><br />
                                        {!dontShowButton && (isCalibrator() || isAdmin()) &&
                                            <button type="button" className="btn border border-secondary text-secondary mt-2" onClick={() => showModal('calModal')}>
                                                REGISTRAR
                                            </button>
                                        }
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            {length > pageSize &&
                <div className="d-flex justify-content-center mt-4 mb-5 ">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info btn-sm text-white">
                        &#10096;&#10096;&#10096;
                    </button>
                    <div className="d-inline-block mx-1">
                        {pageCount > 5 ?
                            <div className="mx-1 d-flex align-items-center">
                                <small>Página</small>
                                <select className="form-select mx-1" onChange={(e) => gotoPage(parseInt(e.target.value))}>
                                    {pageOptions.map((item, idx) => {
                                        if (parseInt(item) === parseInt(pageIndex)) {
                                            return <option key={idx} value={parseInt(item)} selected>{parseInt(item) + 1}</option>
                                        } else {
                                            return <option key={idx} value={parseInt(item)}>{parseInt(item) + 1}</option>
                                        }
                                    })}
                                </select>
                                <small>de<span className="ms-1">{pageCount}</span></small>
                            </div> :
                            <div className="mx-3 d-flex justify-content-center">
                                {pageOptions.map((item, idx) => {
                                    return <span key={idx}
                                        className={item === pageIndex ? "m-1 text-white bg-info p-1 rounded" : "m-2 text-info cursor-pointer"}
                                        onClick={() => { gotoPage(idx) }}>{parseInt(item) + 1}</span>
                                })}
                            </div>
                        }
                    </div>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info btn-sm text-white">
                        &#10097;&#10097;&#10097;
                    </button>
                </div>
            }
        </>
    )
}