import OrderService from "../../Services/Licenses/OrderService"
import Urls from "../General/Urls"

export const paginatePaymentInstallment = async url => {
    const data = await OrderService.getPaymentInstallmentWithUrl(url)
    return data
}

export const filterPaymentInstallment = async params => {
    const data = await OrderService.getPaymentInstallmentWithUrl(Urls.urlPayPaymentInstallment + params)
    return data
}