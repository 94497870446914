import React from 'react';
import { toast } from 'react-toastify';

import ClientService from '../../Services/Licenses/ClientService'
import { capitalizeEachWord } from '../../Utilities/General/CapitalizeEachWord';
import { toggleDisabled } from '../../Utilities/General/HandleClick';

export default function CreateClient({
    hideModal,
    countries,
    typeIdClient,
    val,
    newClient,
    setNewClient,
    newClientBillingData,
    setNewClientBillingData,
    forceUpdate,
    newPhoneContact,
    setNewPhoneContact,
    newEmailContact,
    setNewEmailContact,
    clients,
    setClients,
    order,
    setOrder,
    phoneContacts,
    setPhoneContacts,
    clientBillingData,
    setClientBillingData,
    setLocationConfirmed,
    fromClients
}) {

    const handleChangeClient = e => {
        setNewClient({
            ...newClient,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeClientBillingData = e => {
        setNewClientBillingData({
            ...newClientBillingData,
            [e.target.name]: e.target.value
        })
    }

    const handleChangePhoneContact = e => {
        setNewPhoneContact({
            ...newPhoneContact,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeEmail = e => {
        setNewEmailContact({
            ...newEmailContact,
            [e.target.name]: e.target.value
        })
    }

    // CLIENTE
    const saveNewClient = () => {
        var v = val;
        console.error("saveNewClient -> v", v);
        if (v.fieldValid('type_id') && v.fieldValid('id_client') &&
            v.fieldValid('clientName') && v.fieldValid('country') &&
            v.fieldValid('note') && v.fieldValid('razon_social') && v.fieldValid('vat_number') &&
            v.fieldValid('iva') && v.fieldValid('type') && v.fieldValid('person') &&
            v.fieldValid('carnet_id') && v.fieldValid('country_code') && v.fieldValid('area_code') &&
            v.fieldValid('phone_number') && v.fieldValid('email')) {
            // pongo en mayusculas la primera letra del nombre del cliente
            newClient.name = capitalizeEachWord(newClient.name)

            let [currentCountry] = countries.filter(c => c.iso_code === newClient.country)
            let [currentTypeId] = typeIdClient.filter(tic => tic.id === parseInt(newClient.type_id))

            // guardo el id del pais del cliente en los datos de facturacion
            newClientBillingData.address_country = currentCountry.id

            toggleDisabled('submit_create_client')
            ClientService.saveNewClient(newClient, currentTypeId.name, currentCountry.name)
                .then(res => {
                    if (res !== undefined && res.status !== 400) {
                        setClients([...clients, res.data])
                        if (fromClients !== 'true') {
                            document.getElementById('clientInput').value = res.data.id
                            setOrder({
                                ...order,
                                new_client: res.data.id,
                            })
                            setLocationConfirmed(false)
                        }
                        setNewClient({})
                        newClientBillingData.client = res.data.id
                        newPhoneContact.client = res.data.id
                        newEmailContact.client = res.data.id
                        ClientService.saveNewPhoneContact(newPhoneContact)
                            .then(data => {
                                setNewPhoneContact({})
                                setPhoneContacts([...phoneContacts, data])
                            })
                        ClientService.saveNewEmailContact(newEmailContact)
                            .then(() => {
                                setNewEmailContact({})
                            })
                        ClientService.saveNewClientBillingData(newClientBillingData)
                            .then(data => {
                                hideModal('newClient')
                                document.getElementById('clientForm').reset();
                                setClientBillingData([...clientBillingData, data])
                                setNewClientBillingData({})
                                toggleDisabled('submit_create_client')
                                toast.success('Cliente creado correctamente', {
                                    position: 'bottom-left',
                                    hideProgressBar: true,
                                    className: 'success-toast'
                                })
                            })
                    }
                })
        } else {
            val.showMessages();
            forceUpdate(1)
        }
    }

    return (
        <div className="mx-3 my-3">

            <form id="clientForm" className="d-flex flex-wrap justify-content-around">

                <div className="border border-dark rounded p-3 mb-3 col-12 col-lg-5">

                    <h3 className="d-block mb-3 text-info">Datos personales</h3>
                    <hr />

                    <div className="form-group">
                        <label htmlFor="name" className="mb-2">Nombre y Apellido</label>
                        <input type="text" name="name" className="form-control" onChange={e => handleChangeClient(e)} />
                        {val.message('clientName', newClient.name, 'required|max:150|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9-&!"#$%/()=?¿]', { className: 'text-danger' })}
                    </div>

                    <div className="form-group my-3">
                        <label htmlFor="type_id" className="mb-2">Tipo de documento</label>
                        <select name="type_id" className="form-select" onChange={e => handleChangeClient(e)}>
                            <option value="">Seleccione</option>
                            {typeIdClient?.map((tyc, tycIndex) => {
                                return <option key={tycIndex} value={tyc.id}>{tyc.name}</option>
                            })}
                        </select>
                        {val.message('type_id', newClient.type_id, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="id_client" className="mb-2">Núm. de documento</label>
                        <input type="text" name="id_client" className="form-control" onChange={e => handleChangeClient(e)} />
                        {val.message('id_client', newClient.id_client, 'required|regex:[A-Za-z0-9/_-]|not_regex:[¡¿?!"@#¢$%&/()+.]|min:1|max:100', { className: 'text-danger' })}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="country" className="mb-2">País</label>
                        <select id="country" name="country" className="form-select" onChange={e => handleChangeClient(e)}>
                            <option value="">Seleccione</option>
                            {
                                countries?.map((country, idx) => {
                                    return (
                                        <option key={idx} value={country.iso_code}>{country.name}</option>
                                    )
                                })
                            }
                        </select>
                        {val.message('country', newClient.country, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="phone" className="mb-2">Teléfono</label>
                        <div className='d-flex'>
                            <div>
                                <input
                                    type="text"
                                    name="country_code"
                                    className="form-control"
                                    placeholder='Cód. País'
                                    onChange={e => handleChangePhoneContact(e)}
                                />
                                {val.message('country_code', newPhoneContact.country_code, 'max:5|numeric', { className: 'text-danger' })}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="area_code"
                                    className="form-control"
                                    onChange={e => handleChangePhoneContact(e)}
                                    placeholder='Cód. Área'
                                />
                                {val.message('area_code', newPhoneContact.area_code, 'max:10|numeric', { className: 'text-danger' })}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="number"
                                    className="form-control"
                                    onChange={e => handleChangePhoneContact(e)}
                                    placeholder='Número'
                                />
                                {val.message('phone_number', newPhoneContact.number, 'numeric|not_regex:[-]|max:150', { className: 'text-danger' })}
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="email" className="mb-2">Email</label>
                        <input type="email" name="email" className="form-control" onChange={e => handleChangeEmail(e)} />
                        {val.message('email', newEmailContact.email, 'required|email|max:254', { className: 'text-danger' })}
                    </div>

                    <div className="form-group">
                        <label htmlFor="note" className="mb-2">Observaciones</label>
                        <textarea name="note" className="form-control textarea" maxlength="250" onChange={e => handleChangeClient(e)}></textarea>
                        {val.message('note', newClient.note, 'max:250', { className: 'text-danger' })}
                    </div>

                </div>

                <div className="border border-dark rounded p-3 mb-3 col-12 col-lg-5">

                    <h3 className="d-block mb-3 text-info">Datos de facturación</h3>
                    <hr />

                    <div className="form-group">
                        <label htmlFor="carnet_id" className="mb-2">Matrícula</label>
                        <input type="text" name="carnet_id" className="form-control" onChange={e => handleChangeClientBillingData(e)} />
                        {val.message('carnet_id', newClientBillingData.carnet_id, 'required|regex:[0-9A-Za-z- ]|not_regex:[!"#$%&()@]|max:100', { className: 'text-danger' })}
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="name" className="mb-2">Razón Social</label>
                        <input type="text" name="name" className="form-control" onChange={e => handleChangeClientBillingData(e)} />
                        {val.message('razon_social', newClientBillingData.name, 'required|max:150|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü]', { className: 'text-danger' })}
                    </div>

                    <div className="form-group my-3">
                        <label htmlFor="vat_number" className="mb-2">Cuit/Cuil/RUT</label>
                        <input type="text" name="vat_number" className="form-control" onChange={e => handleChangeClientBillingData(e)} />
                        {val.message('vat_number', newClientBillingData.vat_number, 'required|max:80', { className: 'text-danger' })}
                    </div>

                    <div className="form-group">
                        <label htmlFor="iva" className="mb-2">Condición Fiscal</label>
                        <select name="iva" className="form-select" onChange={e => handleChangeClientBillingData(e)}>
                            <option value="">Seleccione</option>
                            <option value="Consumidor Final">Consumidor Final</option>
                            <option value="Responsable Inscripto">Responsable Inscripto</option>
                            <option value="Exento">Exento</option>
                            <option value="Monotributista">Monotributista</option>
                        </select>
                        {val.message('iva', newClientBillingData.iva, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group my-3">
                        <label htmlFor="type" className="mb-2">Tipo de cliente</label>
                        <select name="type" className="form-select" onChange={e => handleChangeClientBillingData(e)}>
                            <option value="">Seleccione</option>
                            <option value="Cliente Final">Cliente Final</option>
                            <option value="Distribuidor">Distribuidor</option>
                        </select>
                        {val.message('type', newClientBillingData.type, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group">
                        <label htmlFor="person" className="mb-2">Persona</label>
                        <select name="person" className="form-select" onChange={e => handleChangeClientBillingData(e)}>
                            <option value="">Seleccione</option>
                            <option value="Física">Física</option>
                            <option value="Jurídica">Jurídica</option>
                        </select>
                        {val.message('person', newClientBillingData.person, 'required', { className: 'text-danger' })}
                    </div>

                </div>
            </form>

            <div className="mt-3 mb-3 d-block mx-auto text-center">
                <button
                    type="button"
                    className="btn btn-secondary bg-transparent text-secondary col-5 me-2"
                    onClick={() => {
                        hideModal('newClient')
                        document.getElementById('clientForm').reset();
                        val.hideMessages();
                        setNewClient({})
                        setNewClientBillingData({})
                        forceUpdate(2)
                    }}>
                    CANCELAR
                </button>
                <button
                    id='submit_create_client'
                    type="button"
                    className="btn btn-info text-white col-5"
                    onClick={saveNewClient}>
                    GUARDAR
                </button>
            </div>
        </div>
    );

}