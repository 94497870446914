import React, { useState, useEffect } from "react";

import CalibrationService from "../../../Services/Calibrations/CalibrationService";
import { isAuth } from "../../../Utilities/Auth/AuthPermissions";

export default function HandleSuffixes() {

    const [suffixes, setSuffixes] = useState([]);
    const [newSuffix, setNewSuffix] = useState({ text: '' });
    const [nameInvalid, setNameInvalid] = useState(false);

    useEffect(() => {
        if (isAuth()) {
            CalibrationService.getCalSuffix()
                .then(data => {
                    setSuffixes(data);
                })
        }
    }, [])

    const handleChange = e => {
        e.preventDefault();
        setNewSuffix({
            text: e.target.value
        })
    }

    const saveNewSuffix = e => {
        e.preventDefault();
        if (newSuffix.text.length === 0) {
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
            CalibrationService.saveNewCalSuffix(newSuffix)
                .then(data => {
                    document.getElementById('suffixForm').reset();
                    setNewSuffix({ text: '' });
                    setSuffixes([...suffixes, data]);
                })
        }

    }

    const deleteSuffix = id => {
        CalibrationService.deleteCalSuffix(id)
            .then(() => {
                setSuffixes([
                    ...suffixes?.filter(item => item.id !== id)
                ])
            })
    }

    return (

        <div className="d-flex flex-column">
            {suffixes?.length === 0 ?
                <strong className="mx-auto">No hay sufijos guardados.</strong> :
                <table className="table table-hover text-center">
                    <tbody>
                        {suffixes?.map((suffix, idx) => {
                            return <tr key={idx}>
                                <td>{suffix.text}</td>
                                <td><button type="button" className="btn btn-sm btn-info bg-transparent text-info" onClick={() => deleteSuffix(suffix.id)}>Borrar</button></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
            <form id="suffixForm" onChange={(e) => handleChange(e)}>
                <label htmlFor="text">Nuevo</label>
                <div className="form-group d-flex mx-auto" >
                    <input type="text" name="text" className="form-control" />
                    {nameInvalid && <small className="text-danger">* Nombre requerido</small>}
                    <input type="submit" className="btn btn-info btn-sm ms-2 bg-transparent text-info" value="GUARDAR" onClick={saveNewSuffix} />
                </div>
            </form>
        </div>

    )
}