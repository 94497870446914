import React, { useContext, useEffect, useState } from "react";
import { FiCopy } from 'react-icons/fi'
import { BiEdit } from "react-icons/bi";

import BillingDataDetails from "./BillingDataDetails";
import useDistCenters from "../../Hooks/Stock/useDistCenters";
import OrderContext from '../../Contexts/OrderContext'
import Loader from "../Commons/Loader";
import ShowPreassembledInputProducts from "./ShowPreasembledInputProducts";
import LogisticData from "../../Pages/WorkOrders/Components/LogisticData";
import EditClientDataOnOrder from "../Commercial/EditClientDataOnOrder";

import { isAdmin, isCustomerSuccess, isFinancial, isManufacturer, isSellerEditor } from "../../Utilities/Auth/AuthPermissions";
import { getCurrentStatus } from "../../Utilities/Orders/GetCurrentStatus";
import { showModal, showTooltip } from "../../Utilities/General/BootstrapApi";
import { copy } from '../../Utilities/General/Clipboard'

export default function ViewOrder({
  order,
  clients,
  setClients,
  emails,
  countries,
  stockOrder,
  articles,
  saleProductStock,
  uSoundPaymentOrder,
  paymentServiceProducts,
  customers,
  typeOrderStatus,
  clientBillingData,
  setClientBillingData
}) {

  const { orders } = useContext(OrderContext)

  const distCenters = useDistCenters()

  const [currentStatus, setCurrentStatus] = useState('')
  const [currentSaleProductItem, setCurrentSaleProductItem] = useState('')
  const [editClient, setEditClient] = useState({
    id_client: '',
    vat_number: '',
    carnet_id: ''
  })
  const [newLogisticData, setNewLogisticData] = useState({
    logistic_company: '',
    tracking_code: ''
  })
  const [preassembledToExplore, setPreassembledToExplore] = useState({
    movementdetail_set: []
  })
  const [totalFeatures, setTotalFeatures] = useState(null)

  const shippingData = order?.ordershippingdata;
  const billingData = order?.orderbillingdata;

  useEffect(() => {
    if (order !== undefined) {
      //console.log(typeOrderStatus)
      setCurrentStatus(getCurrentStatus(order.id, typeOrderStatus, orders))
    }
  }, [order, typeOrderStatus, orders])

  useEffect(() => {
    if (uSoundPaymentOrder !== undefined) {
      const reduced = uSoundPaymentOrder?.orderdetail_set.map(od => parseFloat(od.amount)).reduce((prev, curr) => prev + curr, 0).toFixed(2)
      setTotalFeatures(isNaN(reduced) ? 0 : reduced)
    }
  }, [uSoundPaymentOrder])

  return (
    <>

      <EditClientDataOnOrder
        editClient={editClient}
        setEditClient={setEditClient}
        clients={clients}
        setClients={setClients}
        clientBillingData={clientBillingData}
        setClientBillingData={setClientBillingData}
      />

      {order === undefined ?
        <Loader />
        :
        <div>
          <div className="mt-3">
            <div className="d-flex">
              <h4 className="text-info">Cliente</h4>
              {(order.orderstatus_set
                .filter(os => os.type_order_status_spanish_name !== 'Editada')
                .length === 1 && (isAdmin() || isSellerEditor())) &&
                <div
                  id="editClient"
                  className="ms-2 only-cursor-pointer fs-5"
                  onMouseEnter={() => showTooltip('editClient', `Editar ${order.payments_service_customer_id === null ?
                    clients.find(c => c.id === order.new_client).name :
                    customers.find(c => c.id === parseInt(order.payments_service_customer_id)).name}`)}
                  onClick={() => {
                    setEditClient({
                      ...clients.find(c => c.id === order.new_client),
                      vat_number: clientBillingData.find(cbd => cbd.client === order.new_client).vat_number,
                      carnet_id: clientBillingData.find(cbd => cbd.client === order.new_client).carnet_id
                    })
                    showModal('editClientOnOrder')
                  }}
                >
                  <BiEdit />
                </div>
              }
            </div>
            <table className="table table-hover text-center">
              <tbody>
                <tr>
                  <th>Nombre</th>
                  <td>
                    {
                      order.payments_service_customer_id === null ?
                        clients.filter(c => c.id === order.new_client)[0]?.name :
                        customers.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.name
                    }
                    <FiCopy
                      id="nameTT"
                      className="ms-2 only-cursor-pointer"
                      onClick={() => copy('nameTT', order.payments_service_customer_id === null ?
                        clients.find(c => c.id === order.new_client).name :
                        customers.find(c => c.id === parseInt(order.payments_service_customer_id)).name)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Documento</th>
                  <td>
                    {
                      order.payments_service_customer_id === null ?
                        clients.filter(c => c.id === order.new_client)[0]?.id_client :
                        customers.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.shipping.recipient_id
                    }
                    <FiCopy
                      id="dniTT"
                      className="ms-2 only-cursor-pointer"
                      onClick={() => copy('dniTT', order.payments_service_customer_id === null ?
                        clients.filter(c => c.id === order.new_client)[0].id_client :
                        customers.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.shipping.recipient_id)}
                    />
                  </td>
                </tr>
                {order.new_client !== null &&
                  <tr>
                    <th>CUIT/CUIL/RUT</th>
                    <td>
                      {clientBillingData.find(c => c.client === order.new_client)?.vat_number}
                      <FiCopy
                        id="vatTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('vatTT', clientBillingData.find(c => c.client === order.new_client)?.vat_number)}
                      />
                    </td>
                  </tr>
                }
                {order.new_client !== null &&
                  <tr>
                    <th>Matrícula</th>
                    <td>
                      {clientBillingData.find(c => c.client === order.new_client)?.carnet_id}
                      <FiCopy
                        id="carnetTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('carnetTT', clientBillingData.find(c => c.client === order.new_client)?.carnet_id)}
                      />
                    </td>
                  </tr>
                }
                <tr>
                  <th>Email/s</th>
                  <td>
                    {
                      order.payments_service_customer_id === null ?
                        emails.filter(item => item.client === order.new_client).length > 0
                          ? emails
                            .filter(item => item.client === order.new_client)
                            .map(email => (
                              <span key={email.id} className="d-block">
                                {email.email}
                                <FiCopy
                                  id="cliEmailTT"
                                  className="ms-2 only-cursor-pointer"
                                  onClick={() => copy('cliEmailTT', email.email)}
                                />
                              </span>
                            ))
                          : "Sin emails registrados" :
                        <span>
                          {customers.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.email}
                          <FiCopy
                            id="cusEmailTT"
                            className="ms-2 only-cursor-pointer"
                            onClick={() => copy('cusEmailTT', customers.filter(c => c.id === parseInt(order.payments_service_customer_id))[0].email)}
                          />
                        </span>
                    }
                  </td>
                </tr>
                <tr>
                  <th>Observaciones</th>
                  <td>
                    {clients.filter(c => c.id === order.new_client)[0]?.note}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="my-5 md-overflow-scroll">
            <h4 className="text-info">Productos</h4>
            <table className="table table-hover text-center">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Detalle</th>
                  <th>Bonificado</th>
                  <th>Nro. de serie</th>
                  <th>Licencia</th>
                  <th>Distribuidor</th>
                </tr>
              </thead>
              <tbody>
                {order.paymentdetail_set.map((pd, pdIndex) => {
                  return (
                    <tr key={pdIndex}>
                      <td>
                        {pd.article_alias !== null ? pd.article_alias : pd.article_name}
                        {pd.article_name === 'Audiómetro Freemium' &&
                          <table className="table table-light mt-2">
                            <thead>
                              <tr>
                                <th>Equipo</th>
                                <th>Evaluación</th>
                                <th>Período</th>
                                <th>Precio final</th>
                                <th>% bonificado</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uSoundPaymentOrder?.orderdetail_set.filter(od => od.tag === pd.tag).length === 1 ?
                                <tr>
                                  <td>
                                    {pd.tag}
                                  </td>
                                  <td>
                                    {uSoundPaymentOrder?.orderdetail_set.find(od => od.tag === pd.tag)?.product_sku} - Upfront
                                  </td>
                                  <td>
                                    De por vida
                                  </td>
                                  <td>
                                    N/A
                                  </td>
                                  <td>
                                    N/A
                                  </td>
                                </tr> :
                                uSoundPaymentOrder?.orderdetail_set.map((upo, upoIndex) => {
                                  if (pd.tag === upo.tag) {
                                    return (
                                      <tr key={upoIndex}>
                                        <td>
                                          {upo.tag}
                                        </td>
                                        <td>
                                          {paymentServiceProducts.map(psp => {
                                            if (upo.product_sku === psp.sku) {
                                              return psp.name
                                            }
                                          })}
                                        </td>
                                        <td>
                                          {upo.pricing_period === '12' ? 'Anual' :
                                            upo.pricing_period === '6' ?
                                              upo.pricing_period + ' meses' : 'De por vida'
                                          }
                                        </td>
                                        <td>
                                          {`${upo.amount ? upo.amount : 'N/A'} ${upo.currency ? upo.currency : ''}`}
                                        </td>
                                        <td>
                                          {(upo.discount !== undefined && upo.discount !== null) ? upo.discount + '%' : 'N/A'}
                                        </td>
                                      </tr>
                                    )
                                  }
                                })}
                            </tbody>
                          </table>
                        }
                      </td>
                      <td>{pd.count}</td>
                      <td>{pd.rewarded ? "Sí" : "No"}</td>
                      <td>
                        {stockOrder === undefined ?
                          <span className="d-flex justify-content-center">
                            Sin asignar
                          </span> :
                          <span className='d-flex flex-column justify-content-center'>
                            {
                              pd.article_sku !== 'NBK_AUD_FREM_0001' ?
                                articles.map((art) => {
                                  return saleProductStock.map((sps) => {
                                    return stockOrder.ordersaleproductitem_set.map(
                                      (ospi, ospiIndex) => {
                                        if (
                                          pd.article === art.id &&
                                          art.sku === sps.sku &&
                                          sps.id === ospi.sale_product_item.sale_product
                                        ) {
                                          return (
                                            <>
                                              <span
                                                key={ospiIndex}
                                                className={`${currentStatus.spanish_name === 'Aceptada' ||
                                                  currentStatus.spanish_name === 'Armando' ||
                                                  currentStatus.spanish_name === 'Enviada' ||
                                                  currentStatus.spanish_name === 'Finalizada' ? 'cursor-pointer' : ''}`}
                                                onClick={() => {
                                                  if (currentStatus.spanish_name === 'Aceptada' ||
                                                    currentStatus.spanish_name === 'Armando' ||
                                                    currentStatus.spanish_name === 'Enviada' ||
                                                    currentStatus.spanish_name === 'Finalizada') {
                                                    // setPreassembledToExplore(saleProductItemMovementsList.find(item => item.id === ospi.sale_product_item.id))
                                                    setCurrentSaleProductItem(ospi.sale_product_item)
                                                    showModal('showPAip')
                                                  }
                                                }}
                                              >
                                                {ospi.sale_product_item.serial_number}
                                              </span>
                                              <ShowPreassembledInputProducts
                                                saleProductItem={currentSaleProductItem}
                                              />
                                            </>
                                          );
                                        }
                                      }
                                    );
                                  });
                                }) 
                              :
                                stockOrder.ordersaleproductitem_set
                                  .filter(ospi => ospi.sale_product_item.sale_product_sku === 'NBK_AUD_FREM_0001')
                                  .map((ospi, ospiIndex) => {
                                    if (pd.tag === '#' + (ospiIndex + 1)) {
                                      return (
                                        <>
                                          <span
                                            key={ospiIndex}
                                            className={`${currentStatus.spanish_name === 'Aceptada' ||
                                              currentStatus.spanish_name === 'Armando' ||
                                              currentStatus.spanish_name === 'Enviada' ||
                                              currentStatus.spanish_name === 'Finalizada' ? 'cursor-pointer' : ''}`}
                                            onClick={() => {
                                              if (currentStatus.spanish_name === 'Aceptada' ||
                                                currentStatus.spanish_name === 'Armando' ||
                                                currentStatus.spanish_name === 'Enviada' ||
                                                currentStatus.spanish_name === 'Finalizada') {
                                                // setPreassembledToExplore(saleProductItemMovementsList.find(item => item.id === ospi.sale_product_item.id))
                                                setCurrentSaleProductItem(ospi.sale_product_item)
                                                showModal('showPAip')
                                              }
                                            }}
                                          >
                                            {ospi.sale_product_item.serial_number}
                                          </span>
                                          <ShowPreassembledInputProducts
                                            saleProductItem={currentSaleProductItem}
                                          />
                                        </>
                                      );
                                    }
                                  })
                            }
                          </span>
                        }
                      </td>
                      <td>
                        <span className="d-flex flex-column justify-content-center">
                          {stockOrder === undefined &&
                            'Sin asignar'
                          }
                          {pd.article_sku !== 'NBK_AUD_FREM_0001' ?
                            articles.map((art) => {
                              return saleProductStock.map((sps) => {
                                return stockOrder?.ordersaleproductitem_set.map(
                                  (so, soIndex) => {
                                    if (
                                      pd.article === art.id &&
                                      art.sku === sps.sku &&
                                      sps.id === so.sale_product_item.sale_product
                                    ) {
                                      return (
                                        <span key={soIndex} className="d-flex justify-content-center">
                                          {so.sale_product_item.code !== null ? so.sale_product_item.code : 'Sin asignar'}
                                        </span>
                                      );
                                    }
                                  }
                                );
                              });
                            }) :
                            stockOrder?.ordersaleproductitem_set
                              .filter(ospi => ospi.sale_product_item.sale_product_sku === 'NBK_AUD_FREM_0001')
                              .map((ospi, ospiIndex) => {
                                if (pd.tag === '#' + (ospiIndex + 1)) {
                                  return (
                                    <span key={ospiIndex}>
                                      {ospi.sale_product_item.code !== null ? ospi.sale_product_item.code : 'Sin asignar'}
                                    </span>
                                  );
                                }
                              })
                          }
                        </span>
                      </td>
                      <td>
                        {stockOrder === undefined ?
                          'Sin asignar' :
                          <>
                            {distCenters.find(dc => dc.id === stockOrder.ordersaleproductitem_set.find(ospi => ospi?.sale_product_item.sale_product_sku === pd.article_sku)?.sale_product_item.distribution_center)?.name}
                          </>
                          || 'Sin asignar'
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div>
              {!isCustomerSuccess() &&
                <>
                  <span className="d-block mb-2">
                    <strong>Total Productos</strong>:{" "}
                    {billingData !== null ? billingData.amount : order.amount}{" "}
                    {order.advancepayment.difference_installments_currency}
                  </span>
                  <span className="d-block mb-2">
                    <strong>Total Características</strong>:{" "}
                    {totalFeatures === 0 ?
                      'N/A' :
                      <>
                        {totalFeatures}{" "}
                        {order.advancepayment.difference_installments_currency}
                      </>
                    }
                  </span>
                  <span className="d-block mb-2">
                    <strong>Total</strong>:{" "}
                    {(parseFloat(billingData !== null ? billingData.amount : order.amount) + parseFloat(totalFeatures)).toFixed(2)}{" "}
                    {order.advancepayment.difference_installments_currency}
                  </span>
                </>
              }
              <span className="d-block">
                <strong>Observaciones</strong>: {order.note}
              </span>
            </div>
          </div>

          {!isCustomerSuccess() &&
            <div className="md-overflow-scroll">
              <h4 className="text-info">Pago</h4>
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th>Forma</th>
                    <th>Método</th>
                    <th>Cuotas</th>
                    <th>Importe</th>
                    <th>Moneda</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {order.advancepayment.full_payment ? "Total" : "Anticipo"}
                    </td>
                    <td>{order.advancepayment.method}</td>
                    <td>{1}</td>
                    <td>{order.advancepayment.advancepayment_amount}</td>
                    <td>
                      {order.advancepayment.difference_installments_currency}
                    </td>
                  </tr>
                  {!order.advancepayment.full_payment && (
                    <tr>
                      <td>Diferencia</td>
                      <td>
                        {order.advancepayment.difference_installments_method}
                      </td>
                      <td>{order.advancepayment.difference_installments}</td>
                      <td>
                        {order.advancepayment.difference_installments_amount}
                      </td>
                      <td>
                        {order.advancepayment.difference_installments_currency}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          }

          {(billingData !== null && (isAdmin() || isFinancial())) &&
            <BillingDataDetails
              order={order}
              billingData={billingData}
              countries={countries}
              currency={order.advancepayment.difference_installments_currency}
              clients={clients}
              customers={customers}
            />
          }

          {shippingData !== null &&
            <div className="mb-5">
              <h4 className="text-info">Datos de envío</h4>
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th>Dirección</th>
                    <td>
                      {shippingData.address}
                      <FiCopy
                        id="shipAddressTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipAddressTT', shippingData.address)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Piso.</th>
                    <td>
                      {shippingData.address_floor_apartment}
                      {shippingData.address_floor_apartment !== null &&
                        <FiCopy
                          id="shipFloorTT"
                          className="ms-2 only-cursor-pointer"
                          onClick={() => copy('shipFloorTT', shippingData.address_floor_apartment)}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Dpto.</th>
                    <td>
                      {shippingData.address_apartment}
                      {shippingData.address_apartment !== null &&
                        <FiCopy
                          id="shipFloorTT"
                          className="ms-2 only-cursor-pointer"
                          onClick={() => copy('shipFloorTT', shippingData.address_apartment)}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Ciudad</th>
                    <td>
                      {shippingData.address_city}
                      <FiCopy
                        id="shipCityTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipCityTT', shippingData.address_city)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>CP</th>
                    <td>
                      {shippingData.address_postal_code}
                      <FiCopy
                        id="shipPostalTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipPostalTT', shippingData.address_postal_code)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Estado / Provincia / Región</th>
                    <td>
                      {shippingData.address_province}
                      <FiCopy
                        id="shipProvinceTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipProvinceTT', shippingData.address_province)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>País</th>
                    <td>
                      {countries.map((country) => {
                        if (country.id === shippingData.address_country) {
                          return country.name;
                        }
                      })}
                      <FiCopy
                        id="shipCountryTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipCountryTT', countries.find(c => c.id === shippingData.address_country).name)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Teléfono de contacto</th>
                    <td>
                      {shippingData.phone_contact}
                      <FiCopy
                        id="shipPhoneTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipPhoneTT', shippingData.phone_contact)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Nombre Receptor</th>
                    <td>
                      {shippingData.name_recipient}
                      <FiCopy
                        id="shipNameRecipientTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipNameRecipientTT', shippingData.name_recipient)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Documento Receptor</th>
                    <td>
                      {shippingData.id_recipient}
                      <FiCopy
                        id="shipIdRecipientTT"
                        className="ms-2 only-cursor-pointer"
                        onClick={() => copy('shipIdRecipientTT', shippingData.id_recipient)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Observaciones</th>
                    <td>
                      {shippingData.observation}
                      {shippingData.observation?.length > 0 &&
                        <FiCopy
                          id="shipObsTT"
                          className="ms-2 only-cursor-pointer"
                          onClick={() => copy('shipObsTT', shippingData.observation)}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Empresa logística</th>
                    <td>
                      {shippingData.logistic_company}
                      {shippingData.logistic_company !== null &&
                        <FiCopy
                          id="shipLogCompTT"
                          className="ms-2 only-cursor-pointer"
                          onClick={() => copy('shipLogCompTT', shippingData.logistic_company)}
                        />
                      }
                      {((isManufacturer() || isAdmin()) &&
                        currentStatus.spanish_name !== 'Anulada') &&
                        <BiEdit
                          className="ms-2 only-cursor-pointer"
                          onClick={() => {
                            setNewLogisticData({
                              logistic_company: shippingData.logistic_company,
                              tracking_code: shippingData.tracking_code
                            })
                            showModal('logisticDataViewOrder')
                          }}
                        />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Código de seguimiento</th>
                    <td>
                      {shippingData.tracking_code}
                      {shippingData.tracking_code !== null &&
                        <FiCopy
                          id="shipTrackCodeTT"
                          className="ms-2 only-cursor-pointer"
                          onClick={() => copy('shipTrackCodeTT', shippingData.tracking_code)}
                        />
                      }
                      {((isManufacturer() || isAdmin()) &&
                        currentStatus.spanish_name !== 'Anulada') &&
                        <BiEdit
                          className="ms-2 only-cursor-pointer"
                          onClick={() => {
                            setNewLogisticData({
                              logistic_company: order.ordershippingdata.logistic_company,
                              tracking_code: order.ordershippingdata.tracking_code
                            })
                            showModal('logisticDataViewOrder')
                          }}
                        />
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }

          <LogisticData
            id="ViewOrder"
            ordershippingdata={shippingData}
            data={newLogisticData}
            setData={setNewLogisticData}
          />

          {!isCustomerSuccess() &&
            <div className="mb-3 md-overflow-scroll">
              <h4 className="text-info">Vendedores</h4>
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th>Cazador 1</th>
                    <th>Cazador 2</th>
                    <th>Closer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{order.sweeper_name}</td>
                    <td>{order.hunter_name}</td>
                    <td>{order.closer_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </div>
      }
    </>
  );
}
