import { useEffect, useState } from "react";

import PaymentService from '../../Services/Licenses/PaymentService'

export default function useCurrencies() {

    const [currencies, setCurrencies] = useState([])

    useEffect(() => {
        (async () => {
            const data = await PaymentService.getCurrencies()
            setCurrencies(data)
        })()
    }, [])

    return currencies
}