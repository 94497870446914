import { useContext, useState } from 'react';
import {
    useTable,
    useSortBy,
} from "react-table";
import { BiLoaderCircle } from 'react-icons/bi'

import TableOrdersHeader from './TableOrdersHeader';
import OrderContext from '../../Contexts/OrderContext';

import { paginateOrders } from '../../Utilities/Orders/Pagination'
import OrderService from '../../Services/Licenses/OrderService';
import { paginateLicenses } from '../../Utilities/CS/Pagination';
import LicenseService from '../../Services/Licenses/LicenseService';

export default function TableBackPaginated({
    data,
    columns,
    setData,
    paymentReplacement,
    fromCSPanel
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: paymentReplacement ?
                        'Ingreso' :
                        fromCSPanel ?
                            'serial_number' :
                            'id',
                    desc: true
                }
            ]
        },
        // metodo para eliminar case sensitive en sorting
        sortTypes: {
            alphanumeric: (row1, row2, columnName) => {
                const rowOneColumn = row1.values[columnName];
                const rowTwoColumn = row2.values[columnName];
                if (isNaN(rowOneColumn)) {
                    return rowOneColumn?.toUpperCase() >
                        rowTwoColumn?.toUpperCase()
                        ? 1
                        : -1;
                }
                if (rowOneColumn === null || rowTwoColumn === null) return 0
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        }
    }, useSortBy);

    const {
        nextPage,
        setNextPage,
        previousPage,
        setPreviousPage,
        totalRegisters,
        setTotalRegisters,
        pageSize
    } = useContext(OrderContext)

    const [currentPage, setCurrentPage] = useState(0)
    const [loadingPage, setLoadingPage] = useState(false)

    const toggleObservation = index => {
        let up = document.getElementById('upArrow' + index)
        let down = document.getElementById('downArrow' + index)
        let row = document.getElementById('row' + index)

        if (up.classList.contains('d-none')) {
            up.classList.remove('d-none')
        } else {
            up.classList.add('d-none')
        }

        if (down.classList.contains('d-none')) {
            down.classList.remove('d-none')
        } else {
            down.classList.add('d-none')
        }

        if (row.classList.contains('order-td')) {
            row.classList.remove('order-td')
        } else {
            row.classList.add('order-td')
        }
    }

    const handlePageChange = flag => {
        if (previousPage === null) {
            setCurrentPage(1)
            return
        }
        if (nextPage === null) {
            setCurrentPage(Array.from({ length: Math.ceil(totalRegisters / pageSize) }).length)
            return
        }
        let url = flag === 'prev' ? previousPage : nextPage
        let params = url.split('?')[1].split('&')
        let [offsetParam] = params.filter(p => p.includes('offset'))
        if (offsetParam === undefined) {
            setCurrentPage(0)
            return
        }
        let offsetStr = offsetParam.split('=')[1]
        let offset = parseInt(offsetStr) / 10
        setCurrentPage(offset)
    }

    return (
        <>

            <TableOrdersHeader
                setLoadingPage={setLoadingPage}
                setData={setData}
                fromCSPanel={fromCSPanel}
            />

            <div className="text-center md-overflow-scroll">
                <table className="table table-hover" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "  ↓" : "  ↑") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {data.length === 0 &&
                            <tr className="text-center">
                                <td colSpan="100%" className='border-0'>
                                    <strong className='d-block mt-3'>
                                        Sin resultados
                                    </strong>
                                </td>
                            </tr>
                        }
                        {loadingPage ?
                            <tr>
                                <td colSpan="100%" className='border-0'>
                                    <BiLoaderCircle className='text-info fs-2 mt-2 pageLoader' />
                                </td>
                            </tr> :
                            rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            if (cell.column.Header === 'Artículos' && cell.value.props.children.length > 1) {
                                                return <td {...cell.getCellProps()}>
                                                    <div className='d-flex col-12'>
                                                        <div id={'row' + cell.row.index} className='order-td col-10'>
                                                            {cell.render("Cell")}
                                                        </div>
                                                        <div
                                                            className='bg-info rounded h-25 ms-1 text-white cursor-pointer col-2'
                                                            onClick={() => toggleObservation(cell.row.index)}>
                                                            <span
                                                                id={'upArrow' + cell.row.index}
                                                                className='d-none'
                                                            >
                                                                &#9650;
                                                            </span>
                                                            <span
                                                                id={'downArrow' + cell.row.index}
                                                            >
                                                                &#9660;
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            {(data.length > 0 && pageSize <= 10 && totalRegisters > 10) &&
                <div className="my-5 d-flex justify-content-center">
                    <button
                        onClick={() => {
                            setLoadingPage(true)
                            handlePageChange('prev')
                            if (fromCSPanel) {
                                paginateLicenses(previousPage)
                                    .then(data => {
                                        setTotalRegisters(data.count)
                                        setNextPage(data.next)
                                        setPreviousPage(data.previous)
                                        setData(data.results)
                                        setLoadingPage(false)
                                    })
                            } else {
                                paginateOrders(previousPage)
                                    .then(data => {
                                        setTotalRegisters(data.count)
                                        setNextPage(data.next)
                                        setPreviousPage(data.previous)
                                        setData(data.results)
                                        setLoadingPage(false)
                                    })
                            }
                        }}
                        className="btn btn-info btn-sm text-white"
                        disabled={previousPage === null}
                    >
                        &#10096;&#10096;&#10096;
                    </button>
                    <div className="d-inline-block mx-1">
                        <div className="mx-1 d-flex align-items-center">
                            <small>Página</small>
                            <select
                                className="form-select mx-1"
                                onChange={(e) => {
                                    setLoadingPage(true)
                                    let value = parseInt(e.target.value) - 1
                                    setCurrentPage(value)
                                    if (fromCSPanel) {
                                        LicenseService.getLicenses({
                                            ordering: '-id',
                                            limit: pageSize,
                                            offset: value === 0 ? null : parseInt(value + '0')
                                        }).then(data => {
                                            setCurrentPage(value)
                                            setTotalRegisters(data.count)
                                            setNextPage(data.next)
                                            setPreviousPage(data.previous)
                                            setData(data.results)
                                            setLoadingPage(false)
                                        })
                                    } else {
                                        OrderService.getOrders({
                                            ordering: '-id',
                                            limit: pageSize,
                                            offset: value === 0 ? null : parseInt(value + '0')
                                        }).then(data => {
                                            setCurrentPage(value)
                                            setTotalRegisters(data.count)
                                            setNextPage(data.next)
                                            setPreviousPage(data.previous)
                                            setData(data.results)
                                            setLoadingPage(false)
                                        })
                                    }
                                }}
                            >
                                {Array.from({ length: Math.ceil(totalRegisters / pageSize) }).map((_, idx) => {
                                    if (idx === currentPage) return <option key={idx} value={idx + 1} selected>{idx + 1}</option>
                                    return <option key={idx} value={idx + 1}>{idx + 1}</option>
                                })}
                            </select>
                            <small>de<span className="ms-1">{Math.ceil(totalRegisters / pageSize)}</span></small>
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setLoadingPage(true)
                            handlePageChange('next')
                            if (fromCSPanel) {
                                paginateLicenses(nextPage)
                                    .then(data => {
                                        setTotalRegisters(data.count)
                                        setNextPage(data.next)
                                        setPreviousPage(data.previous)
                                        setData(data.results)
                                        setLoadingPage(false)
                                    })
                            } else {
                                paginateOrders(nextPage)
                                    .then(data => {
                                        setTotalRegisters(data.count)
                                        setNextPage(data.next)
                                        setPreviousPage(data.previous)
                                        setData(data.results)
                                        setLoadingPage(false)
                                    })
                            }
                        }}
                        className="btn btn-info btn-sm text-white"
                        disabled={nextPage === null}
                    >
                        &#10097;&#10097;&#10097;
                    </button>
                </div>
            }

        </>
    )
}