import React from "react";

export default function CreateClientBillingData({
    countries,
    val,
    forceUpdate,
    newClientBillingData,
    setNewClientBillingData,
    handleSubmit
}) {

    const handleChange = e => {
        setNewClientBillingData({
            ...newClientBillingData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="col-lg-8 mx-auto border border-muted rounded p-4">
            <h3 className="text-info text-center">Nuevos datos de facturación</h3>
            <form id="newCBDForm">

                <div className="d-flex flex-wrap">
                    <div className="form-group col-12 col-lg-4">
                        <label htmlFor="carnet_id">Matrícula</label>
                        <input type="text" name="carnet_id" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('carnet_idView', newClientBillingData.carnet_id, 'required|regex:[0-9A-Za-z- ]|not_regex:[!"#$%&()@]|max:100', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-lg-4 my-2 my-lg-0">
                        <label htmlFor="name">Razón Social</label>
                        <input type="text" name="name" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('razon_socialView', newClientBillingData.name, 'required|max:150|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü]', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-lg-4">
                        <label htmlFor="vat_number">Cuit/Cuil/RUT</label>
                        <input type="text" name="vat_number" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('vat_numberView', newClientBillingData.vat_number, 'required|max:80', { className: 'text-danger' })}
                    </div>
                </div>

                <div className="d-flex flex-wrap mt-lg-3">
                    <div className="form-group col-12 col-lg-3 my-2 my-lg-0">
                        <label htmlFor="iva">Cond. Fiscal</label>
                        <select name="iva" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            <option value="Consumidor Final">Consumidor Final</option>
                            <option value="Responsable Inscripto">Responsable Inscripto</option>
                            <option value="Exento">Exento</option>
                            <option value="Monotributista">Monotributista</option>
                        </select>
                        {val.message('ivaView', newClientBillingData.iva, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-lg-3">
                        <label htmlFor="type">Tipo</label>
                        <select name="type" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            <option value="Cliente Final">Cliente Final</option>
                            <option value="Distribuidor">Distribuidor</option>
                        </select>
                        {val.message('typeView', newClientBillingData.type, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-lg-3 my-2 my-lg-0">
                        <label htmlFor="person">Persona</label>
                        <select name="person" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            <option value="Física">Física</option>
                            <option value="Jurídica">Jurídica</option>
                        </select>
                        {val.message('personView', newClientBillingData.person, 'required', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-lg-3">
                        <label htmlFor="addres_country">País</label>
                        <select name="address_country" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            {countries.map((c, cIndex) => {
                                return <option key={cIndex} value={c.id}>{c.name}</option>
                            })}
                        </select>
                        {val.message('address_countryView', newClientBillingData.address_country, 'required', { className: 'text-danger' })}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn btn-sm bg-white text-info border border-info"
                        onClick={() => {
                            document.getElementById('newCBDDiv').classList.add('d-none')
                            document.getElementById('newCBDButton').classList.remove('d-none')
                            document.getElementById('newCBDForm').reset()
                            setNewClientBillingData({})
                            val.hideMessages()
                            forceUpdate(2)
                        }}
                    >
                        CANCELAR
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-info text-white ms-2"
                        onClick={handleSubmit}
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    )
}