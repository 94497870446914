import React from "react";
import { toast } from "react-toastify";

import PaymentService from "../../Services/Licenses/PaymentService";
import { hideModal } from "../../Utilities/General/BootstrapApi";
import { toggleDisabled } from "../../Utilities/General/HandleClick";

export default function CreateSeller({
    newSeller,
    setNewSeller,
    val,
    order,
    setOrder,
    sellers,
    setSellers,
    newSellerType,
    forceUpdate
}) {

    const handleChange = e => {
        setNewSeller({
            [e.target.name]: e.target.value
        });
    }

    // VENDEDORES
    const handleSubmit = () => {

        if (!val.fieldValid('name')) {
            val.showMessages()
            forceUpdate(1)
            return
        }

        toggleDisabled('submit_create_seller')
        PaymentService.saveNewSeller(newSeller)
            .then(data => {
                val.hideMessages()
                forceUpdate(2)
                document.getElementById('sellerForm').reset()
                setSellers([...sellers, data])
                setLastSeller(data.id)
                hideModal('newSeller')
                setNewSeller({})
                toggleDisabled('submit_create_seller')
                toast.success('Vendedor creado correctamente', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
            })
    }

    const setLastSeller = id => {
        if (newSellerType === 'sweeper') {
            document.getElementById('sweeperInput').value = parseInt(id)
            setOrder({
                ...order,
                sweeper: id
            })
        }
        if (newSellerType === 'hunter') {
            document.getElementById('hunterInput').value = parseInt(id)
            setOrder({
                ...order,
                hunter: id
            })
        }
        if (newSellerType === 'closer') {
            document.getElementById('closerInput').value = parseInt(id)
            setOrder({
                ...order,
                closer: id
            })
        }
    }

    return (
        <>
            <form id="sellerForm">
                <div className="form-group">
                    <label htmlFor="name">Nombre</label>
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={e => handleChange(e)}
                    />
                    {val.message('name', newSeller.name, 'required|max:150|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9]', { className: 'text-danger' })}
                </div>
            </form>
            <button
                id="submit_create_seller"
                type="button"
                className="btn btn-info text-white mt-2"
                onClick={handleSubmit}
            >
                GUARDAR
            </button>
        </>
    );
}