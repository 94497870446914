import {
    useTable,
    useSortBy,
    usePagination
} from "react-table";

export default function TableFrontPaginated({
    data,
    columns,
    // preAssembledTable
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,
        pageOptions,
        gotoPage,
        pageCount
    } = useTable({
        columns,
        data,
        initialState: {
            // sortBy: [
            //     {
            //         id: preAssembledTable === 'true' ? 'last_update' : 'id',
            //         desc: true
            //     }
            // ],
            pageSize: 20,
            pageindex: 0
        },
        // metodo para eliminar case sensitive en sorting
        sortTypes: {
            alphanumeric: (row1, row2, columnName) => {
                const rowOneColumn = row1.values[columnName];
                const rowTwoColumn = row2.values[columnName];
                if (isNaN(rowOneColumn)) {
                    return rowOneColumn.toUpperCase() >
                        rowTwoColumn.toUpperCase()
                        ? 1
                        : -1;
                }
                return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
            }
        }
    }, useSortBy, usePagination);

    const toggleObservation = index => {
        let up = document.getElementById('upArrow' + index)
        let down = document.getElementById('downArrow' + index)
        let row = document.getElementById('row' + index)

        if (up.classList.contains('d-none')) {
            up.classList.remove('d-none')
        } else {
            up.classList.add('d-none')
        }

        if (down.classList.contains('d-none')) {
            down.classList.remove('d-none')
        } else {
            down.classList.add('d-none')
        }

        if (row.classList.contains('order-td')) {
            row.classList.remove('order-td')
        } else {
            row.classList.add('order-td')
        }
    }

    return (
        <>

            <div className="text-center md-overflow-scroll">
                <table className="table table-hover" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "  ↓" : "  ↑") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page?.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header === 'Artículos' && cell.value.props.children.length > 1) {
                                            return <td {...cell.getCellProps()}>
                                                <div className='d-flex col-12'>
                                                    <div id={'row' + cell.row.index} className='order-td col-10'>
                                                        {cell.render("Cell")}
                                                    </div>
                                                    <div
                                                        className='bg-info rounded h-25 ms-1 text-white cursor-pointer col-2'
                                                        onClick={() => toggleObservation(cell.row.index)}>
                                                        <span
                                                            id={'upArrow' + cell.row.index}
                                                            className='d-none'
                                                        >
                                                            &#9650;
                                                        </span>
                                                        <span
                                                            id={'downArrow' + cell.row.index}
                                                        >
                                                            &#9660;
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {pageCount > 1 &&
                <div className="my-5 d-flex justify-content-center">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info btn-sm text-white">
                        &#10096;&#10096;&#10096;
                    </button>
                    <div className="d-inline-block mx-1">
                        {pageCount > 5 ?
                            <div className="mx-1 d-flex align-items-center">
                                <small>Página</small>
                                <select className="form-select mx-1" onChange={(e) => gotoPage(parseInt(e.target.value))}>
                                    {pageOptions.map((item, idx) => {
                                        if (parseInt(item) === parseInt(state.pageIndex)) {
                                            return <option key={idx} value={parseInt(item)} selected>{parseInt(item) + 1}</option>
                                        } else {
                                            return <option key={idx} value={parseInt(item)}>{parseInt(item) + 1}</option>
                                        }
                                    })}
                                </select>
                                <small>de<span className="ms-1">{pageCount}</span></small>
                            </div> :
                            <div className="mx-1 d-flex flex-wrap justify-content-center">
                                {pageOptions.map((item, idx) => {
                                    return <span key={idx}
                                        className={item === state.pageIndex ? "m-1 text-white bg-info p-1 rounded" : "m-2 text-info cursor-pointer"}
                                        onClick={() => { gotoPage(idx) }}>{parseInt(item) + 1}</span>
                                })}
                            </div>
                        }
                    </div>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info btn-sm text-white">
                        &#10097;&#10097;&#10097;
                    </button>
                </div>
            }
        </>
    )
}