import React from 'react'

export default function GroupsSearch({
    serialNumberRef,
    smartphoneRef,
    products,
    earphoneRef,
    boneHeadRef,
    wireRef,
    showModal,
    suffixRef,
    suffixes,
    getGroups
}) {
    return (
        <div>
            <div className="bg-light-green p-3 rounded">
                <h5 className="text-success">Teléfono y accesorios</h5>
            </div>
            <div>
                <div className="card-body d-flex justify-content-between flex-wrap">
                    <div className="col-12 col-sm-5 col-lg-2 mb-2 mb-lg-0">
                        <strong className="d-block mb-1">Número de serie</strong>
                        <input ref={serialNumberRef} type="text" className="form-control" placeholder="Ingresa número de serie" />
                    </div> 
                </div>
                <div className="card-body d-flex justify-content-between flex-wrap">
                    <div className="col-12 col-sm-5 col-lg-2 mb-2 mb-lg-0">
                        <strong className="d-block mb-1">Smartphone</strong>
                        <select ref={smartphoneRef} className="form-select">
                            <option value="all">Todos</option>
                            {products?.map((product, idx) => {
                                if (product.type === 'phone') {
                                    return (
                                        <option key={idx} value={product.name}>{product.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-5 col-lg-2 mb-2 mb-lg-0">
                        <strong className="d-block mb-1">Auricular</strong>
                        <select ref={earphoneRef} className="form-select">
                            <option value="all">Todos</option>
                            {products?.map((product, idx) => {
                                if (product.type === 'earphone') {
                                    return (
                                        <option key={idx} value={product.name}>{product.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-5 col-lg-2 mb-2 mb-lg-0">
                        <strong className="d-block mb-1">Vincha</strong>
                        <select ref={boneHeadRef} className="form-select">
                            <option value="all">Todos</option>
                            {products?.map((product, idx) => {
                                if (product.type === 'bone head') {
                                    return (
                                        <option key={idx} value={product.name}>{product.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-5 col-lg-2 mb-2 mb-lg-0">
                        <strong className="d-block mb-1">Cable</strong>
                        <select ref={wireRef} className="form-select">
                            <option value="all">Todos</option>
                            {products?.map((product, idx) => {
                                if (product.type === 'wire') {
                                    return (
                                        <option key={idx} value={product.name}>{product.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-5 col-lg-2 mb-3 mb-lg-0">
                        <span className="d-block mb-1"><strong>Sufijo</strong> <small className="text-info cursor-pointer" onClick={() => showModal('suffixModal')}>Editar</small></span>
                        <select ref={suffixRef} className="form-select">
                            <option value="all">Todos</option>
                            <option value="no suffix">Sin sufijo</option>
                            {suffixes?.map((suffix, idx) => {
                                return <option key={idx} value={suffix.text}>{suffix.text}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-12 col-sm-5 col-lg-1 d-flex align-items-end pb-sm-3 pb-lg-0">
                        <button type="button"
                            className="btn btn-sm btn-success text-white d-block mx-auto w-100 mb-sm-1 mb-lg-0"
                            onClick={() => getGroups()}
                        >
                            BUSCAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}