import SimpleReactValidator from 'simple-react-validator';

const validator = {

    rules: new SimpleReactValidator(
        {
        messages: {
            alpha_space: '* Este campo sólo puede contener letras y espacios.',
            required: '* Este campo es requerido.',
            alpha: '* Este campo sólo puede contener letras.',
            alpha_num: '* Este campo sólo puede contener letras y números',
            alpha_num_space: '* Este campo sólo puede contener letras, números y espacios.',
            email: '* Ingrese un e-mail válido.',
            numeric: '* Ingrese números válidos.',
            min: '* El dato ingresado es demasiado pequeño.',
            max: '* El dato ingresado es demasiado grande.',
            regex: '* El dato ingresado no es válido.',
            not_regex: '* El dato ingresado no es válido.'
        }
    })

}

export default validator;

