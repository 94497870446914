import React, { useEffect } from "react";

import GoogleMaps from "../../../AppComponents/Commercial/GoogleMaps";

export default function CreateShippingData({
    typePhone,
    phoneProviders,
    phoneContacts,
    clients,
    countries,
    order,
    clientShippingData,
    shippingData,
    setShippingData,
    phoneContact,
    setPhoneContact,
    autocompleteRef,
    handleAutocomplete,
    locationConfirmed,
    locationFound,
    val,
    handleShippingSection,
    mapCenter,
    handleSelect,
    confirmLocation,
    clientSelected,
    phoneContactRef,
    office
}) {

    // DATOS DE UBICACION
    const handleChangeShippingData = e => {
        setShippingData({
            ...shippingData,
            client: parseInt(order.new_client),
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'number') {
            setShippingData({
                ...shippingData,
                client: parseInt(order.new_client),
                phone_contact: e.target.value
            })
        }
    }

    useEffect(() => {
        if (shippingData !== undefined) {
            let [country] = countries.filter(c => c.id === parseInt(parseInt(shippingData.address_country)))
            if (country !== undefined) {
                handleSelect(`
                    ${shippingData.address_address} 
                    ${shippingData.address_number} 
                    ${shippingData.address_city} 
                    ${shippingData.address_province} 
                    ${country.name}
                `)
            }
        }
    }, [shippingData])

    // DATOS DE CONTACTO
    const toggleRecipient = e => {
        let clientCheck = document.getElementById('clientRecipientCheckbox')
        let otherCheck = document.getElementById('otherRecipientCheckbox')
        let newRecipient = document.getElementById('newRecipientData')
        let recipientId = document.getElementById('recipientId')
        let recipientName = document.getElementById('recipientName')
        let [currentClient] = clients.filter(c => c.id === parseInt(order.new_client))
        if (e.target.name === 'clientRecipient') {
            if (e.target.checked) {
                clientCheck.checked = true
                otherCheck.checked = false
                newRecipient.classList.add('d-none')
                // si recibe el cliente, seteo sus datos en shipping data en los campos correspondientes
                if (currentClient !== undefined) {
                    setShippingData({
                        ...shippingData,
                        client: parseInt(order.new_client),
                        name_recipient: currentClient.name,
                        id_recipient: currentClient.id_client
                    })
                }
            } else {
                otherCheck.checked = true
                newRecipient.classList.remove('d-none')
                recipientId.value = ''
                recipientName.value = ''
            }
        } else {
            if (e.target.checked) {
                clientCheck.checked = false
                newRecipient.classList.remove('d-none')
                recipientId.value = ''
                recipientName.value = ''
            } else {
                clientCheck.checked = true
                newRecipient.classList.add('d-none')
                // si recibe el cliente, seteo sus datos en shipping data en los campos correspondientes
                if (currentClient !== undefined) {
                    setShippingData({
                        ...shippingData,
                        client: parseInt(order.new_client),
                        name_recipient: currentClient.name,
                        id_recipient: currentClient.id_client
                    })
                }
            }
        }
    }

    // esto es para resetear el receptor cuando cambie el cliente seleccionado
    useEffect(() => {
        if (clientSelected !== null && clientSelected !== undefined) {
            toggleRecipient({
                target: {
                    name: 'clientRecipient',
                    checked: true
                },
            })
        }
    }, [clientSelected])

    const toggleNewPhoneContact = e => {
        let newPhoneData = document.getElementById('newPhoneData')
        let newPhoneTypeInput = document.getElementById('newPhoneTypeInput')
        let newPhoneProviderInput = document.getElementById('newPhoneProviderInput')
        let newCountryCodeInput = document.getElementById('newCountryCodeInput')
        let newAreaCodeInput = document.getElementById('newAreaCodeInput')
        let newNumberInput = document.getElementById('newNumberInput')
        let newLineInput = document.getElementById('newLineInput')
        let newNoteInput = document.getElementById('newNoteInput')
        if (e.target.name === 'phone_contact' && e.target.value === 'new') {
            newPhoneData.classList.remove('d-none')
            newPhoneTypeInput.value = ''
            newPhoneProviderInput.value = ''
            newCountryCodeInput.value = ''
            newAreaCodeInput.value = ''
            newNumberInput.value = ''
            newLineInput.value = ''
            newNoteInput.value = ''
        } else {
            newPhoneData.classList.add('d-none')
        }
    }

    const handleChangeRecipient = e => {
        setShippingData({
            ...shippingData,
            client: parseInt(order.new_client),
            [e.target.name]: e.target.value
        })
    }

    const handleChangePhoneContact = e => {
        setPhoneContact({
            ...phoneContact,
            client: parseInt(order.new_client),
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'phone_contact') {
            let value = e.target.value
            let split = value.split(' ')
            setPhoneContact({
                ...phoneContact,
                client: parseInt(order.new_client),
                number: split[3]
            })
        }
    }

    useEffect(() => {
        if (phoneContactRef.current?.value === 'new') {
            setShippingData({
                ...shippingData,
                phone_contact: `+${phoneContact.country_code} ${phoneContact.area_code} ${phoneContact.number}`
            })
        }
    }, [phoneContact])

    return (
        <>
            <div className="row d-flex flex-wrap">
                <div className="form-group mb-3">
                    <label htmlFor="shipping_data">Direcciones guardadas</label>
                    <div className="mt-2">
                        <span>Seleccione o ingrese una nueva dirección</span>
                        {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                            <select id="addressInput" className="form-select mt-2" name="address"
                                ref={autocompleteRef}
                                onChange={e => {
                                    handleChangeShippingData(e)
                                    handleAutocomplete(e.target.value)
                                }}                                >
                                <option value="new">Nueva</option>
                                {clientShippingData?.map((csd, csdIndex) => {
                                    if (csd.client === parseInt(order.new_client)) {
                                        return <option key={csdIndex} value={csd.address}>{csd.address}</option>
                                    }
                                })}
                                {office?.map(o => <option key={o.id} value={o.label}>
                                    {`${o.address_address} ${o.address_number} - ${o.label}`}
                                </option>)}
                            </select> :
                            <select id="addressInput" ref={autocompleteRef} className="form-select mt-2" name="address" disabled >
                                <option value=""></option>
                                {clientShippingData?.map((csd, csdIndex) => {
                                    if (csd.client === parseInt(order.new_client)) {
                                        return <option key={csdIndex} value={csd.address}>{csd.address}</option>
                                    }
                                })}
                                {office?.map(o => <option key={o.id} value={o.label}>
                                    {`${o.address_address} ${o.address_number} - ${o.label}`}
                                </option>)}
                            </select>
                        }
                    </div>
                </div>
                <div className="form-group col-lg-4">
                    <label className="col-6" htmlFor="address_country">País</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <select id="address_countryInput" className="form-select mt-1" name="address_country" onChange={e => handleChangeShippingData(e)} >
                            <option value=""></option>
                            {countries?.map((c, cIndex) => {
                                return <option key={cIndex} value={c.id}>{c.name}</option>
                            })}
                        </select> :
                        <select id="address_countryInput" className="form-select mt-1" name="address_country" onChange={e => handleChangeShippingData(e)} disabled >
                            <option value=""></option>
                            {countries?.map((c, cIndex) => {
                                return <option key={cIndex} value={c.id}>{c.name}</option>
                            })}
                        </select>
                    }
                    {
                        val.message(
                            'address_country',
                            shippingData.address_country,
                            'required',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-lg-4 mt-3 mt-lg-0">
                    <label htmlFor="address_province">Provincia / Estado / Región</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_provinceInput"
                            type="text"
                            name="address_province"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_provinceInput"
                            type="text"
                            name="address_province"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_province',
                            shippingData.address_province,
                            'required|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-lg-4 mt-3 mt-lg-0">
                    <label className="col-6" htmlFor="address_city">Ciudad</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_cityInput"
                            type="text"
                            name="address_city"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_cityInput"
                            type="text"
                            name="address_city"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_city',
                            shippingData.address_city,
                            'required|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
            </div>
            <div className="row d-flex flex-wrap mt-3">
                <div className="form-group col-xl-2">
                    <label htmlFor="address_postal_code">CP</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_postal_codeInput"
                            type="text"
                            name="address_postal_code"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_postal_codeInput"
                            type="text"
                            name="address_postal_code"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_postal_code',
                            shippingData.address_postal_code,
                            'required|numeric|max:50|not_regex:[-]',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-xl-3 mt-3 mt-xl-0">
                    <label htmlFor="address_address">Calle</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_addressInput"
                            type="text"
                            name="address_address"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_addressInput"
                            type="text"
                            name="address_address"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_address',
                            shippingData.address_address,
                            'required|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü/-]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-xl-3 mt-3 mt-xl-0">
                    <label htmlFor="address_number">Número</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_numberInput"
                            type="text"
                            name="address_number"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_numberInput"
                            type="text"
                            name="address_number"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_number',
                            shippingData.address_number,
                            'required|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü/-]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-xl-2 mt-2 mt-xl-0">
                    <label htmlFor="address_floor_apartment">Piso.</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_floor_apartmentInput"
                            type="text"
                            name="address_floor_apartment"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_floor_apartmentInput"
                            type="text"
                            name="address_floor_apartment"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_floor_apartment',
                            shippingData.address_floor_apartment,
                            'regex:[A-Za-z0-9]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="form-group col-xl-2 mt-2 mt-xl-0">
                    <label htmlFor="address_apartment">Dpto.</label>
                    {(!locationConfirmed && order.new_client !== undefined && order.new_client !== '') ?
                        <input
                            id="address_apartmentInput"
                            type="text"
                            name="address_apartment"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                        /> :
                        <input
                            id="address_apartmentInput"
                            type="text"
                            name="address_apartment"
                            className="form-control mt-1"
                            onChange={e => {
                                handleChangeShippingData(e)
                                handleShippingSection()
                            }}
                            disabled
                        />
                    }
                    {
                        val.message(
                            'address_apartment',
                            shippingData.address_apartment,
                            'regex:[A-Za-z0-9]|max:150',
                            { className: 'text-danger' }
                        )
                    }
                </div>
                <div className="d-flex flex-wrap justify-content-around">
                    <div className="form-group col-md-4 mt-3">
                        <label htmlFor="phone_contact">Teléfono de contacto</label>
                        <div>
                            <span>Seleccione o ingrese un nuevo teléfono</span>
                            {(order.new_client !== undefined && order.new_client !== '') ?
                                <select id="phone_contactInput"
                                    name="phone_contact"
                                    className="form-select mt-1"
                                    ref={phoneContactRef}
                                    onChange={e => {
                                        toggleNewPhoneContact(e)
                                        handleChangePhoneContact(e)
                                        handleChangeShippingData(e)
                                    }}                                >
                                    <option value="">Seleccione</option>
                                    <option value="new">Nuevo</option>
                                    {phoneContacts?.map((pc, pcIndex) => {
                                        if (pc.client === parseInt(order.new_client)) {
                                            return <option key={pcIndex}
                                                value={`+ ${pc.country_code} ${pc.area_code} ${pc.number}`}>
                                                {`+ ${pc.country_code} ${pc.area_code} ${pc.number}`}
                                            </option>
                                        }
                                    })}
                                </select> :
                                <select className="form-select mt-1" disabled >
                                    {phoneContacts?.map((pc, pcIndex) => {
                                        if (pc.client === parseInt(order.new_client)) {
                                            return <option key={pcIndex}
                                                value={`+ ${pc.country_code} ${pc.area_code} ${pc.number}`}>
                                                {`+ ${pc.country_code} ${pc.area_code} ${pc.number}`}
                                            </option>
                                        }
                                    })}
                                </select>
                            }
                        </div>
                        <div id="newPhoneData" className="mt-1 p-2 shadow rounded d-none">
                            <select
                                id="newPhoneTypeInput"
                                name="type_phone"
                                className="form-select"
                                onChange={e => handleChangePhoneContact(e)}
                            >
                                <option value="">Tipo</option>
                                {typePhone?.map((tp, tpIndex) => {
                                    return <option key={tpIndex} value={tp.id}>{tp.name}</option>
                                })}
                            </select>
                            <select
                                id="newPhoneProviderInput"
                                name="phone_provider"
                                className="form-select my-1"
                                onChange={e => handleChangePhoneContact(e)}
                            >
                                <option value="">Proveedor</option>
                                {phoneProviders?.map((pp, ppIndex) => {
                                    return <option key={ppIndex} value={pp.id}>{pp.name}</option>
                                })}
                            </select>
                            <div className="d-flex mt-1">
                                <span className="col-3">
                                    <input
                                        id="newCountryCodeInput"
                                        type="text"
                                        name="country_code"
                                        placeholder="Cód. país"
                                        className="form-control"
                                        onChange={e => handleChangePhoneContact(e)}
                                    />
                                    {
                                        val.message(
                                            'country_code',
                                            phoneContact.country_code,
                                            'numeric|not_regex:[-]|max:5',
                                            { className: 'text-danger' }
                                        )
                                    }
                                </span>
                                <span className="col-3">
                                    <input
                                        id="newAreaCodeInput"
                                        type="text"
                                        name="area_code"
                                        placeholder="Cód. área"
                                        className="form-control"
                                        onChange={e => handleChangePhoneContact(e)}
                                    />
                                    {
                                        val.message(
                                            'area_code',
                                            phoneContact.area_code,
                                            'numeric|not_regex:[-]|max:10',
                                            { className: 'text-danger' }
                                        )
                                    }
                                </span>
                                <span className="col-6">
                                    <input
                                        id="newNumberInput"
                                        type="text"
                                        name="number"
                                        placeholder="Número"
                                        className="form-control"
                                        onChange={e => {
                                            handleChangePhoneContact(e)
                                            handleChangeShippingData(e)
                                        }}
                                    />
                                    {
                                        val.message(
                                            'number',
                                            phoneContact.number,
                                            'numeric|not_regex:[-]|max:150',
                                            { className: 'text-danger' }
                                        )
                                    }
                                </span>
                            </div>
                            <select
                                id="newLineInput"
                                name="line"
                                className="form-select my-1"
                                onChange={e => handleChangePhoneContact(e)}
                            >
                                <option value="">Línea</option>
                                <option value="Móvil">Móvil</option>
                                <option value="Fija">Fija</option>
                            </select>
                            <textarea
                                id="newNoteInput"
                                placeholder="Observaciones"
                                name="note"
                                className="form-control textarea"
                                onChange={e => handleChangePhoneContact(e)}
                            ></textarea>
                            {
                                val.message(
                                    'note',
                                    phoneContact.note,
                                    'max:250',
                                    { className: 'text-danger' }
                                )
                            }
                        </div>
                    </div>
                    <div className="form-group col-md-4 mt-3">
                        <label htmlFor="recipient">Receptor</label>
                        <div className="d-flex flex-column justify-content-start">
                            <div className="d-flex mt-2">
                                <span>Recibe el cliente</span>
                                <input
                                    type="checkbox"
                                    id="clientRecipientCheckbox"
                                    name="clientRecipient"
                                    className="form-check ms-2"
                                    defaultChecked
                                    onChange={e => toggleRecipient(e)}
                                />
                            </div>
                            <div className="d-flex mt-1">
                                <span>Recibe un tercero</span>
                                <input
                                    type="checkbox"
                                    id="otherRecipientCheckbox"
                                    name="otherRecipient"
                                    className="form-check ms-2"
                                    onChange={e => toggleRecipient(e)}
                                />
                            </div>
                        </div>
                        <div id="newRecipientData" className="p-2 shadow rounded d-none">
                            <input
                                placeholder="DNI/CUIL/RUT del receptor"
                                id="recipientId"
                                type="text"
                                name="id_recipient"
                                className="form-control"
                                onChange={e => {
                                    handleChangeRecipient(e)
                                    handleShippingSection()
                                }}
                            />
                            {
                                val.message(
                                    'id_recipient',
                                    shippingData.id_recipient,
                                    'regex:[A-Za-z0-9/_-]|not_regex:[¡¿?!"@#¢$%&/()+]|max:100',
                                    { className: 'text-danger' }
                                )
                            }
                            <input
                                placeholder="Nombre del receptor"
                                id="recipientName"
                                type="text"
                                name="name_recipient"
                                className="form-control mt-1"
                                onChange={e => {
                                    handleChangeRecipient(e)
                                    handleShippingSection()
                                }}
                            />
                            {
                                val.message(
                                    'name_recipient',
                                    shippingData.name_recipient,
                                    'regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9]|max:150',
                                    { className: 'text-danger' }
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="my-4 pt-4 border-top border-muted overflow-hidden">
                    <GoogleMaps
                        locationFound={locationFound}
                        locationConfirmed={locationConfirmed}
                        confirmLocation={confirmLocation}
                        mapCenter={mapCenter}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="observation">Observaciones</label>
                    <textarea
                        name="observation"
                        className="form-control textarea"
                        onChange={e => {
                            handleChangeShippingData(e)
                            handleShippingSection()
                        }}
                    ></textarea>
                    {
                        val.message(
                            'observation',
                            shippingData.observation,
                            'max:500',
                            { className: 'text-danger' }
                        )
                    }
                </div>

            </div>
        </>
    )

}