import { useEffect, useState } from "react";

import StockOrderService from "../../Services/Stock/StockOrderService";

export default function useDistCenters() {
    const [distCenters, setDistCenters] = useState([])

    useEffect(() => {
        StockOrderService.getDistCenters().then(data => setDistCenters(data))
    }, [])
    
    return distCenters
}