import { useRef, useState } from "react"
import { FcSearch } from 'react-icons/fc'
import { GrClose } from 'react-icons/gr'
import { toast } from "react-toastify"

import SearchResults from "../../../AppComponents/Commons/SearchResults"
import { hideModal, showModal } from "../../../Utilities/General/BootstrapApi"

export default function MovementsFilter({
    originalMovementsToDisplay,
    suppliers,
    manufacturers,
    items,
    inputProducts
}) {

    const searchRef = useRef('')

    const [value, setValue] = useState('')
    const [results, setResults] = useState([])

    const applyFilters = () => {
        if (searchRef.current.value.length === 0) {
            return
        }
        toast.warning(`Filtrando entre ${originalMovementsToDisplay.length} registros. Espere por favor...`, {
            position: 'bottom-center',
            className: 'warning-toast',
            autoClose: false
        })
        let filteredData = []
        setTimeout(() => {
            let movementsDisplayed = [...originalMovementsToDisplay]
            movementsDisplayed.forEach(displayed => {
                if (
                    displayed.movement.toString() === searchRef.current.value ||
                    displayed.item_input_product_name?.toLowerCase().includes(searchRef.current.value.toLowerCase()) ||
                    displayed.item_input_product_serial_number.includes(searchRef.current.value) ||
                    displayed.quantity.toString() === searchRef.current.value ||
                    displayed.type?.toLowerCase().includes(searchRef.current.value.toLowerCase()) ||
                    displayed.timestamp.includes(searchRef.current.value) ||
                    displayed.user_name?.toLowerCase().includes(searchRef.current.value.toLowerCase()) ||
                    displayed.observation?.toLowerCase().includes(searchRef.current.value.toLowerCase())
                ) {
                    filteredData = [...filteredData.filter(item => item.id !== displayed.id), displayed]
                }
                items.forEach(item => {
                    suppliers.forEach(sup => {
                        if (displayed.item === item.id &&
                            item.supplier === sup.id &&
                            sup.name.toLowerCase().includes(searchRef.current.value.toLowerCase())
                        ) {
                            filteredData = [...filteredData.filter(item => item.id !== displayed.id), displayed]
                        }
                    })
                })
                items.forEach(item => {
                    inputProducts.forEach(ip => {
                        manufacturers.forEach(man => {
                            if (
                                displayed.item === item.id &&
                                item.input_product === ip.id &&
                                ip.manufacturer === man.id &&
                                man.name.toLowerCase().includes(searchRef.current.value.toLowerCase())
                            ) {
                                filteredData = [...filteredData.filter(item => item.id !== displayed.id), displayed]
                            }
                        })
                    })
                })
            })
            setValue(searchRef.current.value)
            setResults([...filteredData])
            showModal('searchResultsModal')
            toast.dismiss()
        }, 2000);
    }

    const resetFilter = () => {
        document.getElementById('searchInput').value = ''
    }

    return (
        <div className="bg-light p-3 rounded col-5">
            <div className="input-group">
                <input
                    id="searchInput"
                    type="text"
                    className="form-control"
                    placeholder="Filtrar..."
                    aria-label="Filtrar..."
                    aria-describedby="basic-addon2"
                    ref={searchRef}
                />
                <span
                    className="input-group-text only-cursor-pointer"
                    id="basic-addon2"
                    onClick={() => resetFilter()}
                >
                    <GrClose />
                </span>
                <span
                    className="input-group-text only-cursor-pointer"
                    id="basic-addon2"
                    onClick={() => applyFilters()}
                >
                    <FcSearch />
                </span>
            </div>
            <SearchResults
                value={value}
                results={results}
                hideModal={hideModal}
            />
        </div>
    )
};
