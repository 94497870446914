import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import CreatePreAssembledOrder from './Components/CreatePreAssembledOrder';
import LicensesAssignement from './Components/LicensesAssignement';
import PreAssembledOrderAvailable from './Components/PreAssembledOrderAvailable';
import useDistCenters from '../../Hooks/Stock/useDistCenters';

import PaymentService from '../../Services/Licenses/PaymentService'
import InputProductService from '../../Services/Stock/InputProductService'
import SaleProductService from '../../Services/Stock/SaleProductService'
import StockOrderService from '../../Services/Stock/StockOrderService'
import MovementService from '../../Services/Stock/MovementService'
import { getToken, getUsername, isAdmin, isAuth, isCustomerSuccess, isManufacturer } from '../../Utilities/Auth/AuthPermissions';
import { hideTooltip, showTooltip, hideModal, showModal } from '../../Utilities/General/BootstrapApi';
import { toggleDisabled } from '../../Utilities/General/HandleClick'

export default function PreOrders() {

    const [typesInputProduct, setTypesInputProduct] = useState([]);
    const [articles, setArticles] = useState([])
    const [sellers, setSellers] = useState([])
    const [stockOrdersAvailable, setStockOrdersAvailable] = useState([])
    const [inputProducts, setInputProducts] = useState([]);
    const [saleProducts, setSaleProducts] = useState([]);
    const [saleProductStock, setSaleProductStock] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsStock, setItemsStock] = useState([]);
    const [saleProductItemMovementsList, setSaleProductItemMovementsList] = useState([]);

    const [newPAO, setNewPAO] = useState({
        ordersaleproductitem_set: []
    })

    const [newMovement, setNewMovement] = useState({
        movementdetail_set: []
    });

    const [workOnParent, setWorkOnParent] = useState(null);
    const [workOnStockOrder, setWorkOnStockOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingPAO, setLoadingPAO] = useState(false);

    let navigate = useNavigate()
    let params = useParams()

    const distCenters = useDistCenters()


    useEffect(() => {
        PaymentService.getSellers().then(data => setSellers(data))
        PaymentService.getArticles()
            .then(data => setArticles(data?.sort((a, b) => {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })))
        InputProductService.getTypesInputProduct().then(data => setTypesInputProduct(data))
        InputProductService.getInputProducts().then(data => setInputProducts(data))
        SaleProductService.getSaleProducts().then(data => setSaleProducts(data))
        SaleProductService.getSaleProductStock().then(data => {
            setSaleProductStock(data)
            setLoading(false)
        })
        StockOrderService.getStockOrdersAvailable("True").then(data => setStockOrdersAvailable(data))
        MovementService.getItems().then(data => setItems(data))
        MovementService.getItemsStock().then(data => setItemsStock(data))
    }, []);

    useEffect(() => {
        if (isAuth()) {
            StockOrderService.getSaleProductItemMovementsListByStatus('inputs products assigned').then(data => setSaleProductItemMovementsList(data))
        }
    }, [])


    

    const saveNewPAO = () => {
        // verifico que no haya cantidades en 0
        newPAO.ordersaleproductitem_set = newPAO.ordersaleproductitem_set.filter(item => item.count > 0)

        if (!checkNewPAOCounts(newPAO)) {
            toast.warning('Se creará una orden de pre-armado con productos sin stock.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })
        }

        StockOrderService.saveNewStockOrder(generateLicenseObject(newPAO))
            .then(data => {
                setStockOrdersAvailable([...stockOrdersAvailable, data])
                resetForm()
                setNewPAO({
                    ordersaleproductitem_set: []
                })
                hideModal('createPAOModal')
                toast.success('Orden creada correctamente', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
                setLoadingPAO(false)
            })
    }

    const resetForm = () => {
        saleProductStock.map((sps, spsIndex) => {
            document.getElementById('count' + spsIndex).value = 0
        })
    }

    const checkNewPAOCounts = newPAO => {
        let flag = true
        newPAO.ordersaleproductitem_set.map(ospi => {
            articles.map(art => {
                saleProductStock.map(sps => {
                    if (ospi.article === art.id &&
                        art.sku === sps.sku &&
                        sps.stock <= 0) {
                        flag = false
                    }
                })
            })
        })
        return flag
    }

    const generateLicenseObject = pao => {
        let result = {}
        let ospi = []
        pao.ordersaleproductitem_set.map(obj => {
            return articles.map(art => {
                return saleProducts.map(sp => {
                    if (obj.article === art.id && art.sku === sp.sku) {
                        for (let i = 0; i < obj.count; i++) {
                            ospi.push({
                                sale_product_item: {
                                    sale_product: sp.id,
                                    serial_number: 'EMPTY',
                                    status: 'created'
                                }
                            })
                        }
                    }
                })
            })
        })
        result.available = true
        result.ordersaleproductitem_set = ospi
        return result
    }

    const saveNewMovement = (changeSerial = false) => {

        toggleDisabled('assignement_btn')
        const newSerial = `${items.find(i => i.id === newMovement.movementdetail_set[0].item).serial_number}_${workOnStockOrder.serial_number}`

        // copia del stock order para actualizar estado
        let updatedData = workOnParent
        let dist = distCenters.find(dc => dc.name === "Oficina Jujuy (en base)")
        updatedData.ordersaleproductitem_set.forEach(ospi => {
            ospi.sale_product_item.distribution_center = dist.id
        })

        // recorrer arrar de datos actualizados
        for (let i = 0; i < updatedData.ordersaleproductitem_set.length; i++) {
            if (updatedData.ordersaleproductitem_set[i].sale_product_item.id === workOnStockOrder.id) {
                updatedData.ordersaleproductitem_set[i].sale_product_item.status = 'inputs products assigned'
                if (changeSerial) {
                    updatedData.ordersaleproductitem_set[i].sale_product_item.serial_number = newSerial
                }
            }
        }

        if (newMovement.movementdetail_set.length === 0) {
            return
        }

        if (changeSerial) {
            StockOrderService.updateSaleProductItem({
                ...workOnStockOrder,
                serial_number: newSerial
            })
                .then(() => {

                    // request de actualizacion de estados
                    StockOrderService.updateStockOrder(updatedData.id, updatedData)
                        .then(data => {

                            // actualizar el array de stock orders con la orden que se edito
                            setStockOrdersAvailable([
                                ...stockOrdersAvailable.filter(so => so.id !== updatedData.id),
                                data
                            ])

                            // compruebo si todos los sale product item ya fueron asignados
                            let is_available = false
                            for (let i = 0; i < data.ordersaleproductitem_set.length; i++) {
                                if (data.ordersaleproductitem_set[i].sale_product_item.status === 'created') {
                                    is_available = true
                                }
                            }

                            // si todos fueron asignados, cambio la disponibilidad de la licencia a false
                            if (!is_available) {
                                updatedData.available = false
                                StockOrderService.updateStockOrder(updatedData.id, updatedData)
                                    .then(data => {
                                        setStockOrdersAvailable([
                                            ...stockOrdersAvailable.filter(so => so.id !== updatedData.id)
                                        ])
                                    })
                            }

                            // guardar nuevo movimiento de egreso
                            MovementService.saveNewMovement(newMovement)
                                .then(() => {
                                    StockOrderService.getSaleProductItemMovementsList().then(data => setSaleProductItemMovementsList(data))
                                    MovementService.getItemsStock().then(data => setItemsStock(data))
                                    SaleProductService.getSaleProductStock().then(data => setSaleProductStock(data))
                                    setNewMovement({
                                        movementdetail_set: []
                                    });
                                    setWorkOnStockOrder(null)
                                    setWorkOnParent(null)
                                    let form = document.getElementById('assignementForm')
                                    if (form !== null) {
                                        form.reset()
                                    }
                                    toggleDisabled('assignement_btn')
                                    toast.success('Insumos asignados correctamente', {
                                        position: 'bottom-left',
                                        hideProgressBar: true,
                                        className: 'success-toast'
                                    })
                                })
                        })

                })

        } else {

            // request de actualizacion de estados
            StockOrderService.updateStockOrder(updatedData.id, updatedData)
                .then(data => {

                    // actualizar el array de stock orders con la orden que se edito
                    setStockOrdersAvailable([
                        ...stockOrdersAvailable.filter(so => so.id !== updatedData.id),
                        data
                    ])

                    // compruebo si todos los sale product item ya fueron asignados
                    let is_available = false
                    for (let i = 0; i < data.ordersaleproductitem_set.length; i++) {
                        if (data.ordersaleproductitem_set[i].sale_product_item.status === 'created') {
                            is_available = true
                        }
                    }

                    // si todos fueron asignados, cambio la disponibilidad de la licencia a false
                    if (!is_available) {
                        updatedData.available = false
                        StockOrderService.updateStockOrder(updatedData.id, updatedData)
                            .then(data => {
                                setStockOrdersAvailable([
                                    ...stockOrdersAvailable.filter(so => so.id !== updatedData.id)
                                ])
                            })
                    }
                })

            // guardar nuevo movimiento de egreso
            MovementService.saveNewMovement(newMovement)
                .then(() => {
                    MovementService.getItemsStock().then(data => setItemsStock(data))
                    SaleProductService.getSaleProductStock().then(data => setSaleProductStock(data))
                    setNewMovement({
                        movementdetail_set: []
                    });
                    setWorkOnStockOrder(null)
                    setWorkOnParent(null)
                    let form = document.getElementById('assignementForm')
                    if (form !== null) {
                        form.reset()
                    }
                    toggleDisabled('assignement_btn')
                    toast.success('Insumos asignados correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                })
        }
    }

    return (
        <>

            <PrincipalBar />

{/*             <AudiometerConverter
                data={saleProductItemMovementsList?.filter(spiml =>
                    spiml.status === 'inputs products assigned' && spiml.sale_product_sku === 'NBK_AUD_DIAG_0001') || []}
                setStockOrders={setStockOrders}
            /> */}

            <div className="modal fade" id="createPAOModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-info" id="staticBackdropLabel">Nueva orden de pre-armado</h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                resetForm()
                                setNewPAO({
                                    ordersaleproductitem_set: []
                                })
                                hideModal('createPAOModal')
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <CreatePreAssembledOrder
                                sellers={sellers}
                                setSellers={setSellers}
                                articles={articles}
                                saleProductStock={saleProductStock}
                                newPAO={newPAO}
                                setNewPAO={setNewPAO}
                                handleSubmit={saveNewPAO}
                                hideModal={hideModal}
                                resetForm={resetForm}
                                loadingPAO={loadingPAO}
                                setLoadingPAO={setLoadingPAO}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="licencesModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-info" id="staticBackdropLabel">{isCustomerSuccess() ? 'Consulta' : 'Asignación'} de insumos</h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                hideModal('licencesModal')
                                setWorkOnStockOrder(null)
                                setNewMovement({
                                    movementdetail_set: []
                                })
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <LicensesAssignement
                                saleProducts={saleProducts}
                                workOnStockOrder={workOnStockOrder}
                                items={items}
                                newMovement={newMovement}
                                inputProducts={inputProducts}
                                saleProductItemMovementsList={saleProductItemMovementsList}
                                distCenters={distCenters}
                            />
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                hideModal('licencesModal')
                                setWorkOnStockOrder(null)
                                setNewMovement({
                                    movementdetail_set: []
                                })
                            }}>CERRAR</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id='workOrdersHeader' className='d-flex flex-wrap mx-3 mt-5 p-3'>
                {!loading &&
                    <>
                        
                        <div className="col-12 col-lg-12 d-flex flex-column justify-content-center mt-4 mt-lg-0">
                            <h4 className='text-info text-center'>Órdenes de pre-armado sin insumos</h4>
                            {(isManufacturer() || isAdmin()) &&
                                <button
                                    type='button'
                                    className="py-2 mb-2 col-4 col-sm-4 col-md-3 align-self-end"
                                    onClick={() => showModal('createPAOModal')}
                                >
                                    +Orden de pre-armado
                                </button>
                            }
                            <PreAssembledOrderAvailable
                                saleProducts={saleProducts}
                                stockOrdersAvailable={stockOrdersAvailable}
                                setWorkOnParent={setWorkOnParent}
                                workOnStockOrder={workOnStockOrder}
                                setWorkOnStockOrder={setWorkOnStockOrder}
                                typesInputProduct={typesInputProduct}
                                items={items}
                                itemsStock={itemsStock}
                                newMovement={newMovement}
                                setNewMovement={setNewMovement}
                                saveNewMovement={saveNewMovement}
                                inputProducts={inputProducts}
                                saleProductItemMovementsList={saleProductItemMovementsList}
                                loading={loading}
                                distCenters={distCenters}
                            />
                            <button
                                type='button'
                                className="my-3 py-2 col-12 col-sm-8 col-md-6 align-self-center"
                                onClick={() => showModal('licencesModal')}
                            >
                                Ver Productos Prearmados
                            </button>
                            
                            <button
                                type='button'
                                className="py-2 col-12 col-sm-8 col-md-6 align-self-center"
                                onClick={() => navigate('/inicio')}
                            >
                                Volver
                            </button>
                        </div>
                    </>
                }
            </div>

        </>
    )

}