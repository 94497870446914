import React, { useRef } from "react";

import CreateManufacturer from "./CreateManufacturer";
import CreateTypeInputProduct from "./CreateTypeInputProduct";
import { hideModal } from "../../../Utilities/General/BootstrapApi";

export default function CreateInputProduct({
    setWillCreateManufacturer,
    willCreateManufacturer,
    willCreateTypeInputProduct,
    setWillCreateTypeInputProduct,
    toggleValues,
    cancelButton,
    saveNewManufacturer,
    setNewManufacturer,
    newManufacturer,
    manufacturers,
    newInputProduct,
    setNewInputProduct,
    handleSubmit,
    typesInputProduct,
    newTypeInputProduct,
    setNewTypeInputProduct,
    saveNewTypeInputProduct,
    val,
    forceUpdate
}) {

    const skuRef = useRef();
    const nameInputProductRef = useRef();
    const manufacturerRef = useRef();
    const type_input_productRef = useRef();

    const handleChange = e => {
        setNewInputProduct({
            sku: skuRef.current.value,
            name: nameInputProductRef.current.value,
            manufacturer: manufacturerRef.current.value,
            type_input_product: type_input_productRef.current.value,
            is_serializable: e.target.checked === undefined ? false : e.target.checked
        });
    }

    return (
        <>

            {newInputProduct !== undefined &&
                <div className="modal fade" id="newInputProduct" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                    setWillCreateTypeInputProduct(false)
                                    setWillCreateManufacturer(false)
                                    setNewInputProduct({});
                                    setNewTypeInputProduct({})
                                    setNewManufacturer({})
                                    document.getElementById('newInputProductForm').reset()
                                    hideModal('newInputProduct')
                                }}></button>
                            </div>
                            <div className="modal-body">

                                <div className="d-flex justify-content-center">
                                    <form id="newInputProductForm" className="col-12 m-3" onChange={e => handleChange(e)}>
                                        <div className="text-center bg-info p-1">
                                            <p className="text-white mb-1">Alta de artículo</p>
                                        </div>
                                        <div className="p-3">
                                            <div className="form-group mb-2">
                                                <label htmlFor="skuIP">SKU</label>
                                                <input type="text" name="skuIP" ref={skuRef} className="form-control" />
                                                {val.current.message('skuIP', newInputProduct.sku, 'required|regex:[a-zA-Z0-9-/]|not_regex:[!¿¡?"@#$%&()=<>+]|max:50', { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="nameInputProduct">Nombre</label>
                                                <input type="text" name="nameInputProduct" ref={nameInputProductRef} className="form-control" />
                                                {val.current.message('nameInputProduct', newInputProduct.name, 'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&/()=<>+-]|max:200', { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="manufacturerInputProduct">Fabricante</label>
                                                {!willCreateManufacturer ?
                                                    <span className="cursor-pointer text-info ms-1" onClick={() => setWillCreateManufacturer(true)}>
                                                        Nuevo
                                                    </span> :
                                                    <span className="d-block col-8 mx-auto">
                                                        <CreateManufacturer
                                                            willCreateManufacturer={willCreateManufacturer}
                                                            setWillCreateManufacturer={setWillCreateManufacturer}
                                                            cancelButton={'true'}
                                                            handleSubmit={saveNewManufacturer}
                                                            newManufacturer={newManufacturer}
                                                            setNewManufacturer={setNewManufacturer}
                                                            val={val}
                                                            forceUpdate={forceUpdate}
                                                        />
                                                    </span>
                                                }
                                                <select name="manufacturerIP" ref={manufacturerRef} className="form-select">
                                                    <option value="">Seleccione</option>
                                                    {manufacturers?.map((elem, idx) => {
                                                        return <option key={idx} value={elem.id}>{elem.name}</option>
                                                    })}
                                                </select>
                                                {val.current.message('manufacturerIP', newInputProduct.manufacturer, 'required', { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="type_input_productIP">Tipo</label>
                                                {!willCreateTypeInputProduct ?
                                                    <span className="cursor-pointer text-info ms-1" onClick={() => setWillCreateTypeInputProduct(true)}>
                                                        Nuevo
                                                    </span> :
                                                    <span className="d-block col-8 mx-auto">
                                                        <CreateTypeInputProduct
                                                            willCreateTypeInputProduct={willCreateTypeInputProduct}
                                                            setWillCreateTypeInputProduct={setWillCreateTypeInputProduct}
                                                            cancelButton={'true'}
                                                            handleSubmit={saveNewTypeInputProduct}
                                                            newTypeInputProduct={newTypeInputProduct}
                                                            setNewTypeInputProduct={setNewTypeInputProduct}
                                                            val={val}
                                                            forceUpdate={forceUpdate}
                                                        />
                                                    </span>
                                                }
                                                <select name="type_input_productIP" ref={type_input_productRef} className="form-select" >
                                                    <option value="">Seleccione</option>
                                                    {typesInputProduct?.map((elem, idx) => {
                                                        return <option key={idx} value={elem.id}>{elem.name}</option>
                                                    })}
                                                </select>
                                                {val.current.message('type_input_productIP', newInputProduct.type_input_product, 'required', { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3 d-flex align-items-center">
                                                <label htmlFor="is_serializable">Serializable</label>
                                                <input type="checkbox" name="is_serializable" className="checkbox form-check-input" />
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <button type="button" className="btn btn-info btn-sm text-white" onClick={e => {
                                                    if (!val.current.fieldValid('skuIP') ||
                                                        !val.current.fieldValid('nameInputProduct') ||
                                                        !val.current.fieldValid('manufacturerIP') ||
                                                        !val.current.fieldValid('type_input_productIP')) {
                                                        val.current.showMessages()
                                                        forceUpdate(1)
                                                    } else {
                                                        handleSubmit(e, newInputProduct)
                                                    }
                                                }}>
                                                    GUARDAR
                                                </button>
                                                {!willCreateManufacturer && !willCreateTypeInputProduct && cancelButton &&
                                                    <input type="button" value="CANCELAR" className="btn btn-info btn-sm text-info bg-transparent"
                                                        onClick={() => toggleValues()} />
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    setWillCreateTypeInputProduct(false)
                                    setWillCreateManufacturer(false)
                                    setNewInputProduct({});
                                    setNewTypeInputProduct({})
                                    setNewManufacturer({})
                                    document.getElementById('newInputProductForm').reset()
                                    hideModal('newInputProduct')
                                }}>CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}