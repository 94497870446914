import React, { useRef } from "react";

export default function CreateManufacturer({
    setWillCreateManufacturer,
    cancelButton,
    handleSubmit,
    newManufacturer,
    setNewManufacturer,
    val,
    forceUpdate,
    fromInventory,
    hideModal
}) {

    const nameRef = useRef();

    const handleChange = () => {
        setNewManufacturer({
            name: nameRef.current.value
        })
    }

    return (

        <form id="newManufacturerForm" className="border border-muted rounded m-3" onChange={handleChange}>
            <div className="text-center bg-info p-1">
                <p className="text-white mb-1">Alta de fabricante</p>
            </div>
            <div className="p-3">
                <div className="form-group mb-2">
                    <label htmlFor="name">Nombre</label>
                    <input type="text" name="nameMan" ref={nameRef} className="form-control" />
                    {val.current.message('nameMan', newManufacturer.name, 'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&=<>+]|max:200', { className: 'text-danger' })}
                </div>
                <div className="d-flex justify-content-around">
                    <button type="button" className="btn btn-info btn-sm text-white" onClick={e => {
                        if (!val.current.fieldValid('nameMan')) {
                            val.current.showMessages()
                            forceUpdate(1)
                        } else {
                            handleSubmit('create')
                            if(fromInventory){
                                hideModal('newManufacturer')
                            }
                        }
                    }}>
                        GUARDAR
                    </button>
                    {cancelButton &&
                        <input type="button" value="CANCELAR" className="btn btn-info btn-sm text-info bg-transparent"
                            onClick={() => {
                                setWillCreateManufacturer(false)
                            }}
                        />
                    }
                </div>
            </div>
        </form>

    )

}