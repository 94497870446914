import React from 'react';

export default function CheckEdit () {
        return (
            <div>
                <svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="Capa_1" data-name="Capa 1">
                        <path id="Trazado_612" data-name="Trazado 612" d="M12,1.913A10.087,10.087,0,1,1,1.913,12,10.087,10.087,0,0,1,12,1.913M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Z" transform="translate(0 0)" fill="#0dcaf0" />
                        <path id="Trazado_613" data-name="Trazado 613" d="M13.607,21.915a.8.8,0,0,1-.553-.222L8.388,17.4a1.166,1.166,0,0,1-.149-1.466A.811.811,0,0,1,9.5,15.76l3.981,3.661,5.808-8.212a.837.837,0,0,1,.847-.4.941.941,0,0,1,.7.68,1.177,1.177,0,0,1-.177,1.047L14.3,21.568A.861.861,0,0,1,13.607,21.915Z" transform="translate(-2.456 -4.369)" fill="#0dcaf0" />
                    </g>
                </svg>
            </div>
        )
    }