import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BiEdit } from 'react-icons/bi'
import { TiWarning } from 'react-icons/ti'

import BillingDataDetails from './BillingDataDetails'
import useDistCenters from '../../Hooks/Stock/useDistCenters'
import LogisticData from '../../Pages/WorkOrders/Components/LogisticData'

import { showModal } from '../../Utilities/General/BootstrapApi'
import { isAdmin, isAdminViewer, isCustomerSuccess, isFinancial, isManufacturer } from '../../Utilities/Auth/AuthPermissions'
import OrderService from '../../Services/Licenses/OrderService'
import { setPreAssembledStock } from '../../Utilities/Orders/SetPreAssembledStock'
import { setFreemiumTags } from '../../Utilities/Orders/SetFreemiumTags'
import { checkDcStockByCountry } from '../../Utilities/Orders/CheckDcStockByCountry'

export default function OrderDataConfirm({
    order,
    stockOrder,
    stockOrders,
    clients,
    emails,
    sellers,
    countries,
    articles,
    hideModal,
    handleSubmit,
    toOrderStatus,
    handlePaid,
    handleCancel,
    handleAccept,
    handleAssembling,
    handleSend,
    loadingChangeStatus,
    setLoadingChangeStatus,
    saleProducts,
    uSoundPaymentOrder,
    paymentServiceProducts,
    customers,
    fromCreateOrder,
    cancelObsRef,
    shippingData
}) {

    const [orderList, setOrderList] = useState([])
    const [spiList, setSpiList] = useState([])
    const [stock, setStock] = useState([])
    const [flattenData, setFlattenData] = useState([])
    const [obsLength, setObsLength] = useState(0)
    const [newLogisticData, setNewLogisticData] = useState({
        logistic_company: '',
        tracking_code: ''
    })
    const [preAssembledAvailable, setPreAssembledAvailable] = useState({
        result: false,
        country: ''
    })

    const distCenters = useDistCenters()

    const [totalFeatures, setTotalFeatures] = useState(null)

    useEffect(() => {
        if (order !== undefined) {
            let [freemArticle] = articles.filter(art => art.sku === 'NBK_AUD_FREM_0001')
            let iterable = [...order.paymentdetail_set]
            if (toOrderStatus === 'created or replacement') {
                iterable = setFreemiumTags(
                    order.paymentdetail_set,
                    articles,
                    saleProducts
                )
            }
            if (toOrderStatus === 'edition') {
                let freemiums = [...iterable.filter(pd => pd.article === freemArticle?.id)]
                for (let i = 0; i < freemiums.length; i++) {
                    freemiums[i].tag = '#' + (i + 1)
                }
                iterable = [
                    ...iterable.filter(pd => pd.article !== freemArticle?.id),
                    ...freemiums
                ]
            }
            let itemsForList = []
            for (let i = 0; i < iterable.length; i++) {
                for (let j = 0; j < iterable[i].count; j++) {
                    itemsForList.push({
                        ...iterable[i],
                        count: 1
                    })
                }
            }
            setOrderList(itemsForList)
        }
    }, [order])

    useEffect(() => {
        if (stockOrders !== undefined && saleProducts !== undefined) {
            setStock(setPreAssembledStock(stockOrders, saleProducts))
        }
    }, [stockOrders, saleProducts])

    useEffect(() => {
        if (toOrderStatus === 'accepted') {
            setPreAssembledAvailable(checkDcStockByCountry(order, distCenters, stockOrders, countries))
        }
    }, [order, distCenters, toOrderStatus, countries, stockOrders])

    useEffect(() => {
        if (uSoundPaymentOrder !== undefined) {
          const reduced = uSoundPaymentOrder?.orderdetail_set.map(od => od.amount === undefined ? 0 : parseFloat(od.amount)).reduce((prev, curr) => prev + curr, 0).toFixed(2)
          setTotalFeatures(isNaN(reduced) ? 0 : reduced)
        }
      }, [uSoundPaymentOrder])

    const handleChangeAssoc = (e, olIndex) => {
        if (!checkDuplicated(e.target.value)) document.getElementById('selectAssoc' + olIndex).value = ""
        if (e.target.value.length === 0) {
            setSpiList([...spiList.filter(item => item.index !== olIndex)])
            let elem = document.getElementById('selectDist' + olIndex)
            if (elem !== null) {
                elem.value = ''
            }
        }
        stockOrders?.forEach(so => {
            so.ordersaleproductitem_set.forEach(ospi => {
                if (ospi.sale_product_item.id === parseInt(e.target.value)) {
                    setSpiList([
                        ...spiList.filter(item => item.index !== olIndex),
                        {
                            id: ospi.sale_product_item.id,
                            sku: ospi.sale_product_item.sale_product_sku,
                            serial_number: ospi.sale_product_item.serial_number,
                            index: olIndex
                        }
                    ])
                }
            })
        })
    }

    const checkDuplicated = value => {
        let flag = true
        let license
        for (let i = 0; i < spiList.length; i++) {
            if (spiList[i].id === parseInt(value)) {
                flag = false
                license = spiList[i].serial_number
            }
        }
        if (!flag) {
            toast.error(`La licencia ${license} ya está asignada.`, {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
        }
        return flag
    }

    const checkAssigned = () => {
        // comprobar que todas las licencias esten asignadas
        let allAssigned = true
        if (spiList.length < orderList.filter(ol => ol.article_sku !== 'NBK_RENOV_ANU_0001').length && toOrderStatus === 'accepted') {
            allAssigned = false
        }
        if (!allAssigned) {
            toast.error('Existen productos sin asignar.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
        }
        return allAssigned
    }

    const handleSubmitAssoc = () => {
        const LAST_DIAG = 576
        let assignedDiags = spiList.filter(sl => sl.sku === 'NBK_AUD_DIAG_0001')
        if (assignedDiags.length > 0) {
            assignedDiags.forEach(ad => {
                let serial = parseInt(ad.serial_number.split('_')[1])
                if (serial <= LAST_DIAG) {
                    toast.warning(`El audiómetro n° ${serial} tiene una vincha y un cable divisor ya asignados. Solicite soporte a Jimena para separar estos insumos según los nuevos lineamientos. (Haga click en este mensaje para cerrarlo)`, {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        autoClose: false,
                        className: 'warning-toast'
                    })
                }
            })
        }
        if (!checkAssigned()) {
            setLoadingChangeStatus(false)
            return
        }
        setLoadingChangeStatus(true)
        OrderService.associatePreassembled(order.id, spiList)
            .then(() => {
                setSpiList([])
                handleAccept(order)
            })
    }

    useEffect(() => {
        let flatten = []
        stockOrders?.forEach(so => {
            so.ordersaleproductitem_set.forEach(ospi => {
                flatten.push(ospi.sale_product_item)
            })
        })
        setFlattenData([...flatten.sort((a, b) => {
            let first = parseInt(a.serial_number.substr(a.serial_number.length - 8))
            let second = parseInt(b.serial_number.substr(b.serial_number.length - 8))
            if (first > second) return 1
            if (first < second) return -1
            return 0
        })])
    }, [stockOrders])

    const handleChangeObs = e => {
        setObsLength(e.target.value.length)
    }

    useEffect(() => {
        checkObsLength()
    }, [obsLength])

    const checkObsLength = () => {
        let element = document.getElementById('obsLengthError')
        if (obsLength > 500) {
            element?.classList.remove('d-none')
            return false
        } else {
            element?.classList.add('d-none')
            return true
        }
    }

    return (
        <>
            <div className="modal fade" id="orderDataConfirm" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-info" id="staticBackdropLabel">
                                Confirmar
                                {
                                    toOrderStatus === 'paid' ? ' pago ' :
                                        toOrderStatus === 'canceled' ? ' anulación ' :
                                            toOrderStatus === 'accepted' ? ' aceptación ' :
                                                toOrderStatus === 'assembling' ? ' armado ' :
                                                    toOrderStatus === 'sended' ? ' envío ' :
                                                        ' datos '
                                }
                                de orden
                                {
                                    order !== undefined && order.id !== undefined && ` #${order.id}`
                                }
                            </h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                setSpiList([])
                                hideModal('orderDataConfirm')
                            }}></button>
                        </div>
                        {order !== undefined &&
                            <div className="modal-body">
                                {toOrderStatus === 'canceled' &&
                                    <>
                                        <div className='mt-3 shadow rounded'>
                                            <textarea
                                                id='obsTextarea'
                                                rows={8}
                                                className='textarea form-control'
                                                placeholder='Ingrese observaciones sobre la anulación (Opcional)'
                                                ref={cancelObsRef}
                                                onChange={e => handleChangeObs(e)}
                                            >
                                            </textarea>
                                        </div>
                                        <small
                                            className='text-muted d-flex justify-content-end mt-3 mb-4'
                                        >
                                            <span id='obsLengthError' className='d-none text-danger me-2 d-none'>
                                                * Observación demasiado larga
                                            </span>
                                            {obsLength + ' / 500'}
                                        </small>
                                    </>
                                }
                                <div className='mt-3'>
                                    <h4 className="text-info">Cliente</h4>
                                    <table className='table table-hover text-center'>
                                        <tbody>
                                            <tr>
                                                <th>Nombre</th>
                                                <td>
                                                    {
                                                        (order.payments_service_customer_id === null || fromCreateOrder === 'true') ?
                                                            clients?.filter(c => c.id === parseInt(order.new_client))[0]?.name :
                                                            customers?.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.name
                                                    }
                                                </td>
                                            </tr>
                                            {!isManufacturer() &&
                                                <tr>
                                                    <th>Documento</th>
                                                    <td>
                                                        {
                                                            (order.payments_service_customer_id === null || fromCreateOrder === 'true') ?
                                                                clients?.filter(c => c.id === parseInt(order.new_client))[0]?.id_client :
                                                                customers?.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.shipping.recipient_id
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                            {!isManufacturer() &&
                                                <tr>
                                                    <th>Email/s</th>
                                                    <td>
                                                        {emails !== undefined &&
                                                            <>
                                                                {
                                                                    (order.payments_service_customer_id === null || fromCreateOrder === 'true') ?
                                                                        emails?.filter(item => item.client === parseInt(parseInt(order.new_client))).length > 0 ?
                                                                            emails?.filter(item => item.client === parseInt(parseInt(order.new_client))).map(email => (
                                                                                <span key={email.id} className='d-block'>
                                                                                    {email.email}
                                                                                </span>
                                                                            )) : 'Sin emails registrados' :
                                                                        customers?.filter(c => c.id === parseInt(order.payments_service_customer_id))[0]?.email
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <th>Observaciones</th>
                                                <td>
                                                    {clients?.filter(c => c.id === parseInt(order.new_client))[0]?.note}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="my-5 md-overflow-scroll">
                                    <h4 className="text-info">Productos</h4>
                                    {(toOrderStatus === 'accepted' && !preAssembledAvailable.result) &&
                                        <div className='d-flex justify-content-end align-items-center mb-3'>
                                            <TiWarning className='text-warning fs-2 me-2' />
                                            <strong className='text-warning'>
                                                No hay pre-armados disponibles en las bases de {preAssembledAvailable.country}
                                            </strong>
                                        </div>
                                    }
                                    <table className='table table-hover text-center'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Detalle</th>
                                                <th>Bonificado</th>
                                                {(toOrderStatus !== 'sended' && toOrderStatus !== 'canceled') &&
                                                    <th>
                                                        Stock de pre-armados
                                                    </th>
                                                }
                                                {(isManufacturer() ||
                                                    isAdmin() ||
                                                    isAdminViewer()) &&
                                                    <>
                                                        <th>Nros. de serie</th>
                                                        <th>Pre-armados</th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderList.sort((a, b) => a.article_name - b.article_name).map((ol, olIndex) => {
                                                return (
                                                    <tr key={olIndex}>
                                                        <td>
                                                            {saleProducts?.map(sp => {
                                                                return articles?.map(art => {
                                                                    if (art.sku === sp.sku && ol.article === art.id) {
                                                                        if (sp.name === 'Audiómetro Freemium') {
                                                                            return (
                                                                                <div key={sp.id}>
                                                                                    <p>{sp.alias !== null ? sp.alias : sp.name}</p>
                                                                                    <table className='table table-light'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Equipo</th>
                                                                                                <th>Evaluación</th>
                                                                                                <th>Período</th>
                                                                                                <th>Precio final</th>
                                                                                                <th>% bonificado</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {uSoundPaymentOrder?.orderdetail_set.filter(od => od.tag === ol.tag).length === 1 ?
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        {ol.tag}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {uSoundPaymentOrder?.orderdetail_set.find(od => od.tag === ol.tag)?.product_sku || uSoundPaymentOrder?.orderdetail_set.find(od => od.tag === ol.tag)?.sku} - Upfront
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        De por vida
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        N/A
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        N/A
                                                                                                    </td>
                                                                                                </tr> :
                                                                                                uSoundPaymentOrder?.orderdetail_set.map((od, psIndex) => {
                                                                                                    if (od.tag === ol.tag) {
                                                                                                        return paymentServiceProducts.map(psp => {
                                                                                                            return psp.pricing_set.map(pricing => {
                                                                                                                if (pricing.id === od.pricing) {
                                                                                                                    return (
                                                                                                                        <tr key={psIndex}>
                                                                                                                            <td>
                                                                                                                                {od.tag}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {psp.name}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {pricing.period === 12 ? 'Anual' : pricing.period === 6 ? pricing.period + ' meses' : 'De por vida'}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {`${od.amount ? od.amount : 'N/A'} ${od.currency ? od.currency : ''}`}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {(od.discount !== undefined && od.discount !== null) ? od.discount + '%' : 'N/A'}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                }
                                                                                                            })
                                                                                                        })
                                                                                                    }
                                                                                                })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return sp.alias !== null ? sp.alias : sp.name
                                                                    }
                                                                })
                                                            })}
                                                        </td>
                                                        <td>{ol.count}</td>
                                                        <td>{(ol.rewarded || isCustomerSuccess()) ? 'Sí' : 'No'}</td>
                                                        {(toOrderStatus !== 'sended' && toOrderStatus !== 'canceled') &&
                                                            <td>
                                                                {(!JSON.stringify(stock).includes(ol.article_alias !== null ? ol.article_alias : ol.article_name) &&
                                                                    ol.article_name !== undefined) &&
                                                                    <span className="bg-danger p-1 rounded text-white">
                                                                        0
                                                                    </span>
                                                                }
                                                                {(!JSON.stringify(stock).includes(articles.find(art => art.id === ol.article)?.alias !== null ?
                                                                    articles.find(art => art.id === ol.article)?.alias : articles.find(art => art.id === ol.article)?.name) &&
                                                                    ol.article_name === undefined) &&
                                                                    <span className="bg-danger p-1 rounded text-white">
                                                                        0
                                                                    </span>
                                                                }
                                                                {stock?.map(st => {
                                                                    return articles?.map(art => {
                                                                        if (ol.article === art.id && st.sale_product === (art.alias !== null ? art.alias : art.name)) {
                                                                            return (
                                                                                <span
                                                                                    key={art.id}
                                                                                    className="bg-success p-1 rounded text-white"
                                                                                >
                                                                                    {st.stock}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    })
                                                                })}
                                                            </td>
                                                        }
                                                        {(isManufacturer() ||
                                                            isAdmin() ||
                                                            isAdminViewer()) &&
                                                            <>
                                                                <td>
                                                                    {stockOrder === undefined ?
                                                                        <span className="d-flex justify-content-center">
                                                                            Sin asignar
                                                                        </span> :
                                                                        <span className="d-flex flex-column justify-content-center">
                                                                            {articles?.map((art) => {
                                                                                return saleProducts?.map((sp) => {
                                                                                    return stockOrder.ordersaleproductitem_set
                                                                                        .sort((a, b) => {
                                                                                            if (a.sale_product_item.sale_product_sku < b.sale_product_item.sale_product_sku) { return -1 }
                                                                                            if (a.sale_product_item.sale_product_sku > b.sale_product_item.sale_product_sku) { return 1 }
                                                                                            return 0
                                                                                        })
                                                                                        ?.map((ospi, ospiIndex) => {
                                                                                            if (
                                                                                                ol.article === art.id &&
                                                                                                art.sku === sp.sku &&
                                                                                                sp.id === ospi.sale_product_item.sale_product &&
                                                                                                olIndex === ospiIndex
                                                                                            ) {
                                                                                                return (
                                                                                                    <span key={ospiIndex}>
                                                                                                        {ospi.sale_product_item.serial_number}
                                                                                                    </span>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                        );
                                                                                });
                                                                            })}
                                                                        </span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {stockOrder === undefined ?
                                                                        ((isManufacturer() || isAdmin()) &&
                                                                            toOrderStatus === 'accepted' &&
                                                                            ol.article_sku !== 'NBK_RENOV_ANU_0001') ?
                                                                            <select id={'selectAssoc' + olIndex} className='form-select p-1'
                                                                                onChange={e => handleChangeAssoc(e, olIndex)}>
                                                                                <option value="">Seleccione</option>
                                                                                {distCenters.map(dc => {
                                                                                    let country = countries.find(c => c.iso_code === dc.country_iso_origin)
                                                                                    return (
                                                                                        <optgroup key={dc.id} label={`${dc.name} - ${country.name}${country.id === order.ordershippingdata.address_country && !dc.name.includes('(en viaje)') ? ' (Recomendado)' : ''}`}>
                                                                                            {flattenData
                                                                                                .filter(fd => fd.status === 'inputs products assigned' && fd.sale_product_sku === ol.article_sku)
                                                                                                .filter(fd => fd.distribution_center === dc.id)
                                                                                                .map(fd => (
                                                                                                    <option key={fd.id} value={fd.id}>
                                                                                                        {fd.serial_number}
                                                                                                    </option>
                                                                                                ))}
                                                                                        </optgroup>
                                                                                    )
                                                                                })
                                                                                }
                                                                            </select> :
                                                                            <span className="d-flex justify-content-center">
                                                                                Sin asignar
                                                                            </span> :
                                                                        <span className="d-flex flex-column justify-content-center">
                                                                            {articles?.map(art => {
                                                                                return saleProducts.map((sp) => {
                                                                                    return stockOrder?.ordersaleproductitem_set
                                                                                        .sort((a, b) => {
                                                                                            if (a.sale_product_item.sale_product_sku < b.sale_product_item.sale_product_sku) { return -1 }
                                                                                            if (a.sale_product_item.sale_product_sku > b.sale_product_item.sale_product_sku) { return 1 }
                                                                                            return 0
                                                                                        })
                                                                                        .map((ospi, ospiIndex) => {
                                                                                            if (
                                                                                                ol.article === art.id &&
                                                                                                art.sku === sp.sku &&
                                                                                                sp.id === ospi.sale_product_item.sale_product &&
                                                                                                olIndex === ospiIndex
                                                                                            ) {
                                                                                                return (
                                                                                                    <span key={ospiIndex}>
                                                                                                        {ospi.sale_product_item.code !== null ? ospi.sale_product_item.code : 'Sin asignar'}
                                                                                                    </span>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                        );
                                                                                });
                                                                            })}
                                                                        </span>
                                                                    }
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        {!isCustomerSuccess() &&
                                            <>
                                                <span className="d-block mb-2">
                                                    <strong>Total Productos</strong>:{" "}
                                                    {toOrderStatus === 'created or replacement' && Object.entries(order.orderbillingdata === null ? {} : order.orderbillingdata).length > 0 ? order?.orderbillingdata.amount : order.amount} {order.advancepayment.difference_installments_currency}
                                                </span>
                                                <span className="d-block mb-2">
                                                    <strong>Total Características</strong>:{" "}
                                                    {totalFeatures === 0 ?
                                                        'N/A' :
                                                        <>
                                                            {totalFeatures}{" "}
                                                            {order.advancepayment.difference_installments_currency}
                                                        </>
                                                    }
                                                </span>
                                                <span className="d-block mb-2">
                                                    <strong>Total</strong>:{" "}
                                                    {(parseFloat(Object.entries(order.orderbillingdata === null ? {} : order.orderbillingdata).length > 0 ? order?.orderbillingdata.amount : order.amount) + parseFloat(totalFeatures)).toFixed(2)}{" "}
                                                    {order.advancepayment.difference_installments_currency}
                                                </span>
                                            </>
                                        }
                                        <span className='d-block'>
                                            <strong>Observaciones</strong>: {order.note}
                                        </span>
                                    </div>
                                </div>

                                {(!isManufacturer() && !isCustomerSuccess()) &&
                                    <div className='md-overflow-scroll'>
                                        <h4 className="text-info">Pago</h4>
                                        <table className='table table-hover text-center'>
                                            <thead>
                                                <tr>
                                                    <th>Forma</th>
                                                    <th>Método</th>
                                                    <th>Cuotas</th>
                                                    <th>Importe</th>
                                                    <th>Moneda</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{order.advancepayment.full_payment ? 'Total' : 'Anticipo'}</td>
                                                    <td>{order.advancepayment.method}</td>
                                                    <td>{1}</td>
                                                    <td>{order.advancepayment.advancepayment_amount}</td>
                                                    <td>{order.advancepayment.advancepayment_currency}</td>
                                                </tr>
                                                {!order.advancepayment.full_payment &&
                                                    <tr>
                                                        <td>Diferencia</td>
                                                        <td>{order.advancepayment.difference_installments_method}</td>
                                                        <td>{order.advancepayment.difference_installments}</td>
                                                        <td>{order.advancepayment.difference_installments_amount}</td>
                                                        <td>{order.advancepayment.difference_installments_currency}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {((toOrderStatus === 'created or replacement' && Object.entries(order.orderbillingdata).length > 0)
                                    || (toOrderStatus === 'edition' && order.orderbillingdata !== null)
                                    || (toOrderStatus !== 'created or replacement' && toOrderStatus !== 'edition' && toOrderStatus !== 'paid')) &&
                                    (isAdmin() || isFinancial()) &&
                                    <BillingDataDetails
                                        order={order}
                                        billingData={order?.orderbillingdata}
                                        countries={countries}
                                        currency={order.advancepayment.difference_installments_currency}
                                        clients={clients}
                                        customers={customers}
                                    />
                                }

                                {(shippingData || order?.ordershippingdata) &&
                                    <div className="my-5">
                                        <LogisticData
                                            id="OrderDataConfirm"
                                            ordershippingdata={shippingData || order?.ordershippingdata}
                                            data={newLogisticData}
                                            setData={setNewLogisticData}
                                        />
                                        <h4 className="text-info">Datos de envío</h4>
                                        <table className='table table-hover'>
                                            <tbody>
                                                <tr>
                                                    <th>Dirección</th>
                                                    <td>{shippingData?.address || order?.ordershippingdata.address}</td>
                                                </tr>
                                                <tr>
                                                    <th>Piso.</th>
                                                    <td>{shippingData?.address_floor_apartment || order?.ordershippingdata.address_floor_apartment}</td>
                                                </tr>
                                                <tr>
                                                    <th>Dpto.</th>
                                                    <td>{shippingData?.address_apartment || order?.ordershippingdata.address_apartment}</td>
                                                </tr>
                                                <tr>
                                                    <th>Ciudad</th>
                                                    <td>{shippingData?.address_city || order?.ordershippingdata.address_city}</td>
                                                </tr>
                                                <tr>
                                                    <th>CP</th>
                                                    <td>{shippingData?.address_postal_code || order?.ordershippingdata.address_postal_code}</td>
                                                </tr>
                                                <tr>
                                                    <th>Estado / Provincia / Región</th>
                                                    <td>{shippingData?.address_province || order?.ordershippingdata.address_province}</td>
                                                </tr>
                                                <tr>
                                                    <th>País</th>
                                                    <td>
                                                        {toOrderStatus === 'created or replacement' ?
                                                            countries?.find(c => c.id === parseInt(shippingData.address_country))?.name :
                                                            countries?.find(c => c.id === order.ordershippingdata.address_country)?.name
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Teléfono de contacto</th>
                                                    <td>{shippingData?.phone_contact || order?.ordershippingdata.phone_contact}</td>
                                                </tr>
                                                <tr>
                                                    <th>Nombre Receptor</th>
                                                    <td>{shippingData?.name_recipient || order?.ordershippingdata.name_recipient}</td>
                                                </tr>
                                                <tr>
                                                    <th>Documento Receptor</th>
                                                    <td>{shippingData?.id_recipient || order?.ordershippingdata.id_recipient}</td>
                                                </tr>
                                                <tr>
                                                    <th>Observaciones</th>
                                                    <td>{shippingData?.observation || order?.ordershippingdata.observation}</td>
                                                </tr>
                                                {isManufacturer() &&
                                                    <tr>
                                                        <th>Email/s del cliente</th>
                                                        <td>
                                                            {emails?.filter(item => item.client === parseInt(order.new_client)) !== undefined ?
                                                                emails?.filter(item => item.client === parseInt(order.new_client)).map(email => (
                                                                    <span key={email.id} className='d-block'>
                                                                        {email.email}
                                                                    </span>
                                                                )) :
                                                                'Sin emails registrados'
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th>Empresa logística</th>
                                                    <td>
                                                        {shippingData?.logistic_company || order?.ordershippingdata.logistic_company}
                                                        {(isManufacturer() || isAdmin()) &&
                                                            <BiEdit
                                                                className="ms-2 only-cursor-pointer"
                                                                onClick={() => {
                                                                    setNewLogisticData({
                                                                        logistic_company: order.ordershippingdata.logistic_company,
                                                                        tracking_code: order.ordershippingdata.tracking_code
                                                                    })
                                                                    showModal('logisticDataOrderDataConfirm')
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Código de seguimiento</th>
                                                    <td>
                                                        {shippingData?.tracking_code || order?.ordershippingdata.tracking_code}
                                                        {(isManufacturer() || isAdmin()) &&
                                                            <BiEdit
                                                                className="ms-2 only-cursor-pointer"
                                                                onClick={() => {
                                                                    setNewLogisticData({
                                                                        logistic_company: order.ordershippingdata.logistic_company,
                                                                        tracking_code: order.ordershippingdata.tracking_code
                                                                    })
                                                                    showModal('logisticDataOrderDataConfirm')
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {!isCustomerSuccess() &&
                                    <div className='mb-3 md-overflow-scroll'>
                                        <h4 className="text-info">Vendedores</h4>
                                        <table className='table table-hover text-center'>
                                            <thead>
                                                <tr>
                                                    <th>Cazador 1</th>
                                                    <th>Cazador 2</th>
                                                    <th>Closer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {sellers?.map(seller => {
                                                            if (seller.id === parseInt(order.sweeper)) {
                                                                return seller.name
                                                            }
                                                        })}
                                                    </td>
                                                    <td>
                                                        {sellers?.map(seller => {
                                                            if (seller.id === parseInt(order.hunter)) {
                                                                return seller.name
                                                            }
                                                        })}
                                                    </td>
                                                    <td>
                                                        {sellers?.map(seller => {
                                                            if (seller.id === parseInt(order.closer)) {
                                                                return seller.name
                                                            }
                                                        })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        }
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                setSpiList([])
                                hideModal('orderDataConfirm')
                            }}>
                                CANCELAR
                            </button>
                            {loadingChangeStatus ?
                                <button type="button" className="btn btn-info text-white user-select-all"
                                    disabled
                                >
                                    CONFIRMAR
                                </button> :
                                <button type="button" className="btn btn-info text-white"
                                    onClick={() => {
                                        if (toOrderStatus === 'canceled' && !checkObsLength()) return
                                        setLoadingChangeStatus(true)
                                        if (toOrderStatus === 'paid') {
                                            handlePaid(order)
                                            return
                                        }
                                        if (toOrderStatus === 'canceled') {
                                            handleCancel(order)
                                            return
                                        }
                                        if (toOrderStatus === 'accepted') {
                                            handleSubmitAssoc()
                                            return
                                        }
                                        if (toOrderStatus === 'assembling') {
                                            handleAssembling(order)
                                            return
                                        }
                                        if (toOrderStatus === 'sended') {
                                            handleSend(order)
                                            return
                                        }
                                        handleSubmit()
                                    }}>
                                    CONFIRMAR
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}