import React, { useState, useEffect, useRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import swal from "sweetalert"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { toast } from "react-toastify"

import PrincipalBar from "../../AppComponents/Commons/PrincipalBar"
import CreateClient from '../../AppComponents/Commercial/CreateClient'
import CreateSeller from '../../AppComponents/Commercial/CreateSeller'
import EditShippingData from "./Components/EditShippingData"
import OrderDataConfirm from "../../AppComponents/Orders/OrderDataConfirm"
import OrderContext from "../../Contexts/OrderContext"
import EditFreemiumSettings from "./Components/EditFreemiumSettings"

import validator from "../../Utilities/General/Validator";
import { preventWheel } from "../../Utilities/General/PreventWheel"
import { showModal, hideModal } from "../../Utilities/General/BootstrapApi"
import OrderService from "../../Services/Licenses/OrderService"
import PaymentService from "../../Services/Licenses/PaymentService"
import ClientService from "../../Services/Licenses/ClientService"
import SaleProductService from "../../Services/Stock/SaleProductService"
import { getUsername, isAdmin, isAuth, isCustomerSuccess, isSellerEditor } from "../../Utilities/Auth/AuthPermissions"
import { getModifiedFields } from "../../Utilities/EditOrder/GetModifiedFields"
import StockOrderService from "../../Services/Stock/StockOrderService"
import { setPreAssembledStock } from "../../Utilities/Orders/SetPreAssembledStock"
import UsoundPaymentsService from '../../Services/UsoundPayments/UsoundPaymentsService'
import { checkBoneHeads } from "../../Utilities/Orders/CheckBoneHeads"

import CheckEdit from '../../Assets/CheckEdit'
import WarningEdit from '../../Assets/WarningEdit'

export default function EditOrder() {

  const {
    orders,
    setOrders
  } = useContext(OrderContext)

  const [sellers, setSellers] = useState([])
  const [typePhone, setTypePhone] = useState([])
  const [phoneProviders, setPhoneProviders] = useState([])
  const [phoneContacts, setPhoneContacts] = useState([])
  const [emailContacts, setEmailContacts] = useState([])
  const [articles, setArticles] = useState([])
  const [saleProducts, setSaleProducts] = useState([])
  const [clients, setClients] = useState([])
  const [clientShippingData, setClientShippingData] = useState([])
  const [clientBillingData, setClientBillingData] = useState([])
  const [typeIdClient, setTypeIdClient] = useState([])
  const [countries, setCountries] = useState([])
  const [currency, setCurrency] = useState([])
  const [office, setOffice] = useState([])
  const [stockOrders, setStockOrders] = useState([])
  const [stock, setStock] = useState([])
  const [usoundPaymentsProducts, setUsoundPaymentProducts] = useState([])
  const [uSoundPaymentOrder, setUsoundPaymentOrder] = useState({
    orderdetail_set: []
  })
  const [originalUSoundPaymentOrder, setOriginalUsoundPaymentOrder] = useState({
    orderdetail_set: []
  })
  const [originalOrder, setOriginalOrder] = useState({
    advancepayment: {},
    paymentdetail_set: [],
    orderstatus_set: [],
    ordershippingdata: {},
    orderbillingdata: {}
  })
  const [order, setOrder] = useState({
    advancepayment: {},
    paymentdetail_set: [],
    orderstatus_set: [],
    ordershippingdata: {},
    orderbillingdata: {}
  })
  const [typeOrderStatus, setTypeOrderStatus] = useState([])
  const [currentStatus, setCurrentStatus] = useState(null)
  const [paymentDetail, setPaymentDetail] = useState([])
  const [customers, setCustomers] = useState([])

  const [originalShippingData, setOriginalShippingData] = useState({})
  const [shippingData, setShippingData] = useState({})
  const [phoneContact, setPhoneContact] = useState({})
  const [newSeller, setNewSeller] = useState({})
  const [newClient, setNewClient] = useState({});
  const [newClientBillingData, setNewClientBillingData] = useState({});
  const [newEmailContact, setNewEmailContact] = useState({})
  const [newPhoneContact, setNewPhoneContact] = useState({})
  const [, forceUpdate] = useState()
  const [location, setLocation] = useState({});
  const [locationConfirmed, setLocationConfirmed] = useState(true)
  const [locationFound, setLocationFound] = useState(true)
  const [mapCenter, setMapCenter] = useState({
    lat: -24.1851575,
    lng: -80
  });
  const [clientSelected, setClientSelected] = useState(null)
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

  const autocompleteRef = useRef()
  const clientRef = useRef()

  const params = useParams()
  const navigate = useNavigate()
  let val = validator.rules

  // COMIENZO GENERAL
  useEffect(() => {
    if (isAuth()) {
      OrderService.getOrder(params.id)
        .then(data => {
          if (data?.origin === 'TiendaNube') {
            setTimeout(() => {
              navigate('/ordenes-venta')
            }, 1000);
          } else {
            setOriginalOrder(data)
            setOrder(data)
          }
        })
    }
  }, []);

  useEffect(() => {
    if (orders?.length === 0) {
      OrderService.getOrders().then(data => setOrders(data))
    }
  }, [orders])

  useEffect(() => {
    let title = document.getElementById('title1')
    let section = document.getElementById('section1')
    if (title !== null && section !== null) {
      title.classList.add('bg-info', 'text-white')
      section.classList.remove('d-none')
    }
    if (isAuth()) {
      OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
      ClientService.getTypePhone().then(data => setTypePhone(data))
      ClientService.getPhoneProvider().then(data => setPhoneProviders(data))
      ClientService.getPhoneContacts().then(data => setPhoneContacts(data))
      ClientService.getEmailContacts().then(data => setEmailContacts(data))
      ClientService.getClients().then(data => setClients(
        data?.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        })))
      ClientService.getClientShippingData().then(data => setClientShippingData(data))
      ClientService.getTypeIdClient().then(data => setTypeIdClient(data))
      ClientService.getClientBillingData().then(data => setClientBillingData(data))
      PaymentService.getSellers().then(data => setSellers(data))
      PaymentService.getArticles().then(data => setArticles(data))
      PaymentService.getCountries().then(data => setCountries(
        data?.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        })))
      PaymentService.getCurrencies().then(data => setCurrency(data))
      PaymentService.getOffice().then(data => setOffice(data))
      SaleProductService.getSaleProducts().then(data => setSaleProducts(
        data?.sort((a, b) => {
          if (a.name < b.name) { return -1 }
          if (a.name > b.name) { return 1 }
          return 0
        })))
      StockOrderService.getStockOrders().then(data => setStockOrders(data))
      UsoundPaymentsService.getCustomers().then(data => setCustomers(data))
      UsoundPaymentsService.getOrders().then(data => {
        setUsoundPaymentOrder(data?.filter(upo => parseInt(upo.order_id) === parseInt(params.id))[0])
        setOriginalUsoundPaymentOrder(data?.filter(upo => parseInt(upo.order_id) === parseInt(params.id))[0])
      })
      UsoundPaymentsService.getProducts().then(data => setUsoundPaymentProducts(data))
    }
  }, [originalOrder])

  useEffect(() => {
    let allStatus = order?.orderstatus_set?.filter(os => os.type_order_status_trunk)?.sort((a, b) => b.id - a.id)
    if (allStatus !== undefined) {
      setCurrentStatus(allStatus[0]?.type_order_status_spanish_name)
    }
  }, [typeOrderStatus, originalOrder])

  useEffect(() => {
    if (currentStatus !== null) {
      if (currentStatus === 'Anulada' ||
        ((currentStatus === 'Creada' || currentStatus === 'Pagada') && !isAdmin() && !isSellerEditor()) ||
        (currentStatus === 'Reposición' && !isAdmin() && !isCustomerSuccess()) ||
        ((currentStatus === 'Aceptada' || currentStatus === 'Armando' || currentStatus === 'Enviada') && !isAdmin()) ||
        (order?.payments_service_customer_id !== null && order?.payments_service_customer_id !== undefined)) {
        navigate('/ordenes-venta')
      }
    }
  }, [currentStatus]);

  useEffect(() => {
    setInitialValues(originalOrder, saleProducts, articles)
  }, [originalOrder, saleProducts, articles])

  const setInitialValues = (originalOrder, saleProducts, articles) => {
    let clientInput = document.getElementById('clientInput')

    let amountInput = document.getElementById('amountInput')
    let currencyInput = document.getElementById('currencyInput')

    let differenceRow = document.getElementById('difference_row')
    let fullPaymentInput = document.getElementById('fullPaymentInput')
    let methodInput = document.getElementById('methodInput')
    let differenceMethodInput = document.getElementById('differenceMethodInput')
    let differenceInstallmentsInput = document.getElementById('differenceInstallmentsInput')

    let sweeperInput = document.getElementById('sweeperInput')
    let hunterInput = document.getElementById('hunterInput')
    let closerInput = document.getElementById('closerInput')

    clientInput.value = originalOrder?.new_client

    let freemiumCount = originalOrder?.paymentdetail_set.reduce((acc, curr) => acc + (curr.article_name === 'Audiómetro Freemium' ? curr.count : 0), 0)
    originalOrder?.paymentdetail_set.map((pd, pdIndex) => {
      articles?.map((art, artIndex) => {
        saleProducts?.map((sp, spIndex) => {
          if (pd.article === art.id && art.sku === sp.sku && pd.article_name !== 'Audiómetro Freemium') {
            document.getElementById('count' + spIndex).value = pd.count
            document.getElementById('rewarded' + spIndex).checked = pd.rewarded
          }
          if (pd.article === art.id && art.sku === sp.sku && pd.article_name === 'Audiómetro Freemium') {
            document.getElementById('count' + spIndex).value = freemiumCount
          }
        })
      })
    })

    let obd = originalOrder?.orderbillingdata
    if (obd) {
      amountInput.value = obd.amount
      let [curr] = currency?.filter(item => item.id === obd.currency)
      if (curr !== undefined) {
        currencyInput.value = curr.name
      }
    } else {
      amountInput.value = originalOrder?.amount
      currencyInput.value = originalOrder?.currency
    }

    if (order?.amount === "0.00") {
      setOrder({
        ...order,
        amount: obd?.amount
      })
    }

    if (originalOrder?.advancepayment.full_payment) {
      differenceRow.classList.add('d-none')
      fullPaymentInput.value = 'total'
    } else {
      differenceRow.classList.remove('d-none')
      fullPaymentInput.value = 'anticipo'
    }
    methodInput.value = originalOrder?.advancepayment.method
    differenceMethodInput.value = originalOrder?.advancepayment.difference_installments_method
    differenceInstallmentsInput.value = originalOrder?.advancepayment.difference_installments

    sweeperInput.value = originalOrder?.sweeper
    hunterInput.value = originalOrder?.hunter
    closerInput.value = originalOrder?.closer
  }

  useEffect(() => {
    let addressInput = document.getElementById('addressInput')
    let addressCountryInput = document.getElementById('address_countryInput')
    if (addressInput !== null && addressCountryInput !== null &&
      originalOrder?.ordershippingdata !== null) {
      let osd = originalOrder?.ordershippingdata
      if (osd) {
        addressInput.value = osd.address
        addressCountryInput.value = osd.address_country
      }
    }
  }, [originalOrder])

  const toggleSections = value => {
    for (let i = 1; i <= 5; i++) {
      if (shippingData === undefined && i === 4) {
        continue
      }
      if (i === parseInt(value)) {
        document.getElementById('title' + i).classList.add('bg-info', 'text-white', 'rounded')
        document.getElementById('section' + i).classList.remove('d-none')
      } else {
        document.getElementById('title' + i).classList.remove('bg-info', 'text-white', 'rounded')
        document.getElementById('section' + i).classList.add('d-none')
      }
    }
  }

  const handleChange = e => {
    setOrder({
      ...order,
      new_client: parseInt(clientRef.current.value),
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    handleClientSection()
    handleProductSection()
    handlePaymentSection()
    handleShippingSection()
    handleVendorSection()
  }, [order, originalOrder, shippingData, originalShippingData, locationConfirmed])

  // PRODUCTOS
  useEffect(() => {
    if (stockOrders !== undefined && saleProducts !== undefined) {
      setStock(setPreAssembledStock(stockOrders, saleProducts))
    }
  }, [stockOrders, saleProducts])

  const handleChangeCount = (e, spSku, inputId) => {
    setPaymentDetail({
      ...paymentDetail,
      article: articles?.filter(art => art.sku === spSku)[0].id,
      count: spSku === 'NBK_AUD_FREM_0001' ? 1 : parseInt(e.target.value),
      rewarded: e.target.checked,
      inputId
    })
  }

  const handleChangeRewarded = (e, spSku) => {
    articles?.map(art => {
      order.paymentdetail_set?.map(pd => {
        if (art.id === pd.article && art.sku === spSku) {
          setOrder({
            ...order,
            paymentdetail_set: [
              ...order.paymentdetail_set?.filter(item => item.article !== pd.article),
              {
                ...pd,
                rewarded: e.target.checked
              }
            ]
          })
        }
      })
    })
  }

  useEffect(() => {
    if (order?.paymentdetail_set !== undefined) {
      let flag = true
      for (let i = 0; i < order?.paymentdetail_set?.length; i++) {
        let [freemiumArticle] = articles?.filter(art => art.sku === 'NBK_AUD_FREM_0001')
        if (paymentDetail.article !== freemiumArticle.id) {
          if (order?.paymentdetail_set[i].article === paymentDetail.article) {
            setOrder({
              ...order,
              paymentdetail_set: [
                ...order?.paymentdetail_set?.filter(item => item.article !== paymentDetail.article),
                paymentDetail
              ]
            })
            flag = false
          }
        } else {
          const pdLength = order?.paymentdetail_set?.filter(pd => pd.article === freemiumArticle.id)?.length
          const value = parseInt(document.getElementById(paymentDetail.inputId).value)
          const difference = pdLength - value
          if (difference >= 1) {
            let newValues = order?.paymentdetail_set?.filter(pd => pd.article === freemiumArticle.id)
            for (let i = 0; i < difference; i++) {
              newValues.pop()
            }
            setOrder({
              ...order,
              paymentdetail_set: [
                ...order?.paymentdetail_set?.filter(pd => pd.article !== freemiumArticle.id),
                ...newValues
              ]
            })
          } else {
            setOrder({
              ...order,
              paymentdetail_set: [
                ...order?.paymentdetail_set,
                paymentDetail
              ]
            })
          }
          flag = false
        }
      }
      if (flag) {
        setOrder({
          ...order,
          paymentdetail_set: [
            ...order?.paymentdetail_set,
            paymentDetail
          ]
        })
      }
    }
  }, [paymentDetail])

  // PAGO
  const toggleFullPayment = e => {
    let row = document.getElementById('difference_row')
    if (e.target.value === 'anticipo') {
      row.classList.remove('d-none')
      setOrder({
        ...order,
        advancepayment: {
          ...order?.advancepayment,
          full_payment: false
        }
      })
    } else {
      row.classList.add('d-none')
      setOrder({
        ...order,
        advancepayment: {
          ...order?.advancepayment,
          full_payment: true
        }
      })
    }
  }

  const handleChangePayment = e => {
    setOrder({
      ...order,
      advancepayment: {
        ...order?.advancepayment,
        [e.target.name]: e.target.value
      }
    })
  }

  // DATOS DE ENVIO
  const handleAutocomplete = value => {
    let addressInput = document.getElementById('addressInput')
    let addressCountryInput = document.getElementById('address_countryInput')
    let addressProvinceInput = document.getElementById('address_provinceInput')
    let addressCityInput = document.getElementById('address_cityInput')
    let addressPostalCodeInput = document.getElementById('address_postal_codeInput')
    let addressAddressInput = document.getElementById('address_addressInput')
    let addressNumberInput = document.getElementById('address_numberInput')
    let addressFloorApartmentInput = document.getElementById('address_floor_apartmentInput')
    let clientCheck = document.getElementById('clientRecipientCheckbox')
    let otherCheck = document.getElementById('otherRecipientCheckbox')
    let newRecipient = document.getElementById('newRecipientData')
    let recipientName = document.getElementById('recipientName')
    let recipientId = document.getElementById('recipientId')
    let phone_contactInput = document.getElementById('phone_contactInput')
    let newPhoneTypeInput = document.getElementById('newPhoneTypeInput')
    let newPhoneProviderInput = document.getElementById('newPhoneProviderInput')
    let newCountryCodeInput = document.getElementById('newCountryCodeInput')
    let newAreaCodeInput = document.getElementById('newAreaCodeInput')
    let newNumberInput = document.getElementById('newNumberInput')
    let newLineInput = document.getElementById('newLineInput')
    let newNoteInput = document.getElementById('newNoteInput')

    if (value === 'new') {
      setLocationConfirmed(false)

      addressInput.value = 'new'
      addressCountryInput.value = ''
      addressProvinceInput.value = ''
      addressCityInput.value = ''
      addressPostalCodeInput.value = ''
      addressAddressInput.value = ''
      addressNumberInput.value = ''
      addressFloorApartmentInput.value = ''

      phone_contactInput.value = ''
      newPhoneTypeInput.value = ''
      newPhoneProviderInput.value = ''
      newCountryCodeInput.value = ''
      newAreaCodeInput.value = ''
      newNumberInput.value = ''
      newLineInput.value = ''
      newNoteInput.value = ''

    } else {

      let [currentOffice] = office?.filter(item => item.label === value)
      let currentShipData = clientShippingData?.filter(csd => csd.address === value && csd.client === parseInt(order?.new_client))[0] !== undefined ?
        clientShippingData?.filter(csd => csd.address === value && csd.client === parseInt(order?.new_client))[0] : currentOffice

      if (currentShipData.name_recipient === undefined ||
        currentShipData.id_recipient === undefined) {
        currentShipData.name_recipient = 'Retiro por oficina'
        currentShipData.id_recipient = 'Retiro por oficina'
      }

      addressCountryInput.value = currentShipData.address_country
      addressProvinceInput.value = currentShipData.address_province
      addressCityInput.value = currentShipData.address_city
      addressPostalCodeInput.value = currentShipData.address_postal_code
      addressAddressInput.value = currentShipData.address_address
      addressNumberInput.value = currentShipData.address_number
      addressFloorApartmentInput.value = currentShipData.address_floor_apartment

      setShippingData(currentShipData)

      let flag = false
      clients?.map(c => {
        if ((currentShipData.name_recipient === c.name && c.id === parseInt(order?.new_client)) ||
          currentShipData.name_recipient === undefined) {
          flag = true
        }
      })
      if (flag) {
        newRecipient.classList.add('d-none')
        clientCheck.checked = true
        otherCheck.checked = false
        recipientName.value = ''
        recipientId.value = ''
      } else {
        newRecipient.classList.remove('d-none')
        clientCheck.checked = false
        otherCheck.checked = true
        recipientName.value = currentShipData === undefined ? '' : currentShipData.name_recipient
        recipientId.value = currentShipData === undefined ? '' : currentShipData.id_recipient
      }

      phone_contactInput.value = currentShipData === undefined ? '' : currentShipData.phone_contact
    }
  }

  // GOOGLE MAPS
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setMapCenter(latLng)
        setLocation(latLng)
        findLocation()
      })
      .catch(error => console.error('Error', error));
  }

  const findLocation = () => {
    if (!locationFound) {
      setLocationFound(true)
    }
  }

  const confirmLocation = () => {
    if (!locationConfirmed) {
      setLocationConfirmed(true)
    } else {
      setLocationConfirmed(false)
    }
  }

  // FINAL GENERAL
  const handleClientSection = () => {
    let v = val
    let check = document.getElementById('check-client')
    let warning = document.getElementById('warning-client')
    if (v.fieldValid('new_client')) {
      check.classList.remove('d-none')
      warning.classList.add('d-none')
      return true
    } else {
      check.classList.add('d-none')
      warning.classList.remove('d-none')
      return false
    }
  }

  const handleProductSection = () => {
    let v = val
    let check = document.getElementById('check-product')
    let warning = document.getElementById('warning-product')
    if (v.fieldValid('amount') && v.fieldValid('note')) {
      check.classList.remove('d-none')
      warning.classList.add('d-none')
      return true
    } else {
      check.classList.add('d-none')
      warning.classList.remove('d-none')
      return false
    }
  }

  const handlePaymentSection = () => {
    let v = val
    let check = document.getElementById('check-payment')
    let warning = document.getElementById('warning-payment')

    if ((v.fieldValid('advancepayment_amount') &&
      document.getElementById('difference_installments_amountInput').value !== 'No válido') ||
      order?.advancepayment.full_payment) {
      check.classList.remove('d-none')
      warning.classList.add('d-none')
      return true
    } else {
      check.classList.add('d-none')
      warning.classList.remove('d-none')
      return false
    }
  }

  const handleShippingSection = () => {
    if (shippingData !== undefined) {
      let v = val
      let check = document.getElementById('check-shipping')
      let warning = document.getElementById('warning-shipping')

      if (
        v.fieldValid('address_province') && v.fieldValid('address_city') && v.fieldValid('address_address') &&
        v.fieldValid('address_number') && v.fieldValid('address_postal_code') && v.fieldValid('address_floor_apartment') &&
        v.fieldValid('id_recipient') && v.fieldValid('name_recipient') && v.fieldValid('country_code') &&
        v.fieldValid('area_code') && v.fieldValid('number') && v.fieldValid('observation') && v.fieldValid('note') && locationConfirmed
      ) {
        check.classList.remove('d-none')
        warning.classList.add('d-none')
        return true
      } else {
        check.classList.add('d-none')
        warning.classList.remove('d-none')
        return false
      }
    }
    return true
  }

  const handleVendorSection = () => {
    let v = val
    let check = document.getElementById('check-vendor')
    let warning = document.getElementById('warning-vendor')
    if ((v.fieldValid('sweeper') && v.fieldValid('hunter') && v.fieldValid('closer'))) {
      check.classList.remove('d-none')
      warning.classList.add('d-none')
      return true
    } else {
      check.classList.add('d-none')
      warning.classList.remove('d-none')
      return false
    }
  }

  const handleCancel = () => {
    swal({
      title: '¿Descartar cambios?',
      text: 'Se reiniciará la orden a sus valores originales.',
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true
    }).then(accept => {
      if (accept) {
        window.location.reload()
      }
    })
  }

  const preSubmit = () => {
    // si no pasa la validacion, muestro los mensajes de error y paro la ejecucion
    if (
      !handleClientSection() ||
      !handleProductSection() ||
      !handlePaymentSection() ||
      !handleShippingSection() ||
      !handleVendorSection()
    ) {
      val.showMessages()
      forceUpdate(1)
      return
    }

    // seteo los rewarded indefinidos en false antes de enviar la request
    // verifico que el paymentdetail no este vacio
    let flag = true
    order?.paymentdetail_set?.map(pd => {
      if (pd.rewarded === undefined) {
        pd.rewarded = false
      }
      if (pd.count !== 0) {
        flag = false
      }
    })
    if (flag) {
      toast.warning('No se puede guardar una orden sin productos ingresados', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'warning-toast'
      })
      return
    }

    // si es reposicion, siempre van a ser productos bonificados
    if (isCustomerSuccess()) {
      order?.paymentdetail_set?.map(pd => {
        pd.rewarded = true
      })
    }

    // corrijo cantidades mal ingresadas
    order.paymentdetail_set = order?.paymentdetail_set?.filter(item => item.count > 0 && !isNaN(item.count))

    // seteo el monto de cada cuota de la diferencia
    order.advancepayment.difference_installments_amount = (parseFloat(order?.amount) - parseFloat(order?.advancepayment.advancepayment_amount)) / parseInt(order?.advancepayment.difference_installments)

    // corrijo inconsistencias en los datos de pago
    if (order?.advancepayment.full_payment) {
      order.advancepayment.advancepayment_amount = order?.amount
      order.advancepayment.difference_installments = 1
      order.advancepayment.difference_installments_amount = 0
      order.advancepayment.difference_installments_method = order?.advancepayment.method
    }
    order.advancepayment.difference_installments_currency = order?.currency
    order.advancepayment.advancepayment_currency = order?.currency

    // seteo el id del cliente, la orden y la direccion en los datos de envío por las dudas 
    // si existe un shipping data
    if (shippingData !== undefined) {
      setShippingData({
        ...shippingData,
        client: parseInt(order?.new_client),
        order: order?.id,
        address: shippingData.address_address + ' ' + shippingData.address_number,

        // seteo las coordenadas en shipping data
        latitude: location.lat?.toFixed(8),
        longitude: location.lng?.toFixed(8)
      })
    }

    // verificar montos
    if (order?.amount?.length > 10 || order?.advancepayment.advancepayment_amount?.length > 10) {
      toast.warning('Los montos no pueden tener más de 10 dígitos', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'warning-toast'
      })
      return
    }


    // modal de confirmacion
    let [freemiumArticle] = articles?.filter(art => art.name.includes('Freemium'))
    let freemiumsInOrder = order?.paymentdetail_set?.filter(pd => pd.article === freemiumArticle.id)
    if (freemiumsInOrder?.length > 0) {
      let currentCountry = countries.find(country => country.id === parseInt(shippingData.address_country))?.name
      // if (currentCountry === 'Argentina' ||
      //   currentCountry === 'Bolivia' ||
      //   currentCountry === 'Brasil' ||
      //   currentCountry === 'Chile' ||
      //   currentCountry === 'Colombia' ||
      //   currentCountry === 'Ecuador' ||
      //   currentCountry === 'Perú') {
      showModal('freemiumSettings')
      // } else {
      //   toast.error(`El Audiómetro Freemium no está disponible para clientes de ${currentCountry}. Disponible en Argentina, Bolivia, Brasil, Chile, Colombia, Ecuador y Perú.`, {
      //     position: 'bottom-left',
      //     hideProgressBar: true,
      //     className: 'error-toast'
      //   })
      // }
    } else {
      showModal('orderDataConfirm')
    }
  }

  const handleSubmit = () => {

    const { result, difference } = checkBoneHeads(order.paymentdetail_set,
      uSoundPaymentOrder?.orderdetail_set || [],
      usoundPaymentsProducts,
      articles)
    if (!result) {
      let bonehead = articles.find(art => art.sku === 'NBK_VINCH_OSEA_0001')
      order.paymentdetail_set.push({
        article: bonehead.id,
        count: difference,
        rewarded: true,
        tag: null
      })
    }

    // saco los tags a los no freemiums
    let [freemArticle] = articles?.filter(art => art.sku === 'NBK_AUD_FREM_0001')
    let notFreemiums = [...order?.paymentdetail_set?.filter(pd => pd.article !== freemArticle.id)]
    for (let i = 0; i < notFreemiums?.length; i++) {
      Object.defineProperties(notFreemiums[i], {
        tag: {
          value: null
        }
      })
    }

    // siempre agrego los tags a todos los freemiums de la orden empezando por 1
    let freemiums = [...order?.paymentdetail_set?.filter(pd => pd.article === freemArticle.id)]
    for (let i = 0; i < freemiums?.length; i++) {
      freemiums[i].tag = '#' + (i + 1)
    }

    order.paymentdetail_set = [
      ...notFreemiums,
      ...freemiums
    ]

    // si el estado es "Pagada" o "Reposición" y el cliente cambia, se actualizan los datos de facturacion de la orden
    if (currentStatus === 'Pagada' || currentStatus === 'Reposición') {
      let [cbd] = clientBillingData?.filter(elem => elem.client === parseInt(order.new_client))
      let obd = order.orderbillingdata

      let newOBD = {
        vat_number: cbd.vat_number,
        name: cbd.name,
        iva: cbd.iva,
        type: cbd.type,
        person: cbd.person,
        address_province: shippingData !== undefined ? shippingData.address_province : obd.address_province,
        address_city: shippingData !== undefined ? shippingData.address_city : obd.address_city,
        address_locality: shippingData !== undefined ? shippingData.address_locality : obd.address_locality,
        address_address: shippingData !== undefined ? shippingData.address_address : obd.address_address,
        address_number: shippingData !== undefined ? shippingData.address_number : obd.address_number,
        address_floor_apartment: shippingData !== undefined ? shippingData.address_floor_apartment : obd.address_floor_apartment,
        address: shippingData !== undefined ? shippingData.address : obd.address,
        address_postal_code: shippingData !== undefined ? shippingData.address_postal_code : obd.address_postal_code,
        amount: order.amount,
        amount_usd: order.amount_usd,
        carnet_id: cbd.carnet_id,
        address_country: shippingData !== undefined ? shippingData.address_country : obd.address_country,
        order: order.id
      }

      currency?.map(cur => {
        if (cur.name === order.currency) {
          newOBD.currency = cur.id
        }
      })

      OrderService.updateOrderBillingData(obd.id, newOBD)
        .then(data => {
          let [order] = orders.filter(item => item.id === data.order)
          setOrders([
            ...orders.filter(item => item.id !== order.id),
            {
              ...order,
              orderbillingdata: data
            }
          ])
        })
    }

    // si hay un nuevo telefono, lo guardo por post
    let flag = false
    phoneContacts?.map(pc => {
      if (pc.number === phoneContact.number) {
        flag = true
      }
    })
    if (!flag && phoneContact.number !== undefined) {
      ClientService.saveNewPhoneContact(phoneContact)
        .then(data => {
          console.log(data)
        })
    }

    // hay ordenes que no tienen order shipping data ni client shipping data
    if (shippingData !== undefined) {
      // si el cliente es el mismo
      if (shippingData.client === originalOrder.new_client) {
        let [csd] = clientShippingData?.filter(elem => elem.address === originalShippingData.address && elem.client === originalOrder.new_client)
        if (csd !== undefined) {
          ClientService.updateClientShippingData(csd.id, shippingData)
            .then(data => {
              console.log(data)
            })
        }
      } else {
        // si es otro
        ClientService.saveNewClientShippingData(shippingData)
          .then(data => {
            console.log(data)
          })
      }

      // la request a order shipping data siempre sera por put en este componente de edicion de orden, haya cambios o no
      let osd = order.ordershippingdata
      OrderService.updateOrderShippingData(osd.id, shippingData)
        .then(data => {
          let [order] = orders.filter(item => item.id === data.order)
          setOrders([
            ...orders.filter(item => item.id !== order.id),
            {
              ...order,
              ordershippingdata: data
            }
          ])
        })
    }

    // borrar los estudios de orderdetail cuyo tag no este en paymentdetail
    if (originalUSoundPaymentOrder !== undefined) {
      originalUSoundPaymentOrder.orderdetail_set.forEach(upo => {
        let flag = false
        uSoundPaymentOrder.orderdetail_set.forEach(od => {
          order.paymentdetail_set.forEach(pd => {
            if (upo.product_sku === od.product_sku && upo.tag === pd.tag && pd.article === freemArticle.id) {
              flag = true
            }
          })
        })
        if (!flag) {
          UsoundPaymentsService.deleteOrderDetail(upo.id)
        }
      })
    }

    // si hay una orden del servicio de pagos y tiene estudios, la actualizo
    if (uSoundPaymentOrder?.orderdetail_set?.length > 0 && uSoundPaymentOrder.order_id !== undefined) {
      UsoundPaymentsService.updateOrder(uSoundPaymentOrder)
        .then(data => {
          console.log(data)
        })
    }

    // si hay una orden del servicio de pagos y NO tiene estudios, la borro
    if (uSoundPaymentOrder?.orderdetail_set?.length === 0 && uSoundPaymentOrder.order_id !== undefined) {
      UsoundPaymentsService.deleteOrder(uSoundPaymentOrder)
        .then(data => {
          console.log(data)
        })
    }

    // si no hay una orden del servicio de pagos, la creo
    if (uSoundPaymentOrder !== undefined && uSoundPaymentOrder.order_id === undefined) {
      uSoundPaymentOrder.order_id = params.id
      UsoundPaymentsService.saveNewOrder(uSoundPaymentOrder)
        .then(data => {
          console.log(data)
        })
    }

    OrderService.updateOrder(params.id, order)
      .then(data => {
        // nuevo estado edited
        let newStatus = {
          order: data.id,
          user_name: getUsername(),
          type_order_status: typeOrderStatus?.filter(tos => tos.name === 'edited')[0].id
        }
        OrderService.saveNewOrderStatus(newStatus)
          .then(newOSResponse => {
            data.orderstatus_set = [...data.orderstatus_set, newOSResponse]
            setOrders([
              ...orders?.filter(item => item.id !== parseInt(params.id)),
              data
            ])
            // mail estado edited
            let detail = getModifiedFields(originalOrder, order, originalShippingData.address, autocompleteRef.current?.value)
            if (detail?.length > 0) {
              OrderService.sendMailOrderStatus(data.id, 'edited', 'editada', detail)
            }
            toast.success('Orden editada correctamente', {
              position: 'bottom-left',
              hideProgressBar: true,
              className: 'success-toast'
            })
            hideModal('orderDataConfirm')
            setTimeout(() => {
              navigate('/ordenes-venta')
            }, 2000);
          })
      })
  }

  return (

    <>

      <PrincipalBar />

      <div className="container-fluid px-5">

        <div className="mt-5 d-flex flex-wrap justify-content-center">
          <div className="col-12 col-md-4">
            <h2 className="text-info">Editar orden #{order?.id}</h2>
            <div className="d-flex align-items-center">
              <strong>Estado actual: </strong>
              <span className="bg-info text-white ms-2 p-1 rounded">
                {currentStatus === null ? 'cargando...' : currentStatus}
              </span>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-3 mt-md-0 col-12 col-md-8 justify-content-end h-50">
            <button type="button"
              className="btn border border-info text-info col-12 col-md-3"
              onClick={() => {
                navigate('/ordenes-venta')
              }}>
              VOLVER
            </button>
            <button type="button"
              className="btn btn-info bg-white text-info col-12 col-md-3 my-3 my-md-0 mx-md-2"
              onClick={handleCancel}>
              DESCARTAR CAMBIOS
            </button>
            <button type="button"
              className="btn btn-info text-white col-12 col-md-3"
              onClick={preSubmit}>
              GUARDAR
            </button>
          </div>
        </div>

        <EditFreemiumSettings
          order={order}
          originalOrder={originalOrder}
          freemiumArticle={articles?.filter(art => art.name.includes('Freemium'))[0] || []}
          clients={clients}
          countries={countries}
          originalUSoundPaymentOrder={originalUSoundPaymentOrder}
          uSoundPaymentOrder={uSoundPaymentOrder !== undefined ? uSoundPaymentOrder : {
            orderdetail_set: []
          }}
          setUsoundPaymentOrder={setUsoundPaymentOrder}
          paymentServiceProducts={usoundPaymentsProducts}
          articles={articles}
          shippingData={shippingData}
        />

        <OrderDataConfirm
          order={order}
          clients={clients}
          sellers={sellers}
          emails={emailContacts}
          countries={countries}
          saleProducts={saleProducts}
          articles={articles}
          hideModal={hideModal}
          handleSubmit={handleSubmit}
          loadingChangeStatus={loadingChangeStatus}
          setLoadingChangeStatus={setLoadingChangeStatus}
          stockOrders={stockOrders}
          toOrderStatus="edition"
          uSoundPaymentOrder={uSoundPaymentOrder}
          paymentServiceProducts={usoundPaymentsProducts}
          customers={customers}
          shippingData={shippingData}
        />

        <div className="row mt-3 d-flex justify-content-between flex-wrap">

          <div className="col-xl-3 p-3">
            <ul className="list-style-none">
              <div className="d-flex align-items-center">
                <li
                  id="title1"
                  className="px-2 py-1 only-cursor-pointer text-info fs-5 rounded d-flex me-2"
                  onClick={() => toggleSections('1')}>
                  <span className="me-2">
                    Cliente
                  </span>
                </li>
                <li>
                  <span id="check-client" className="d-none">
                    <CheckEdit />
                  </span>
                  <span id="warning-client" className="d-none">
                    <WarningEdit />
                  </span>
                </li>
              </div>
              <div className="d-flex align-items-center">
                <li
                  id="title2"
                  className="px-2 py-1 my-2 only-cursor-pointer text-info fs-5 rounded d-flex me-2"
                  onClick={() => toggleSections('2')}>
                  <span className="me-2">
                    Productos
                  </span>
                </li>
                <li>
                  <span id="check-product" className="d-none">
                    <CheckEdit />
                  </span>
                  <span id="warning-product" className="d-none">
                    <WarningEdit />
                  </span>
                </li>
              </div>
              <div className={isCustomerSuccess() ? "d-none" : "d-flex align-items-center"}>
                <li
                  id="title3"
                  className="px-2 py-1 only-cursor-pointer text-info fs-5 rounded d-flex me-2"
                  onClick={() => toggleSections('3')}>
                  <span className="me-2">
                    Pago
                  </span>
                </li>
                <li>
                  <span id="check-payment" className="d-none">
                    <CheckEdit />
                  </span>
                  <span id="warning-payment" className="d-none">
                    <WarningEdit />
                  </span>
                </li>
              </div>
              {shippingData !== undefined &&
                <div className="d-flex align-items-center">
                  <li
                    id="title4"
                    className={isCustomerSuccess() ?
                      "px-2 py-1 only-cursor-pointer text-info fs-5 rounded d-flex me-2" :
                      "px-2 py-1 my-2 only-cursor-pointer text-info fs-5 rounded d-flex me-2"}
                    onClick={() => toggleSections('4')}>
                    <span className="me-2">
                      Datos de envío
                    </span>
                  </li>
                  <li>
                    <span id="check-shipping" className="d-none">
                      <CheckEdit />
                    </span>
                    <span id="warning-shipping" className="d-none">
                      <WarningEdit />
                    </span>
                  </li>
                </div>
              }
              <div className={isCustomerSuccess() ? "d-none" : "d-flex align-items-center"}>
                <li
                  id="title5"
                  className="px-2 py-1 cursor-pointer text-info fs-5 rounded d-flex me-2"
                  onClick={() => toggleSections('5')}>
                  <span className="me-2">
                    Vendedores
                  </span>
                </li>
                <li>
                  <span id="check-vendor" className="d-none">
                    <CheckEdit />
                  </span>
                  <span id="warning-vendor" className="d-none">
                    <WarningEdit />
                  </span>
                </li>
              </div>
            </ul>
          </div>

          <div className="col-xl-9 py-3 px-5">

            <div id="section1" className="d-none" onFocus={handleClientSection}>
              <select
                id="clientInput"
                name="new_client"
                className="form-select"
                ref={clientRef}
                onChange={e => {
                  handleChange(e)
                  document.getElementById('addressInput').value = 'new'
                  handleAutocomplete('new')
                  setClientSelected(parseInt(clientRef.current.value))
                }}
              >
                {clients?.map((client, idx) => {
                  if (client.id === originalOrder?.new_client) {
                    return <option key={idx} value={client.id} selected>{client.name}</option>
                  } else {
                    return <option key={idx} value={client.id}>{client.name}</option>
                  }
                })}
              </select>
              {val.message('new_client', order?.new_client, 'required', { className: 'text-danger' })}
              <button
                type="button"
                className="btn btn-sm btn-info text-white mt-3"
                onClick={() => showModal('newClient')}
              >
                Agregar nuevo
              </button>
              <div className="modal fade" id="newClient" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title text-info" id="staticBackdropLabel">Nuevo cliente</h3>
                    </div>
                    <div className="modal-body">
                      <CreateClient
                        hideModal={hideModal}
                        countries={countries}
                        typeIdClient={typeIdClient}
                        val={val}
                        newClient={newClient}
                        setNewClient={setNewClient}
                        newClientBillingData={newClientBillingData}
                        setNewClientBillingData={setNewClientBillingData}
                        forceUpdate={forceUpdate}
                        newPhoneContact={newPhoneContact}
                        setNewPhoneContact={setNewPhoneContact}
                        newEmailContact={newEmailContact}
                        setNewEmailContact={setNewEmailContact}
                        clients={clients}
                        setClients={setClients}
                        order={order}
                        setOrder={setOrder}
                        phoneContacts={phoneContacts}
                        setPhoneContacts={setPhoneContacts}
                        clientBillingData={clientBillingData}
                        setClientBillingData={setClientBillingData}
                        setLocationConfirmed={setLocationConfirmed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="section2" className="d-none md-overflow-scroll">
              <table className="table table-hover text-center">
                <thead className="border-bottom border-dark">
                  <th>Nombre</th>
                  <th>Detalle</th>
                  <th className={isCustomerSuccess() ? 'd-none' : "col-3 text-center"}>Bonificado</th>
                  <th>Stock de pre-armados</th>
                </thead>
                <tbody id="articlesSet">
                  {saleProducts?.map((sp, spIndex) => {
                    return (
                      <tr key={spIndex}>
                        <td>
                          {sp.alias === null ? sp.name : sp.alias}
                        </td>
                        <td>
                          <input
                            id={'count' + spIndex}
                            name="count"
                            type="number"
                            min={0}
                            step={1}
                            className="form-control w-50 mx-auto"
                            defaultValue={0}
                            onChange={e => handleChangeCount(e, sp.sku, 'count' + spIndex)}
                            onWheel={() => preventWheel('count' + spIndex)}
                          />
                        </td>
                        <td className={isCustomerSuccess() ? 'd-none' : ''}>
                          <input
                            id={'rewarded' + spIndex}
                            name="rewarded"
                            type="checkbox"
                            onChange={e => handleChangeRewarded(e, sp.sku)}
                          />
                        </td>
                        <td>
                          {stock?.map(st => {
                            if ((sp.alias !== null ? sp.alias : sp.name) === st.sale_product) {
                              return (
                                <span
                                  key={sp.id}
                                  className="bg-success p-1 rounded text-white"
                                >
                                  {st.stock}
                                </span>
                              )
                            }
                          })}
                          {!JSON.stringify(stock).includes((sp.alias !== null ? sp.alias : sp.name)) &&
                            <span className="bg-danger p-1 rounded text-white">
                              0
                            </span>
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className={isCustomerSuccess() ? "d-none" : "row d-flex flex-wrap justify-content-end p-2"}>
                <div className="form-group d-flex align-items-center col-sm-3">
                  <label htmlFor="amount" className="me-2 fs-5"><strong>Total</strong></label>
                  <input
                    id="amountInput"
                    type="number"
                    min="0"
                    max="99999999"
                    step="0.01"
                    name="amount"
                    className="form-control me-1"
                    onChange={e => {
                      handleChange(e)
                      handleProductSection()
                    }}
                  />
                  {val.message('amount', order?.amount, 'required', { className: 'text-danger' })}
                </div>
                <div className="form-group col-sm-2">
                  <select id="currencyInput" name="currency" className="form-select" onChange={e => handleChange(e)}>
                    {currency?.map((curr, currIndex) => {
                      return <option key={currIndex} value={curr.name}>{curr.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className="row p-2">
                <div className="form-group">
                  <label htmlFor="note">Observaciones</label>
                  <textarea
                    name="note"
                    className="form-control textarea"
                    defaultValue={order?.note}
                    onSelect={e => {
                      handleChange(e)
                      handleProductSection()
                    }}
                    onChange={e => {
                      handleChange(e)
                      handleProductSection()
                    }}
                  ></textarea>
                  {val.message('note', order?.note, 'max:250', { className: 'text-danger' })}
                </div>
              </div>
            </div>

            <div id="section3" className="d-none md-overflow-scroll">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th className="col-2">Forma</th>
                    <th className="col-3">Método de pago</th>
                    <th className="col-1">Cuotas</th>
                    <th className="col-2">Importe</th>
                    <th className="col-1">Moneda</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pt-2 ps-0 pe-3">
                      <select id="fullPaymentInput" name="full_payment" className="form-select"
                        onChange={e => toggleFullPayment(e)}>
                        <option value="anticipo">Anticipo</option>
                        <option value="total">Total</option>
                      </select>
                    </td>
                    <td className="pt-2 px-1">
                      <select
                        id="methodInput"
                        name="method"
                        className="form-select"
                        onChange={e => handleChangePayment(e)}
                      >
                        <option value="Efectivo">Efectivo</option>
                        <option value="Stripe">Stripe</option>
                        <option value="rebill">rebill</option>
                        <option value="dLocal">dLocal</option>
                        <option value="Mercado Pago">Mercado Pago</option>
                        <option value="Transferencia Bancaria">Transferencia Bancaria</option>
                        <option value="Cheque">Cheque</option>
                        <option value="PayPal">PayPal</option>
                        <option value="Western Union/MoneyGram/Argenper">Western Union/MoneyGram/Argenper</option>
                        <option value="Mercado Pago Ahora 12/18">Mercado Pago Ahora 12/18</option>
                        <option value="Tienda Nube Ahora 12/18">Tienda Nube Ahora 12/18</option>
                      </select>
                    </td>
                    <td className="pt-2 px-1">
                      <div className="rounded p-1 text-center">
                        1
                      </div>
                    </td>
                    <td className="pt-2 px-1">
                      {!order?.advancepayment.full_payment ?
                        <input
                          type="text"
                          name="advancepayment_amount"
                          className="form-control"
                          defaultValue={order?.advancepayment.advancepayment_amount}
                          onChange={e => {
                            handleChangePayment(e)
                            handlePaymentSection()
                          }}
                        /> :
                        <input
                          type="text"
                          name="advancepayment_amount"
                          className="form-control"
                          defaultValue={order?.amount}
                          disabled
                        />
                      }
                      {val.message('advancepayment_amount',
                        order?.advancepayment.advancepayment_amount,
                        `required|regex:[0-9.]|not_regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü-]|max:${order?.advancepayment.full_payment ? order?.amount : order?.advancepayment.advancepayment_amount},num`,
                        { className: 'text-danger' })}
                    </td>
                    <td className="pt-2 pe-0 ps-1">
                      <input
                        type="text"
                        name="advancepayment_currency"
                        className="form-control"
                        value={order?.currency}
                        disabled
                      />
                    </td>
                  </tr>
                  <tr id="difference_row">
                    <td className="pt-2 ps-0 pe-3">
                      <div className="rounded border border-muted text-align-center p-1">Diferencia</div>
                    </td>
                    <td className="pt-2 px-1">
                      <select
                        id="differenceMethodInput"
                        name="difference_installments_method"
                        className="form-select"
                        onChange={e => handleChangePayment(e)}
                      >
                        <option value="Efectivo">Efectivo</option>
                        <option value="Stripe">Stripe</option>
                        <option value="rebill">rebill</option>
                        <option value="dLocal">dLocal</option>
                        <option value="Mercado Pago">Mercado Pago</option>
                        <option value="Transferencia Bancaria">Transferencia Bancaria</option>
                        <option value="Cheque">Cheque</option>
                        <option value="PayPal">PayPal</option>
                        <option value="Western Union/MoneyGram/Argenper">Western Union/MoneyGram/Argenper</option>
                        <option value="Mercado Pago Ahora 12/18">Mercado Pago Ahora 12/18</option>
                        <option value="Tienda Nube Ahora 12/18">Tienda Nube Ahora 12/18</option>
                      </select>
                    </td>
                    <td className="pt-2 px-1">
                      <select
                        id="differenceInstallmentsInput"
                        name="difference_installments"
                        className="form-select"
                        onChange={e => handleChangePayment(e)}
                        defaultValue={order?.advancepayment.difference_installments}
                      >
                        {Array.from({ length: 18 }).map((inst, idx) => {
                          return <option key={idx} value={idx + 1}>{idx + 1}</option>
                        })}
                      </select>
                    </td>
                    <td className="pt-2 px-1">
                      <input
                        id="difference_installments_amountInput"
                        type="text"
                        name="difference_installments_amount"
                        className="form-control"
                        value={
                          isNaN(((parseFloat(order?.amount) - parseFloat(order?.advancepayment.advancepayment_amount)) / parseInt(order?.advancepayment.difference_installments)).toFixed(2)) ||
                            (((parseFloat(order?.amount) - parseFloat(order?.advancepayment.advancepayment_amount)) / parseInt(order?.advancepayment.difference_installments)).toFixed(2)).includes('-') ?
                            'No válido' :
                            ((parseFloat(order?.amount) - parseFloat(order?.advancepayment.advancepayment_amount)) / parseInt(order?.advancepayment.difference_installments)).toFixed(2)
                        }
                        disabled
                      />
                    </td>
                    <td className="pt-2 pe-0 ps-1">
                      <input
                        type="text"
                        name="difference_installments_currency"
                        className="form-control"
                        value={order?.currency}
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div id="section4" className="d-none">
              <EditShippingData
                typePhone={typePhone}
                phoneProviders={phoneProviders}
                phoneContacts={phoneContacts}
                clients={clients}
                countries={countries}
                order={order}
                clientShippingData={clientShippingData}
                orderShippingData={order?.ordershippingdata}
                originalShippingData={originalShippingData}
                setOriginalShippingData={setOriginalShippingData}
                shippingData={shippingData}
                setShippingData={setShippingData}
                phoneContact={phoneContact}
                setPhoneContact={setPhoneContact}
                autocompleteRef={autocompleteRef}
                handleAutocomplete={handleAutocomplete}
                clientRef={clientRef}
                locationConfirmed={locationConfirmed}
                locationFound={locationFound}
                val={val}
                handleShippingSection={handleShippingSection}
                mapCenter={mapCenter}
                setMapCenter={setMapCenter}
                handleSelect={handleSelect}
                confirmLocation={confirmLocation}
                clientSelected={clientSelected}
                setClientSelected={setClientSelected}
                office={office}
              />
            </div>

            <div id="section5" className="d-none" onFocus={handleVendorSection}>
              <button
                type="button"
                className="btn btn-sm btn-info text-white"
                onClick={() => showModal('newSeller')}
              >
                Agregar nuevo
              </button>
              <div className="modal fade" id="newSeller" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title text-info" id="staticBackdropLabel">Nuevo vendedor</h3>
                      <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                        hideModal('newSeller')
                        val.hideMessages()
                        forceUpdate(2)
                        document.getElementById('sellerForm').reset()
                      }}></button>
                    </div>
                    <div className="modal-body">
                      <CreateSeller
                        newSeller={newSeller}
                        setNewSeller={setNewSeller}
                        val={val}
                        order={order}
                        setOrder={setOrder}
                        sellers={sellers}
                        setSellers={setSellers}
                        forceUpdate={forceUpdate}
                      />
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => {
                        hideModal('newSeller')
                        val.hideMessages()
                        forceUpdate(2)
                        document.getElementById('sellerForm').reset()
                      }}>
                        CERRAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <strong className="d-block mb-2">Cazador 1</strong>
                <select id="sweeperInput" name="sweeper" className="form-select" onChange={e => handleChange(e)}>
                  {sellers?.filter(seller => seller.enabled || seller.id === originalOrder?.sweeper).map((seller, idx) => {
                    return <option key={idx} value={seller.id}>{seller.name}</option>
                  })}
                </select>
                {val.message('sweeper', order?.sweeper, 'required', { className: 'text-danger' })}
              </div>
              <div className="my-4">
                <strong className="d-block mb-2">Cazador 2</strong>
                <select id="hunterInput" name="hunter" className="form-select" onChange={e => handleChange(e)}>
                  {sellers?.filter(seller => seller.enabled || seller.id === originalOrder?.hunter).map((seller, idx) => {
                    return <option key={idx} value={seller.id}>{seller.name}</option>
                  })}
                </select>
                {val.message('hunter', order?.hunter, 'required', { className: 'text-danger' })}
              </div>
              <div>
                <strong className="d-block mb-2">Closer</strong>
                <select id="closerInput" name="closer" className="form-select" onChange={e => handleChange(e)}>
                  {sellers?.filter(seller => seller.enabled || seller.id === originalOrder?.closer).map((seller, idx) => {
                    return <option key={idx} value={seller.id}>{seller.name}</option>
                  })}
                </select>
                {val.message('closer', order?.closer, 'required', { className: 'text-danger' })}
              </div>
            </div>

          </div>

        </div>

      </div>
    </>

  )
}