import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { error, errorTimeout, existingSkuOrName, networkError, serverError, expiredSession } from '../../Utilities/General/StatusHandler';

const InputProductService = {

    getInputProducts: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockInputProduct + '?enabled=True',
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewInputProduct: async newInputProduct => {
        try {
            const res = await axios.post(
                GlobalUrls.urlStockInputProduct,
                newInputProduct,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingSkuOrName(newInputProduct.sku, newInputProduct.name)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateInputProduct: async (id, newInputProduct) => {
        try {
            const res = await axios.put(
                GlobalUrls.urlStockInputProduct + `${id}/`,
                newInputProduct,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingSkuOrName(newInputProduct.sku, newInputProduct.name)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getInputProductStock: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockInputProductStock + '?enabled=True',
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getTypesInputProduct: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockTypeInputProduct,
                { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewTypeInputProduct: async newTypeInputProduct => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlStockTypeInputProduct,
                newTypeInputProduct,
                { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default InputProductService