import React from 'react';

export default function InventoryCard() {

    return (
        <div>
            <svg id="opinion" xmlns="http://www.w3.org/2000/svg" width="28.861" height="25.104" viewBox="0 0 28.861 25.104">
                <g id="Grupo_1037" data-name="Grupo 1037" transform="translate(0 0)">
                    <path id="Trazado_618" data-name="Trazado 618" d="M26.324,58.43H2.537A2.539,2.539,0,0,1,0,55.893V35.863a2.539,2.539,0,0,1,2.537-2.537H26.324a2.539,2.539,0,0,1,2.537,2.537V55.893A2.539,2.539,0,0,1,26.324,58.43ZM2.537,35.017a.846.846,0,0,0-.846.846V55.893a.846.846,0,0,0,.846.846H26.324a.846.846,0,0,0,.846-.846V35.863a.846.846,0,0,0-.846-.846Z" transform="translate(0 -33.326)" fill="#43eba2" />
                </g>
                <path id="Trazado_619" data-name="Trazado 619" d="M252.824,137.5H260.4a.846.846,0,0,0,0-1.691h-7.573a.846.846,0,0,0,0,1.691Z" transform="translate(-237.774 -130.035)" fill="#43eba2" />
                <path id="Trazado_620" data-name="Trazado 620" d="M252.824,197.507H260.4a.846.846,0,0,0,0-1.691h-7.573a.846.846,0,0,0,0,1.691Z" transform="translate(-237.774 -186.657)" fill="#43eba2" />
                <path id="Trazado_621" data-name="Trazado 621" d="M97.624,140.695a.845.845,0,0,0,1.2,0l3.438-3.438a.846.846,0,0,0-1.2-1.2l-2.84,2.84-1.094-1.094a.846.846,0,0,0-1.2,1.2Z" transform="translate(-90.291 -130.036)" fill="#43eba2" />
                <path id="Trazado_622" data-name="Trazado 622" d="M252.824,346.833H260.4a.846.846,0,0,0,0-1.691h-7.573a.846.846,0,0,0,0,1.691Z" transform="translate(-237.774 -327.565)" fill="#43eba2" />
                <path id="Trazado_623" data-name="Trazado 623" d="M252.824,285.837H260.4a.846.846,0,0,0,0-1.691h-7.573a.846.846,0,0,0,0,1.691Z" transform="translate(-237.774 -270.007)" fill="#43eba2" />
                <path id="Trazado_624" data-name="Trazado 624" d="M95.931,289.086a.845.845,0,0,0,1.2,0l1.151-1.151,1.151,1.151a.846.846,0,0,0,1.2-1.2l-1.151-1.151,1.151-1.151a.846.846,0,0,0-1.2-1.2l-1.151,1.151-1.151-1.151a.846.846,0,1,0-1.2,1.2l1.151,1.151-1.151,1.151a.846.846,0,0,0,0,1.2Z" transform="translate(-90.29 -270.007)" fill="#43eba2" />
            </svg>
        </div>
    );

}
