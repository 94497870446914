import { useState } from "react";
import swal from "sweetalert";

import useUsoundPaymentsProducts from "../../../Hooks/General/useUsoundPaymentsProducts";
import useCurrencies from "../../../Hooks/General/useCurrencies";
import useLicenses from "../../../Hooks/CS/useLicenses";

import { hideModal } from "../../../Utilities/General/BootstrapApi";
import UsoundPaymentsService from "../../../Services/UsoundPayments/UsoundPaymentsService";
import LicenseService from "../../../Services/Licenses/LicenseService";

export default function AddFreemiumFeatures({
    license,
    setSerial
}) {

    const products = useUsoundPaymentsProducts()
    const currencies = useCurrencies()

    const { licenses, setLicenses } = useLicenses()

    const [order, setOrder] = useState({
        orderdetail_set: [],
        amount: '',
        currency: '',
        origin: 'dashboard-features-sale',
        license_id: null
    })

    const handleChange = (e, name) => {
        if (name === undefined) {
            setOrder({
                ...order,
                [e.target.name]: e.target.value
            })
            return
        }
        if (e.target.value === 'delete') {
            setOrder({
                ...order,
                orderdetail_set: [
                    ...order.orderdetail_set.filter(od => od.name !== name)
                ]
            })
            return
        }
        setOrder({
            ...order,
            orderdetail_set: [
                ...order.orderdetail_set.filter(od => od.name !== name),
                {
                    tag: license.code_code,
                    name,
                    [e.target.name]: e.target.value
                }
            ]
        })
    }

    const handleValidation = () => {
        let od = document.getElementById('odVal')
        let am = document.getElementById('amountVal')
        let curr = document.getElementById('currVal')
        order.orderdetail_set.length === 0 ? od.classList.remove('d-none') : od.classList.add('d-none')
        order.amount.length === 0 ? am.classList.remove('d-none') : am.classList.add('d-none')
        order.currency.length === 0 ? curr.classList.remove('d-none') : curr.classList.add('d-none')
        if (order.orderdetail_set.length === 0 ||
            order.amount.length === 0 ||
            order.currency.length === 0) {
            return false
        } else {
            return true
        }
    }

    const handleReset = () => {
        hideModal('addFF')
        document.getElementById('odVal').classList.add('d-none')
        document.getElementById('amountVal').classList.add('d-none')
        document.getElementById('currVal').classList.add('d-none')
        document.getElementById('amountInput').value = ''
        document.getElementById('currencySelect').value = ''
        let selects = document.querySelectorAll('.selTest')
        for (let i = 0; i < selects.length; i++) {
            selects[i].value = 'delete'
        }
        setOrder({
            orderdetail_set: [],
            amount: '',
            currency: '',
            origin: 'dashboard-features-sale',
            license_id: null
        })
    }

    const handleSubmit = () => {
        if (!handleValidation()) return
        swal({
            title: '¿Guardar nuevos estudios de Audiómetro Modular?',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar']
        })
            .then(confirm => {
                if (confirm) {
                    UsoundPaymentsService.saveNewOrder({
                        ...order,
                        amount: parseInt(order.amount),
                        license_id: license?.id.toString(),
                        orderdetail_set: [
                            ...order.orderdetail_set.map(od => {
                                return {
                                    pricing: parseInt(od.pricing),
                                    tag: od.tag
                                }
                            })
                        ]
                    })
                        .then(() => {
                            LicenseService.getLicense(license.id)
                                .then(data => {
                                    setLicenses([
                                        ...licenses.filter(lic => lic.id !== license.id),
                                        data
                                    ])
                                    setSerial(data)
                                    handleReset()
                                })
                        })
                }
            })
    }

    return (
        <div className="modal fade" id="addFF">
            <div className="modal-dialog modal-xl shadow">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info">
                            Agregar nuevos estudios Modular
                        </h3>
                        <button
                            type="button"
                            className="btn-close" aria-label="Close"
                            onClick={handleReset}
                        ></button>
                    </div>
                    <div className="modal-body d-flex flex-wrap gap-4">
                        <div className="col-12 col-lg-6">
                            <table className="table table-hover text-center">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Período</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products?.map(p => {
                                        if (license?.modelfreemium_set.filter(mf => mf.feature === p.sku && mf.lifetime).length > 0) return <></>
                                        return (
                                            <tr key={p.id}>
                                                <td>{p.name}</td>
                                                <td>
                                                    <select
                                                        name="pricing"
                                                        className="form-select selTest"
                                                        onChange={e => handleChange(e, p.name)}
                                                    >
                                                        <option value="delete">No agregado</option>
                                                        {p.pricing_set
                                                            .filter(ps => ps.country_iso === license?.country)
                                                            .map(ps => {
                                                                return (
                                                                    <option
                                                                        key={ps.id}
                                                                        value={ps.id}
                                                                    >
                                                                        {ps.period === 12 ?
                                                                            'Anual' :
                                                                            ps.period === 6 ?
                                                                                '6 meses' :
                                                                                'De por vida'
                                                                        }
                                                                    </option>
                                                                )
                                                            })}
                                                    </select>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 col-lg-5 border border-muted rounded p-4">
                            <h4 className="text-center text-info">
                                Vista previa
                            </h4>
                            <ul className="list-group list-group-flush">
                                {order.orderdetail_set.length === 0 ?
                                    <li className="list-group-item text-center">No hay estudios agregados.</li> :
                                    order.orderdetail_set
                                        .sort((a, b) => {
                                            if (a.name > b.name) return 1
                                            if (a.name < b.name) return -1
                                            return 0
                                        })
                                        .map(od => {
                                            let evaluation = products.find(p => p.name === od.name)
                                            let period = evaluation.pricing_set.find(ps => ps.id === parseInt(od.pricing)).period
                                            return (
                                                <li key={parseInt(od.pricing)} className="list-group-item d-flex justify-content-between">
                                                    <span>
                                                        {od.name}
                                                    </span>
                                                    <span>
                                                        {period === 12 ? 'Anual' : period === 6 ? '6 meses' : 'De por vida'}
                                                    </span>
                                                </li>
                                            )
                                        })}
                            </ul>
                            <small id="odVal" className="d-none text-danger">
                                * No puede guardar una orden sin estudios agregados.
                            </small>
                            <p className="mt-3 mb-1 text-center text-info">Ingrese monto y moneda</p>
                            <div className="d-flex gap-2 mx-auto justify-content-center">
                                <div>
                                    <input
                                        id="amountInput"
                                        type="number"
                                        name="amount"
                                        className="form-control"
                                        onChange={e => handleChange(e)}
                                    />
                                    <small id="amountVal" className="d-none text-danger">
                                        * Ingrese un monto válido
                                    </small>
                                </div>
                                <div>
                                    <select
                                        id="currencySelect"
                                        name="currency"
                                        className="form-select"
                                        onChange={e => handleChange(e)}
                                    >
                                        <option value="" selected disabled>Seleccione</option>
                                        {currencies?.map(c => {
                                            return (
                                                <option key={c.id} value={c.name}>{c.name}</option>
                                            )
                                        })}
                                    </select>
                                    <small id="currVal" className="d-none text-danger">
                                        * Ingrese moneda.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleReset}
                        >
                            CANCELAR
                        </button>
                        <button
                            type="button"
                            className="btn btn-info text-white"
                            onClick={handleSubmit}
                        >
                            CONFIRMAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}