import React from 'react';

export default function OrdersCard() {

    return (
        <div>
            <svg id="carrito-de-compras" xmlns="http://www.w3.org/2000/svg" width="28.892" height="25.394" viewBox="0 0 28.892 25.394">
                <path id="Trazado_615" data-name="Trazado 615" d="M9.309,16.93H24.66a.847.847,0,0,0,.814-.614L28.86,4.465a.846.846,0,0,0-.814-1.079H7.357L6.751.663A.847.847,0,0,0,5.925,0H.846a.846.846,0,0,0,0,1.693h4.4L8.3,15.446a2.539,2.539,0,0,0,1.009,4.869H24.66a.846.846,0,1,0,0-1.693H9.311a.846.846,0,0,1,0-1.693ZM26.924,5.079l-2.9,10.158H9.99L7.733,5.079Zm0,0" transform="translate(0 0)" fill="#43eba2" />
                <path id="Trazado_616" data-name="Trazado 616" d="M150,362.539A2.539,2.539,0,1,0,152.539,360,2.542,2.542,0,0,0,150,362.539Zm2.539-.846a.846.846,0,1,1-.846.846A.848.848,0,0,1,152.539,361.693Zm0,0" transform="translate(-141.535 -339.685)" fill="#43eba2" />
                <path id="Trazado_617" data-name="Trazado 617" d="M362,362.539A2.539,2.539,0,1,0,364.539,360,2.542,2.542,0,0,0,362,362.539Zm2.539-.846a.846.846,0,1,1-.846.846A.848.848,0,0,1,364.539,361.693Zm0,0" transform="translate(-341.572 -339.685)" fill="#43eba2" />
            </svg>
        </div>
    );

}
