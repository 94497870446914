import * as criptoJS from 'crypto-js';

const Cripto = {

    encrypt: string => {
        var hash = criptoJS.AES.encrypt(string, 'dsfgf465dsfg654dhnd35sasg6j68yuhikmv2b4t746mu8g7bn1387mkryikde325').toString();
        return hash;
    },

    decrypt: (string, key) => {
        try {
            var userString = criptoJS.AES.decrypt(string, key);
            var result = JSON.parse(userString.toString(criptoJS.enc.Utf8));
            return result;
        } catch (error) {
            return error;
        }
    },

}

export default Cripto;




