import React from "react";

import {
    useTable,
    useGlobalFilter,
    useSortBy,
    usePagination,
} from "react-table";

import { GlobalFilter } from "./GlobalFilter";

function TableClients({
    data,
    columns,
    isSellerEditor,
    isAdmin,
    showModal
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,
        setGlobalFilter,
        pageOptions,
        gotoPage,
        pageCount
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: 'id',
                    desc: true
                }
            ],
            pageSize: 20,
            pageIndex: 0
        }
    }, useGlobalFilter, useSortBy, usePagination);

    const { globalFilter } = state;

    return (
        <>
            <div className="row d-flex flex-wrap mx-1 mb-2">
                <div className="col-lg-5">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
                {(isSellerEditor || isAdmin) &&
                    <div className="col">
                        <button
                            type="button"
                            className="linkClientNew mx-3"
                            onClick={() => showModal('newClient')}>
                            +Agregar nuevo
                        </button>
                    </div>
                }
            </div>

            <div className="text-center md-overflow-scroll">
                <table className="table table-hover" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "  ↓" : "  ↑") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page?.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="my-5 d-flex justify-content-center">
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info btn-sm text-white">
                    &#10096;&#10096;&#10096;
                </button>
                <div className="d-inline-block mx-1">
                    {pageCount > 5 ?
                        <div className="mx-1 d-flex align-items-center">
                            <small>Página</small>
                            <select className="form-select mx-1" onChange={(e) => gotoPage(parseInt(e.target.value))}>
                                {pageOptions.map((item, idx) => {
                                    if (parseInt(item) === parseInt(state.pageIndex)) {
                                        return <option key={idx} value={parseInt(item)} selected>{parseInt(item) + 1}</option>
                                    } else {
                                        return <option key={idx} value={parseInt(item)}>{parseInt(item) + 1}</option>
                                    }
                                })}
                            </select>
                            <small>de<span className="ms-1">{pageCount}</span></small>
                        </div> :
                        <div className="mx-1 d-flex flex-wrap justify-content-center">
                            {pageOptions.map((item, idx) => {
                                return <span key={idx}
                                    className={item === state.pageIndex ? "m-1 text-white bg-info p-1 rounded" : "m-2 text-info cursor-pointer"}
                                    onClick={() => { gotoPage(idx) }}>{parseInt(item) + 1}</span>
                            })}
                        </div>
                    }
                </div>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info btn-sm text-white">
                    &#10097;&#10097;&#10097;
                </button>
            </div>
        </>
    );
}

export default TableClients;
