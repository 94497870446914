import { createContext } from "react"

const PaymentInstallmentContext = createContext({
    paymentInstallment: [],
    setPaymentsInstallments: () => { },
    nextPage: [],
    setNextPage: () => { },
    previousPage: [],
    setPreviousPage: () => { },
    totalRegisters: [],
    setTotalRegisters: () => { },
    pageSize: 10,
    setPageSize: () => { }
})

export default PaymentInstallmentContext