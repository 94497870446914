import Cripto from "./Crypto";

let key = 'dsfgf465dsfg654dhnd35sasg6j68yuhikmv2b4t746mu8g7bn1387mkryikde325'

const allowedScopes = [
    'dashboard-seller',
    'dashboard-seller-editor',
    'dashboard-calibrator',
    'dashboard-manufacturer',
    'dashboard-financial',
    'dashboard-admin',
    'dashboard-admin-viewer',
    'dashboard-customer-success'
]

const permissions = [
    {
        route: 'inicio',
        allowed: [
            'all'
        ]
    },
    {
        route: 'clientes',
        allowed: [
            'all'
        ]
    },
    {
        route: 'ordenes-venta',
        allowed: [
            'dashboard-seller',
            'dashboard-seller-editor',
            'dashboard-customer-success',
            'dashboard-financial'
        ]
    },
    {
        route: 'comprobantes-de-pago',
        allowed: [
            'dashboard-seller',
            'dashboard-seller-editor',
            'dashboard-financial'
        ]
    },
    {
        route: 'nueva-orden-venta',
        allowed: [
            'dashboard-seller-editor',
            'dashboard-customer-success'
        ]
    },
    {
        route: 'editar-orden',
        allowed: [
            'dashboard-seller-editor',
            'dashboard-customer-success'
        ]
    },
    {
        route: 'inventario',
        allowed: [
            'dashboard-manufacturer'
        ]
    },
    {
        route: 'ordenes-trabajo',
        allowed: [
            'dashboard-manufacturer',
            'dashboard-customer-success'
        ]
    },
    {
        route: 'ordenes-prearmado',
        allowed: [
            'dashboard-manufacturer',
        ]
    },
    {
        route: 'centros-de-distribucion',
        allowed: [
            'dashboard-manufacturer',
        ]
    },
    {
        route: 'archivos-calibracion',
        allowed: [
            'dashboard-calibrator'
        ]
    },
    {
        route: 'calibraciones',
        allowed: [
            'dashboard-calibrator'
        ]
    },
    {
        route: 'facturacion',
        allowed: [
            'dashboard-financial'
        ]
    },
    {
        route: 'panel-cs',
        allowed: [
            'dashboard-customer-success'
        ]
    }
]

export const getUser = () => {
    let user = {}
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    if (JSON.stringify(auth) !== '{}') {
        user = auth
    }
    return user
}

export const getScopes = () => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    let scopes = []
    let allowedScope = false
    if (JSON.stringify(auth) !== '{}') {
        for (let i = 0; i < auth.scopes.length; i++) {
            if (allowedScopes.includes(auth.scopes[i])) {
                allowedScope = true
            }
        }
    }
    if (allowedScope) {
        scopes = auth.scopes
    }
    return scopes
}

export const isAuth = () => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    if (JSON.stringify(auth) !== '{}' && getScopes().length === 0) {
        return 'only django'
    }
    let flag = false
    if (JSON.stringify(auth) !== '{}' && getScopes().length > 0) {
        flag = true
    }
    return flag
}

export const getUsername = () => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    let username = ''
    if (auth !== null) {
        username = auth.username
    }
    return username
}

export const getPerson = () => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    let person = auth.person
    return person
}

export const getToken = () => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    let token = auth.token
    return token
}

export const isAdmin = () => {
    let flag = false
    if (getScopes().includes('dashboard-admin')) {
        flag = true
    }
    return flag
}

export const isAdminViewer = () => {
    let flag = false
    if (getScopes().includes('dashboard-admin-viewer')) {
        flag = true
    }
    return flag
}

export const isSellerEditor = () => {
    let flag = false
    if (getScopes().includes('dashboard-seller-editor')) {
        flag = true
    }
    return flag
}

export const isCustomerSuccess = () => {
    let flag = false
    if (getScopes().includes('dashboard-customer-success')) {
        flag = true
    }
    return flag
}

export const isManufacturer = () => {
    let flag = false
    if (getScopes().includes('dashboard-manufacturer')) {
        flag = true
    }
    return flag
}

export const isCalibrator = () => {
    let flag = false
    if (getScopes().includes('dashboard-calibrator')) {
        flag = true
    }
    return flag
}

export const isFinancial = () => {
    let flag = false
    if (getScopes().includes('dashboard-financial')) {
        flag = true
    }
    return flag
}

export const isAllowed = route => {
    let auth = Cripto.decrypt(localStorage.getItem('usound'), key)
    let flag = false
    if (isAuth()) {
        for (let i = 0; i < route.length; i++) {
            for (let j = 0; j < permissions.length; j++) {
                if (route[i] === permissions[j].route) {
                    for (let k = 0; k < permissions[j].allowed.length; k++) {
                        for (let m = 0; m < auth.scopes.length; m++) {
                            if (permissions[j].allowed[k] === auth.scopes[m] ||
                                permissions[j].allowed[k] === 'all' ||
                                (isAdmin() || isAdminViewer())) {
                                flag = true
                            }
                        }
                    }
                }
            }
        }
    }
    return flag
}
