import React from 'react';

function Edit() {

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_977" data-name="Grupo 977" transform="translate(-630 -378)">
          <g id="Grupo_967" data-name="Grupo 967" transform="translate(630 378)">
            <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#fb9f15" />
          </g>
          <g id="editar" transform="translate(631.44 384.05)">
            <path id="Trazado_593" data-name="Trazado 593" d="M182.41,73.833a.356.356,0,0,0-.1.179l-.5,2.49a.352.352,0,0,0,.346.421.336.336,0,0,0,.069-.007l2.489-.5a.351.351,0,0,0,.18-.1l5.571-5.571-2.489-2.489Zm0,0" transform="translate(-176.697 -65.524)" fill="#fff" />
            <path id="Trazado_594" data-name="Trazado 594" d="M394.909,1.016a1.762,1.762,0,0,0-2.489,0l-.974.974,2.489,2.489.974-.975a1.76,1.76,0,0,0,0-2.489Zm0,0" transform="translate(-379.415)" fill="#fff" />
          </g>
        </g>
      </svg>
    </div>
  );

}

export default Edit;

