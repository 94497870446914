import React from "react";

export default function CreateClientShippingData({
    countries,
    val,
    forceUpdate,
    newClientShippingData,
    setNewClientShippingData,
    handleSubmit
}) {

    const handleChange = e => {
        setNewClientShippingData({
            ...newClientShippingData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="col-lg-8 mx-auto border border-muted rounded p-4">
            <h3 className="text-info text-center">Nuevos datos de envío</h3>
            <form id="newCSDForm">
                <div className="d-flex flex-wrap">
                    <div className="form-group col-12 col-xl-4">
                        <label htmlFor="address_country">País</label>
                        <select id="address_countryInput"
                            name="address_country"
                            className="form-select mt-1"
                            onChange={e => handleChange(e)} >
                            <option value=""></option>
                            {countries.map((c, cIndex) => {
                                return <option key={cIndex} value={c.id}>{c.name}</option>
                            })}
                        </select>
                    </div>

                    <div className="form-group col-12 col-xl-4 my-2 my-xl-0">
                        <label htmlFor="address_province">Prov. / Estado / Región</label>
                        <input
                            id="address_provinceInput"
                            type="text"
                            name="address_province"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_province', newClientShippingData.address_province, 'required|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|max:150', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-xl-4">
                        <label htmlFor="address_city">Ciudad</label>
                        <input
                            id="address_cityInput"
                            type="text"
                            name="address_city"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_city', newClientShippingData.address_city, 'required|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9]|max:150', { className: 'text-danger' })}
                    </div>
                </div>

                <div className="row d-flex flex-wrap mt-xl-3">

                    <div className="form-group col-12 col-xl-3 my-2 my-xl-0">
                        <label htmlFor="address_postal_code">CP</label>
                        <input
                            id="address_postal_codeInput"
                            type="text"
                            name="address_postal_code"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_postal_code', newClientShippingData.address_postal_code, 'required|numeric|max:50|not_regex:[-]', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-xl-3">
                        <label htmlFor="address_address">Calle</label>
                        <input
                            id="address_addressInput"
                            type="text"
                            name="address_address"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_address', newClientShippingData.address_address, 'required|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü/-]|max:150', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-xl-3 my-2 my-xl-0">
                        <label htmlFor="address_number">Número</label>
                        <input
                            id="address_numberInput"
                            type="text"
                            name="address_number"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_number', newClientShippingData.address_number, 'required|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü/-]|max:150|not_regex:[-]', { className: 'text-danger' })}
                    </div>

                    <div className="form-group col-12 col-xl-3">
                        <label htmlFor="address_floor_apartment">Piso.</label>
                        <input
                            id="address_floor_apartmentInput"
                            type="text"
                            name="address_floor_apartment"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_floor_apartment', newClientShippingData.address_floor_apartment, 'regex:[A-Za-z0-9]|max:150|not_regex:[-]', { className: 'text-danger' })}
                    </div>
                    <div className="form-group col-12 col-xl-3">
                        <label htmlFor="address_apartment">Dpto.</label>
                        <input
                            id="address_apartmentInput"
                            type="text"
                            name="address_apartment"
                            className="form-control mt-1"
                            onChange={e => handleChange(e)}
                        />
                        {val.message('address_apartment', newClientShippingData.address_apartment, 'regex:[A-Za-z0-9]|max:150|not_regex:[-]', { className: 'text-danger' })}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn btn-sm bg-white text-info border border-info"
                        onClick={() => {
                            document.getElementById('newCSDDiv').classList.add('d-none')
                            document.getElementById('newCSDButton').classList.remove('d-none')
                            document.getElementById('newCSDForm').reset()
                            setNewClientShippingData({})
                            val.hideMessages()
                            forceUpdate(2)
                        }}
                    >
                        CANCELAR
                    </button>
                    <button
                        id="submit_button_csd"
                        type="button"
                        className="btn btn-sm btn-info text-white ms-2"
                        onClick={handleSubmit}
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    )
}