import { toast } from "react-toastify"

export const checkPreAssembledStockByOrder = (paymentdetail, stock) => {
    paymentdetail.forEach(pd => {
        const st = stock.find(s => s.sale_product === pd.article_name)
        if (pd.count > st?.stock || st === undefined) {
            toast.warning(`No hay stock suficiente para armar el producto ${pd.article_alias !== null ? pd.article_alias : pd.article_name}. Comuníquese con el área de Producción.`, {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })
        }
    })
}