import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { errorTimeout, existingData, networkError, serverError, expiredSession } from '../../Utilities/General/StatusHandler';

const SaleProductService = {

    getSaleProducts: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockSaleProduct + '?enabled=True',
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewSaleProduct: async newSaleProduct => {
        try {
            const res = await axios.post(
                GlobalUrls.urlStockSaleProduct,
                newSaleProduct,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingData(newSaleProduct.sku, newSaleProduct.suffix)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateSaleProduct: async (id, newSaleProduct) => {
        try {
            const res = await axios.put(
                GlobalUrls.urlStockSaleProduct + `${id}/`,
                newSaleProduct,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingData(newSaleProduct.sku, newSaleProduct.suffix)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getSaleProductStock: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockSaleProductStock + '?enabled=True',
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default SaleProductService