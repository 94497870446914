import { useRef, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FcSearch } from 'react-icons/fc'
import { GrClose } from 'react-icons/gr'

import OrderContext from '../../Contexts/OrderContext'

import { filterOrders } from '../../Utilities/Orders/Pagination'
import OrderService from '../../Services/Licenses/OrderService'
import { filterLicenses } from '../../Utilities/CS/Pagination'
import LicenseService from '../../Services/Licenses/LicenseService'
import PaymentService from '../../Services/Licenses/PaymentService'
import {
    isAdmin,
    isSellerEditor,
    isCustomerSuccess
} from "../../Utilities/Auth/AuthPermissions"
import Urls from '../../Utilities/General/Urls'

export default function TableOrdersHeader({
    setLoadingPage,
    setData,
    fromCSPanel
}) {

    const {
        setNextPage,
        setPreviousPage,
        setTotalRegisters,
        setPageSize
    } = useContext(OrderContext)

    const [typeOrderStatus, setTypeOrderStatus] = useState([])
    const [countries, setCountries] = useState([])

    const navigate = useNavigate()
    const { pathname: path } = useLocation()

    const stringRef = useRef(null)
    const statusRef = useRef('')
    const countryRef = useRef('')

    useEffect(() => {
        OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
        PaymentService.getCountries().then(data => setCountries(data))
    }, [])

    const handleSearch = () => {
        let str = stringRef.current.value
        let fStr = str.trim().replaceAll(' ', '%20')
        let fInt = parseInt(str.trim())
        if (fromCSPanel) {
            filterLicenses(`?search=${str}`)
                .then(data => {
                    setTotalRegisters(data.length)
                    setNextPage(null)
                    setPreviousPage(null)
                    setData(data)
                    setPageSize(data.length)
                    setLoadingPage(false)
                })
            return
        }
        if (!isNaN(fInt) && str.length > 0) {
            setLoadingPage(true)
            filterOrders(`?id=${fInt}`)
                .then(data => {
                    setTotalRegisters(data.length)
                    setNextPage(null)
                    setPreviousPage(null)
                    setData(data)
                    setPageSize(data.length)
                    setLoadingPage(false)
                })
            return
        }
        if (str.length > 0) {
            setLoadingPage(true)
            filterOrders(`?search=${fStr}`)
                .then(data => {
                    setTotalRegisters(data.length)
                    setNextPage(null)
                    setPreviousPage(null)
                    setData(data)
                    setPageSize(data.length)
                    setLoadingPage(false)
                })

        }
    }

    const handleChangeSearch = () => {
        let str = stringRef.current.value
        if (str.length === 0) {
            setLoadingPage(true)
            if (fromCSPanel) {
                LicenseService.getLicenses({
                    ordering: '-id',
                    limit: 10
                }).then(data => {
                    if (!data) return
                    setTotalRegisters(data.count)
                    setNextPage(data.next)
                    setPreviousPage(data.previous)
                    setData(data.results)
                    setPageSize(10)
                    setLoadingPage(false)
                })
            } else {
                OrderService.getOrders({
                    ordering: '-id',
                    limit: 10
                }).then(data => {
                    if (!data) return
                    setTotalRegisters(data.count)
                    setNextPage(data.next)
                    setPreviousPage(data.previous)
                    setData(data.results)
                    setPageSize(10)
                    setLoadingPage(false)
                })
            }
        }
    }

    const resetFilter = () => {
        let str = stringRef.current.value
        if (str.length > 0) {
            document.getElementById('searchInput').value = ''
            str = ''
            handleChangeSearch()
        }
    }

    const handleFilter = () => {
        setLoadingPage(true)
        const url = Urls.urlPayOrder + `${((statusRef.current.value !== '' && statusRef.current.value !== 'status') || (countryRef.current.value !== '' && countryRef.current.value !== 'country')) ? '?' : ''}${(statusRef.current.value === 'status' || statusRef.current.value === '') ? '' : 'status=' + statusRef.current.value}${(statusRef.current.value !== 'status' && statusRef.current.value !== '' && countryRef.current.value !== 'country' && countryRef.current.value !== '') ? '&' : ''}${(countryRef.current.value === 'country' || countryRef.current.value === '') ? '' : 'country=' + countryRef.current.value}`
        OrderService.getOrdersWithUrl(url,
            ((statusRef.current.value === '' || statusRef.current.value === 'status') && (countryRef.current.value === '' || countryRef.current.value === 'country')) ? {
                ordering: '-id',
                limit: 10
            } : false)
            .then(data => {
                if (!data) return
                if (data.results === undefined) {
                    setData(data)
                    setPageSize(data.length)
                    setTotalRegisters(null)
                    setNextPage(null)
                    setPreviousPage(null)
                } else {
                    setTotalRegisters(data.count)
                    setNextPage(data.next)
                    setPreviousPage(data.previous)
                    setData(data.results)
                    setPageSize(10)
                }
                setLoadingPage(false)
            })
    }

    return (
        <div className='my-4'>
            <div className="d-flex mx-1 mb-2 col-5">
                <div className="input-group me-2">
                    <input
                        id="searchInput"
                        type="text"
                        className="form-control"
                        placeholder="Buscar..."
                        aria-label="Buscar..."
                        aria-describedby="basic-addon2"
                        ref={stringRef}
                        onChange={() => handleChangeSearch()}
                    />
                    <span
                        className="input-group-text only-cursor-pointer"
                        id="basic-addon2"
                        onClick={() => resetFilter()}
                    >
                        <GrClose />
                    </span>
                    <span
                        className="input-group-text only-cursor-pointer"
                        id="basic-addon2"
                        onClick={() => handleSearch()}
                    >
                        <FcSearch />
                    </span>
                </div>
                {((isSellerEditor() || isAdmin()) &&
                    path.split('/')[1] === 'ordenes-venta') &&
                    <div className='d-flex align-items-end mt-2 mt-sm-0'>
                        <button className="btn btn-info text-white"
                            onClick={() => navigate('/nueva-orden-venta')}
                        >
                            +Nueva
                        </button>
                    </div>
                }
                {(isCustomerSuccess() &&
                    path.split('/')[1] === 'ordenes-venta') &&
                    <div className='d-flex align-items-end mt-2 mt-sm-0'>
                        <button className="btn btn-info text-white"
                            onClick={() => navigate('/nueva-orden-venta')}
                        >
                            +Reposición
                        </button>
                    </div>
                }
            </div>
            {!fromCSPanel &&
                <div className='bg-info text-white p-2 rounded mt-2 col-5 ms-1 d-flex justify-content-between align-items-center gap-2'>
                    <h5 className='mb-0 col-4 text-center'>Filtrar por</h5>
                    <select className='form-select w-50' name='status' defaultValue="status" onChange={handleFilter} ref={statusRef}>
                        <option disabled value="status">Estado</option>
                        <option value="">Todos</option>
                        {typeOrderStatus.map(os => {
                            return (
                                <option key={os.id} value={os.name}>{os.spanish_name}</option>
                            )
                        })}
                    </select>
                    <select className='form-select w-50' name='country' defaultValue="country" onChange={handleFilter} ref={countryRef}>
                        <option disabled value="country">País</option>
                        <option value="">Todos</option>
                        {countries.map(c => {
                            return (
                                <option key={c.id} value={c.iso_code}>{c.name}</option>
                            )
                        })}
                    </select>
                </div>
            }
        </div>
    )
}