import React from "react";

export default function CreateEmailContact({
    client,
    newEmailContact,
    setNewEmailContact,
    val,
    forceUpdate,
    handleSubmit
}) {

    const handleChange = e => {
        setNewEmailContact({
            ...newEmailContact,
            client: client,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="col-8 mx-auto">
            <form id="newEmailForm">
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Nuevo email"
                        onChange={e => handleChange(e)}
                    />
                    {val.message('email', newEmailContact.email, 'required|email|max:254', { className: 'text-danger' })}
                </div>

                <div className="form-group">
                    <textarea
                        name="note"
                        className="form-control textarea my-2"
                        placeholder="Observaciones"
                        onChange={e => handleChange(e)}
                    ></textarea>
                    {val.message('emailNote', newEmailContact.note, 'max:250', { className: 'text-danger' })}
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-sm bg-white text-info border border-info"
                        onClick={() => {
                            document.getElementById('newEmailDiv').classList.add('d-none')
                            document.getElementById('newEmailButton').classList.remove('d-none')
                            document.getElementById('newEmailForm').reset()
                            setNewEmailContact({})
                            val.hideMessages()
                            forceUpdate(2)
                        }}
                    >
                        CANCELAR
                    </button>
                    <button
                        id="submit_button_email"
                        type="button"
                        className="btn btn-sm btn-info text-white ms-2"
                        onClick={handleSubmit}
                    >
                        GUARDAR
                    </button>
                </div>
            </form>
        </div>
    )
}