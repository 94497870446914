import { toast } from "react-toastify"

import { showModal } from "./BootstrapApi"
import { toggleDisabled } from "./HandleClick"

export const error = () => {
    toast.error('No se pudo procesar la solicitud.', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const networkError = () => {
    toast.error('Error de red.', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const serverError = () => {
    toast.error('Error en el servidor.', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const errorTimeout = () => {
    toast.error('El servidor ha tardado demasiado tiempo en responder.', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const existingClient = (newClient, currentTypeId, currentCountry) => {
    toggleDisabled('submit_create_client')
    toast.error(
        `Ya existe un cliente con ${currentTypeId}
          Nº ${newClient.id_client} de ${currentCountry}.
      `, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const existingSkuOrName = (sku, name) => {
    toast.error(
        `El sku ${sku} o el nombre ${name} ya existen. Estos valores deben ser únicos.`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const existingData = (sku, suffix) => {
    toast.error(
        `El sku ${sku} o el sufijo ${suffix} ya existen. Estos valores deben ser únicos.`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const existingSerialNumber = (serialNumber = '') => {
    toast.error(
        `N° de serie ${serialNumber} existente. Este valor debe ser único.`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const doubleSerialNumber = serialNumber => {
    toast.error(
        `El n° de serie ${serialNumber} sólo puede ingresarse una vez.`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}

export const expiredSession = () => {
    toast.warning(
        `Su sesión ha expirado.`, {
        toastId: 'expiredSessionToast',
        position: 'bottom-center',
        hideProgressBar: true,
        className: 'warning-toast'
    })
    showModal('loginModal')
}

export const isBuilt = () => {
    toast.warning(
        `El archivo de calibración ya está compilado. Actualice la página.`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
    })
}