import React from 'react';

export default function FirstTitle({ text }) {

    return (
        <div
            href="#!"
            className="d-flex flex-wrap align-items-center justify-content-lg-start mb-md-0 me-md-auto firstTitle">
            {text}
        </div>
    );
    
};
