import { toast } from "react-toastify"

export const checkBoneHeads = (paymentdetail, orderdetail, products, articles) => {
    let diagCount = orderdetail.filter(od => od.pricing === products.find(p => p.sku === 'DIAG_LICENSE_01').pricing_set.find(ps => ps.id === od.pricing)?.id)?.length || 0
    let boneheadCount = paymentdetail.find(pd => pd.article === articles.find(art => art.sku === 'NBK_VINCH_OSEA_0001').id)?.count || 0

    let [bonetestProduct] = products.filter(p => p.sku === "AUTOMATIC_AND_MANUAL_BONE_TEST_01")

    let bonetestCount = 0
    orderdetail?.forEach(od => {
        bonetestProduct.pricing_set.forEach(ps => {
            if (od.pricing === ps.id) {
                bonetestCount++
            }
        })
    })

    let total = diagCount + bonetestCount
    let difference = total - boneheadCount
    if (boneheadCount >= total) {
        return { result: true, difference }
    }
    toast.warning(`
        Se ${(difference) === 1 ? 'agregó' : 'agregaron'} 
        ${difference} 
        ${(difference) === 1 ? 'vincha' : 'vinchas'}
        ${(difference) === 1 ? 'ósea bonificada' : 'óseas bonificadas'} 
         para Licencia Diagnóstico o Modular con Audiometría Ósea`, {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'warning-toast'
    })
    return { result: false, difference }
}