import React from 'react';

import { hideModal } from '../../../Utilities/General/BootstrapApi';

export default function InfoSaleProduct({
    workOn,
    saleProducts,
    typeInputProduct
}) {

    const [saleProduct] = saleProducts?.filter(sp => sp.sku === workOn)

    return (
        <div className="modal fade" id="infoSaleProduct" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                            hideModal('infoSaleProduct')
                        }}></button>
                    </div>
                    <div className="modal-body">
                        {saleProduct !== undefined &&
                            <>
                                <div className="mb-4">
                                    <h3 className="text-info">Producto</h3>
                                    <p className="d-block mt-3">
                                        <strong>SKU: </strong>{workOn} <br />
                                        <strong>Nombre: </strong>{saleProduct.name} <br />
                                        <strong>Sufijo: </strong>{saleProduct.suffix}
                                    </p>
                                </div>
                                <div>
                                    <h3 className="text-info">Artículos requeridos</h3>
                                    <table className="table table-hover text-center">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {saleProduct.saleproductrequirement_set.map((req, reqIndex) => {
                                                return typeInputProduct.map((tip, tipIndex) => {
                                                    if (req.type_input_product === tip.id) {
                                                        return (
                                                            <tr>
                                                                <td>{tip.name}</td>
                                                                <td>{req.quantity}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            hideModal('infoSaleProduct')
                        }}>CERRAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}