import React, { useEffect, useState } from 'react';
import { FcPhoneAndroid } from 'react-icons/fc'
import Flag from 'react-world-flags'
import { toast } from 'react-toastify';

import { hideModal, showModal } from '../../../Utilities/General/BootstrapApi';

import ArgFlag from '../../../Assets/svg/arg.svg'
import ChiFlag from '../../../Assets/svg/chi.svg'
import ColFlag from '../../../Assets/svg/col.svg'

export default function CreateFreemiumSettings({
    order,
    freemiumArticle,
    clients,
    countries,
    uSoundPaymentOrder,
    setUsoundPaymentOrder,
    paymentServiceProducts,
    shippingData
}) {

    const [freemiumSet, setFreemiumSet] = useState([])

    const [currentCountry, setCurrentCountry] = useState('')

    useEffect(() => {
        setCurrentCountry(countries?.find(country => country.id === parseInt(shippingData.address_country))?.iso_code)
    }, [countries, clients, order, shippingData])

    useEffect(() => {
        let freemiumsForPush = []
        let [freemiumPD] = order.paymentdetail_set.filter(pd => pd.article === freemiumArticle.id)
        if (freemiumPD !== undefined) {
            for (let i = 0; i < freemiumPD.count; i++) {
                freemiumsForPush.push({
                    tag: '#' + (i + 1)
                })
            }
            setFreemiumSet(freemiumsForPush)
        }
    }, [order])

    const handleChange = (e, name, tag) => {
        if (e.target.value === 'exclude') {
            setUsoundPaymentOrder({
                ...uSoundPaymentOrder,
                orderdetail_set: [
                    ...uSoundPaymentOrder.orderdetail_set.filter(ps => ps.tag !== tag || (ps.tag === tag && ps.name !== name))
                ]
            })
        } else {
            let ps = JSON.parse(e.target.value)
            setUsoundPaymentOrder({
                ...uSoundPaymentOrder,
                orderdetail_set: [
                    ...uSoundPaymentOrder.orderdetail_set.filter(ps => ps.tag !== tag || (ps.tag === tag && ps.name !== name)),
                    {
                        pricing: parseInt(ps.id),
                        amount: ps.amount,
                        currency: ps.currency,
                        discount: 0,
                        name,
                        tag
                    }
                ]
            })
        }
    }

    const resetSelects = (tag = null) => {
        let selects = document.getElementsByClassName(tag === null ? 'freemiumSelects' : 'select' + tag)
        for (let i = 0; i < selects.length; i++) {
            selects[i].value = 'exclude'
        }
    }

    const disableSelects = (flag, tag) => {
        let selects = document.getElementsByClassName('select' + tag)
        for (let i = 0; i < selects.length; i++) {
            selects[i].disabled = flag
        }
    }

    const handleLicenseType = (e, product, tag) => {
        if (product.sku === 'MODULAR_LICENSE_01') {
            disableSelects(false, tag)
            document.getElementById('DIAG_LICENSE_01check' + tag).checked = false
        }
        if (product.sku === ('DIAG_LICENSE_01')) {
            disableSelects(true, tag)
            document.getElementById('MODULAR_LICENSE_01check' + tag).checked = false
        }
        if (e.target.checked) {
            setUsoundPaymentOrder({
                ...uSoundPaymentOrder,
                orderdetail_set: [
                    ...uSoundPaymentOrder.orderdetail_set.filter(od => od.tag !== tag),
                    {
                        tag,
                        pricing: product.pricing_set.find(ps => ps.country_iso === currentCountry)?.id || null,
                        sku: product.sku
                    }
                ]
            })
        } else {
            setUsoundPaymentOrder({
                ...uSoundPaymentOrder,
                orderdetail_set: [
                    ...uSoundPaymentOrder.orderdetail_set.filter(od => od.tag !== tag)
                ]
            })
        }
        resetSelects(tag)
    }

    const allAudiometersAssigned = () => {
        let flag = true
        freemiumSet.forEach(fs => {
            if (!uSoundPaymentOrder.orderdetail_set.some(od => od.tag === fs.tag)) {
                flag = false
                toast.error(`Elija una licencia para el audiómetro ${fs.tag}`, {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'error-toast'
                })
            }
        })
        return flag
    }

    const handleChangeRewarded = (e, name, tag) => {
        let pricing = uSoundPaymentOrder.orderdetail_set.find(od => od.tag === tag && od.name === name).pricing
        let base_amount
        paymentServiceProducts.forEach(prod => {
            prod.pricing_set.forEach(ps => {
                if (ps.id === pricing) base_amount = ps.amount
            })
        })
        setUsoundPaymentOrder({
            ...uSoundPaymentOrder,
            orderdetail_set: [
                ...uSoundPaymentOrder.orderdetail_set.filter(od => od.tag !== tag || od.name !== name || od.name === undefined),
                {
                    ...uSoundPaymentOrder.orderdetail_set.find(od => od.tag === tag && od.name === name),
                    discount: e.target.value,
                    amount: (parseInt(base_amount) - ((parseInt(base_amount) / 100) * parseInt(e.target.value))).toFixed(2)
                }
            ]
        })
    }

    const resetChecks = () => {
        const checks = document.getElementsByClassName('license-check')
        for (let i = 0; i < checks.length; i++) {
            checks[i].checked = false
        }
    }

    return (
        <div className="modal fade" id="freemiumSettings">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info" id="staticBackdropLabel">Configurar Servicios Modular</h3>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                hideModal('freemiumSettings')
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className='text-center'>
                            <p>
                                <strong>
                                    Seleccione las evaluaciones incluidas en cada Audiómetro Modular de la orden y sus períodos correspondientes
                                </strong>
                            </p>
                            <p>
                                País:
                                {currentCountry === 'AR' &&
                                    <img src={ArgFlag} width="40" className="ms-2" alt='' />
                                }
                                {currentCountry === 'BO' &&
                                    <Flag code='BO' className='ms-2' width="40" />
                                }
                                {currentCountry === 'BR' &&
                                    <Flag code='BR' className='ms-2' width="40" />
                                }
                                {currentCountry === 'CL' &&
                                    <img src={ChiFlag} width="40" className="ms-2" alt='' />
                                }
                                {currentCountry === 'CO' &&
                                    <img src={ColFlag} width="40" className="ms-2" alt='' />
                                }
                                {currentCountry === 'EC' &&
                                    <Flag code='EC' className='ms-2' width="40" />
                                }
                                {currentCountry === 'PE' &&
                                    <Flag code='PE' className='ms-2' width="40" />
                                }
                            </p>
                        </div>
                        <div className='d-flex flex-wrap justify-content-around'>
                            {freemiumSet.map(fs => {
                                return (
                                    <div className='d-flex flex-column justify-content-center text-center p-3 m-2 rounded shadow-sm'>
                                        <span className='fs-1'>
                                            <FcPhoneAndroid />
                                        </span>
                                        <strong>Audiómetro {fs.tag}</strong>
                                        <div className='border border-muted rounded mt-3 p-3'>
                                            <strong>Seleccione el tipo de licencia</strong>
                                            {paymentServiceProducts
                                                .filter(p => p.type === 'license')
                                                .map(p => {
                                                    return (
                                                        <div className='d-flex'>
                                                            <p className='mb-0'>{p.name}</p>
                                                            <input
                                                                id={p.sku + 'check' + fs.tag}
                                                                type="checkbox"
                                                                className="form-check ms-1 license-check"
                                                                onChange={e => handleLicenseType(e, p, fs.tag)}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                        <table id={'features_table_' + fs.tag} className='table table-hover mt-3'>
                                            <thead>
                                                <tr>
                                                    <th>Evaluación</th>
                                                    <th>Período y monto de base</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentServiceProducts
                                                    .filter(p => p.type === 'feature')
                                                    .sort((a, b) => {
                                                        if (a.name > b.name) return 1
                                                        if (b.name > a.name) return -1
                                                        return 0
                                                    })
                                                    .map(p => {
                                                        return (
                                                            <tr key={p.id}>
                                                                <td>
                                                                    {p.name}
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className={`form-select freemiumSelects ${'select' + fs.tag}`}
                                                                        onChange={e => handleChange(e, p.name, fs.tag)}
                                                                        disabled={!uSoundPaymentOrder.orderdetail_set.some(od => od.tag === fs.tag) || uSoundPaymentOrder.orderdetail_set.filter(od => od.tag === fs.tag).some(od => od.sku === 'DIAG_LICENSE_01')}
                                                                    >
                                                                        <option value="exclude">No incluido</option>
                                                                        {p.pricing_set.sort((a, b) => b.period - a.period).map(ps => {
                                                                            if (ps.country_iso === currentCountry) {
                                                                                return (
                                                                                    <option key={ps.id} value={JSON.stringify(ps)}>
                                                                                        {`${ps.period === 0 ? 'De por vida' : ps.period === 12 ? 'Anual' : ps.period + ' meses'} - ${ps.amount} ${ps.currency}`}
                                                                                    </option>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                        <div className='mt-4'>
                                            <h4>Aplicar Descuentos</h4>
                                            <table className='table'>
                                                <thead>
                                                    <th>Nombre</th>
                                                    <th>Monto bonificado</th>
                                                    <th>% bonificación</th>
                                                </thead>
                                                <tbody>
                                                    {uSoundPaymentOrder.orderdetail_set
                                                        .filter(od => od.tag === fs.tag && od.name !== undefined)
                                                        .sort((a, b) => {
                                                            if (a.name > b.name) return 1
                                                            if (b.name > a.name) return -1
                                                            return 0
                                                        })
                                                        .map(od => {
                                                            return (
                                                                <tr>
                                                                    <td>{od.name}</td>
                                                                    <td>{od.amount + ' ' + od.currency}</td>
                                                                    <td>
                                                                        <select
                                                                            className={`form-select freemiumSelects ${'select' + fs.tag}`}
                                                                            onChange={e => handleChangeRewarded(e, od.name, fs.tag)}
                                                                            disabled={!uSoundPaymentOrder.orderdetail_set.some(od => od.tag === fs.tag) || uSoundPaymentOrder.orderdetail_set.filter(od => od.tag === fs.tag).some(od => od.sku === 'DIAG_LICENSE_01')}
                                                                        >
                                                                            <option value="0">Sin bonificación</option>
                                                                            {Array.from({ length: 100 })
                                                                                .map((_, idx) => {
                                                                                    if ((idx + 1) % 5 === 0) {
                                                                                        return (
                                                                                            <option key={idx} value={idx + 1}>{idx + 1}%</option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                resetSelects()
                                resetChecks()
                                setUsoundPaymentOrder({
                                    orderdetail_set: []
                                })
                            }}
                        >
                            BORRAR DATOS
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                hideModal('freemiumSettings')
                            }}
                        >
                            VOLVER A LA ORDEN
                        </button>
                        <button
                            type="button"
                            className="btn btn-info text-white"
                            onClick={() => {
                                if (!allAudiometersAssigned()) return
                                hideModal('freemiumSettings')
                                showModal('orderDataConfirm')
                            }}
                        >
                            CONFIRMAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}