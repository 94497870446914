import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';

import { isCalibrator, isAdmin } from '../../Utilities/Auth/AuthPermissions';

import Delete from '../../Assets/Delete';

export default function TableCalibrationsService({
    columns,
    data,
    length,
    filteredRows,
    built,
    showModal,
    dontShowButton,
    typeRef,
    calibration_typeRef,
    connection_typeRef,
    pageSizeProp,
    emissionType,
    size,
    deleteFeature,
    handleDelete
}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        pageOptions,
        pageCount,
        gotoPage
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: emissionType === 'pure tone' ? 'frequency' : 'id',
                    desc: true
                }
            ],
            pageSize: pageSizeProp,
            pageIndex: 0
        }
    }, useSortBy, usePagination);

    return (
        <>
            <div className={size === 'lg' ? "text-center lg-overflow-scroll" : "text-center md-overflow-scroll"}>
                <table {...getTableProps()} className={parseInt(length) === 0 ? "table" : "table table-hover"}   >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="calibration-th"
                                    >
                                        <span className="me-1">
                                            {column.isSorted ? (column.isSortedDesc ? "  ↓" : "  ↑") : ""}
                                        </span>
                                        {column.render('Header')}
                                    </th>
                                ))}
                                {deleteFeature === 'true' && data.length > 1 && <th></th>}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page?.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                    {deleteFeature === 'true' && data.length > 1 &&
                                        <td>
                                            <span className='cursor-pointer' onClick={() => handleDelete(row.original)}>
                                                <Delete />
                                            </span>
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                        {parseInt(length) === 0 && !filteredRows &&
                            <tr>
                                <td colSpan="9" className="p-3">
                                    <strong className="text-muted">
                                        No hay resultados que mostrar.
                                    </strong>
                                </td>
                            </tr>
                        }
                        {parseInt(length) === 0 && filteredRows &&
                            <tr>
                                {built ?
                                    <td colSpan="9" className={dontShowButton ? 'p-4 pb-0' : 'p-4'}>
                                        <strong className="text-muted">
                                            No se han encontrado resultados.
                                        </strong><br />
                                    </td> :
                                    <td colSpan="9" className={dontShowButton ? 'p-4 pb-0' : 'p-4'}>
                                        <strong className="text-muted">
                                            No se han encontrado resultados.<br />
                                            {!dontShowButton &&
                                                typeRef === 'all' &&
                                                connection_typeRef === 'all' &&
                                                calibration_typeRef === 'all' &&
                                                (isCalibrator() || isAdmin()) &&
                                                <>
                                                    ¡Registre una nueva calibración!
                                                </>
                                            }
                                        </strong><br />
                                        {!dontShowButton && (isCalibrator() || isAdmin()) &&
                                            typeRef === 'all' &&
                                            connection_typeRef === 'all' &&
                                            calibration_typeRef === 'all' &&
                                            <button type="button" className="btn border border-secondary text-secondary mt-2" onClick={() => showModal('calModal')}>
                                                REGISTRAR
                                            </button>
                                        }
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            {length > pageSizeProp &&
                <div className="d-flex justify-content-center">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info btn-sm text-white">
                        &#10096;&#10096;&#10096;
                    </button>
                    {pageCount > 5 ?
                        <div id="selectPagination" className="mx-1 d-flex align-items-center">
                            <small>Página</small>
                            <select className="form-select mx-1" onChange={(e) => gotoPage(parseInt(e.target.value))}>
                                {pageOptions.map((item, idx) => {
                                    if (parseInt(item) === parseInt(state.pageIndex)) {
                                        return <option key={idx} value={parseInt(item)} selected>{parseInt(item) + 1}</option>
                                    } else {
                                        return <option key={idx} value={parseInt(item)}>{parseInt(item) + 1}</option>
                                    }
                                })}
                            </select>
                            <small>de<span className="ms-1">{pageCount}</span></small>
                        </div> :
                        <div id="indexesPagination" className="mx-1 d-flex justify-content-center">
                            {pageOptions.map((item, idx) => {
                                return <span key={idx}
                                    className={item === state.pageIndex ? "m-1 text-white bg-info p-1 rounded" : "m-2 text-info cursor-pointer"}
                                    onClick={() => { gotoPage(idx) }}>{parseInt(item) + 1}</span>
                            })}
                        </div>
                    }
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info btn-sm text-white">
                        &#10097;&#10097;&#10097;
                    </button>
                </div>
            }
        </>
    )
}