import { S3Client } from "@aws-sdk/client-s3";
import { IAMClient } from '@aws-sdk/client-iam'

const config = {
    region: 'us-east-1',
    credentials: {
        client: new IAMClient({ region: 'us-east-1' }),
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    },
}

export const s3 = new S3Client(config)