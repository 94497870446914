import { useEffect, useState } from "react";

import Loader from "../../../AppComponents/Commons/Loader";
import useDistCenters from "../../../Hooks/Stock/useDistCenters";
import AdminDistCenters from "./AdminDistCenters";

import { setPreAssembledStock } from "../../../Utilities/Orders/SetPreAssembledStock";
import { setDistCentersStock } from "../../../Utilities/Orders/SetDistCentersStock";
import { showModal } from "../../../Utilities/General/BootstrapApi";

export default function PreAssembledStock({
    stockOrders,
    saleProducts,
    saleProductItemMovementsList,
    setSaleProductItemMovementsList,
    workOn,
    setWorkOn,
    setStockOrders
}) {

    const [dcStock, setDcStock] = useState([])
    const [stock, setStock] = useState([])
    const [currentSP, setCurrentSP] = useState(null)
    const distCenters = useDistCenters()

    useEffect(() => {
        setStock(setPreAssembledStock(stockOrders, saleProducts))
        setDcStock(setDistCentersStock(distCenters, stockOrders))
    }, [stockOrders, saleProducts, distCenters])

    return (
        <div>
            <AdminDistCenters
                saleProductItemMovementsList={
                    saleProductItemMovementsList?.filter(spiml => spiml.distribution_center === workOn)}
                setSaleProductItemMovementsList={setSaleProductItemMovementsList}
                workOn={workOn}
                distCenters={distCenters}
                currentSP={currentSP}
                saleProducts={saleProducts}
                setStockOrders={setStockOrders}
            />
            <h3 className="text-center text-info">Pre-armados disponibles</h3>
            {stock.length === 0 ?
                <div>
                    <Loader />
                    <strong className="d-block text-center">Cargando...</strong>
                </div> :
                stockOrders.length > 0 ?
                    <table className="table table-hover text-center">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Total</th>
                                <th>Distribuidor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stock.map((st, stIndex) => (
                                <tr key={stIndex}>
                                    <td>{st.sale_product}</td>
                                    <td>{st.stock}</td>
                                    <td>
                                        {dcStock.map(item => {
                                            let count = item.stock.filter(el => (saleProducts.find(sp => sp.id === el.sale_product).alias !== null ?
                                                saleProducts.find(sp => sp.id === el.sale_product).alias : saleProducts.find(sp => sp.id === el.sale_product).name) === st.sale_product).length
                                            if (count > 0) return (
                                                <span
                                                    key={item.dist}
                                                    className="d-block cursor-pointer"
                                                    onClick={() => {
                                                        setWorkOn(item.dist)
                                                        setCurrentSP(st.sale_product)
                                                        showModal('adminDistCenters')
                                                    }}
                                                >
                                                    {distCenters.find(dc => dc.id === item.dist).country_iso_origin} - {distCenters.find(dc => dc.id === item.dist).name}: {count}
                                                </span>
                                            )
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> :
                    <strong className="d-block text-center">No hay datos que mostrar.</strong>
            }
        </div>
    )
}