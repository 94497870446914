import React, { useMemo, useEffect, useState } from 'react';
import format from 'date-fns/format';
import { toast } from 'react-toastify';

import TableFrontPaginated from '../../../AppComponents/Orders/TableFrontPaginated';
import Loader from '../../../AppComponents/Commons/Loader';

import { getPerson, isAdmin, isCustomerSuccess, isManufacturer } from '../../../Utilities/Auth/AuthPermissions';

export default function LicensesAssignement({
    saleProducts,
    workOnStockOrder,
    items,
    newMovement,
    inputProducts,
    saleProductItemMovementsList,
    distCenters
}) {

    let allReqs = []
    if (workOnStockOrder !== null) {
        let [saleProduct] = saleProducts.filter(sp => sp.id === workOnStockOrder.sale_product)
        for (let i = 0; i < saleProduct.saleproductrequirement_set.length; i++) {
            for (let j = 0; j < saleProduct.saleproductrequirement_set[i].quantity; j++) {
                allReqs.push(saleProduct.saleproductrequirement_set[i])
            }
        }
    }

    useEffect(() => {
        let duplicated = false
        let counter
        for (let i = 0; i < newMovement.movementdetail_set.length; i++) {
            counter = 0
            let [currentItem] = items.filter(item => item.id === newMovement.movementdetail_set[i].item)
            let [currentInputProduct] = inputProducts.filter(ip => ip.id === currentItem?.input_product)
            for (let j = 0; j < newMovement.movementdetail_set.length; j++) {
                if (newMovement.movementdetail_set[i].item === newMovement.movementdetail_set[j].item) {
                    counter++
                }
            }
            if (counter > 1 && currentInputProduct.is_serializable) {
                duplicated = true
            }
        }
        if (duplicated) {
            document.getElementById('duplicatedMessage')?.classList.remove('d-none')
        } else {
            document.getElementById('duplicatedMessage')?.classList.add('d-none')
        }
    }, [newMovement])



    const columnsPreAssembled = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Nombre',
                disableSortBy: true,
                accessor: spiml => {
                    return saleProducts.map(sp => {
                        if (spiml.sale_product === sp.id) {
                            return (
                                <div key={spiml.id}>
                                    <span
                                        className='cursor-pointer d-block'
                                        onClick={() => {
                                            document.getElementById('showAssignedItems' + spiml.id).classList.toggle('d-none')
                                        }}
                                    >
                                        {sp.alias !== null ? sp.alias : sp.name}
                                    </span>
                                    <span id={'showAssignedItems' + spiml.id} className="d-none">
                                        <strong>Insumos asignados</strong>
                                        <ul className='list-style-none'>
                                            {spiml.movementdetail_set.map(md => (
                                                <li key={md.id}>
                                                    <p
                                                        className='d-block'>
                                                        {`${md.item_input_product_name} - ${'Serie / lote: ' + md.item_input_product_serial_number}`}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </span>
                                </div>
                            )
                        }
                    })
                }
            },
            {
                Header: 'SKU',
                accessor: 'sale_product_sku'
            },
            {
                Header: 'Nros. de serie',
                accessor: 'serial_number'
            },
            {
                Header: 'Licencia',
                disableSortBy: true,
                accessor: spiml => spiml.code
            },
            {
                Header: 'Fecha prearmado',
                accessor: 'timestamp',
                Cell: ({ value }) => {
                    return format(new Date(value), 'dd/MM/yyyy HH:mm')
                }
            },
            {
                Header: 'Distribuidor',
                disableSortBy: true,
                accessor: spiml => distCenters.find(dc => dc.id === spiml.distribution_center).name
            }
        ],
        [
            distCenters,
            saleProducts
        ]
    )

    return (
        <>
            <h4 className={`text-info text-center ${isCustomerSuccess() && 'mt-5'}`}>
                Productos pre-armados
            </h4>
            <div id='preAssembledContainer' className='md-overflow-scroll'>
                {saleProductItemMovementsList?.length === 0 ?
                    <div className='text-center'>
                        <Loader />
                        <strong className="d-block text-center">Cargando...</strong>
                    </div> :
                    <TableFrontPaginated
                        columns={columnsPreAssembled}
                        data={
                            saleProductItemMovementsList?.filter(spiml => spiml.status === 'inputs products assigned')
                                .sort((a, b) => b.id - a.id)
                            || []
                        }
                    />
                }
            </div>
        </>
    )
}