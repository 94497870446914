import React, { useRef, useEffect, useState } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";

import InputProductService from "../../../Services/Stock/InputProductService";
import MovementService from "../../../Services/Stock/MovementService";
import { isAuth } from "../../../Utilities/Auth/AuthPermissions";
import { getPerson } from "../../../Utilities/Auth/AuthPermissions";
import { checkItemsOccurences } from "../../../Utilities/Inventory/CheckItemsOccurrences";
import { generateSerialNumber } from "../../../Utilities/Inventory/GenerateSerialNumber";
import { toggleDisabled } from '../../../Utilities/General/HandleClick'

export default function CreateMovement({
    inputProducts,
    suppliers,
    setNewMovement,
    newMovement,
    newItems,
    setNewItems,
    val,
    forceUpdate,
    inputProductsStock,
    setInputProductsStock,
    items,
    is_serializable,
    setIs_serializable,
    choiceArticle,
    setChoiceArticle,
    disableButton,
    setDisableButton,
    setFail,
    fail,
    setLoadingMovement,
    setItems,
    setMovements,
    movements,
    itemsStock,
}) {

    const [totalStock, setTotalStock] = useState(null);

    const typeRef = useRef('ingreso');
    const inputProductRef = useRef('no_choice');

    useEffect(() => {
        if (newItems.length > 1) {
            setNewItems([{}])
        }
    }, [choiceArticle])

    const handleItemChange = (e, index) => {
        newItems.map((ni, idx) => {
            if (typeRef.current.value === 'ingreso') {
                newItems[idx].input_product = inputProductRef.current.value;
            }
            if (idx === index) {
                newItems[idx] = {
                    ...newItems[idx],
                    [e.target.name]: e.target.value
                }
            }
        })
        setNewItems(newItems)
        if (is_serializable) {
            newItems.map((ni, idx) => {
                newItems[idx].quantity = 1
            })
        }
        if (inputProductRef.current.value !== 'no_choice') {
            setChoiceArticle(true)
        } else {
            setChoiceArticle(false)
        }
        if (typeRef.current.value === 'egreso') {
            setTotalStock(inputProductsStock.filter(ips => ips.id === parseInt(inputProductRef.current.value))[0].stock)
            if (e.target.name === 'item') {
                let input = document.getElementById('loteStockInput' + index)
                let value = itemsStock.filter(is => is.id === parseInt(e.target.value) && is.input_product === parseInt(inputProductRef.current.value))[0]?.stock
                if (input !== null) input.value = value !== undefined ? value : '-'
            }
        }
    }

    useEffect(() => {
        newItems.map((ni, idx) => {
            let sup = document.getElementById('supplierInput' + idx)
            let serial = document.getElementById('serial_numberInput' + idx)
            let item = document.getElementById('itemInputMov' + idx)
            let warranty = document.getElementById('warranty_dateInput' + idx)
            let quantity = document.getElementById('quantityInput' + idx)
            if (typeRef.current.value === 'ingreso') {
                if (sup !== null) {
                    sup.value = newItems[idx].supplier !== undefined ? newItems[idx].supplier : ""
                }
                if (serial !== null) {
                    serial.value = newItems[idx].serial_number !== undefined ? newItems[idx].serial_number : ""
                }
                if (warranty !== null) {
                    warranty.value = newItems[idx].warranty_date !== undefined ? newItems[idx].warranty_date : ""
                }
                if (quantity !== null) {
                    quantity.value = newItems[idx].quantity !== undefined ? newItems[idx].quantity : ""
                }
            } else {
                if (item !== null) {
                    item.value = newItems[idx].item !== undefined ? newItems[idx].item : ""
                }
                if (quantity !== null) {
                    quantity.value = newItems[idx].quantity !== undefined ? newItems[idx].quantity : ""
                }
            }
        })
        let currentItems = items.filter(item => item.input_product === parseInt(inputProductRef.current.value))
        if (newItems.length === currentItems.length && typeRef.current.value === 'egreso') {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [newItems])

    useEffect(() => {
        if (newItems.length >= 2) {
            let sup = document.getElementById('supplierInput' + (newItems.length - 1))
            if (sup !== null) {
                sup.value = newItems[newItems.length - 2].supplier
                newItems[newItems.length - 1].supplier = newItems[newItems.length - 2].supplier
            }
        }
    }, [newItems])

    const handleMovementChange = e => {
        setNewMovement({
            ...newMovement,
            [e.target.name]: e.target.value
        })
    }

    const checkItemsAdded = (value, idx) => {
        let flag = true
        let i = 0
        while (i < newItems.length) {
            if (parseInt(newItems[i].item) === value && i !== idx) {
                flag = false
                break
            }
            i++
        }
        return flag
    }

    const validateArticle = () => {
        let flag = true
        if (inputProductRef.current.value === 'no_choice') {
            flag = false
        }
        if (!flag) {
            document.getElementById('ipError').classList.remove('d-none')
        } else {
            document.getElementById('ipError').classList.add('d-none')
        }
        return flag
    }

    const validateFields = () => {
        let generalFlag = true
        let serialFlag = true
        let supFlag = true
        let itemFlag = true

        for (let i = 0; i < newItems.length; i++) {
            let sup = document.getElementById('supplierInput' + i)
            let serial = document.getElementById('serial_numberInput' + i)
            let item = document.getElementById('itemInputMov' + i)
            let regex = /^[a-zA-Z0-9.]*$/

            if (newMovement.type === 'ingreso') {
                if (sup.value.length === 0) {
                    document.getElementById('supplierMessage' + i).classList.remove('d-none')
                    supFlag = false
                } else {
                    document.getElementById('supplierMessage' + i).classList.add('d-none')
                    supFlag = true
                }
                if ((serial.value.length > 1 && serial.value.length < 200) || !is_serializable) {
                    document.getElementById('serial_numberInputMessage' + i).classList.add('d-none')
                    document.getElementById('serial_numberInputLengthMessage' + i).classList.add('d-none')
                    serialFlag = true
                } else {
                    if (serial.value.length < 1) {
                        document.getElementById('serial_numberInputMessage' + i).classList.remove('d-none')
                    }
                    if (serial.value.length > 200) {
                        document.getElementById('serial_numberInputLengthMessage' + i).classList.remove('d-none')
                    }
                    serialFlag = false
                }
                if (!regex.test(serial.value)) {
                    document.getElementById('serial_numberInputCharMessage' + i).classList.remove('d-none')
                    serialFlag = false
                } else {
                    document.getElementById('serial_numberInputCharMessage' + i).classList.add('d-none')
                    serialFlag = true
                }
            }

            if (newMovement.type === 'egreso') {
                if (item.value.length === 0) {
                    document.getElementById('serial_numberInputMessage' + i).classList.remove('d-none')
                    itemFlag = false
                } else {
                    document.getElementById('serial_numberInputMessage' + i).classList.add('d-none')
                    itemFlag = true
                }
            }
        }

        if (supFlag && serialFlag && itemFlag) {
            generalFlag = true
        } else {
            generalFlag = false
        }

        return generalFlag
    }

    const validateQuantity = () => {
        let generalFlag = true
        let stockFlag = true
        let quantityFlag = true
        let total = 0

        for (let i = 0; i < newItems.length; i++) {
            let quantity = document.getElementById('quantityInput' + i)
            total = total + parseInt(quantity.value)

            if (!is_serializable) {
                if (parseInt(quantity.value) < 1 || quantity.value.length === 0) {
                    document.getElementById('totalRequiredMessage' + i).classList.remove('d-none')
                } else {
                    document.getElementById('totalRequiredMessage' + i).classList.add('d-none')
                }
                if (parseInt(quantity.value) > 1000) {
                    document.getElementById('totalMaxError' + i).classList.remove('d-none')
                } else {
                    document.getElementById('totalMaxError' + i).classList.add('d-none')
                }
                quantityFlag = parseInt(quantity.value) >= 1 && quantity.value.length > 0 && parseInt(quantity.value) <= 1000 ? true : false
            }
        }

        if (newMovement.type === 'egreso' && !checkCounts()) {
            document.getElementById('totalStockError').classList.remove('d-none')
            stockFlag = false
        } else {
            document.getElementById('totalStockError').classList.add('d-none')
            stockFlag = true
        }

        if (stockFlag && quantityFlag) {
            generalFlag = true
        } else {
            generalFlag = false
        }

        return generalFlag
    }

    const checkCounts = () => {
        let flag = true
        newItems.forEach(ni => {
            let [element] = itemsStock.filter(is => is.id === parseInt(ni.item))
            let stock = element.stock
            if (Math.abs(ni.quantity) > stock) flag = false
        })
        return flag
    }

    useEffect(() => {
        if (isAuth()) {
            InputProductService.getInputProductStock()
                .then(data => {
                    setInputProductsStock(data)
                })
        }
    }, [inputProducts])

    const saveNewMovement = (e, newItems, newMovement) => {
        e.preventDefault();
        let [currentInputProduct] = inputProducts.filter(ip => ip.id === parseInt(newItems[0].input_product))
        // solo auto genera el numero de lote cuando el articulo no es serializable
        if (!currentInputProduct?.is_serializable) {
            generateSerialNumber(items, newItems)
            // solo checkea la duplicidad de items cuando el articulo es serializable
        } else {
            if (!checkItemsOccurences(newItems)) {
                document.getElementById('saveMovementButton').removeAttribute('disabled')
                return
            }
        }
        if (fail) {
            newMovement.observation = newMovement.observation !== undefined ? 'Artículo defectuoso - ' + newMovement.observation : 'Artículo defectuoso'
        }
        newMovement.user_name = getPerson()
        let newItemsArray = []
        if (newMovement.type === 'ingreso') {
            newItems.forEach(ni => {
                newItemsArray.push({
                    quantity: ni.quantity,
                    item: {
                        input_product: ni.input_product,
                        supplier: ni.supplier,
                        serial_number: ni.serial_number,
                        warranty_date: ni.warranty_date || null
                    }
                })
            })
            newMovement.movementdetail_set = newItemsArray
            MovementService.saveNewMovementEntry(newMovement)
                .then(data => {
                    if (data !== undefined) {
                        let itemsResponses = []
                        let movementDetailResponse = []
                        data.movementdetail_set.forEach(md => {
                            itemsResponses.push(md.item)
                            movementDetailResponse.push({
                                quantity: md.quantity,
                                item_input_product_name: md.item.input_product_name,
                                item_input_product_serial_number: md.item.serial_number,
                                item_supplier_name: md.item.supplier_name,
                                item_input_product_manufacturer_name: md.item.input_product_manufacturer_name
                            })
                        })
                        data.movementdetail_set = movementDetailResponse
                        setMovements([data, ...movements])
                        setItems([...items, ...itemsResponses])
                        setNewItems([{}]);
                        setNewMovement({});
                        setFail(false)
                        document.getElementById('newMovementForm').reset()
                        document.getElementById('newMovementSection').classList.add('d-none')
                        toast.success('Movimiento creado correctamente', {
                            position: 'bottom-left',
                            hideProgressBar: true,
                            className: 'success-toast'
                        })
                    }
                })
        } else {
            newItems.map((ni, i) => {
                ni.quantity = '-' + ni.quantity;
            })
            newMovement.movementdetail_set = newItems
            MovementService.saveNewMovement(newMovement)
                .then(data => {
                    setNewItems([{}]);
                    setNewMovement({});
                    setFail(false)
                    setMovements([data, ...movements])
                    document.getElementById('newMovementForm').reset()
                    document.getElementById('newMovementSection').classList.add('d-none')
                    toast.success('Movimiento creado correctamente', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'success-toast'
                    })
                })
        }
        toggleDisabled('saveMovementButton')
        setLoadingMovement(false)
    }

    return (
        <div id="newMovementSection" className="d-flex justify-content-center flex-column m-5 mt-3 p-5 shadow rounded d-none">

            <div className="mx-auto mt-2 col-12">
                <form id="newMovementForm">
                    <div className="form-group mb-3">
                        <label htmlFor="type" className="mb-1">Tipo</label>
                        <select name="type" className="form-select" ref={typeRef}
                            onChange={(e) => {
                                handleMovementChange(e)
                                setNewItems([{}])
                                setChoiceArticle(false)
                                inputProductRef.current.value = "no_choice";
                            }}>
                            <option value="ingreso">Ingreso</option>
                            <option value="egreso">Egreso</option>
                        </select>
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="input_product" className="mb-1">Artículo</label>
                        <select id="inputProductInput" name="input_product" className="form-select" ref={inputProductRef}
                            onChange={(e) => {
                                handleItemChange(e)
                                validateArticle()
                                setNewItems([{}])
                                let [currentInputProduct] = inputProducts.filter(ip => ip.id === parseInt(inputProductRef.current.value))
                                if (currentInputProduct !== undefined && currentInputProduct.is_serializable) {
                                    setIs_serializable(true)
                                } else {
                                    setIs_serializable(false)
                                }
                                let inputs = document.getElementsByClassName('stockInput')
                                for (let i = 0; i < inputs.length; i++) {
                                    inputs[i].value = '-'
                                }
                            }}>
                            <option value="no_choice">Seleccione</option>
                            {inputProductsStock?.map((elem, idx) => {
                                if (typeRef.current.value === 'ingreso') {
                                    return <option key={idx} value={elem.id}>{elem.name}</option>
                                } else {
                                    if (elem.stock >= 1) {
                                        return <option key={idx} value={elem.id}>{elem.name}</option>
                                    }
                                }
                            })}
                        </select>
                        <div id="ipError" className="d-none mb-3">
                            <small className="text-danger">
                                * El artículo es requerido.
                            </small>
                        </div>
                    </div>

                    {(typeRef.current.value === 'egreso' && inputProductRef.current.value !== 'no_choice') &&
                        <div className="form-group d-flex align-items-center mb-3 pb-0">
                            <label>Art. defectuoso</label>
                            <input type="checkbox" className="form-check ms-2 mt-2"
                                onChange={e => {
                                    if (e.target.checked) {
                                        setFail(true)
                                    } else {
                                        setFail(false)
                                    }
                                }} />
                        </div>
                    }

                    {newItems.map((ni, idx) => {
                        return (
                            <>
                                {choiceArticle ?
                                    <div key={idx} className={
                                        typeRef.current.value === 'ingreso' ?
                                            "d-flex flex-wrap justify-content-around p-3 border border-muted rounded mb-3" :
                                            "d-flex flex-wrap justify-content-around p-3 border border-muted rounded mb-3 mx-auto"
                                    }>
                                        {typeRef.current.value === 'ingreso' ?
                                            <>
                                                <div className="form-group col-12 col-lg-2">
                                                    <label htmlFor="supplier" className="mb-1">Proveedor</label>
                                                    <select id={"supplierInput" + idx} name="supplier" className="form-select" onChange={(e) => {
                                                        handleItemChange(e, idx)
                                                        validateFields()
                                                    }}>
                                                        <option value="">Seleccione</option>
                                                        {suppliers.map((elem, idx) => {
                                                            return <option key={idx} value={elem.id}>{elem.name}</option>
                                                        })}
                                                    </select>
                                                    <small id={"supplierMessage" + idx} className="text-danger d-none">
                                                        * Proveedor requerido.
                                                    </small>
                                                </div>

                                                <div className="form-group col-12 col-lg-2 my-2 my-lg-0">
                                                    <label htmlFor="serial_number" className="mb-1">{is_serializable ? "Nº de serie" : "N° de lote"}</label>
                                                    {is_serializable ?
                                                        <input
                                                            id={"serial_numberInput" + idx}
                                                            type="text"
                                                            name="serial_number"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                handleItemChange(e, idx)
                                                                validateFields()
                                                            }}
                                                        /> :
                                                        <input
                                                            id={"serial_numberInput" + idx}
                                                            type="text"
                                                            name="serial_number"
                                                            className="form-control"
                                                            placeholder="Autogenerado"
                                                            disabled
                                                        />
                                                    }
                                                    <small id={"serial_numberInputMessage" + idx} className="text-danger d-none d-block">
                                                        * N° de serie / lote requerido.
                                                    </small>
                                                    <small id={"serial_numberInputLengthMessage" + idx} className="text-danger d-none">
                                                        * El valor es demasiado largo.
                                                    </small>
                                                    <small id={"serial_numberInputCharMessage" + idx} className="text-danger d-none">
                                                        * El valor ingresado no es válido.
                                                    </small>
                                                </div>

                                                <div className="form-group col-12 col-lg-3 mb-2 mb-lg-0">
                                                    <label htmlFor="warranty_date" className="mb-1">Garantía</label>
                                                    <input id={"warranty_dateInput" + idx} type="date" name="warranty_date" className="form-control" onChange={(e) => handleItemChange(e, idx)} />
                                                </div>
                                            </> :
                                            <>
                                                <div className="form-group col-12 col-lg-3">
                                                    <label htmlFor="item" className="mb-1">{is_serializable ? "Nº de serie" : "N° de lote"}</label>
                                                    <select id={"itemInputMov" + idx} name="item" className="form-select" onChange={(e) => {
                                                        handleItemChange(e, idx)
                                                        validateFields()
                                                    }}>
                                                        <option value="">Seleccione</option>
                                                        {items.sort((a, b) => parseInt(a.serial_number) - parseInt(b.serial_number)).map(item => {
                                                            return inputProductsStock.map(ips => {
                                                                if (
                                                                    item.input_product === ips.id &&
                                                                    item.input_product === parseInt(inputProductRef.current.value) &&
                                                                    checkItemsAdded(item.id, idx) &&
                                                                    item.stock > 0
                                                                ) {
                                                                    return <option key={item.id} value={item.id}>{item.serial_number}</option>
                                                                }
                                                            })
                                                        })}
                                                    </select>
                                                    <small id={"serial_numberInputMessage" + idx} className="text-danger d-block d-none">
                                                        * N° de serie / lote requerido.
                                                    </small>
                                                </div>

                                                {!is_serializable &&
                                                    <div className="border border-muted rounded form-group col-12 col-lg-2 d-flex flex-column text-center justify-content-between mt-2 mt-lg-0 mx-lg-2">
                                                        <label>Stock lote</label>
                                                        <span className="p-2">
                                                            <input id={'loteStockInput' + idx} defaultValue='-' type="text" className="form-control text-center stockInput" disabled />
                                                        </span>
                                                    </div>
                                                }

                                                <div className="border border-muted rounded form-group col-12 col-lg-2 d-flex flex-column text-center justify-content-between mt-2 mt-lg-0 me-lg-2">
                                                    <label>Stock total</label>
                                                    <span className="p-2">
                                                        {totalStock || '-'}
                                                    </span>
                                                </div>
                                            </>
                                        }

                                        <div className={typeRef.current.value === 'ingreso' ? "form-group col-12 col-lg-2" : "form-group col-12 col-lg-2"}>
                                            <label htmlFor="quantity" className="mb-1">Cantidad</label>
                                            {is_serializable ?
                                                <input id={"quantityInput" + idx} type="text" name="quantity" value={1} className="form-control"
                                                    placeholder="1" disabled /> :
                                                <input id={"quantityInput" + idx} type="number" min="1" step={1} name="quantity" className="form-control"
                                                    onChange={(e) => {
                                                        handleItemChange(e, idx)
                                                        validateQuantity()
                                                    }}
                                                />
                                            }
                                            <small id={"totalRequiredMessage" + idx} className="text-danger d-block d-none">
                                                * Cantidad requerida.
                                            </small>
                                            <small id={"totalMaxError" + idx} className="text-danger d-block d-none">
                                                * Cantidad máxima: 1000.
                                            </small>
                                        </div>

                                        {idx > 0 &&
                                            <button type="button" className="btn btn-danger btn-sm col-1 h-50 w-auto my-auto" onClick={() => {
                                                setNewItems(newItems.filter((ni, i) => {
                                                    return i !== idx;
                                                }))
                                            }}>
                                                Borrar
                                            </button>
                                        }
                                    </div> :
                                    <div key={idx} className="d-flex flex-wrap justify-content-around p-3 border border-muted rounded mb-3">
                                        <strong>Seleccione un artículo</strong>
                                    </div>
                                }
                            </>
                        )
                    })}

                    <div id="totalStockError" className="text-center my-3 d-none">
                        <small className="text-danger d-block">
                            * La cantidad debe ser menor o igual al stock del lote.
                        </small>
                    </div>

                    {choiceArticle && !disableButton &&
                        <div className={
                            typeRef.current.value === 'ingreso' ?
                                "mb-3" :
                                "mb-3 col-6 mx-auto"
                        }>
                            <button type="button" className="btn btn-info btn-sm text-white" onClick={() => {
                                setNewItems([...newItems, {}])
                            }}>
                                Nuevo
                            </button>
                            <button type="button" className="btn btn-info btn-sm text-white ms-2" onClick={() => {
                                swal({
                                    title: '¿Reiniciar movimiento?',
                                    icon: 'warning',
                                    dangerMode: true,
                                    buttons: ['Cancelar', 'Reiniciar']
                                }).then(reset => {
                                    if (reset) {
                                        setNewItems([{}])
                                        setNewMovement({})
                                    }
                                })
                            }}>
                                Reiniciar
                            </button>
                        </div>
                    }

                    <div className="form-group mb-3">
                        <label htmlFor="observation" className="mb-1">Observaciones</label>
                        <textarea name="observation" className="form-control textarea" onChange={(e) => handleMovementChange(e)}></textarea>
                        {val.current.message('observationMov', newMovement.observation, 'max:500', { className: 'text-danger' })}
                    </div>

                    <button
                        id="saveMovementButton"
                        type="button"
                        className="btn btn-info btn-sm text-white d-block mx-auto col-3"
                        onClick={e => {
                            if (newMovement.type === undefined) {
                                newMovement.type = 'ingreso'
                            }
                            if (
                                val.current.fieldValid('observationMov') &&
                                validateArticle() &&
                                validateFields() &&
                                validateQuantity()
                            ) {
                                toggleDisabled('saveMovementButton')
                                saveNewMovement(e, newItems, newMovement)
                            } else {
                                val.current.showMessageFor('observationMov')
                                forceUpdate(1)
                            }
                        }}>
                        GUARDAR
                    </button>

                </form>
            </div>

        </div>
    )

}