import { FiCopy } from "react-icons/fi";

import { hideModal } from "../../../Utilities/General/BootstrapApi";
import { copy } from "../../../Utilities/General/Clipboard";

export default function ShowNewPassword({
    newPass
}) {
    return (
        <div className="modal fade" id="newPassModal">
            <div className="modal-dialog shadow">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info">
                            Nueva contraseña generada
                        </h3>
                        <button
                            type="button"
                            className="btn-close" aria-label="Close"
                            onClick={() => hideModal('newPassModal')}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <h2 className="text-center">
                                {newPass}
                            </h2>
                            <FiCopy
                                id="newPass"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('newPass', newPass)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => hideModal('newPassModal')}
                        >
                            CERRAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}