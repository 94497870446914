import React from 'react';

import Loader from '../../../AppComponents/Commons/Loader';
import CreateEmailContact from './CreateEmailContact';
import CreatePhoneContact from './CreatePhoneContact';
import CreateClientShippingData from './CreateClientShippingData';
import CreateClientBillingData from './CreateClientBillingData';

import { isAdmin, isSellerEditor } from '../../../Utilities/Auth/AuthPermissions';
import ClientService from '../../../Services/Licenses/ClientService';
import { toggleDisabled } from '../../../Utilities/General/HandleClick'

import Delete from '../../../Assets/Delete'

export default function ViewClient({
    client,
    typeIdClient,
    countries,
    typePhone,
    phoneProviders,
    val,
    forceUpdate,
    emailContacts,
    setEmailContacts,
    newEmailContact,
    setNewEmailContact,
    phoneContacts,
    setPhoneContacts,
    newPhoneContact,
    setNewPhoneContact,
    clientShippingData,
    setClientShippingData,
    newClientShippingData,
    setNewClientShippingData,
    clientBillingData,
    setClientBillingData,
    newClientBillingData,
    setNewClientBillingData
}) {

    const handleSubmitPhone = () => {
        if (!val.fieldValid('country_code') || !val.fieldValid('area_code') ||
            !val.fieldValid('number') || !val.fieldValid('phoneNote')) {
            val.showMessages()
            forceUpdate(1)
            return
        }
        toggleDisabled('submit_btn_phone_contact')
        ClientService.saveNewPhoneContact(newPhoneContact)
            .then(data => {
                setPhoneContacts([...phoneContacts, data])
                document.getElementById('newPhoneDiv').classList.add('d-none')
                document.getElementById('newPhoneButton').classList.remove('d-none')
                document.getElementById('newPhoneForm').reset()
                toggleDisabled('submit_btn_phone_contact')
                setNewPhoneContact({})
                val.hideMessages()
            })
    }

    const handleSubmitEmail = () => {
        if (!val.fieldValid('email') || !val.fieldValid('emailNote')) {
            val.showMessages()
            forceUpdate(1)
            return
        }
        toggleDisabled('submit_button_email')
        ClientService.saveNewEmailContact(newEmailContact)
            .then(data => {
                setEmailContacts([...emailContacts, data])
                document.getElementById('newEmailDiv').classList.add('d-none')
                document.getElementById('newEmailButton').classList.remove('d-none')
                document.getElementById('newEmailForm').reset()
                toggleDisabled('submit_button_email')
                setNewEmailContact({})
                val.hideMessages()
            })
    }

    const handleSubmitCBD = () => {
        newClientBillingData.client = client.id
        if (!val.fieldValid('carnet_idView') || !val.fieldValid('razon_socialView') ||
            !val.fieldValid('vat_numberView') || !val.fieldValid('ivaView') ||
            !val.fieldValid('typeView') || !val.fieldValid('personView')) {
            val.showMessages()
            forceUpdate(1)
            return
        }
        ClientService.saveNewClientBillingData(newClientBillingData)
            .then(data => {
                setClientBillingData([...clientBillingData, data])
                document.getElementById('newCBDDiv').classList.add('d-none')
                document.getElementById('newCBDButton').classList.remove('d-none')
                document.getElementById('newCBDForm').reset()
                setNewClientBillingData({})
                val.hideMessages()
            })
    }

    const handleSubmitCSD = () => {
        newClientShippingData.client = client.id
        newClientShippingData.name_recipient = client.name
        newClientShippingData.id_recipient = client.id_client
        newClientShippingData.address = newClientShippingData.address_address + ' ' + newClientShippingData.address_number
        if (!val.fieldValid('address_province') || !val.fieldValid('address_city') ||
            !val.fieldValid('address_postal_code') || !val.fieldValid('address_address') ||
            !val.fieldValid('address_number') || !val.fieldValid('address_floor_apartment')) {
            val.showMessages()
            forceUpdate(1)
            return
        }
        toggleDisabled('submit_button_csd')
        ClientService.saveNewClientShippingData(newClientShippingData)
            .then(data => {
                setClientShippingData([...clientShippingData, data])
                document.getElementById('newCSDDiv').classList.add('d-none')
                document.getElementById('newCSDButton').classList.remove('d-none')
                document.getElementById('newCSDForm').reset()
                toggleDisabled('submit_button_csd')
                setNewClientShippingData({})
                val.hideMessages()
            })
    }

    const handleDeletePhone = id => {
        ClientService.deletePhoneContact(id)
            .then(() => {
                setPhoneContacts(phoneContacts.filter(pc => pc.id !== id))
            })
    }

    const handleDeleteEmail = id => {
        ClientService.deleteEmailContact(id)
            .then(() => {
                setEmailContacts(emailContacts.filter(ec => ec.id !== id))
            })
    }

    const handleDeleteCBD = id => {
        ClientService.deleteClientBillingData(id)
            .then(() => {
                setClientBillingData(clientBillingData.filter(cbd => cbd.id !== id))
            })
    }

    const handleDeleteCSD = id => {
        ClientService.deleteClientShippingData(id)
            .then(() => {
                setClientShippingData(clientShippingData.filter(csd => csd.id !== id))
            })
    }

    return (

        <div>

            {(client === undefined || client === null) ?
                <Loader /> :
                <>
                    <h2 className="text-info text-center">
                        {client.name}
                    </h2>

                    <div className='my-4'>
                        <h4 className="d-block mb-3">Datos personales</h4>
                        <hr />
                        <table className='table table-hover'>
                            <tbody>
                                <tr>
                                    <th>Tipo de documento</th>
                                    <td>{typeIdClient?.map(tyc => {
                                        if (tyc.id === client.type_id) {
                                            return tyc.name
                                        }
                                    })}</td>
                                </tr>
                                <tr>
                                    <th>Número de documento</th>
                                    <td>{client.id_client}</td>
                                </tr>
                                <tr>
                                    <th>Nombre y apellido</th>
                                    <td>{client.name}</td>
                                </tr>
                                <tr>
                                    <th>País</th>
                                    <td>{countries.map(country => {
                                        if (country.iso_code === client.country) {
                                            return country.name
                                        }
                                    })}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4 className="d-block mb-3">Datos de contacto</h4>
                        <hr />
                        <div className='d-flex mt-4'>
                            <h5 className="text-info">Teléfonos</h5>
                            {(isSellerEditor() || isAdmin()) &&
                                <button
                                    id="newPhoneButton"
                                    type="button"
                                    className="btn btn-sm btn-info text-white ms-2"
                                    onClick={() => {
                                        document.getElementById('newPhoneDiv').classList.remove('d-none')
                                        document.getElementById('newPhoneButton').classList.add('d-none')
                                    }}
                                >
                                    Agregar
                                </button>
                            }
                        </div>
                        <div className='md-overflow-scroll'>
                            <table className='table table-hover text-center'>
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Proveedor</th>
                                        <th>Línea</th>
                                        <th>Número</th>
                                        <th>Observaciones</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {phoneContacts?.map(pc => {
                                        if (pc.client === client.id) {
                                            return (
                                                <tr key={pc.id}>
                                                    <td>
                                                        {typePhone.map(tp => {
                                                            if (tp.id === pc.type_phone) {
                                                                return tp.name
                                                            }
                                                        })}
                                                    </td>
                                                    <td>
                                                        {phoneProviders.map(pp => {
                                                            if (pp.id === pc.phone_provider) {
                                                                return pp.name
                                                            }
                                                        })}
                                                    </td>
                                                    <td>
                                                        {pc.line}
                                                    </td>
                                                    <td>
                                                        {`+${pc.country_code} - ${pc.area_code} - ${pc.number}`}
                                                    </td>
                                                    <td>
                                                        {pc.note}
                                                    </td>
                                                    <td className='only-cursor-pointer text-center'
                                                        onClick={() => handleDeletePhone(pc.id)}>
                                                        <Delete />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div id="newPhoneDiv" className="d-none">
                            <CreatePhoneContact
                                client={client.id}
                                typePhone={typePhone}
                                phoneProviders={phoneProviders}
                                newPhoneContact={newPhoneContact}
                                setNewPhoneContact={setNewPhoneContact}
                                val={val}
                                handleSubmit={handleSubmitPhone}
                                forceUpdate={forceUpdate}
                            />
                        </div>
                        <div className='d-flex mt-5'>
                            <h5 className="text-info">Emails</h5>
                            {(isSellerEditor() || isAdmin()) &&
                                <button
                                    id="newEmailButton"
                                    type="button"
                                    className="btn btn-sm btn-info text-white ms-2"
                                    onClick={() => {
                                        document.getElementById('newEmailDiv').classList.remove('d-none')
                                        document.getElementById('newEmailButton').classList.add('d-none')
                                    }}
                                >
                                    Agregar
                                </button>
                            }
                        </div>
                        <div className='md-overflow-scroll'>
                            <table className='table table-hover text-center'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Observaciones</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailContacts?.map((ec, ecIndex) => {
                                        if (ec.client === client.id) {
                                            return (
                                                <tr key={ecIndex}>
                                                    <td>{ec.email}</td>
                                                    <td>{ec.note}</td>
                                                    {(isSellerEditor() || isAdmin()) &&
                                                        <td className='only-cursor-pointer text-center'
                                                            onClick={() => handleDeleteEmail(ec.id)}>
                                                            <Delete />
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div id="newEmailDiv" className="d-none">
                            <CreateEmailContact
                                client={client.id}
                                newEmailContact={newEmailContact}
                                setNewEmailContact={setNewEmailContact}
                                val={val}
                                handleSubmit={handleSubmitEmail}
                                forceUpdate={forceUpdate}
                            />
                        </div>
                        <div className='d-flex mt-5'>
                            <h5 className="text-info">Datos de facturación</h5>
                            {/* {(isSellerEditor() || isAdmin()) &&
                                <button
                                    id="newCBDButton"
                                    type="button"
                                    className="btn btn-sm btn-info text-white ms-2"
                                    onClick={() => {
                                        document.getElementById('newCBDDiv').classList.remove('d-none')
                                        document.getElementById('newCBDButton').classList.add('d-none')
                                    }}
                                >
                                    Agregar
                                </button>
                            } */}
                        </div>
                        <div className='md-overflow-scroll'>
                            <table className='table table-hover text-center'>
                                <thead>
                                    <tr>
                                        <th>Matrícula</th>
                                        <th>Razón Social</th>
                                        <th>CUIT/CUIL/RUT</th>
                                        <th>Condición Fiscal</th>
                                        <th>Tipo de cliente</th>
                                        <th>Persona</th>
                                        {/* <th>Acción</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientBillingData?.map((cbd, cbdIndex) => {
                                        if (cbd.client === client.id) {
                                            return (
                                                <tr key={cbdIndex}>
                                                    <td>
                                                        {cbd.carnet_id}
                                                    </td>
                                                    <td>
                                                        {cbd.name}
                                                    </td>
                                                    <td>
                                                        {cbd.vat_number}
                                                    </td>
                                                    <td>
                                                        {cbd.iva}
                                                    </td>
                                                    <td>
                                                        {cbd.type}
                                                    </td>
                                                    <td>
                                                        {cbd.person}
                                                    </td>
                                                    {/* {(isSellerEditor() || isAdmin()) &&
                                                        <td className='only-cursor-pointer text-center'
                                                            onClick={() => handleDeleteCBD(cbd.id)}>
                                                            <Delete />
                                                        </td>
                                                    } */}
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div id="newCBDDiv" className="d-none">
                            <CreateClientBillingData
                                countries={countries}
                                val={val}
                                forceUpdate={forceUpdate}
                                newClientBillingData={newClientBillingData}
                                setNewClientBillingData={setNewClientBillingData}
                                handleSubmit={handleSubmitCBD}
                            />
                        </div>
                        <div className='d-flex mt-5'>
                            <h5 className="text-info">Datos de envío</h5>
                            {(isSellerEditor() || isAdmin()) &&
                                <button
                                    id="newCSDButton"
                                    type="button"
                                    className="btn btn-sm btn-info text-white ms-2"
                                    onClick={() => {
                                        document.getElementById('newCSDDiv').classList.remove('d-none')
                                        document.getElementById('newCSDButton').classList.add('d-none')
                                    }}
                                >
                                    Agregar
                                </button>
                            }
                        </div>
                        <div className='md-overflow-scroll'>
                            <table className='table table-hover text-center'>
                                <thead>
                                    <tr>
                                        <th>Dirección</th>
                                        <th>Piso.</th>
                                        <th>Dpto.</th>
                                        <th>Ciudad</th>
                                        <th>Provincia</th>
                                        <th>CP</th>
                                        <th>País</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientShippingData?.map(csd => {
                                        if (csd.client === client.id) {
                                            return (
                                                <tr key={csd.id}>
                                                    <td>{csd.address}</td>
                                                    <td>{csd.address_floor_apartment}</td>
                                                    <td>{csd.address_apartment}</td>
                                                    <td>{csd.address_city}</td>
                                                    <td>{csd.address_province}</td>
                                                    <td>{csd.address_postal_code}</td>
                                                    <td>
                                                        {countries.map(country => {
                                                            if (country.id === csd.address_country) {
                                                                return country.name
                                                            }
                                                        })}
                                                    </td>
                                                    {(isSellerEditor() || isAdmin()) &&
                                                        <td className='only-cursor-pointer text-center'
                                                            onClick={() => handleDeleteCSD(csd.id)}>
                                                            <Delete />
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div id="newCSDDiv" className="d-none">
                            <CreateClientShippingData
                                countries={countries}
                                val={val}
                                forceUpdate={forceUpdate}
                                newClientShippingData={newClientShippingData}
                                setNewClientShippingData={setNewClientShippingData}
                                handleSubmit={handleSubmitCSD}
                            />
                        </div>
                    </div>
                </>
            }

        </div>

    );
}