import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import swal from "sweetalert";
import { format } from "date-fns";
import { toast } from "react-toastify";

import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
import FirstTitle from "../../AppComponents/Commons/FirstTitle";
import ButtonBack from '../../AppComponents/Commons/ButtonBack';
import Loader from "../../AppComponents/Commons/Loader";
import TableCalibrationsService from "../../AppComponents/Calibrations/TableCalibrationsService";
import CreateCalibration from "./Components/CreateCalibration";
import HandleSuffixes from "./Components/HandleSuffixes";
import TableEmissionData from './Components/TableEmissionData';
import EmissionDataChanges from "./Components/EmissionDataChanges";
import DivideValues from "./Components/DivideValues";
import GroupsFilter from "./Components/GroupsFilter";
import GroupsSearch from "./Components/GroupsSearch";

import { showTooltip, hideTooltip, showModal, hideModal } from "../../Utilities/General/BootstrapApi";
import CalibrationService from "../../Services/Calibrations/CalibrationService";
import CalBuildService from '../../Services/Calibrations/CalBuildService'
import CalGroupService from "../../Services/Calibrations/CalGroupService";
import { isAuth } from "../../Utilities/Auth/AuthPermissions";
import { toggleDisabled } from '../../Utilities/General/HandleClick'

import Delete from '../../Assets/Delete';
import Slice from '../../Assets/Slice';

export default function ViewCalibrations() {

    const params = useParams();

    const [products, setProducts] = useState([]);
    const [suffixes, setsuffixes] = useState([]);
    const [calBuild, setCalBuild] = useState({});
    const [calibrations, setCalibrations] = useState([]);
    const [allCalGroups, setAllCalGroups] = useState([]);
    const [backupCalGroups, setBackupCalGroups] = useState([]);
    const [calGroups, setCalGroups] = useState([]);

    const [emissionData, setEmissionData] = useState([]);
    const [emissionDataUpdated, setEmissionDataUpdated] = useState([]);
    const [noiseParameters, setNoiseParameters] = useState([]);
    const [changes, setChanges] = useState([]);

    const [noiseParametersUpdated, setNoiseParametersUpdated] = useState({});
    const [newCalibration, setNewCalibration] = useState({});

    const [displayValue, setDisplayValue] = useState(0)
    const [workOn, setWorkOn] = useState(null);
    const [emissionPureTone, setEmissionPureTone] = useState(null);
    const [newCalAdded, setNewCalAdded] = useState(null);
    const [loading, setLoading] = useState(null);
    const [loadingGroups, setLoadingGroups] = useState(null);
    const [loadingGroupValues, setLoadingGroupValues] = useState(null);
    const [loadingNoiseParameters, setLoadingNoiseParameters] = useState(null);
    const [filteredRows, setFilteredRows] = useState(false);
    const [changesFlagNoiseParams, setChangesFlagNoiseParams] = useState(false);
    const [changesFlagEmissionData, setChangesFlagEmissionData] = useState(false);

    const serialNumberRef = useRef();
    const smartphoneRef = useRef();
    const earphoneRef = useRef();
    const boneHeadRef = useRef();
    const wireRef = useRef();
    const suffixRef = useRef();

    const typeRef = useRef();
    const calibration_typeRef = useRef();
    const connection_typeRef = useRef();

    const micConstantRef = useRef();
    const noiseConstantRef = useRef();
    const noiseFloorRef = useRef();
    const dbhlFloorRef = useRef();
    const dbhlRoofRef = useRef();

    useEffect(() => {
        if (isAuth()) {
            CalibrationService.getCalProducts().then(data => setProducts(data))
            CalibrationService.getCalSuffix().then(data => setsuffixes(data))
            CalBuildService.getCalBuild(params.id).then(data => setCalBuild(data))
        }
    }, [])

    useEffect(() => {
        if (isAuth()) {
            CalibrationService.getCalibrations(params.id).then(data => setCalibrations(data))
        }
    }, [calBuild])

    useEffect(() => {
        if (isAuth()) {
            CalGroupService.getCalGroups(params.id).then(data => setAllCalGroups(data))
        }
    }, [calBuild]);

    const getCalSuffix = cal => {
        let elements = cal.kitname.split('_');
        let i = 0;
        let counter = 0;
        let spliceIndex;
        while (i < cal.kitname.length && counter < 4) {
            if (cal.kitname[i] === '_') {
                counter = counter + 1;
            }
            if (counter === 4) {
                spliceIndex = i + 2;
            }
            i++
        }
        if (elements.length > 4) {
            var suffix = cal.kitname.slice(spliceIndex - 1)
        } else {
            suffix = false;
        }
        return suffix;
    }

    const getGroups = () => {
        if (filteredRows) {
            typeRef.current.value = 'all';
            calibration_typeRef.current.value = 'all';
            connection_typeRef.current.value = 'all';
            if (smartphoneRef.current.value === 'all' &&
                earphoneRef.current.value === 'all' &&
                boneHeadRef.current.value === 'all' &&
                wireRef.current.value === 'all' &&
                suffixRef.current.value === 'all') {
                setCalGroups(backupCalGroups);
            }
        }
        setLoadingGroups(true);
        var smartphoneString = smartphoneRef.current.value === 'all' ? '' : smartphoneRef.current.value;
        var serialNumberString = serialNumberRef.current.value === 'all' ? '' : serialNumberRef.current.value;
        var earphoneString = earphoneRef.current.value === 'all' ? '' : earphoneRef.current.value;
        var boneheadString = boneHeadRef.current.value === 'all' ? '' : boneHeadRef.current.value;
        var wireString = wireRef.current.value === 'all' ? '' : wireRef.current.value;
        var suffixString = suffixRef.current.value === 'all' ? '' : suffixRef.current.value;
        var filteredCalibrations = calibrations.filter(cal => {
            return cal.kitname.includes(smartphoneString) && cal.kitname.includes(earphoneString) &&
                cal.kitname.includes(boneheadString) && cal.kitname.includes(wireString) &&
                cal.kitname.includes(suffixString) &&
                (serialNumberString === '' ? !cal.kitname.includes('NBK_00') : cal.kitname.includes(serialNumberString));
        });
        var filteredGroups = [];
        var i = 0;
        while (i < allCalGroups.length) {
            var j = 0
            while (j < filteredCalibrations.length) {
                if (allCalGroups[i].calibration === filteredCalibrations[j].id) {
                    allCalGroups[i].kitname = filteredCalibrations[j].kitname;
                    filteredGroups.push(allCalGroups[i]);
                }
                j++
            }
            i++
        }
        setFilteredRows(true);
        setBackupCalGroups(filteredGroups);
        setCalGroups(filteredGroups);
        setLoadingGroups(false);
    }

    const getValues = (group, filterData = null) => {
        setLoadingGroupValues(true);
        if (group.type === 'pure_tone') {
            setEmissionPureTone(true);
            CalGroupService.getCalPureTone(group.id)
                .then(data => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].frequency = parseFloat(data[i].frequency)
                    }
                    if (filterData === null) {
                        setEmissionData(data.sort((a, b) => b.dbhl_max_gain - a.dbhl_max_gain))
                    } else {
                        setEmissionData([
                            ...data.filter(i => !filterData.some(fd => fd.id === i.id)),
                            ...filterData
                        ].sort((a, b) => b.dbhl_max_gain - a.dbhl_max_gain))
                    }
                    setLoadingGroupValues(false);
                })
        } else {
            setEmissionPureTone(false);
            CalGroupService.getCalLogo(group.id)
                .then(data => {
                    if (filterData === null) {
                        setEmissionData(data.sort((a, b) => b.id - a.id))
                    } else {
                        setEmissionData([
                            ...data.filter(i => !filterData.some(fd => fd.id === i.id)),
                            ...filterData
                        ].sort((a, b) => b.id - a.id))
                    }
                    setLoadingGroupValues(false);
                })
        }
        setLoadingNoiseParameters(true);
        CalGroupService.getNoiseParameters(group.calibration)
            .then(data => {
                setNoiseParameters([data]);
                setLoadingNoiseParameters(false);
            })
    }

    const preventWheel = (name, idx, noise) => {
        if (noise) {
            var field = document.getElementById(name + 'Input');
        } else {
            field = document.getElementById(name + idx);
        }
        field.blur()
    }

    useEffect(() => {
        if (calGroups.length === 0) {
            setEmissionData([]);
            setNoiseParameters([]);
        }
    }, [calGroups])

    const filterGroups = () => {
        if (typeRef.current.value === 'all' && calibration_typeRef.current.value === 'all' && connection_typeRef.current.value === 'all') {
            setCalGroups(backupCalGroups)
        } else if (typeRef.current.value === 'all' && calibration_typeRef.current.value === 'all' && connection_typeRef.current.value !== 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.connection_type === connection_typeRef.current.value;
            }))
        } else if (typeRef.current.value === 'all' && calibration_typeRef.current.value !== 'all' && connection_typeRef.current.value === 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.calibration_type === calibration_typeRef.current.value;
            }))
        } else if (typeRef.current.value === 'all' && calibration_typeRef.current.value !== 'all' && connection_typeRef.current.value !== 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.calibration_type === calibration_typeRef.current.value && item.connection_type === connection_typeRef.current.value;
            }))
        } else if (typeRef.current.value !== 'all' && calibration_typeRef.current.value === 'all' && connection_typeRef.current.value === 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.type === typeRef.current.value;
            }))
        } else if (typeRef.current.value !== 'all' && calibration_typeRef.current.value === 'all' && connection_typeRef.current.value !== 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.type === typeRef.current.value && item.connection_type === connection_typeRef.current.value;
            }))
        } else if (typeRef.current.value !== 'all' && calibration_typeRef.current.value !== 'all' && connection_typeRef.current.value === 'all') {
            setCalGroups(backupCalGroups.filter(item => {
                return item.type === typeRef.current.value && item.calibration_type === calibration_typeRef.current.value;
            }))
        } else {
            setCalGroups(backupCalGroups.filter(item => {
                return item.type === typeRef.current.value && item.calibration_type === calibration_typeRef.current.value && item.connection_type === connection_typeRef.current.value;
            }))
        }
    }

    const resetFilters = () => {
        typeRef.current.value = 'all';
        calibration_typeRef.current.value = 'all';
        connection_typeRef.current.value = 'all';
        setEmissionData([]);
        setNoiseParameters([]);
        filterGroups();
    }

    const saveNewCalibration = newCalibration => {
        toggleDisabled('submit_new_calibration')
        CalibrationService.saveNewCalibration(newCalibration)
            .then(data => {
                setCalibrations([
                    ...calibrations,
                    data
                ])
                getNewCalGroups()
                toggleDisabled('submit_new_calibration')
                toast.success('Calibración creada correctamente', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
                setLoadingGroups('newCal')
                document.getElementById('newCalibrationForm').reset();
                hideModal('calModal');
            })
    }

    const getNewCalGroups = () => {
        typeRef.current.value = 'all';
        calibration_typeRef.current.value = 'all';
        connection_typeRef.current.value = 'all';
        CalGroupService.getCalGroups(params.id)
            .then(data => {
                setAllCalGroups(data)
                setNewCalAdded('success')
            })
    }

    useEffect(() => {
        if (newCalAdded === 'success') {
            setNewCalAdded(null);
            // este timer esperaba que  se guarde la nueva calibracion, se pidan los nuevos groups
            // y se llame a get groups para que se muestren
            //setTimeout(() => {
            getGroups();
            setLoadingGroups(false);
            //}, 3000);
        }
    }, [newCalAdded])

    useEffect(() => {
        if (emissionData.length > 0) {
            let group = allCalGroups.find(item => item.id === emissionData[0].calibration_group)
            if (group.type === 'pure_tone') {
                var i = 0;
                while (i < emissionData.length) {
                    if (emissionData[i].dbhl_max_gain === null) {
                        emissionData[i].dbhl_max_gain = Math.abs(emissionData[i].min_gain - emissionData[i].max_gain)
                    }
                    if (emissionData[i].amplifier_gain === null) {
                        emissionData[i].amplifier_gain = 15
                    }
                    if (emissionData[i].dbhl_min_gain === null) {
                        emissionData[i].dbhl_min_gain = -10
                    }
                    i++
                }
            }
        }
    }, [emissionData])

    useEffect(() => {
        const group = allCalGroups.find(g => g.id === emissionData[0]?.calibration_group)
        if (group?.type === 'pure_tone') {
            setEmissionDataUpdated([...emissionData.sort((a, b) => b.dbhl_max_gain - a.dbhl_max_gain)])
        }
        if (group?.type === 'logo') {
            setEmissionDataUpdated([...emissionData.sort((a, b) => b.id - a.id)])
        }
    }, [emissionData, allCalGroups])

    const changesDetectedEmissionData = (e, id) => {
        setChangesFlagEmissionData(true);
        setEmissionDataUpdated([
            ...emissionDataUpdated.filter(i => i.id !== parseInt(id)),
            {
                ...emissionDataUpdated.find(i => i.id === parseInt(id)),
                [e.target.name]: e.target.value
            }
        ])
    }


    useEffect(() => {
        const group = allCalGroups.find(g => g.id === emissionDataUpdated[0]?.calibration_group)
        if (group?.type === 'pure_tone') {
            let sorted = [...emissionDataUpdated.sort((a, b) => b.dbhl_max_gain - a.dbhl_max_gain)]
            let j = 0;
            let counter = 0;
            while (j < emissionDataUpdated.length) {
                if (
                    emissionData[j].min_gain.toString() === sorted[j].min_gain &&
                    emissionData[j].max_gain.toString() === sorted[j].max_gain &&
                    emissionData[j].min_gain_new.toString() === sorted[j].min_gain_new &&
                    emissionData[j].max_gain_new.toString() === sorted[j].max_gain_new &&
                    emissionData[j].amplifier_gain.toString() === sorted[j].amplifier_gain &&
                    emissionData[j].dbhl_min_gain.toString() === sorted[j].dbhl_min_gain &&
                    emissionData[j].dbhl_max_gain.toString() === sorted[j].dbhl_max_gain
                ) {
                    counter++
                    if (counter === sorted.length) {
                        setChangesFlagEmissionData(false)
                    }
                }
                j++
            }
        }
        if (group?.type === 'logo') {
            let original = [...emissionData.sort((a, b) => b.id - a.id)]
            let sorted = [...emissionDataUpdated.sort((a, b) => b.id - a.id)]
            let j = 0;
            let counter = 0;
            while (j < emissionDataUpdated.length) {
                if (
                    original[j].dbhl.toString() === sorted[j].dbhl &&
                    original[j].player_volume.toString() === sorted[j].player_volume
                ) {
                    counter++
                    if (counter === sorted.length) {
                        setChangesFlagEmissionData(false)
                    }
                }
                j++
            }
        }
    }, [emissionDataUpdated, allCalGroups])

    const validate = () => {
        let compareData = [];
        let group = allCalGroups.find(item => item.id === emissionData[0].calibration_group)
        let i = 0;
        while (i < emissionData.length) {
            if (group.type === 'pure_tone') {
                let updated = emissionDataUpdated.find(j => j.id === emissionData[i].id)
                if (
                    emissionData[i].min_gain.toString() !== updated.min_gain.toString() ||
                    emissionData[i].max_gain.toString() !== updated.max_gain.toString() ||
                    emissionData[i].min_gain_new.toString() !== updated.min_gain_new.toString() ||
                    emissionData[i].max_gain_new.toString() !== updated.max_gain_new.toString() ||
                    emissionData[i].amplifier_gain.toString() !== updated.amplifier_gain.toString() ||
                    emissionData[i].dbhl_min_gain.toString() !== updated.dbhl_min_gain.toString() ||
                    emissionData[i].dbhl_max_gain.toString() !== updated.dbhl_max_gain.toString()
                ) {
                    compareData.push([emissionData[i], updated])
                    updated.frequency = emissionData[i].frequency
                }
            }
            if (group.type === 'logo') {
                const original = [...emissionData.sort((a, b) => b.id - a.id)]
                const updated = [...emissionDataUpdated.sort((a, b) => b.id - a.id)]
                if (
                    original[i].dbhl.toString() !== updated[i].dbhl.toString() ||
                    original[i].player_volume.toString() !== updated[i].player_volume.toString()
                ) {
                    compareData.push([original[i], updated[i]])
                }
            }
            i++
        }
        let j = 0;
        let flag = 0;
        while (j < compareData.length) {
            if (group.type === 'pure_tone') {
                if (compareData[j][1].min_gain === '' ||
                    compareData[j][1].max_gain === '' ||
                    compareData[j][1].amplifier_gain === '' ||
                    compareData[j][1].dbhl_min_gain === '' ||
                    compareData[j][1].dbhl_max_gain === '') {
                    flag = 1;
                }
                if (parseInt(compareData[j][1].amplifier_gain) < 0 ||
                    parseInt(compareData[j][1].amplifier_gain) > 15) {
                    flag = 2
                }
            } else {
                if (compareData[j][1].dbhl === '' ||
                    compareData[j][1].player_volume === '') {
                    flag = 1;
                }
            }
            j++
        }
        setChanges(compareData)
        if (flag === 1) {
            toast.warning('Verifique que los valores de emisión sean correctos.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })
        } else if (flag === 2) {
            toast.warning('Los valores de amplificación deben estar entre 0 y 15.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })
        } else {
            showModal('emDataChangesModal')
        }
    }

    const saveUpdatedEmissionData = () => {
        setLoadingGroupValues(true);
        let group = allCalGroups.find(item => item.id === emissionData[0].calibration_group)
        for (let j = 0; j < changes.length; j++) {
            changes[j][1].frequency = changes[j][0].frequency
        }
        let promises = []
        let i = 0;
        while (i < changes.length) {
            if (group.type === 'pure_tone') {
                promises.push(CalGroupService.updateCalPureTone(changes[i][0].id, changes[i][1]))
            } else {
                promises.push(CalGroupService.updateCalLogo(changes[i][0].id, changes[i][1]))
            }
            i++
        }
        Promise.all(promises)
            .then(() => {
                if (group.type === 'pure_tone') {
                    CalGroupService.getCalPureTone(group.id)
                        .then(data => {
                            setEmissionData(data.sort((a, b) => b.dbhl_max_gain - a.dbhl_max_gain));
                            setChangesFlagEmissionData(false);
                            setLoadingGroupValues(false);
                            setLoading(false)
                            hideModal('emDataChangesModal')
                            toast.success('Valores de emisión modificados correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                        })
                } else {
                    CalGroupService.getCalLogo(group.id)
                        .then(data => {
                            setEmissionData(data.sort((a, b) => b.id - a.id));
                            setChangesFlagEmissionData(false);
                            setLoadingGroupValues(false);
                            setLoading(false)
                            hideModal('emDataChangesModal')
                            toast.success('Valores de emisión modificados correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                        })
                }
            })
    }

    const resetEmissionData = () => {
        swal({
            title: "¿Descartar cambios?",
            text: 'Se perderán los cambios realizados',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Descartar']
        }).then(confirm => {
            if (confirm) {
                let group = allCalGroups.find(item => item.id === emissionData[0].calibration_group)
                getValues(group)
                setChangesFlagEmissionData(false);
            }
        })
    }

    const saveDividedRange = (value, updatedData, newData) => {
        let group = allCalGroups.find(gr => gr.id === value.calibration_group)
        setLoading(true)
        CalGroupService.updateCalPureTone(value.id, updatedData)
            .then(() => {
                CalGroupService.saveNewCalPureTone(newData)
                    .then(() => {
                        getValues(group)
                        hideModal('divideModal')
                        setLoading(false)
                    })
            })
    }

    const handleDelete = value => {
        let group = allCalGroups.find(acg => acg.id === value.calibration_group)
        swal({
            title: '¿Eliminar registro?',
            text: `Se eliminará el registro #${value.id} definitivamente.`,
            dangerMode: true,
            icon: 'warning',
            buttons: ['Cancelar', 'Eliminar']
        })
            .then(del => {
                if (del) {
                    CalGroupService.deleteCalPureTone(value.id)
                        .then(() => {
                            getValues(group)
                            toast.success('Registro eliminado correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                        })
                }
            })
    }

    const changesDetectedNoiseParams = () => {
        setNoiseParametersUpdated({
            mic_constant: micConstantRef.current.value,
            noise_constant: noiseConstantRef.current.value,
            noise_floor: noiseFloorRef.current.value,
            dbhl_floor: dbhlFloorRef.current.value,
            dbhl_roof: dbhlRoofRef.current.value
        })
    }

    useEffect(() => {
        if (noiseParameters.length > 0) {
            var np = noiseParameters[0];
            if (np.mic_constant.toString() !== micConstantRef.current.value
                || np.noise_constant.toString() !== noiseConstantRef.current.value
                || np.noise_floor.toString() !== noiseFloorRef.current.value
                || np.dbhl_floor.toString() !== dbhlFloorRef.current.value
                || np.dbhl_roof.toString() !== dbhlRoofRef.current.value) {
                setChangesFlagNoiseParams(true)
            } else {
                setChangesFlagNoiseParams(false);
            }
        }
    }, [noiseParametersUpdated])

    const resetParams = () => {
        swal({
            title: "¿Descartar cambios?",
            text: 'Se perderán los cambios realizados.',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Descartar']
        })
            .then(confirm => {
                if (confirm) {
                    setChangesFlagNoiseParams(false);
                    micConstantRef.current.value = noiseParameters[0].mic_constant;
                    noiseConstantRef.current.value = noiseParameters[0].noise_constant;
                    noiseFloorRef.current.value = noiseParameters[0].noise_floor;
                    dbhlFloorRef.current.value = noiseParameters[0].dbhl_floor;
                    dbhlRoofRef.current.value = noiseParameters[0].dbhl_roof;
                }
            })
    }

    const saveUpdatedNoiseParams = () => {
        noiseParametersUpdated.calibration_build = params.id;
        noiseParametersUpdated.product = noiseParameters[0].product;
        noiseParametersUpdated.kitname = noiseParameters[0].kitname;

        if (micConstantRef.current.value === '' ||
            noiseConstantRef.current.value === '' ||
            noiseFloorRef.current.value === '' ||
            dbhlFloorRef.current.value === '' ||
            dbhlRoofRef.current.value === '') {
            toast.warning('Verifique que los valores de los parámetros sean correctos.', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'warning-toast'
            })

        } else {
            swal({
                title: "Confirmar cambios en parámetros de ruido",
                text: `
                ▶ Mic Constant: ${noiseParameters[0].mic_constant} »»» ${noiseParametersUpdated.mic_constant}
                ▶ Noise Constant: ${noiseParameters[0].noise_constant} »»» ${noiseParametersUpdated.noise_constant}
                ▶ Noise Floor: ${noiseParameters[0].noise_floor} »»» ${noiseParametersUpdated.noise_floor}
                ▶ Dbhl Floor: ${noiseParameters[0].dbhl_floor} »»» ${noiseParametersUpdated.dbhl_floor}
                ▶ Dbhl Roof: ${noiseParameters[0].dbhl_roof} »»» ${noiseParametersUpdated.dbhl_roof}
            `,
                icon: 'warning',
                buttons: ['Cancelar', 'Confirmar']
            }).then(confirm => {
                if (confirm) {
                    setLoadingNoiseParameters(true);
                    CalibrationService.updateNoiseParameters(noiseParameters[0].id, noiseParametersUpdated)
                        .then(data => {
                            toast.success('Parámetros de ruido modificados correctamente', {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                            setNoiseParameters([data]);
                            setChangesFlagNoiseParams(false);
                            setLoadingNoiseParameters(false);
                        })
                }
            })
        }
    }

    const columnsCalGroups = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Kit Name',
                disableSortBy: true,
                accessor: data => {
                    return <button type="button" className="border-0 bg-transparent text-info" onClick={() => {
                        setChangesFlagEmissionData(false)
                        setChangesFlagNoiseParams(false)
                        getValues(data)
                    }}>
                        {data.kitname}
                    </button>
                }
            },
            {
                Header: 'Tipo de emisión',
                accessor: 'type'
            },
            {
                Header: 'Tipo de calibración',
                accessor: 'calibration_type'
            },
            {
                Header: 'Tipo de conexión',
                accessor: 'connection_type'
            },
            {
                Header: 'Última act.',
                accessor: 'last_update',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: 'Creado el',
                accessor: 'timestamp',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            }
        ],
        []
    );

    const columnsPureTone = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Frecuencia',
                accessor: 'frequency'
            },
            {
                Header: 'Min gain',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.min_gain;
                    } else {
                        return <input id={'min_gain' + idx}
                            type="number"
                            name="min_gain"
                            step="0.01"
                            defaultValue={data.min_gain}
                            className="form-control table-input-puretone"
                            onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('min_gain', idx)}
                        />
                    }
                }
            },
            {
                Header: 'Max gain',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.max_gain;
                    } else {
                        return <input id={'max_gain' + idx} type="number" name="max_gain" defaultValue={data.max_gain}
                            className="form-control table-input-puretone" onChange={(e) => changesDetectedEmissionData(e, data.id)} onWheel={() => preventWheel('max_gain', idx)} />
                    }
                }
            },
            {
                Header: 'Min gain new',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.min_gain_new || 0;
                    } else {
                        return <input id={'min_gain_new' + idx} type="number" name="min_gain_new" step="0.01"
                            defaultValue={data.min_gain_new !== undefined ? data.min_gain_new : 0} className="form-control table-input-puretone" onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('min_gain_new', idx)} />
                    }
                }
            },
            {
                Header: 'Max gain new',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.max_gain_new || 0;
                    } else {
                        return <input id={'max_gain_new' + idx} type="number" name="max_gain_new" step="0.01"
                            defaultValue={data.max_gain_new !== undefined ? data.max_gain_new : 0} className="form-control table-input-puretone" onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('max_gain_new', idx)} />
                    }
                }
            },
            {
                Header: 'Amplifier gain',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.amplifier_gain;
                    } else {
                        return <input id={'amplifier_gain' + idx} type="number" name="amplifier_gain"
                            min={0} max={15}
                            defaultValue={data.amplifier_gain} className="form-control table-input-puretone"
                            onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('amplifier_gain', idx)} />
                    }
                }
            },
            {
                Header: 'dBHL min gain',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.dbhl_min_gain;
                    } else {
                        return <input id={'dbhl_min_gain' + idx} type="number" name="dbhl_min_gain"
                            defaultValue={data.dbhl_min_gain} className="form-control table-input-puretone" onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('dbhl_min_gain', idx)} />
                    }
                }
            },
            {
                Header: 'dBHL max gain',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.dbhl_max_gain;
                    } else {
                        return <input id={'dbhl_max_gain' + idx} type="number" name="dbhl_max_gain"
                            defaultValue={data.dbhl_max_gain} className="form-control table-input-puretone" onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('dbhl_max_gain', idx)} />
                    }
                }
            },
            {
                Header: 'Últ. act.',
                accessor: 'last_update',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: 'Creado',
                accessor: 'timestamp',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: calBuild?.built ? ' ' : 'Acciones',
                disableSortBy: true,
                accessor: data => {
                    if (!calBuild?.built) {
                        return (
                            <div className="d-flex">
                                <span
                                    id={'sliceButton' + data.id}
                                    className="actions"
                                    onMouseEnter={() => showTooltip('sliceButton' + data.id, 'Dividir rango dinámico')}
                                    onMouseLeave={() => hideTooltip('sliceButton' + data.id)}
                                    onClick={() => {
                                        setWorkOn(data.id)
                                        showModal('divideModal')
                                    }}
                                >
                                    <Slice />
                                </span>
                                <span
                                    id={'deleteButton' + data.id}
                                    className="actions ms-1"
                                    onMouseEnter={() => showTooltip('deleteButton' + data.id, 'Borrar registro')}
                                    onMouseLeave={() => hideTooltip('deleteButton' + data.id)}
                                    onClick={() => handleDelete(data)}
                                >
                                    <Delete />
                                </span>
                            </div>
                        )
                    }
                }
            }
        ],
        [calBuild, emissionData, changesDetectedEmissionData]
    );

    const columnsLogo = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Nivel dBHL',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.dbhl;
                    } else {
                        return <input id={'dbhl' + idx} type="number" name="dbhl" step="0.01" defaultValue={data.dbhl}
                            className="form-control table-input-logo" onChange={(e) => changesDetectedEmissionData(e, data.id)} onWheel={() => preventWheel('dbhl', idx)} />
                    }
                }
            },
            {
                Header: 'Vol. Reproducción',
                disableSortBy: true,
                accessor: (data, idx) => {
                    if (calBuild?.built) {
                        return data.player_volume;
                    } else {
                        return <input id={'player_volume' + idx} type="number" name="player_volume"
                            defaultValue={data.player_volume} className="form-control table-input-logo" onChange={(e) => changesDetectedEmissionData(e, data.id)}
                            onWheel={() => preventWheel('player_volume', idx)} />
                    }
                }
            },
            {
                Header: 'Última act.',
                accessor: 'last_update',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: 'Creado el',
                accessor: 'timestamp',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            }
        ],
        [calBuild, emissionData, changesDetectedEmissionData]
    );

    const columnsNoiseParameters = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Mic Constant',
                accessor: 'mic_constant',
                disableSortBy: true,
                Cell: ({ value }) => {
                    if (calBuild?.built) {
                        return value;
                    } else {
                        return <input id={'mic_constantInput'} type="number" name="mic_constant" ref={micConstantRef} defaultValue={value}
                            className="form-control table-input-noise" onChange={changesDetectedNoiseParams} onWheel={() => preventWheel('mic_constant', 0, true)} />
                    }
                }
            },
            {
                Header: 'Noise Constant',
                disableSortBy: true,
                accessor: 'noise_constant',
                Cell: ({ value }) => {
                    if (calBuild?.built) {
                        return value;
                    } else {
                        return <input id={'noise_constantInput'} type="number" ref={noiseConstantRef} defaultValue={value}
                            className="form-control table-input-noise" onChange={changesDetectedNoiseParams} onWheel={() => preventWheel('noise_constant', 0, true)} />
                    }
                }
            },
            {
                Header: 'Noise Floor',
                disableSortBy: true,
                accessor: 'noise_floor',
                Cell: ({ value }) => {
                    if (calBuild?.built) {
                        return value;
                    } else {
                        return <input id={'noise_floorInput'} type="number" ref={noiseFloorRef} defaultValue={value}
                            className="form-control table-input-noise" onChange={changesDetectedNoiseParams} onWheel={() => preventWheel('noise_floor', 0, true)} />
                    }
                }
            },
            {
                Header: 'dBHL Floor',
                disableSortBy: true,
                accessor: 'dbhl_floor',
                Cell: ({ value }) => {
                    if (calBuild?.built) {
                        return value;
                    } else {
                        return <input id={'dbhl_floorInput'} type="number" ref={dbhlFloorRef} defaultValue={value}
                            className="form-control table-input-noise" onChange={changesDetectedNoiseParams} onWheel={() => preventWheel('dbhl_floor', 0, true)} />
                    }
                }
            },
            {
                Header: 'dBHL Roof',
                disableSortBy: true,
                accessor: 'dbhl_roof',
                Cell: ({ value }) => {
                    if (calBuild?.built) {
                        return value;
                    } else {
                        return <input id={'dbhl_roofInput'} type="number" ref={dbhlRoofRef} defaultValue={value}
                            className="form-control table-input-noise" onChange={changesDetectedNoiseParams} onWheel={() => preventWheel('dbhl_roof', 0, true)} />
                    }
                }
            },
            {
                Header: 'Última act.',
                accessor: 'last_update',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            },
            {
                Header: 'Creado',
                accessor: 'timestamp',
                Cell: ({ value }) => {
                    return format(new Date(value), "dd/MM/yyyy HH:mm");
                }
            }
        ],
        [calBuild]
    )

    if (allCalGroups?.length === 0) return (
        <>
            <PrincipalBar />
            <div className="text-center mt-3">
                <Loader />
                <strong>Cargando calibraciones. Espere por favor...</strong>
            </div>
        </>
    )

    return (
        <>
            <PrincipalBar />

            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
                <FirstTitle text="Calibraciones" />
                <ButtonBack text="VOLVER" destination="/archivos-calibracion" />
            </div>

            {filteredRows &&
                <div className="modal fade" id="calModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title text-info" id="staticBackdropLabel">Nueva calibración</h3>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                    document.getElementById('newCalibrationForm').reset();
                                    hideModal('calModal')
                                }}></button>
                            </div>
                            <div className="modal-body">
                                <CreateCalibration
                                    products={products}
                                    buildId={params.id}
                                    hideModal={hideModal}
                                    smartphoneProp={smartphoneRef.current.value}
                                    earphoneProp={earphoneRef.current.value}
                                    boneHeadProp={boneHeadRef.current.value}
                                    wireProp={wireRef.current.value}
                                    suffixProp={suffixRef.current.value}
                                    setLoadingGroups={setLoadingGroups}
                                    setNewCalAdded={setNewCalAdded}
                                    calibrations={calibrations}
                                    newCalibration={newCalibration}
                                    setNewCalibration={setNewCalibration}
                                    saveNewCalibration={saveNewCalibration}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    document.getElementById('newCalibrationForm').reset();
                                    hideModal('calModal')
                                }}>CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="modal fade" id="suffixModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-info" id="staticBackdropLabel">Sufijos</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                CalibrationService.getCalSuffix().then(data => setsuffixes(data))
                                hideModal('suffixModal')
                            }}></button>
                        </div>
                        <div className="modal-body">
                            <HandleSuffixes />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                onClick={() => {
                                    CalibrationService.getCalSuffix().then(data => setsuffixes(data))
                                    hideModal('suffixModal')
                                }}>CERRAR</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="emDataChangesModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-info" id="staticBackdropLabel">Cambios en valores de emisión</h5>
                            <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => hideModal('emDataChangesModal')}></button>
                        </div>
                        <div className="modal-body">
                            <EmissionDataChanges
                                type={emissionPureTone ? 'pure tone' : 'logo'}
                                changes={changes}
                                setChanges={setChanges}
                                handleSubmit={saveUpdatedEmissionData}
                                loading={loading}
                                setLoading={setLoading}
                                getValues={getValues}
                                allCalGroups={allCalGroups}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => hideModal('emDataChangesModal')}>CERRAR</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="divideModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-info" id="staticBackdropLabel">Dividir rango dinámico</h5>
                            <button type="button" className="btn-close" aria-label="Close"
                                onClick={() => {
                                    document.getElementById('divideInput').value = 0
                                    setDisplayValue(0)
                                    hideModal('divideModal')
                                }}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <DivideValues
                                workOn={workOn}
                                emissionData={emissionData}
                                displayValue={displayValue}
                                setDisplayValue={setDisplayValue}
                                handleSubmit={saveDividedRange}
                                loading={loading}
                                setloading={setLoading}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                onClick={() => {
                                    document.getElementById('divideInput').value = 0
                                    setDisplayValue(0)
                                    hideModal('divideModal')
                                }}>
                                CERRAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {calibrations?.length === 0 ?
                <Loader /> :

                <div className="mx-3 mb-5">

                    <div className="mb-4">
                        <span className="d-inline-block mx-3"><strong>Autor</strong>: {calBuild?.user}</span>
                        <span className="d-inline-block mx-3"><strong>Versión</strong>: {calBuild?.version}</span>
                        <span className="d-inline-block mx-3"><strong>Estado</strong>: {calBuild?.built ? 'no editable' : 'editable'}</span>
                    </div>

                    <GroupsSearch
                        serialNumberRef={serialNumberRef}
                        smartphoneRef={smartphoneRef}
                        products={products}
                        earphoneRef={earphoneRef}
                        boneHeadRef={boneHeadRef}
                        wireRef={wireRef}
                        showModal={showModal}
                        suffixRef={suffixRef}
                        suffixes={suffixes}
                        getGroups={getGroups}
                    />

                    <GroupsFilter
                        filteredRows={filteredRows}
                        typeRef={typeRef}
                        filterGroups={filterGroups}
                        calibration_typeRef={calibration_typeRef}
                        connection_typeRef={connection_typeRef}
                        resetFilters={resetFilters}
                        loadingGroups={loadingGroups}
                        columnsCalGroups={columnsCalGroups}
                        calGroups={calGroups}
                        calBuild={calBuild}
                        showModal={showModal}
                    />

                    <div className="mb-4">
                        <div id="pureTone-button" className="bg-info p-3 d-flex justify-content-between flex-wrap">
                            <h5 className="text-white">{emissionPureTone === null || emissionData.length === 0 ? 'Emisión' : emissionPureTone ? 'Emisión - Tono Puro' : 'Emisión - Logo'}  {noiseParameters.length > 0 && '(' + noiseParameters[0]?.kitname + ')'}</h5>
                            {changesFlagEmissionData &&
                                <span>
                                    <button className="btn btn-sm btn-light bg-transparent text-white me-3" onClick={() => validate()}>GUARDAR</button>
                                    <button type="button" className="btn btn-sm btn-light bg-transparent text-white" onClick={resetEmissionData}>DESCARTAR</button>
                                </span>
                            }
                        </div>
                        <div>
                            {loadingGroupValues ?
                                <Loader /> :
                                calBuild?.built ?
                                    <TableCalibrationsService
                                        columns={emissionPureTone ? columnsPureTone : columnsLogo}
                                        data={emissionData}
                                        length={emissionData.length}
                                        filteredRows={filteredRows}
                                        built={calBuild?.built}
                                        showModal={showModal}
                                        dontShowButton={true}
                                        pageSizeProp={50}
                                        emissionType={emissionPureTone ? 'pure tone' : 'logo'}
                                    /> :
                                    <TableEmissionData
                                        columns={emissionPureTone ? columnsPureTone : columnsLogo}
                                        data={emissionData}
                                        length={emissionData.length}
                                        filteredRows={filteredRows}
                                        built={calBuild?.built}
                                        showModal={showModal}
                                        dontShowButton={true}
                                        pageSizeProp={50}
                                        emissionType={emissionPureTone ? 'pure tone' : 'logo'}
                                    />
                            }
                        </div>
                    </div>

                    <div>
                        <div id="noiseParameters-button" className="bg-noise-params p-3 d-flex justify-content-between flex-wrap">
                            <h5 className="text-white">Parámetros de ruido {noiseParameters.length > 0 && '(' + noiseParameters[0]?.kitname + ')'}</h5>
                            {changesFlagNoiseParams &&
                                <span>
                                    <button type="button" className="btn btn-sm btn-light bg-transparent text-white me-3" onClick={saveUpdatedNoiseParams}>GUARDAR</button>
                                    <button type="button" className="btn btn-sm btn-light bg-transparent text-white" onClick={resetParams}>DESCARTAR</button>
                                </span>
                            }
                        </div>
                        <div>
                            {loadingNoiseParameters ?
                                <Loader /> :
                                <TableCalibrationsService
                                    columns={columnsNoiseParameters}
                                    data={noiseParameters}
                                    length={noiseParameters.length}
                                    filteredRows={filteredRows}
                                    built={calBuild?.built}
                                    showModal={showModal}
                                    dontShowButton={true}
                                    pageSizeProp={1}
                                    size='lg'
                                />
                            }
                        </div>
                    </div>

                </div>
            }
        </>
    )
}