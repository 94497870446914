import { useMemo, useState, useEffect } from "react";
import swal from "sweetalert";

import TableCalibrationsService from "../../../AppComponents/Calibrations/TableCalibrationsService";
import Loader from "../../../AppComponents/Commons/Loader";

export default function EmissionDataChanges({
    type,
    handleSubmit,
    changes,
    setChanges,
    loading,
    setLoading,
    allCalGroups,
    getValues
}) {

    const [oldData, setOldData] = useState([])
    const [newData, setNewData] = useState([])

    let oldDataToPush = []
    let newDataToPush = []

    useEffect(() => {
        if (changes.length > 0) {
            for (let i = 0; i < changes.length; i++) {
                oldDataToPush.push(changes[i][0])
                newDataToPush.push(changes[i][1])
            }
            setOldData(oldDataToPush)
            setNewData(newDataToPush)
        }
    }, [changes])

    const handleDelete = element => {
        swal({
            title: "¿Quitar este registro de los valores a editar?",
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar']
        })
            .then(confirm => {
                if (confirm) {
                    setChanges([
                        ...changes.filter(i => i[0].id !== element.id && i[1].id !== element.id)
                    ])
                    getValues(allCalGroups.find(i => i.id === element.calibration_group), [...newData.filter(nd => nd.id !== element.id)])
                }
            })
    }

    const columnsPureTone = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Frecuencia',
                accessor: 'frequency'
            },
            {
                Header: 'Min gain',
                accessor: 'min_gain'
            },
            {
                Header: 'Max gain',
                accessor: 'max_gain'
            },
            {
                Header: 'Min gain new',
                accessor: 'min_gain_new'
            },
            {
                Header: 'Max gain new',
                accessor: 'max_gain_new'
            },
            {
                Header: 'Amplifier gain',
                accessor: 'amplifier_gain'
            },
            {
                Header: 'dBHL min gain',
                accessor: 'dbhl_min_gain'
            },
            {
                Header: 'dBHL max gain',
                accessor: 'dbhl_max_gain'
            },
        ],
        [type]
    );

    const columnsLogo = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'id'
            },
            {
                Header: 'Nivel dBHL',
                accessor: 'dbhl'
            },
            {
                Header: 'Vol. Reproducción',
                accessor: 'player_volume'
            }
        ],
        [type]
    );

    return (

        <>
            {loading ?
                <Loader /> :
                <>
                    <div>
                        <h6 className="text-info">Valores originales</h6>
                        <TableCalibrationsService
                            columns={type === 'pure tone' ? columnsPureTone : columnsLogo}
                            data={oldData}
                            pageSizeProp={10}
                        />
                    </div>

                    <div>
                        <h6 className="text-info">Valores nuevos</h6>
                        <TableCalibrationsService
                            columns={type === 'pure tone' ? columnsPureTone : columnsLogo}
                            data={newData}
                            pageSizeProp={10}
                            deleteFeature="true"
                            handleDelete={handleDelete}
                        />
                    </div>

                    <button type="button" className="btn btn-sm btn-info text-white d-block mx-auto col-3" onClick={() => {
                        swal({
                            title: "¿Confirmar cambios?",
                            text: 'Se guardará los nuevos valores de emisión.',
                            icon: 'warning',
                            buttons: ['Cancelar', 'Confirmar']
                        })
                            .then(confirm => {
                                if (confirm) {
                                    setLoading(true)
                                    handleSubmit()
                                }
                            })
                    }}>
                        GUARDAR
                    </button>
                </>
            }

        </>
    )
}