import { useContext, useEffect, useState } from "react";

import OrderContext from "../../Contexts/OrderContext";

import LicenseService from "../../Services/Licenses/LicenseService";

export default function useLicenses() {

    const [licenses, setLicenses] = useState([])
    const [licenseData, setLicenseData] = useState(null)

    const {
        setPreviousPage,
        setNextPage,
        setTotalRegisters
    } = useContext(OrderContext)

    useEffect(() => {
        (async () => {
            const data = await LicenseService.getLicenses({
                ordering: '-id',
                limit: 10
            })
            if (data === undefined) return
            setLicenses(data.results)
            setPreviousPage(data.previous)
            setNextPage(data.next)
            setTotalRegisters(data.count)
        })()
    }, [])

    const getLicenseData = async id => {
        const [data] = await LicenseService.getLicenseDataByUniqueId(id)
        if (data === undefined) return setLicenseData(null)
        setLicenseData(data)
    }

    return {
        licenses,
        setLicenses,
        getLicenseData,
        licenseData,
        setLicenseData
    }
}