import React from 'react';

export default function ClientsCard() {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25.195" viewBox="0 0 26 25.195">
                <path id="Trazado_119" data-name="Trazado 119" d="M28.887,25.427a12.94,12.94,0,0,0-6.786-9.6l-.316-.187.158-.316A7.333,7.333,0,0,0,23.38,9.741a7.505,7.505,0,0,0-8.253-6.427,7.376,7.376,0,0,0-5.118,2.876A7.519,7.519,0,0,0,15.947,18.3a7.778,7.778,0,0,0,3.8-1.035h.288a10.754,10.754,0,0,1,6.254,6.887,13.257,13.257,0,0,1,.345,1.438l.086.518H5.149v-.374a10.884,10.884,0,0,1,3.609-6.542,1.121,1.121,0,0,0,.216-1.567h0a1.035,1.035,0,0,0-.762-.359h0a1.179,1.179,0,0,0-.82.345,12.94,12.94,0,0,0-4.4,9.518,1.337,1.337,0,0,0,.3,1.006A1.438,1.438,0,0,0,4.3,28.46H27.722a1.265,1.265,0,0,0,.949-.331,1.308,1.308,0,0,0,.3-1.021A15.183,15.183,0,0,0,28.887,25.427ZM19.7,14.528A5.305,5.305,0,1,1,15.947,5.47h0A5.305,5.305,0,0,1,19.7,14.528Z" transform="translate(-2.984 -3.268)" fill="#43eba2" />
            </svg>
        </div>
    );

}
