import { doubleSerialNumber } from "../General/StatusHandler";

export const checkItemsOccurences = newitems => {
    let flag = true
    let serials = []
    for (let i = 0; i < newitems.length; i++) {
        serials.push(newitems[i].serial_number)
    }
    const occurrences = serials.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {});
    for (let key of Object.keys(occurrences)) {
        if (occurrences[key] > 1) {
            flag = false
            doubleSerialNumber(key)
        }
    }
    return flag
}