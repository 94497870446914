import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { hideModal } from '../../Utilities/General/BootstrapApi'
import ClientService from '../../Services/Licenses/ClientService'

export default function EditClientDataOnOrder({
    editClient,
    setEditClient,
    clients,
    setClients,
    clientBillingData,
    setClientBillingData
}) {

    const handleChange = e => {
        setEditClient({
            ...editClient,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        let cbd = clientBillingData.find(item => item.client === editClient.id)

        if (
            editClient.id_client.length === 0 ||
            editClient.id_client.length > 100 ||
            editClient.vat_number.length === 0 ||
            editClient.vat_number.length > 80 ||
            editClient.carnet_id.length > 100
        ) return

        Promise.all([
            ClientService.updateClient(editClient.id, editClient),
            ClientService.updateClientBillingData(cbd.id, {
                ...cbd,
                ...editClient
            })
        ])
            .then(res => {
                const [client, cbd] = res
                setClients([
                    ...clients.filter(c => c.id !== client.data.id),
                    client.data
                ])
                setClientBillingData([
                    ...clientBillingData.filter(item => item.id !== cbd.id),
                    cbd
                ])
                toast.success('Datos editados correctamente', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
                hideModal('editClientOnOrder')
            })
    }

    if (clientBillingData?.length === 0) return <></>

    return (
        <div className="modal fade" id="editClientOnOrder">
            <div className="modal-dialog rounded shadow border border-dark">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-info">Editar datos de cliente</h5>
                        <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => hideModal('editClientOnOrder')}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className='form-group'>
                                <label htmlFor='id_client' className='mb.1'>
                                    N° de documento
                                </label>
                                <input
                                    value={editClient?.id_client}
                                    className='form-control'
                                    name='id_client'
                                    onChange={e => handleChange(e)}
                                />
                                {editClient?.id_client.length === 0 &&
                                    <small className='text-danger'>
                                        * Este campo es requerido.
                                    </small>
                                }
                                {editClient?.id_client.length > 100 &&
                                    <small className='text-danger'>
                                        * El valor ingresado es demasiado largo.
                                    </small>
                                }
                            </div>
                            <div className='form-group my-3'>
                                <label htmlFor='vat_number' className='mb-1'>
                                    CUIT/CUIL/RUT
                                </label>
                                <input
                                    value={editClient?.vat_number}
                                    name="vat_number"
                                    className='form-control'
                                    onChange={e => handleChange(e)}
                                />
                                {editClient?.vat_number.length === 0 &&
                                    <small className='text-danger'>
                                        * Este campo es requerido.
                                    </small>
                                }
                                {editClient?.vat_number.length > 80 &&
                                    <small className='text-danger'>
                                        * El valor ingresado es demasiado largo.
                                    </small>
                                }
                            </div>
                            <div className='form-group mb-3'>
                                <label htmlFor='carnet_id' className='mb-1'>
                                    Matrícula
                                </label>
                                <input
                                    value={editClient?.carnet_id}
                                    name="carnet_id"
                                    className='form-control'
                                    onChange={e => handleChange(e)}
                                />
                                {editClient?.carnet_id.length > 100 &&
                                    <small className='text-danger'>
                                        * El valor ingresado es demasiado largo.
                                    </small>
                                }
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary bg-white text-secondary"
                            onClick={() => {
                                hideModal('editClientOnOrder')
                            }}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            className="btn btn-info text-white"
                            onClick={() => handleSubmit()}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}