import axios from "axios";
import { toast } from 'react-toastify'

import Urls from "../../Utilities/General/Urls";
import { getToken } from "../../Utilities/Auth/AuthPermissions";
import { networkError, error, serverError, errorTimeout, expiredSession } from "../../Utilities/General/StatusHandler";

const LicenseService = {

    getLicenses: async (params = false) => {
        const { ordering, limit, offset } = params
        try {
            const { data } = await axios.get(
                !params ? Urls.urlPayLicense :
                    Urls.urlPayLicense +
                    `?ordering=${ordering}&limit=${limit}${offset ? '&offset=' + offset : ''}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('400')) return error()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getLicense: async id => {
        try {
            const { data } = await axios.get(
                Urls.urlPayLicense + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('400')) return error()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getLicensesWithUrl: async url => {
        try {
            const { data } = await axios.get(
                url,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getLicenseDataByUniqueId: async id => {
        try {
            const { data } = await axios.get(
                Urls.urlKitProActivationFull + `?uniqueid=${id}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    resetPassword: async email => {
        try {
            const { data } = await axios.post(
                Urls.urlKitProPasswordReset,
                { email },
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default LicenseService