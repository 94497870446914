import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { errorTimeout, error, networkError, serverError, existingClient, expiredSession } from '../../Utilities/General/StatusHandler';

const ClientService = {

    getClients: async () => {
        try {
            const { data } = await axios.get(GlobalUrls.urlPayClient, { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewClient: async (newClient, currentTypeId, currentCountry) => {
        try {
            const res = await axios.post(
                GlobalUrls.urlPayClient,
                newClient,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingClient(newClient, currentTypeId, currentCountry)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateClient: async (id, newClient, currentTypeId, currentCountry) => {
        try {
            const res = await axios.put(
                GlobalUrls.urlPayClient + `${id}/`,
                newClient,
                { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('400')) return existingClient(newClient, currentTypeId, currentCountry)
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getClientShippingData: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayClientShippingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewClientShippingData: async newClientShippingData => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlPayClientShippingData,
                newClientShippingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateClientShippingData: async (id, shippingData) => {
        try {
            const { data } = await axios.put(
                GlobalUrls.urlPayClientShippingData + `${id}/`,
                shippingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteClientShippingData: async id => {
        try {
            await axios.delete(
                GlobalUrls.urlPayClientShippingData + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getClientBillingData: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayClientBillingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewClientBillingData: async newClientBillingData => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlPayClientBillingData,
                newClientBillingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateClientBillingData: async (id, newClientBillingData) => {
        try {
            const { data } = await axios.put(
                GlobalUrls.urlPayClientBillingData + `${id}/`,
                newClientBillingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteClientBillingData: async id => {
        try {
            await axios.delete(
                GlobalUrls.urlPayClientBillingData + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getEmailContacts: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayEmailContact,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewEmailContact: async newEmailContact => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlPayEmailContact,
                newEmailContact,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteEmailContact: async id => {
        try {
            await axios.delete(
                GlobalUrls.urlPayEmailContact + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getTypePhone: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayTypePhone,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getPhoneProvider: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayPhoneProvider,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getPhoneContacts: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayPhoneContact,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewPhoneContact: async newPhoneContact => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlPayPhoneContact,
                newPhoneContact,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deletePhoneContact: async id => {
        try {
            await axios.delete(
                GlobalUrls.urlPayPhoneContact + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getTypeIdClient: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlPayTypeIdClient,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default ClientService