import React from 'react';

export default function Send() {

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_1034" data-name="Grupo 1034" transform="translate(-719 -378)">
          <g id="Grupo_1033" data-name="Grupo 1033" transform="translate(60)">
            <g id="Grupo_968" data-name="Grupo 968" transform="translate(659 378)">
              <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#43eba2" />
            </g>
          </g>
          <g id="enviar" transform="translate(722.693 350.03)">
            <path id="Trazado_614" data-name="Trazado 614" d="M14.6,34.021a.431.431,0,0,0-.571-.5L.276,38.878a.44.44,0,0,0,0,.815l3.864,1.523v4.8a.435.435,0,0,0,.329.425.425.425,0,0,0,.481-.228l1.6-3.235,3.9,2.953a.425.425,0,0,0,.665-.223C14.754,33.522,14.6,34.052,14.6,34.021Zm-3.4,1.535-6.7,4.868L1.623,39.288ZM5,41.141,10.836,36.9c-5.026,5.409-4.764,5.124-4.786,5.154s.057-.13-1.055,2.122Zm5.475,3.713-3.433-2.6,6.208-6.681Z" transform="translate(0 0)" fill="#fff" />
          </g>
        </g>
      </svg>
    </div>
  );

}