import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import { format } from "date-fns";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { FcDocument } from 'react-icons/fc'
import { IoDocumentTextSharp } from "react-icons/io5";

import { useNavigate } from "react-router-dom";

import Loader from "../../AppComponents/Commons/Loader";
import TableBackPaginated from "../../AppComponents/Orders/TableBackPaginated";
import ButtonBack from "../../AppComponents/Commons/ButtonBack";
import FirstTitle from "../../AppComponents/Commons/FirstTitle";
import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import OrdersPipeline from "../../AppComponents/Orders/OrdersPipeline";
import ViewOrder from "../../AppComponents/Orders/ViewOrder";
import { getToken, getUsername, isAdmin, isSellerEditor, isAuth, isCustomerSuccess } from "../../Utilities/Auth/AuthPermissions";
import OrderDataConfirm from "../../AppComponents/Orders/OrderDataConfirm";
import OrderContext from "../../Contexts/OrderContext";

import { showTooltip, hideTooltip, showModal, hideModal } from "../../Utilities/General/BootstrapApi";
import OrderService from '../../Services/Licenses/OrderService';
import ClientService from "../../Services/Licenses/ClientService";
import PaymentService from "../../Services/Licenses/PaymentService";
import SaleProductService from "../../Services/Stock/SaleProductService";
import StockOrderService from "../../Services/Stock/StockOrderService";
import UsoundPaymentsService from "../../Services/UsoundPayments/UsoundPaymentsService";
import { setPreAssembledStock } from "../../Utilities/Orders/SetPreAssembledStock";
import { checkPreAssembledStockByOrder } from "../../Utilities/Orders/CheckPreAssembledStockByOrder";

import Delete from "../../Assets/Delete";
import Edit from "../../Assets/Edit";
import Paid from "../../Assets/Paid";

export default function SaleOrders() {

  const {
    orders,
    setOrders,
    setNextPage,
    setPreviousPage,
    setTotalRegisters
  } = useContext(OrderContext)

  const [clients, setClients] = useState([]);
  const [countries, setCountries] = useState([]);
  const [clientBillingData, setClientBillingData] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [articles, setArticles] = useState([]);
  const [saleProductStock, setSaleProductStock] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [stockOrders, setStockOrders] = useState([])
  const [typeOrderStatus, setTypeOrderStatus] = useState([]);
  const [uSoundPaymentOrders, setUsoundPaymentOrders] = useState([])
  const [usoudPaymentProducts, setUsoundPaymentProducts] = useState([])
  const [customers, setCustomers] = useState([])

  const [toOrderStatus, setToOrderStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [workOn, setWorkOn] = useState(null);
  const [orderFromMail, setOrderFromMail] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

  const params = useParams()
  const navigate = useNavigate()

  const cancelObsRef = useRef('')

  useEffect(() => {
    let backs = document.getElementsByClassName('modal-backdrop');
    if (backs.length > 0 && isAuth()) {
      window.location.reload();
    }
    if (params.id !== undefined) {
      setOrderFromMail(true)
    } else {
      setOrderFromMail(false)
    }
  }, [])

  useEffect(() => {
    OrderService.getOrders({
      ordering: '-id',
      limit: 10
    }).then(data => {
      if (!data) return
      setTotalRegisters(data.count)
      setNextPage(data.next)
      setPreviousPage(data.previous)
      setOrders(data.results)
      setLoading(false)
    })
    ClientService.getClients().then(data => setClients(data))
    ClientService.getClientBillingData().then(data => setClientBillingData(data))
    ClientService.getEmailContacts().then(data => setEmailContacts(data))
    PaymentService.getCountries().then(data => setCountries(data))
    PaymentService.getCurrencies().then(data => setCurrency(data))
    PaymentService.getArticles().then(data => setArticles(data))
    PaymentService.getSellers().then(data => setSellers(data))
    SaleProductService.getSaleProductStock().then(data => setSaleProductStock(data))
    StockOrderService.getStockOrders().then(data => setStockOrders(data))
    OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
    UsoundPaymentsService.getOrders().then(data => setUsoundPaymentOrders(data))
    UsoundPaymentsService.getProducts().then(data => setUsoundPaymentProducts(data))
    UsoundPaymentsService.getCustomers().then(data => setCustomers(data))
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <div
              id={'viewOrderDiv' + value}
              className="cursor-pointer"
              onMouseEnter={() => showTooltip('viewOrderDiv' + value, `Ver orden #${value}`)}
              onMouseLeave={() => hideTooltip('viewOrderDiv' + value)}
              onClick={() => handleView(value)}>
              <span>{value}</span>
            </div>
          )
        }
      },
      {
        Header: "Creada",
        accessor: "timestamp",
        Cell: ({ value }) => {
          return format(new Date(value), "dd/MM/yyyy HH:mm");
        }
      },
      {
        Header: "Cliente",
        disableSortBy: true,
        accessor: order => {
          let [client] = clients.filter(c => c.id === order.new_client)
          let [customer] = customers.filter(c => c.id === parseInt(order.payments_service_customer_id))
          return client?.name || customer?.name
        }
      },
      {
        Header: 'Artículos',
        disableSortBy: true,
        accessor: order => {
          return <>
            {order.paymentdetail_set.map((item, idx) => {
              return (
                <p key={item.id} className={idx >= 1 ? "d-block mt-4" : undefined}>
                  {'(' + item.count + ') ' + (item.article_alias !== null ? item.article_alias : item.article_name)}
                </p>
              )
            })}
          </>
        }
      },
      {
        Header: 'Dirección',
        disableSortBy: true,
        accessor: order => {
          let osd = order.ordershippingdata
          let obd = order.orderbillingdata
          if (osd !== null) {
            return `${osd.address_address} ${osd.address_number}, ${osd.address_city}`
          }
          if (obd !== null) {
            return `${obd.address_address} ${obd.address_number}, ${obd.address_city}`
          }
          return order.shipping_address
        }
      },
      {
        Header: 'Provincia',
        disableSortBy: true,
        accessor: order => {
          let osd = order.ordershippingdata
          let obd = order.orderbillingdata
          if (osd !== null) {
            return osd.address_province
          }
          if (obd !== null) {
            return obd.address_province
          }
          return order.shipping_address_province
        }
      },
      {
        Header: 'País',
        disableSortBy: true,
        accessor: order => {
          let osd = order.ordershippingdata
          let obd = order.orderbillingdata
          if (osd !== null) {
            let [country] = countries.filter(c => c.id === osd.address_country)
            return country.name
          }
          if (obd !== null) {
            let [country] = countries.filter(c => c.id === obd.address_country)
            return country.name
          }
          return countries.map(c => {
            if (c.id === parseInt(order.shipping_address_country)) {
              return c.name
            }
          })
        }
      },
      {
        Header: "Importe",
        accessor: order => {
          let obd = order.orderbillingdata
          if (obd !== null) {
            let [curr] = currency.filter(c => c.id === obd.currency)
            return curr.name + ' ' + obd.amount;
          }
          return order.currency + ' ' + order.amount
        }
      },
      {
        Header: "Estado",
        accessor: order => {
          let allStatus = order.orderstatus_set.sort((a, b) => b.id - a.id).filter(os => os.type_order_status_trunk)
          let display = allStatus[0]?.type_order_status_spanish_name || 'No válido'
          return [display, order.id].toString();
        },
        Cell: ({ value }) => {
          const [display, id] = value.split(',')
          return <button type="button"
            id={'pipelineButton' + id}
            className={
              (display === 'Pagada' || display === 'Reposición') ?
                "btn btn-sm paid-order text-white" :
                display === 'Aceptada' ?
                  "btn btn-sm accepted-order text-white" :
                  display === 'Armando' ?
                    "btn btn-sm assembling-order text-white" :
                    display === 'Enviada' ?
                      "btn btn-sm sended-order text-white" :
                      display === 'Recibida' ?
                        "btn btn-sm btn-primary text-white" :
                        display === 'Finalizada' ?
                          "btn btn-sm btn-success text-white" :
                          display === 'Anulada' ?
                            "btn btn-sm canceled-order text-white" :
                            "btn btn-sm btn-secondary text-white"
            }
            onMouseEnter={() => showTooltip('pipelineButton' + id, `Ver historial de orden #${id}`)}
            onMouseLeave={() => hideTooltip('pipelineButton' + id)}
            onClick={() => {
              setWorkOn(parseInt(id))
              showModal('orderPipeline')
            }}
          >
            {display}
          </button>
        }
      },
      {
        Header: "Acciones",
        disableSortBy: true,
        accessor: order => {
          let obd = order.orderbillingdata
          let osd = order.ordershippingdata
          let allStatus = order.orderstatus_set.sort((a, b) => b.id - a.id)
            .filter(os => os.type_order_status_trunk)
            .map(os => os.type_order_status_spanish_name)
          let currentStatus = allStatus[0]
          return (
            <div className="d-flex justify-content-start">
              {(
                ((isSellerEditor() && (currentStatus === 'Creada' || currentStatus === 'Pagada'))
                  || (isCustomerSuccess() && currentStatus === 'Reposición')
                  || (isAdmin() && currentStatus !== 'Anulada'))
                && order.origin !== 'TiendaNube'
                && order.payments_service_customer_id === null
              ) &&
                <span
                  id={"editButton" + order.id}
                  className="actions me-1"
                  onMouseEnter={() => showTooltip('editButton' + order.id, 'Editar orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('editButton' + order.id)}
                  onClick={() => {
                    hideTooltip('editButton' + order.id)
                    handleEdit(order)
                  }}
                >
                  <Edit />
                </span>
              }
              {(((currentStatus === 'Creada' || currentStatus === 'Pagada') && isSellerEditor()) ||
                (currentStatus === 'Reposición' && isCustomerSuccess()) ||
                (currentStatus !== 'Anulada' && isAdmin())) &&
                <span
                  id={"deleteButton" + order.id}
                  className="actions me-1"
                  onMouseEnter={() => showTooltip('deleteButton' + order.id, 'Anular orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('deleteButton' + order.id)}
                  onClick={() => {
                    setToOrderStatus('canceled')
                    setWorkOn(parseInt(order.id))
                    showModal('orderDataConfirm')
                  }}
                >
                  <Delete />
                </span>
              }
              {(currentStatus === 'Creada' && (isSellerEditor() || isAdmin())) &&
                <span
                  id={"paidButton" + order.id}
                  className="actions"
                  onMouseEnter={() => showTooltip('paidButton' + order.id, `Marcar orden #${order.id} como pagada`)}
                  onMouseLeave={() => hideTooltip('paidButton' + order.id)}
                  onClick={() => {
                    if(order.date_first_payment !== null) {
                      setToOrderStatus('paid')
                      setWorkOn(parseInt(order.id))
                      showModal('orderDataConfirm')
                    } else {
                      toast.error(`La orden #${order.id} no contiene un comprobante de pago`, {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                      })
                    }
                  }}
                >
                  <Paid />
                </span>
              }
              {(obd !== null && obd.invoice_url !== null) &&
                <span
                  id={'downloadBillButton' + order.id}
                  className='actions fs-3 d-flex align-items-center'
                  onMouseEnter={() => showTooltip('downloadBillButton' + order.id, 'Descargar factura de orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('downloadBillButton' + order.id)}
                  onClick={() => {
                    window.open(obd.invoice_url, '_blank')
                  }}
                >
                  <FcDocument />
                </span>
              }
              {(osd !== null && osd.shipping_guide_url !== null) &&
                <span
                  id={'downloadShippButton' + order.id}
                  className='actions fs-3 d-flex align-items-center'
                  onMouseEnter={() => showTooltip('downloadShippButton' + order.id, 'Descargar guia de envío de orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('downloadShippButton' + order.id)}
                  onClick={() => {
                    window.open(osd.shipping_guide_url, '_blank')
                  }}
                >
                  <IoDocumentTextSharp />
                </span>
              }
              {(obd !== null && obd.invoice_autogenerated_url !== null) &&
                <span
                  id={'downloadInvoiceButton' + order.id}
                  className='actions fs-3 d-flex align-items-center'
                  onMouseEnter={() => showTooltip('downloadInvoiceButton' + order.id, 'Descargar factura autogenerada de orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('downloadInvoiceButton' + order.id)}
                  onClick={() => {
                    window.open(obd.invoice_autogenerated_url, '_blank')
                  }}
                >
                  <IoDocumentTextSharp style={{ color: 'red' }}/>
                </span>
              }
            </div>
          );
        },
      },
      {
        Header: "Modificada",
        accessor: "last_update",
        Cell: ({ value }) => {
          return format(new Date(value), 'dd/MM/yyyy HH:mm');
        }
      },
    ],
    [
      orders,
      currency,
      typeOrderStatus,
      countries,
      clients,
      customers
    ]
  );

  const handleCancel = (rowIndex) => {
    const id = rowIndex.id;
    let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'canceled')

    OrderService.checkCanceled(id, typeOrderStatus, getToken())
      .then(isCancelled => {
        if (isCancelled) {

          toast.error(`La orden #${id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'error-toast'
          })

        } else {
          let newOrderStatus = {
            order: id,
            type_order_status: currentStatus.id,
            user_name: getUsername(),
            observation: cancelObsRef.current.value
          }
          OrderService.saveNewOrderStatus(newOrderStatus)
            .then(data => {
              let [order] = orders.filter(item => item.id === data.order)
              order.orderstatus_set = [...order.orderstatus_set, data]
              setOrders([...orders.filter(item => item.id !== order.id), order])
              OrderService.sendMailOrderStatus(id, 'canceled', 'anulada')
              hideModal('orderDataConfirm')
              document.getElementById('obsTextarea').value = ''
              toast.success(`La orden #${id} ha sido anulada correctamente`, {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'success-toast'
              })
              setLoadingChangeStatus(false)
            })
        }
      })
  }

  const handlePaid = order => {
    let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'paid')
    const id = order.id;
    let currentOrderShippingData = order.ordershippingdata
    let [currentClientBillingData] = clientBillingData.filter(cbd => cbd.client === order.new_client)

    const stock = setPreAssembledStock(stockOrders, saleProductStock)
    checkPreAssembledStockByOrder(order.paymentdetail_set, stock)

    OrderService.checkCanceled(id, typeOrderStatus, getToken())
      .then(isCancelled => {
        if (isCancelled) {

          toast.error(`La orden #${id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'error-toast'
          })

        } else {

          let newOrderBillingData = {
            vat_number: currentClientBillingData.vat_number,
            name: currentClientBillingData.name,
            iva: currentClientBillingData.iva,
            type: currentClientBillingData.type,
            person: currentClientBillingData.person,
            address_province: currentOrderShippingData.address_province,
            address_city: currentOrderShippingData.address_city,
            address_locality: currentOrderShippingData.address_locality,
            address_address: currentOrderShippingData.address_address,
            address_number: currentOrderShippingData.address_number,
            address_floor_apartment: currentOrderShippingData.address_floor_apartment,
            address_apartment: currentOrderShippingData.address_apartment,
            address: currentOrderShippingData.address,
            address_postal_code: currentOrderShippingData.address_postal_code,
            amount: order.amount,
            amount_usd: order.amount_usd,
            carnet_id: currentClientBillingData.carnet_id,
            order: id,
            address_country: currentOrderShippingData.address_country,
          }

          currency.map(cur => {
            if (cur.name === order.currency) {
              newOrderBillingData.currency = cur.id
            }
          })

          OrderService.saveNewOrderBillingData(newOrderBillingData)
            .then(data => {
              let [order] = orders.filter(item => item.id === data.order)
              setOrders([
                ...orders.filter(item => item.id !== order.id),
                {
                  ...order,
                  orderbillingdata: data
                }
              ])
              let newOrderStatus = {
                order: id,
                type_order_status: currentStatus.id,
                user_name: getUsername()
              }
              OrderService.saveNewOrderStatus(newOrderStatus)
                .then(data => {
                  let [order] = orders.filter(item => item.id === data.order)
                  order.orderstatus_set = [...order.orderstatus_set, data]
                  setOrders([...orders.filter(item => item.id !== order.id), order])
                  OrderService.sendMailOrderStatus(id, 'paid', 'pagada')
                  hideModal('orderDataConfirm')
                  toast.success(`Orden #${id} marcada como pagada`, {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                  })
                  setLoadingChangeStatus(false)
                })
            })
        }
      });
  }

  const handleEdit = (rowIndex) => {
    const id = rowIndex.id;
    OrderService.checkCanceled(id, typeOrderStatus, getToken())
      .then(isCancelled => {
        if (isCancelled) {
          toast.error(`La orden #${id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'error-toast'
          })
        } else {
          navigate("/editar-orden/" + id);
        }
      })
  };

  const handleView = (id) => {
    setWorkOn(parseInt(id));
    showModal('viewOrderModal');
  };

  return (
    <>
      <PrincipalBar />

      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
        <FirstTitle text="Órdenes de venta" />
        <ButtonBack destination="/inicio" text="VOLVER" />
      </div>

      <div className="modal fade" id="viewOrderModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-info" id="staticBackdropLabel">Orden #{workOn}</h3>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => hideModal('viewOrderModal')}></button>
            </div>
            <div className="modal-body">
              <ViewOrder
                order={orders?.filter(order => order.id === workOn)[0]}
                clients={clients}
                emails={emailContacts}
                countries={countries}
                stockOrder={stockOrders?.filter(item => item.order_id === workOn)[0]}
                articles={articles}
                saleProductStock={saleProductStock}
                uSoundPaymentOrder={uSoundPaymentOrders?.find(upo => parseInt(upo.order_id) === workOn)}
                paymentServiceProducts={usoudPaymentProducts}
                customers={customers}
                typeOrderStatus={typeOrderStatus}
                clientBillingData={clientBillingData}
                setClients={setClients}
                setClientBillingData={setClientBillingData}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => hideModal('viewOrderModal')}>CERRAR</button>
            </div>
          </div>
        </div>
      </div>

      <OrdersPipeline
        orderId={workOn}
      />

      <OrderDataConfirm
        order={orders?.filter(item => item.id === workOn)[0]}
        clients={clients}
        emails={emailContacts}
        sellers={sellers}
        countries={countries}
        articles={articles}
        toOrderStatus={toOrderStatus}
        hideModal={hideModal}
        handlePaid={handlePaid}
        handleCancel={handleCancel}
        loadingChangeStatus={loadingChangeStatus}
        setLoadingChangeStatus={setLoadingChangeStatus}
        saleProducts={saleProductStock}
        stockOrders={stockOrders}
        stockOrder={stockOrders?.filter(item => item.order_id === workOn)[0]}
        uSoundPaymentOrder={uSoundPaymentOrders?.filter(upo => parseInt(upo.order_id) === workOn)[0]}
        paymentServiceProducts={usoudPaymentProducts}
        customers={customers}
        cancelObsRef={cancelObsRef}
      />

      {loading ?
        <div className="container text-center">
          <Loader />
          <strong className="d-block mt-3">Cargando...</strong>
        </div> :
        <div className="mx-2">
          <TableBackPaginated
            columns={columns}
            data={orderFromMail ?
              orders.filter(order => order.id === parseInt(params.id)) :
              orders}
            setData={setOrders}
          />

          {orderFromMail &&
            <button type="button" className="btn btn-info text-white d-block mx-auto"
              onClick={() => {
                setOrderFromMail(false)
                navigate('/ordenes-venta')
              }}>
              Ver todas
            </button>
          }
        </div>
      }

    </>
  );
}
