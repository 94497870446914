import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import TableClients from "./Components/TableClients";
import Loader from "../../AppComponents/Commons/Loader";
import ButtonBack from "../../AppComponents/Commons/ButtonBack";
import FirstTitle from "../../AppComponents/Commons/FirstTitle";
import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
import ViewClient from './Components/ViewClient';
import EditClient from './Components/EditClient';
import CreateClient from "../../AppComponents/Commercial/CreateClient";

import { isAdmin, isAuth, isSellerEditor } from '../../Utilities/Auth/AuthPermissions';
import validator from "../../Utilities/General/Validator";
import { showTooltip, hideTooltip, showModal, hideModal } from "../../Utilities/General/BootstrapApi";
import ClientService from "../../Services/Licenses/ClientService";
import PaymentService from '../../Services/Licenses/PaymentService'

import Edit from "../../Assets/Edit";

export default function Clients() {

  const [typePhone, setTypePhone] = useState([]);
  const [phoneProviders, setPhoneProviders] = useState([]);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [typeIdClient, setTypeIdClient] = useState([]);
  const [countries, setCountries] = useState([]);
  const [clientShippingData, setClientShippingData] = useState([]);
  const [clientBillingData, setClientBillingData] = useState([]);
  const [clients, setClients] = useState([]);

  const [newClient, setNewClient] = useState({});
  const [newClientBillingData, setNewClientBillingData] = useState({});
  const [newEmailContact, setNewEmailContact] = useState({})
  const [newPhoneContact, setNewPhoneContact] = useState({})
  const [newClientShippingData, setNewClientShippingData] = useState({})

  const [, forceUpdate] = useState(0);
  const [showClient, setShowClient] = useState(null);
  const [loadingClients, setLoadingClients] = useState(true);

  const val = validator.rules;

  useEffect(() => {
    if (isAuth()) {
      Promise.allSettled([
        ClientService.getTypePhone().then(data => setTypePhone(data)),
        ClientService.getPhoneContacts().then(data => setPhoneContacts(data)),
        ClientService.getEmailContacts().then(data => setEmailContacts(data)),
        ClientService.getTypeIdClient().then(data => setTypeIdClient(data)),
        ClientService.getClients().then(data => setClients(data)),
        ClientService.getClientShippingData().then(data => setClientShippingData(data)),
        ClientService.getClientBillingData().then(data => setClientBillingData(data)),
        ClientService.getPhoneProvider().then(data => setPhoneProviders(data)),
        PaymentService.getCountries().then(data => {
          setCountries(data.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }));
        })
      ]).then(() => setLoadingClients(false))
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <div className="cursor-pointer"
              onClick={() => handleInfo(value)}>
              <span>{value}</span>
            </div>
          )
        }
      },

      {
        Header: "Fecha",
        accessor: "timestamp",
        defaultCanSort: true,
        Cell: ({ value }) => {
          return format(new Date(value), "dd/MM/yyyy");
        },
      },

      {
        Header: "Cliente",
        accessor: "name",
        defaultCanSort: true
      },

      {
        Header: "N° de documento",
        accessor: "id_client",
        disableSortBy: true
      },

      {
        Header: "País",
        accessor: data => {
          return countries.map(c => {
            if (c.iso_code === data.country) {
              return c.name
            }
          })
        }
      },

      {
        Header: "Observaciones",
        accessor: 'note',
        disableSortBy: true
      },

      {
        Header: "Acciones",
        disableSortBy: true,
        accessor: client => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              {(isSellerEditor() || isAdmin()) &&
                <span
                  id={"editClientButton" + client.id}
                  className="actions"
                  onMouseEnter={() => showTooltip('editClientButton' + client.id, `Editar cliente #${client.id}`)}
                  onMouseLeave={() => hideTooltip('editClientButton' + client.id)}
                  onClick={() => handleEdit(client.id)}
                >
                  <Edit />
                </span>
              }
            </div>
          );
        }
      },
    ],
    [countries]
  )

  const handleEdit = id => {
    showModal('editClientModal')
    setShowClient(parseInt(id))
  }

  const handleInfo = (id) => {
    showModal('viewClientModal')
    setShowClient(parseInt(id))
  }

  return (
    <>

      <PrincipalBar />

      <div className="mx-2">

        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
          <FirstTitle text="Clientes" />
          <ButtonBack destination="/inicio" text="VOLVER" />
        </div>

        <div className="modal fade" id="viewClientModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-info" id="staticBackdropLabel">Cliente #{showClient}</h3>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                  hideModal('viewClientModal')
                  document.getElementById('newPhoneDiv').classList.add('d-none')
                  document.getElementById('newPhoneButton').classList.remove('d-none')
                  document.getElementById('newPhoneForm').reset()
                  setNewPhoneContact({})
                  document.getElementById('newEmailDiv').classList.add('d-none')
                  document.getElementById('newEmailButton').classList.remove('d-none')
                  document.getElementById('newEmailForm').reset()
                  setNewEmailContact({})
                  document.getElementById('newCSDDiv').classList.add('d-none')
                  document.getElementById('newCSDButton').classList.remove('d-none')
                  document.getElementById('newCSDForm').reset()
                  setNewClientShippingData({})
                  val.hideMessages()
                  forceUpdate(2)
                  setShowClient(null)
                }}></button>
              </div>
              <div className="modal-body">
                <ViewClient
                  client={clients?.filter(c => c.id === showClient)[0] || []}
                  typeIdClient={typeIdClient}
                  phoneContacts={phoneContacts}
                  emailContacts={emailContacts}
                  setEmailContacts={setEmailContacts}
                  countries={countries}
                  typePhone={typePhone}
                  phoneProviders={phoneProviders}
                  clientShippingData={clientShippingData}
                  setClientShippingData={(setClientShippingData)}
                  newEmailContact={newEmailContact}
                  setNewEmailContact={setNewEmailContact}
                  newPhoneContact={newPhoneContact}
                  setNewPhoneContact={setNewPhoneContact}
                  setPhoneContacts={setPhoneContacts}
                  val={val}
                  forceUpdate={forceUpdate}
                  newClientShippingData={newClientShippingData}
                  setNewClientShippingData={setNewClientShippingData}
                  clientBillingData={clientBillingData}
                  setClientBillingData={setClientBillingData}
                  newClientBillingData={newClientBillingData}
                  setNewClientBillingData={setNewClientBillingData}
                />
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="btn btn-secondary" onClick={() => {
                  hideModal('viewClientModal')
                  document.getElementById('newPhoneDiv').classList.add('d-none')
                  document.getElementById('newPhoneButton').classList.remove('d-none')
                  document.getElementById('newPhoneForm').reset()
                  setNewPhoneContact({})
                  document.getElementById('newEmailDiv').classList.add('d-none')
                  document.getElementById('newEmailButton').classList.remove('d-none')
                  document.getElementById('newEmailForm').reset()
                  setNewEmailContact({})
                  document.getElementById('newCSDDiv').classList.add('d-none')
                  document.getElementById('newCSDButton').classList.remove('d-none')
                  document.getElementById('newCSDForm').reset()
                  setNewClientShippingData({})
                  val.hideMessages()
                  forceUpdate(2)
                  setShowClient(null)
                }}>CERRAR</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="newClient" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-info" id="staticBackdropLabel">Alta de cliente</h3>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                  hideModal('newClient')
                  document.getElementById('clientForm').reset();
                  val.hideMessages();
                  setNewClient({})
                  setNewClientBillingData({})
                  forceUpdate(2)
                }}></button>
              </div>
              <div>
                <CreateClient
                  hideModal={hideModal}
                  countries={countries}
                  typeIdClient={typeIdClient}
                  val={val}
                  newClient={newClient}
                  setNewClient={setNewClient}
                  newClientBillingData={newClientBillingData}
                  setNewClientBillingData={setNewClientBillingData}
                  forceUpdate={forceUpdate}
                  newPhoneContact={newPhoneContact}
                  setNewPhoneContact={setNewPhoneContact}
                  newEmailContact={newEmailContact}
                  setNewEmailContact={setNewEmailContact}
                  clients={clients}
                  setClients={setClients}
                  phoneContacts={phoneContacts}
                  setPhoneContacts={setPhoneContacts}
                  clientBillingData={clientBillingData}
                  setClientBillingData={setClientBillingData}
                  fromClients={'true'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="editClientModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title text-info" id="staticBackdropLabel">Editar cliente #{showClient}</h3>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                  hideModal('editClientModal')
                  val.hideMessages();
                  forceUpdate(2)
                  setShowClient(null)
                }}></button>
              </div>
              <div className="modal-body">
                <EditClient
                  client={showClient}
                  countries={countries}
                  hideModal={hideModal}
                  typeIdClient={typeIdClient}
                  clients={clients}
                  setClients={setClients}
                  clientBillingData={clientBillingData}
                  setClientBillingData={setClientBillingData}
                  val={val}
                  forceUpdate={forceUpdate}
                  setShowClient={setShowClient}
                />
              </div>
            </div>
          </div>
        </div>

        {loadingClients ?
          <div className="container text-center">
            <Loader />
            <strong className="d-block mt-3">Cargando clientes...</strong>
          </div> :
          clients?.length === 0 ?
            <div className="container text-center">
              <strong className="d-block mt-3">No hay clientes registrados</strong>
              {(isAdmin() || isSellerEditor()) &&
                <button
                  type="button"
                  className="btn btn-info bg-white text-info mt-2"
                  onClick={() => showModal('newClient')}
                >
                  NUEVO
                </button>
              }
            </div> :
            <TableClients
              columns={columns}
              data={clients || []}
              isSellerEditor={isSellerEditor()}
              isAdmin={isAdmin()}
              showModal={showModal}
              hideModal={hideModal}
              countries={countries}
              typeIdClient={typeIdClient}
              clients={clients}
              setClients={setClients}
              val={val}
              forceUpdate={forceUpdate}
              clientBillingData={clientBillingData}
              setClientBillingData={setClientBillingData}
            />
        }

      </div>
    </>
  );
}
