import React, { useContext } from "react";
import { format } from "date-fns";
import { BiDownArrow } from 'react-icons/bi'

import { hideModal } from "../../Utilities/General/BootstrapApi";
import OrderContext from "../../Contexts/OrderContext";

export default function OrdersPipeline({
    orderId
}) {

    const { orders } = useContext(OrderContext)

    const pipeline = orders?.filter(order => order.id === orderId)[0]?.orderstatus_set

    return (
        <div className="modal fade" id="orderPipeline" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="text-info">Historial de la orden #{orderId}</h3>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => hideModal('orderPipeline')}></button>
                    </div>
                    <div className="modal-body" id="pipelineContainer">
                        {pipeline?.sort((a, b) => a.id - b.id).map((os, idx) => {
                            return (
                                <div key={os.id} className="text-center">
                                    <div className="rounded-pill p-3 m-2 mx-auto col-lg-6 shadow bg-light">
                                        <span className="bg-gradient text-white p-1 rounded-pill mb-2 d-block w-50 mx-auto">
                                            {os.type_order_status_spanish_name}
                                        </span>
                                        <p className="m-0">Por <strong>{os.user_name}</strong></p>
                                        <p>{format(new Date(os.timestamp), "dd/MM/yyyy HH:mm")} hs</p>
                                        {os.type_order_status_spanish_name === 'Anulada' &&
                                            <p>
                                                <strong>Observaciones</strong>
                                                <span className="d-block multiline">
                                                    {os.observation}
                                                </span>
                                            </p>
                                        }
                                    </div>
                                    {idx < (pipeline.length - 1) &&
                                        <div className="text-light fs-2">
                                            <BiDownArrow />
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => hideModal('orderPipeline')}>CERRAR</button>
                    </div>
                </div>
            </div>
        </div>
    )

}