import { FiCopy } from "react-icons/fi"

import { copy } from '../../Utilities/General/Clipboard'

export default function BillingDataDetails({
    order,
    billingData,
    countries,
    currency,
    clients,
    customers
}) {

    const [client] = clients?.filter(c => c.id === order?.new_client)
    const [customer] = customers?.filter(c => c.id === parseInt(order?.payments_service_customer_id))

    return (
        <div className="my-5">
            <h4 className="text-info">Datos de facturación</h4>
            <table className="table table-hover">
                <tbody>
                    <tr>
                        <th>Razón Social</th>
                        <td>
                            {billingData?.name}
                            <FiCopy
                                id="bdNameTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdNameTT', billingData?.name)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>N° Documento</th>
                        <td>
                            {client?.id_client || customer?.shipping.recipient_id}
                            <FiCopy
                                id="bdDniTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdDniTT', client?.id_client || customer?.shipping.recipient_id)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>CUIT / CUIL / RUT</th>
                        <td>
                            {billingData?.vat_number}
                            <FiCopy
                                id="bdVatTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdVatTT', billingData?.vat_number)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Persona</th>
                        <td>
                            {billingData?.person}
                            <FiCopy
                                id="dbPersonTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('dbPersonTT', billingData?.person)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Dirección</th>
                        <td>
                            {billingData?.address}
                            <FiCopy
                                id="dbAddressTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('dbAddressTT', billingData?.address)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Piso.</th>
                        <td>
                            {billingData?.address_floor_apartment}
                            {billingData?.address_floor_apartment !== null &&
                                <FiCopy
                                    id="bdFloorTT"
                                    className="ms-2 only-cursor-pointer"
                                    onClick={() => copy('bdFloorTT', billingData?.address_floor_apartment)}
                                />
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>Dpto.</th>
                        <td>
                            {billingData?.address_apartment}
                            {billingData?.address_apartment !== null &&
                                <FiCopy
                                    id="bdFloorTT"
                                    className="ms-2 only-cursor-pointer"
                                    onClick={() => copy('bdFloorTT', billingData?.address_apartment)}
                                />
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>Ciudad</th>
                        <td>
                            {billingData?.address_city}
                            <FiCopy
                                id="bdCityTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdCityTT', billingData?.address_city)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>CP</th>
                        <td>
                            {billingData?.address_postal_code}
                            <FiCopy
                                id="bdPostalTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdPostalTT', billingData?.address_postal_code)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Provincia / Región / Estado</th>
                        <td>
                            {billingData?.address_province}
                            <FiCopy
                                id="bdProvinceTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdProvinceTT', billingData?.address_province)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>País</th>
                        <td>
                            {countries?.filter(c => c.id === billingData?.address_country)[0]?.name}
                            <FiCopy
                                id="bdCountryTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdCountryTT', countries?.filter(c => c.id === billingData?.address_country)[0]?.name)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>CUIT País</th>
                        <td>
                            {billingData?.country_cuit_afip}
                            <FiCopy
                                id="bdAfipTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdAfipTT', billingData?.country_cuit_afip)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Monto y moneda</th>
                        <td>
                            {`${billingData?.amount} ${currency}`}
                            <FiCopy
                                id="bdAmountTT"
                                className="ms-2 only-cursor-pointer"
                                onClick={() => copy('bdAmountTT', `${billingData?.amount} ${currency}`)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}