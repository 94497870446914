import React from 'react';

import Edit from '../../../Assets/Edit';

import { hideModal } from '../../../Utilities/General/BootstrapApi';

export default function AdminManufacturer({
    manufacturers,
    newManufacturer,
    setNewManufacturer,
    val,
    forceUpdate,
    handleSubmit,
    workOn,
    setWorkOn
}) {

    const handleChange = e => {
        setNewManufacturer({
            ...newManufacturer,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="modal fade" id="manufacturers" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h3 className="modal-title text-info" id="staticBackdropLabel">Administrar Fabricantes</h3>
                        <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => {
                                hideModal('manufacturers')
                                setNewManufacturer({})
                                setWorkOn(null)
                                document.getElementById('newManufacturerForm').reset()
                            }}></button>
                    </div>
                    <div className="modal-body">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {manufacturers?.map(man => (
                                    <tr key={man.id}>
                                        {workOn === man.id ?
                                            <>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='name'
                                                    defaultValue={man.name}
                                                    onChange={e => handleChange(e)}
                                                />
                                                {val.current.message('nameManAdminEdit',
                                                    newManufacturer.name,
                                                    'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&=<>+]|max:200',
                                                    { className: 'text-danger' })
                                                }
                                                <small id='adminWarning' className='text-danger d-none'>
                                                    * El dato ingresado no es válido
                                                </small>
                                            </> :
                                            <td className='border-0'>
                                                {man.name}
                                            </td>
                                        }
                                        <td className="border-0">
                                            {workOn === man.id ?
                                                <>
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-info bg-white text-info'
                                                        onClick={() => {
                                                            if (!val.current.fieldValid('nameManAdminEdit')) {
                                                                document.getElementById('adminWarning').classList.remove('d-none')
                                                            } else {
                                                                document.getElementById('adminWarning').classList.add('d-none')
                                                                handleSubmit('edit')
                                                            }
                                                        }}
                                                    >
                                                        Guardar
                                                    </button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-info bg-white text-info ms-2'
                                                        onClick={() => {
                                                            setWorkOn(null)
                                                            document.getElementById('adminWarning').classList.add('d-none')
                                                        }}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </> :
                                                <span className='actions' onClick={() => setWorkOn(man.id)}>
                                                    <Edit />
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <h4 className='text-info'>Nuevo</h4>
                        <form id='newManufacturerForm'>
                            <div className='form-group'>
                                <label htmlFor='name'>Nombre</label>
                                <input
                                    type='text'
                                    name='name'
                                    className='form-control mt-1'
                                    onChange={e => handleChange(e)}
                                />
                                {val.current.message('nameManAdmin',
                                    newManufacturer.name,
                                    'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&=<>+]|max:200',
                                    { className: 'text-danger' })
                                }
                            </div>
                            <button
                                id="submit_manufacturer"
                                type='button'
                                className='btn btn-sm btn-info text-white mt-2'
                                onClick={() => {
                                    if (!val.current.fieldValid('nameManAdmin')) {
                                        val.current.showMessageFor('nameManAdmin')
                                        forceUpdate(1)
                                    } else {
                                        handleSubmit('create')
                                    }
                                }}
                            >
                                GUARDAR
                            </button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            hideModal('manufacturers')
                            setNewManufacturer({})
                            setWorkOn(null)
                            document.getElementById('newManufacturerForm').reset()
                        }}>CERRAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}