import React from 'react'

import Loader from '../../../AppComponents/Commons/Loader'
import TableCalibrationsService from '../../../AppComponents/Calibrations/TableCalibrationsService'

export default function GroupsFilter({
    filteredRows,
    typeRef,
    filterGroups,
    calibration_typeRef,
    connection_typeRef,
    resetFilters,
    loadingGroups,
    columnsCalGroups,
    calGroups,
    calBuild,
    showModal
}) {
    return (
        <div className="bg-light-green mb-4">
            <div className="bg-light-green p-3 pt-4 pb-1">
                <h5 className="text-success">Filtrar por</h5>
            </div>
            <div className="card-body d-flex justify-content-between pt-0 flex-wrap">
                <div className="col-12 mx-auto col-md-3 mx-md-0 col-lg-2">
                    <strong className="d-block mb-1">Tipo de emisión</strong>
                    {filteredRows ?
                        <select className="form-select" ref={typeRef} onChange={() => filterGroups()}>
                            <option value="all">Todos</option>
                            <option value="pure_tone">pure_tone</option>
                            <option value="logo">logo</option>
                        </select> :
                        <select className="form-select" disabled>
                            <option value="all">Todos</option>
                        </select>
                    }
                </div>
                <div className="col-12 mx-auto my-2 col-md-3 my-md-0 mx-md-0 col-lg-2">
                    <strong className="d-block mb-1">Tipo de calibración</strong>
                    {filteredRows ?
                        <select className="form-select" ref={calibration_typeRef} onChange={() => filterGroups()}>
                            <option value="all">Todos</option>
                            <option value="anc_air">anc_air</option>
                            <option value="anc_air_words_emission">anc_air_words_emission</option>
                            <option value="anc_narrow_band_pink_noise">anc_narrow_band_pink_noise</option>
                            <option value="anc_narrow_band_white_noise">anc_narrow_band_white_noise</option>
                            <option value="anc_pink_noise">anc_pink_noise</option>
                            <option value="anc_speech_test_pink_noise">anc_speech_test_pink_noise</option>
                            <option value="anc_speech_test_white_noise">anc_speech_test_white_noise</option>
                            <option value="anc_white_noise">anc_white_noise</option>
                            <option value="narrow_band_pink_noise">narrow_band_pink_noise</option>
                            <option value="narrow_band_white_noise">narrow_band_white_noise</option>
                            <option value="pasive_air">pasive_air</option>
                            <option value="passive_air_words_emission">passive_air_words_emission</option>
                            <option value="pink_noise">pink_noise</option>
                            <option value="speech_test_pink_noise">speech_test_pink_noise</option>
                            <option value="speech_test_white_noise">speech_test_white_noise</option>
                            <option value="white_noise">white_noise</option>
                            <option value="bone_conduction">bone_conduction</option>
                            <option value="bc_narrow_band_noise">bc_narrow_band_noise</option>
                        </select> :
                        <select className="form-select" disabled>
                            <option value="all">Todos</option>
                        </select>
                    }
                </div>
                <div className="col-12 mx-auto col-md-3 mx-md-0 col-lg-2">
                    <strong className="d-block mb-1">Tipo de conexión</strong>
                    {filteredRows ?
                        <select className="form-select" ref={connection_typeRef} onChange={() => filterGroups()}>
                            <option value="all">Todos</option>
                            {/* <option value="wired_headphone">wired_headphone</option>
                            <option value="insert_headphone">insert_headphone</option>
                            <option value="wired_bone_transducer">wired_bone_transducer</option>
                            <option value="bluetooth_bone_tranducer">bluetooth_bone_tranducer</option>
                            <option value="bluetooth_dac_headphone">bluetooth_dac_headphone</option>
                            <option value="bluetooth_dac_bone_transducer">bluetooth_dac_bone_transducer</option>
                            <option value="usb_dac_headphone">usb_dac_headphone</option>
                            <option value="usb_dac_bone_transducer">usb_dac_bone_transducer</option>
                            <option value="bluetooth_headphone">bluetooth_headphone</option> */}
                            <option value="wired_headphone">wired_headphone</option>
                            <option value="bluetooth_dac">bluetooth_dac</option>
                            <option value="usb_dac">usb_dac</option>
                            <option value="bluetooth_headphone">bluetooth_headphone</option>
                        </select> :
                        <select className="form-select" disabled>
                            <option value="all">Todos</option>
                        </select>
                    }
                </div>
                <div className="col-12 mt-3 mx-auto col-md-5 col-lg-3 mt-lg-0 mx-lg-0 d-flex align-items-end">
                    {filteredRows ?
                        <button type="button" className="btn btn-success text-white d-block mx-auto w-100" onClick={() => resetFilters()}>
                            REINICIAR FILTROS
                        </button> :
                        <button type="button" className="btn btn-success text-white d-block mx-auto w-100" disabled>
                            REINICIAR FILTROS
                        </button>
                    }
                </div>
            </div>
            <div className="p-3">
                {loadingGroups === 'newCal' &&
                    <div className="text-center">
                        <strong>Cargando nuevos datos...</strong>
                    </div>
                }
                {loadingGroups ?
                    <Loader /> :
                    <TableCalibrationsService
                        columns={columnsCalGroups}
                        data={calGroups || []}
                        length={calGroups?.length}
                        filteredRows={filteredRows}
                        built={calBuild?.built}
                        showModal={showModal}
                        typeRef={typeRef.current !== undefined && typeRef.current !== null ? typeRef.current.value : ''}
                        calibration_typeRef={calibration_typeRef.current !== undefined && calibration_typeRef.current !== null ? calibration_typeRef.current.value : ''}
                        connection_typeRef={connection_typeRef.current !== undefined && connection_typeRef.current !== null ? connection_typeRef.current.value : ''}
                        pageSizeProp={10}
                    />
                }
            </div>
        </div>
    )
}