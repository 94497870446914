import { useSortBy, useTable, usePagination, useGlobalFilter } from "react-table";

import { GlobalFilterMovements } from "./GlobalFilterMovements";
import MovementsFilter from "./MovementsFilter";

function TableInventory({
    columns,
    data,
    hideFilter,
    movementsFilter,
    articleSettings, pageSize = 10,
    originalMovementsToDisplay,
    setMovementsToDisplay,
    suppliers,
    manufacturers,
    // processMovements,
    // movements,
    items,
    inputProducts
}) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,
        setGlobalFilter,
        pageOptions,
        gotoPage,
        pageCount
    } = useTable({
        columns,
        data,
        initialState: {
            pageSize: pageSize,
            defaultCanSort: true,
            pageIndex: 0
        },
    }, useGlobalFilter, useSortBy, usePagination)

    const { globalFilter } = state;

    const toggleObservation = idx => {
        var element = document.getElementById('obs' + idx);
        if (element.classList.contains('inventory-td')) {
            element.classList.remove('inventory-td');
        } else {
            element.classList.add('inventory-td');
        }
    }

    return (
        <>

            <div className="row d-flex flex-wrap mx-1 mb-2">
                {!hideFilter &&
                    <div className="col-lg-5">
                        <GlobalFilterMovements filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                }
                {(movementsFilter === 'true') &&
                    <MovementsFilter
                        originalMovementsToDisplay={originalMovementsToDisplay}
                        setMovementsToDisplay={setMovementsToDisplay}
                        suppliers={suppliers}
                        manufacturers={manufacturers}
                        // processMovements={processMovements}
                        // movements={movements}
                        items={items}
                        inputProducts={inputProducts}
                    />
                }
            </div>


            <div className="md-overflow-scroll">
                <table className="table table-hover text-center" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="inventory-th"
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' ↓'
                                                    : ' ↑'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page?.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === 'Observaciones' && cell.value !== null && cell.value.length > 40) {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                >
                                                    <div id={'obs' + cell.row.index}
                                                        className="inventory-td float-left text-center">
                                                        {cell.render('Cell')}
                                                    </div>
                                                    <span className="text-info cursor-pointer"
                                                        onClick={() => toggleObservation(cell.row.index)}>
                                                        &#9650;
                                                        &#9660;
                                                    </span>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                <div className={!articleSettings ? "text-center" : ""}>
                                                    {cell.render('Cell')}
                                                </div>
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                {pageCount > 1 &&
                    <div className="my-5 d-flex justify-content-center">
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-info btn-sm text-white">
                            &#10096;&#10096;&#10096;
                        </button>
                        <div className="d-inline-block mx-1">
                            {pageCount > 5 ?
                                <div className="mx-1 d-flex align-items-center">
                                    <small>Página</small>
                                    <select className="form-select mx-1" onChange={(e) => gotoPage(parseInt(e.target.value))}>
                                        {pageOptions.map((item, idx) => {
                                            if (parseInt(item) === parseInt(state.pageIndex)) {
                                                return <option key={idx} value={parseInt(item)} selected>{parseInt(item) + 1}</option>
                                            } else {
                                                return <option key={idx} value={parseInt(item)}>{parseInt(item) + 1}</option>
                                            }
                                        })}
                                    </select>
                                    <small>de<span className="ms-1">{pageCount}</span></small>
                                </div> :
                                <div className="mx-1 d-flex flex-wrap justify-content-center">
                                    {pageOptions.map((item, idx) => {
                                        return <span key={idx}
                                            className={item === state.pageIndex ? "m-1 text-white bg-info p-1 rounded" : "m-2 text-info cursor-pointer"}
                                            onClick={() => { gotoPage(idx) }}>{parseInt(item) + 1}</span>
                                    })}
                                </div>
                            }
                        </div>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-info btn-sm text-white">
                            &#10097;&#10097;&#10097;
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default TableInventory;