import { useEffect, useState } from "react";

import UsoundPaymentsService from '../../Services/UsoundPayments/UsoundPaymentsService'

export default function useUsoundPaymentsProducts() {

    const [products, setProducts] = useState([])

    useEffect(() => {
        (async () => {
            const data = await UsoundPaymentsService.getProducts()
            setProducts(data)
        })()
    }, [])

    return products
}