import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import OrderContext from './Contexts/OrderContext';
import Router from './AppComponents/Router';
import './Styles/App.css'

function App() {

  // PROPIEDADES DE ESTADO DEL CONTEXTO DE ORDENES
  const [orders, setOrders] = useState([])
  const [nextPage, setNextPage] = useState([])
  const [totalRegisters, setTotalRegisters] = useState([])
  const [previousPage, setPreviousPage] = useState([])
  const [pageSize, setPageSize] = useState(10)

  // CIERRE DEL CARTEL INDICADOR DE ENTORNO EN DESARROLLO Y TESTING
  const closeSign = env => {
    document.getElementById(env + '-environment').classList.add('d-none');
    if (env === 'testing') {
      setInterval(() => {
        document.getElementById(env + '-environment').classList.remove('d-none');
      }, 10000);
    }
  }

  return (

    <>

      {/* CARTELES INDICADORES DE ENTORNO */}
      {process.env.REACT_APP_ENVIRONMENT === 'testing' &&
        <>
          <div id="testing-environment" className="text-white rounded p-2 position-fixed">
            <p className="mb-0 d-flex justify-content-end">
              <span onClick={() => closeSign('testing')} className="cursor-pointer">X</span>
            </p>
            <strong>
              ENTORNO <br />
              DE <br />
              PRUEBAS
            </strong>
          </div>
        </>
      }
      {process.env.REACT_APP_ENVIRONMENT === 'development' &&
        <div id="development-environment" className="text-white rounded p-2 position-fixed">
          <p className="mb-0 d-flex justify-content-end">
            <span onClick={() => closeSign('development')} className="cursor-pointer">X</span>
          </p>
          <strong>
            ENTORNO <br />
            DE <br />
            DESARROLLO
          </strong>
        </div>
      }

      {/* CONTEXTO DE ORDENES COMERCIALES */}
      <OrderContext.Provider value={{
        orders,
        setOrders,
        nextPage,
        setNextPage,
        previousPage,
        setPreviousPage,
        totalRegisters,
        setTotalRegisters,
        pageSize,
        setPageSize
      }} >
        {/* ROUTER */}
        <Router />
      </OrderContext.Provider>
      {/* CONTAINER DE TOASTIFY */}
      <ToastContainer />

    </>

  );

}

export default App;




