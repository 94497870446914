import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { hideModal } from '../../../Utilities/General/BootstrapApi';

export default function EditSaleProduct({
    saleProducts,
    workOn,
    val,
    typesInputProduct,
    newRequirement,
    setNewRequirement,
    newSaleProduct,
    setNewSaleProduct,
    requirements,
    setRequirements,
    notAllowed,
    setNotAllowed,
    forceUpdate,
    handleSubmit,
    articles,
    setEditArticle
}) {

    useEffect(() => {
        if (workOn !== null) {
            let [nsp] = saleProducts.filter(item => item.id === parseInt(workOn))
            setNewSaleProduct(nsp)
            if (nsp !== undefined) {
                let [art] = articles.filter(item => item.sku === nsp.sku)
                setEditArticle(art)
                setRequirements(nsp.saleproductrequirement_set)
            }
        }
    }, [workOn])

    const handleChange = e => {
        setNewSaleProduct({
            ...newSaleProduct,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeNewRequirement = e => {
        setNewRequirement({
            ...newRequirement,
            [e.target.name]: parseInt(e.target.value)
        })
    }

    const addNewRequirement = () => {
        if (newRequirement.type_input_product === 0 ||
            newRequirement.type_input_product === "" ||
            newRequirement.quantity < 1 ||
            newRequirement.quantity > 100 ||
            document.getElementById('inputTypeEdit').value === "" ||
            document.getElementById('inputQuantityEdit').value === "" ||
            Object.keys(newRequirement).length === 0) {
            setNotAllowed(true)
        } else {
            setNotAllowed(false)
            setRequirements([
                ...requirements,
                newRequirement
            ]);
            document.getElementById('inputTypeEdit').value = ""
            document.getElementById('inputQuantityEdit').value = ""
            setNewRequirement({
                type_input_product: 0,
                quantity: 0
            })
        }
    }

    useEffect(() => {
        setNewSaleProduct({
            ...newSaleProduct,
            saleproductrequirement_set: requirements
        })
    }, [requirements])

    return (
        <>

            {newSaleProduct !== undefined &&

                <div className="modal fade" id="editSaleProduct" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                    setNewRequirement({})
                                    setRequirements([])
                                    document.getElementById('editSaleProductForm').reset()
                                    hideModal('editSaleProduct')
                                }}></button>
                            </div>
                            <div className="modal-body">

                                <form id="editSaleProductForm">
                                    <div className="text-center bg-info p-1 mb-2">
                                        <p className="text-white mb-1">Editar producto</p>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="sku">SKU</label>
                                        <input
                                            type="text"
                                            name="sku"
                                            className="form-control"
                                            defaultValue={newSaleProduct.sku}
                                            onChange={e => handleChange(e)}
                                        />
                                        {val.current.message('skuSPedit',
                                            newSaleProduct.sku,
                                            'required|regex:[A-Z0-9_]|max:50',
                                            { className: 'text-danger' })}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="nameSaleProduct">Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            defaultValue={newSaleProduct.name}
                                            onChange={e => handleChange(e)}
                                        />
                                        {val.current.message('nameSPedit',
                                            newSaleProduct.name,
                                            'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&/()=<>+-]|max:200',
                                            { className: 'text-danger' })}
                                    </div>
                                </form>

                                <div className="mb-3 text-center">
                                    <label htmlFor="newRequirement">Accesorios</label>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tipo</th>
                                                <th>Cantidad</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requirements.length === 0 ?
                                                <tr>
                                                    <td colSpan="3"><strong>No hay accesorios agregados.</strong></td>
                                                </tr> :
                                                requirements.map((item, idx) => {
                                                    return <tr key={idx}>
                                                        <td>
                                                            {idx + 1}
                                                        </td>
                                                        <td >
                                                            {typesInputProduct.map(type => {
                                                                if (item.type_input_product === type.id) {
                                                                    return type.name
                                                                }
                                                            })}
                                                        </td>
                                                        <td>
                                                            {item.quantity}
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger btn-sm text-white" onClick={() => {
                                                                setRequirements(requirements.filter(req => {
                                                                    return req.type_input_product !== item.type_input_product;
                                                                }))
                                                            }}>
                                                                <strong>X</strong>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between">
                                        {requirements.length === typesInputProduct?.length ?
                                            <select id="inputTypeEdit" className="form-select" disabled></select> :
                                            <select id="inputTypeEdit" name="type_input_product" className="form-select"
                                                onChange={e => handleChangeNewRequirement(e)}>
                                                <option value="">Seleccione</option>
                                                {typesInputProduct?.map(item => {
                                                    let flag = 0;
                                                    requirements.map(json => {
                                                        if (json.type_input_product === item.id) {
                                                            flag = 1
                                                        }
                                                    })
                                                    if (flag === 0) {
                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                    }
                                                })}
                                            </select>
                                        }
                                        {requirements.length === typesInputProduct?.length ?
                                            <input
                                                id="inputQuantityEdit"
                                                type="number"
                                                className="form-control mx-3"
                                                value=""
                                                disabled
                                            /> :
                                            <input
                                                id="inputQuantityEdit"
                                                type="number"
                                                min="1"
                                                name="quantity"
                                                placeholder="Cantidad"
                                                className="form-control mx-3"
                                                onChange={e => handleChangeNewRequirement(e)}
                                            />
                                        }
                                        {requirements.length === typesInputProduct?.length ?
                                            <button
                                                type="button"
                                                className="btn btn-info btn-sm text-white"
                                                disabled>
                                                Agregar
                                            </button> :
                                            <button
                                                type="button"
                                                className="btn btn-info btn-sm text-white"
                                                onClick={addNewRequirement}>
                                                Agregar
                                            </button>
                                        }
                                    </div>
                                    {notAllowed &&
                                        <small className="text-danger d-block mt-3">
                                            * Verifique datos: <br />
                                            - El tipo es requerido. <br />
                                            - La cantidad es requerida. <br />
                                            - Ingrese una cantidad entre 1 y 100.
                                        </small>
                                    }
                                </div>

                                <div className="d-flex justify-content-around">
                                    <button type="button" className="btn btn-info text-white" onClick={e => {
                                        if (newSaleProduct.saleproductrequirement_set.length === 0) {
                                            toast.warning('No hay accesorios agregados', {
                                                position: 'bottom-left',
                                                hideProgressBar: true,
                                                className: 'warning-toast'
                                            })
                                        } else {
                                            if (val.current.fieldValid('nameSaleProduct') &&
                                                val.current.fieldValid('sku')) {
                                                document.getElementById('inputTypeEdit').value = ""
                                                document.getElementById('inputQuantityEdit').value = ""
                                                handleSubmit()
                                            } else {
                                                val.current.showMessages()
                                                forceUpdate(1)
                                            }
                                        }
                                    }}>
                                        GUARDAR
                                    </button>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    setNewRequirement({})
                                    setRequirements([])
                                    document.getElementById('editSaleProductForm').reset()
                                    hideModal('editSaleProduct')
                                }}>CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}
