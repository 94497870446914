import React from "react";

export default function CreatePhoneContact({
    client,
    typePhone,
    phoneProviders,
    newPhoneContact,
    setNewPhoneContact,
    val,
    forceUpdate,
    handleSubmit
}) {

    const handleChange = e => {
        setNewPhoneContact({
            ...newPhoneContact,
            client: client,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="col-lg-8 mx-auto border border-muted rounded p-4">

            <h3 className="text-info text-center">Nuevo teléfono</h3>

            <form id="newPhoneForm">

                <div className="d-flex flex-wrap justify-content-between mt-3">
                    <div className="form-group col-12 col-lg-4">
                        <label htmlFor="type_phone">Tipo</label>
                        <select name="type_phone" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            {typePhone?.map((tp, tpIndex) => {
                                return <option key={tpIndex} value={tp.id}>{tp.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group col-12 col-lg-4 my-2 my-lg-0">
                        <label htmlFor="phone_provider">Proveedor</label>
                        <select name="phone_provider" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            {phoneProviders?.map((pp, ppIndex) => {
                                return <option key={ppIndex} value={pp.id}>{pp.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group col-12 col-lg-4">
                        <label htmlFor="line">Línea</label>
                        <select name="line" className="form-select" onChange={e => handleChange(e)}>
                            <option value="">Seleccione</option>
                            <option value="Móvil">Móvil</option>
                            <option value="Fija">Fija</option>
                        </select>
                    </div>
                </div>

                <div className="d-flex flex-wrap justify-content-between my-lg-3">
                    <div className="form-group col-12 col-lg-4 my-3 my-lg-0">
                        <label htmlFor="country_code">Cód. País</label>
                        <input type="text" name="country_code" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('country_code', newPhoneContact.country_code, 'numeric|not_regex:[-]|max:5', { className: 'text-danger' })}
                    </div>
                    <div className="form-group col-12 col-lg-4">
                        <label htmlFor="area_code">Cód. Área</label>
                        <input type="text" name="area_code" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('area_code', newPhoneContact.area_code, 'numeric|not_regex:[-]|max:10', { className: 'text-danger' })}
                    </div>
                    <div className="form-group col-12 col-lg-4 my-2 my-lg-0">
                        <label htmlFor="number">Número</label>
                        <input type="text" name="number" className="form-control" onChange={e => handleChange(e)} />
                        {val.message('number', newPhoneContact.number, 'numeric|not_regex:[-]|max:150', { className: 'text-danger' })}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="note">Observaciones</label>
                    <textarea name="note" className="textarea form-control" onChange={e => handleChange(e)}></textarea>
                    {val.message('phoneNote', newPhoneContact.note, 'max:250', { className: 'text-danger' })}
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn btn-sm bg-white text-info border border-info"
                        onClick={() => {
                            document.getElementById('newPhoneDiv').classList.add('d-none')
                            document.getElementById('newPhoneButton').classList.remove('d-none')
                            document.getElementById('newPhoneForm').reset()
                            setNewPhoneContact({})
                            val.hideMessages()
                            forceUpdate(2)
                        }}
                    >
                        CANCELAR
                    </button>
                    <button
                        id="submit_btn_phone_contact"
                        type="button"
                        className="btn btn-sm btn-info text-white ms-2"
                        onClick={handleSubmit}
                    >
                        GUARDAR
                    </button>
                </div>

            </form>
        </div>
    )
}