import React from 'react';

import { hideModal } from '../../../Utilities/General/BootstrapApi';

export default function AdminItems({
    inputProducts,
    items
}) {

    return (
        <div className="modal fade" id="items" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h3 className="modal-title text-info" id="staticBackdropLabel">N° de serie y lote registrados</h3>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                            hideModal('items')
                        }}></button>
                    </div>
                    <div className="modal-body">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Artículo</th>
                                    <th>Números</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inputProducts?.sort(function (a, b) {
                                    if (a.name < b.name) { return -1; }
                                    if (a.name > b.name) { return 1; }
                                    return 0;
                                }).map(ip => {
                                    return (
                                        <tr key={ip.id}>
                                            <td>{ip.name}</td>
                                            <td>
                                                {items?.map(it => {
                                                    if (ip.id === it.input_product) {
                                                        return (
                                                            <span key={it.id} className='d-block'>
                                                                {it.serial_number}
                                                            </span>
                                                        )
                                                    }
                                                })}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            hideModal('items')
                        }}>CERRAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}