import React from 'react';

export default function CalibrationsCard() {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
                <g id="calibre" transform="translate(0.25 0.25)">
                    <g id="Grupo_1055" data-name="Grupo 1055">
                        <g id="Grupo_1054" data-name="Grupo 1054">
                            <path id="Trazado_625" data-name="Trazado 625" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm0,23A11.016,11.016,0,0,1,1.42,15H8.536A4.2,4.2,0,0,0,12,17a4.2,4.2,0,0,0,3.464-2H22.58A11.016,11.016,0,0,1,12,23Zm3.187-9a.5.5,0,0,0-.431.247A3.271,3.271,0,0,1,12,16a3.271,3.271,0,0,1-2.755-1.753A.5.5,0,0,0,8.813,14H1.186a11,11,0,1,1,21.629,0Z" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_626" data-name="Trazado 626" d="M219.224,149.611l-3.106,3.106a2.063,2.063,0,0,0-.7-.129,2.111,2.111,0,1,0,1.572.73l2.97-2.97a.522.522,0,0,0-.738-.738Zm-3.8,6.107a1.043,1.043,0,1,1,1.043-1.043A1.043,1.043,0,0,1,215.42,155.718Z" transform="translate(-203.494 -142.583)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_627" data-name="Trazado 627" d="M245.855,35.13a.522.522,0,0,0,.522-.522V32.522a.522.522,0,1,0-1.043,0v2.087A.522.522,0,0,0,245.855,35.13Z" transform="translate(-233.855 -30.435)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_628" data-name="Trazado 628" d="M418.607,245.333h-2.087a.522.522,0,1,0,0,1.043h2.087a.522.522,0,1,0,0-1.043Z" transform="translate(-396.694 -233.855)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_629" data-name="Trazado 629" d="M34.608,245.333H32.522a.522.522,0,1,0,0,1.043h2.087a.522.522,0,0,0,0-1.043Z" transform="translate(-30.435 -233.855)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_630" data-name="Trazado 630" d="M142.767,66.2l-.887-1.539a.522.522,0,0,0-.9.522l.887,1.539a.522.522,0,0,0,.907-.516Z" transform="translate(-134.328 -61.251)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_631" data-name="Trazado 631" d="M397.375,141.1a.522.522,0,0,0-.711-.2l-.006,0-1.539.887a.522.522,0,0,0,.522.9l1.539-.887A.522.522,0,0,0,397.375,141.1Z" transform="translate(-376.586 -134.26)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_632" data-name="Trazado 632" d="M66.61,141.769l-.006,0-1.539-.887a.522.522,0,0,0-.528.9l.006,0h0l1.539.887a.522.522,0,1,0,.528-.9Z" transform="translate(-61.135 -134.231)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                            <path id="Trazado_633" data-name="Trazado 633" d="M333.322,64.479a.522.522,0,0,0-.708.19l-.887,1.539a.522.522,0,1,0,.9.522l.887-1.539A.522.522,0,0,0,333.322,64.479Z" transform="translate(-316.167 -61.261)" fill="#43eba2" stroke="#43eba2" strokeWidth="0.5" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );

}
