import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { error, errorTimeout, networkError, serverError, expiredSession } from '../../Utilities/General/StatusHandler';

const ManufacturerService = {

    getManufacturers: async () => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlStockManufacturer,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewManufacturer: async newManufacturer => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlStockManufacturer,
                newManufacturer,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateManufacturer: async (id, newManufacturer) => {
        try {
            const { data } = await axios.put(
                GlobalUrls.urlStockManufacturer + `${id}/`,
                newManufacturer,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

}

export default ManufacturerService