import { useState } from "react"
import { format } from "date-fns"
import swal from "sweetalert"

import AddFreemiumFeatures from "./AddFreemiumFeatures"

import { hideModal, showTooltip } from "../../../Utilities/General/BootstrapApi"
import { showModal } from "../../../Utilities/General/BootstrapApi"
import LicenseService from "../../../Services/Licenses/LicenseService"
import ShowNewPassword from "./ShowNewPassword"

export default function ViewLicense({
    serial,
    setSerial,
    licenseData
}) {

    const [newPass, setNewPass] = useState('')

    const handleReset = email => {
        swal({
            title: `¿Reiniciar contraseña con el email ${email}?`,
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar']
        })
            .then(confirm => {
                if (confirm) {
                    LicenseService.resetPassword(email)
                        .then(data => {
                            if (data !== undefined) {
                                setNewPass(data.password)
                                showModal('newPassModal')
                            }
                        })
                }
            })
    }

    return (
        <div className="modal fade" id="viewLicenseModal">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info">
                            N° de serie - {serial?.serial_number}
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => hideModal('viewLicenseModal')}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <table className="table text-center">
                            <thead className="border-dark border-bottom">
                                <th>Versión instalada</th>
                                <th>Calibración asignada</th>
                                <th>Código de servicio</th>
                                <th>Emails</th>
                            </thead>
                            {licenseData !== null &&
                                <tbody>
                                    <tr>
                                        <td>{licenseData.version_name}</td>
                                        <td>{licenseData.calibration_kitname}</td>
                                        <td>
                                            {`UAC${licenseData.calibration_id}_P${licenseData.count_patients}_M${licenseData.count_visits_with_tests}_T${licenseData.count_tests}_S${licenseData.count_surveys}`}
                                        </td>
                                        <td>
                                            {licenseData.activation_many.map(am => {
                                                return (
                                                    <span
                                                        id={'new_pass_TT' + am.id}
                                                        key={am.id} className="d-block my-1 cursor-pointer"
                                                        onMouseEnter={() => showTooltip('new_pass_TT' + am.id, 'Haga click para reiniciar contraseña')}
                                                        onClick={() => handleReset(am.email)}
                                                    >
                                                        {am.email}
                                                    </span>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                        <ShowNewPassword
                            newPass={newPass}
                        />
                        <div className="mt-4 d-flex align-items-center">
                            <h4 className="text-info">
                                Estudios habilitados
                            </h4>
                            <button
                                type="button"
                                className="btn btn-sm btn-info text-white ms-2"
                                onClick={() => showModal('addFF')}
                            >
                                Agregar
                            </button>
                            <AddFreemiumFeatures
                                license={serial}
                                setSerial={setSerial}
                            />
                        </div>
                        {serial?.modelfreemium_set.length === 0 ?
                            <strong className="d-block w-100 text-center mt-3">
                                No hay datos que mostrar.
                            </strong> :
                            <table className="table table-hover text-center">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Vencimiento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serial?.modelfreemium_set.map(mf => {
                                        let now = Date.now()
                                        let period = Date.parse(mf.current_period_end)
                                        return (
                                            <tr key={mf.id}>
                                                <td>{mf.feature_name}</td>
                                                <td className={mf.lifetime ? 'text-dark' :
                                                    now > period ? 'text-danger' : 'text-success'}>
                                                    {mf.lifetime ? 'Sin vencimiento' :
                                                        format(new Date(mf.current_period_end), 'dd/MM/yyyy HH:mm')}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => hideModal('viewLicenseModal')}
                        >CERRAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}