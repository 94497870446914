import React from 'react';

export default function Delete() {

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_976" data-name="Grupo 976" transform="translate(-659 -378)">
          <g id="Grupo_968" data-name="Grupo 968" transform="translate(659 378)">
            <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#fe4747" />
          </g>
          <path id="borrar" d="M8.842,10.545,5.449,7.153,2.056,10.545a1.2,1.2,0,0,1-1.7-1.7L3.746,5.449.353,2.057a1.2,1.2,0,0,1,1.7-1.7L5.449,3.746,8.842.353a1.2,1.2,0,0,1,1.7,1.7L7.152,5.449l3.393,3.393a1.2,1.2,0,0,1-1.7,1.7Zm0,0" transform="translate(665.551 384.551)" fill="#fff" />
        </g>
      </svg>
    </div>
  );

}
