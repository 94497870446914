import { createContext } from "react"

const OrderContext = createContext({
    orders: [],
    setOrders: () => { },
    nextPage: null,
    setNextPage: () => { },
    previousPage: null,
    setPreviousPage: () => { },
    totalRegisters: null,
    setTotalRegisters: () => { },
    pageSize: 10,
    setPageSize: () => { }
})

export default OrderContext