import React from 'react';

import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';

export default function Error() {

    return (
        <div className="text-align-center">
            <header>
                <PrincipalBar fromError={'true'} />
            </header>
            <div className="container mt-3">
                <h2>Página no encontrada</h2>
                <p>
                    La página que buscas no existe.
                </p>
            </div>
        </div>
    );
}
