export const setPreAssembledStock = (stockOrders, saleProducts) => {
    let total = []
    stockOrders?.forEach(so => {
        so.ordersaleproductitem_set.forEach(ospi => {
            if (ospi.sale_product_item.status === 'inputs products assigned') {
                return (
                    total.push(ospi)
                )
            }
        })
    })

    const count = {};
    for (const element of total) {
        if (count[element.sale_product_item.sale_product]) {
            count[element.sale_product_item.sale_product] += 1;
        } else {
            count[element.sale_product_item.sale_product] = 1;
        }
    }

    let itemsOccurrences = []
    Object.keys(count).forEach(key => {
        itemsOccurrences.push({
            sale_product: saleProducts.find(sp => sp.id === parseInt(key))?.alias !== null ?
                saleProducts.find(sp => sp.id === parseInt(key))?.alias :
                saleProducts.find(sp => sp.id === parseInt(key))?.name
        })
    })
    for (let i = 0; i < itemsOccurrences.length; i++) {
        itemsOccurrences[i].stock = Object.values(count)[i]
    }
    return itemsOccurrences
}