export const getModifiedFields = (originalOrder, order, originalAddress, newAddress) => {
    let changes = []
    let originalFields = []
    const originalKeys = Object.keys(originalOrder)
    const originalValues = Object.values(originalOrder)
    for (let key in originalKeys) {
        if (originalValues[key] !== null) {
            originalFields.push({
                [originalKeys[key]]: originalValues[key]
            })
        }
    }
    let newFields = []
    const newKeys = Object.keys(order)
    const newValues = Object.values(order)
    for (let key in newKeys) {
        if (newValues[key] !== null) {
            newFields.push({
                [newKeys[key]]: newValues[key]
            })
        }
    }
    for (let i = 0; i < originalFields.length; i++) {
        if (Object.values(originalFields[i])[0] !== Object.values(newFields[i])[0]) {
            changes.push(' ' + Object.keys(originalFields[i])[0])
        }
    }
    changes = changes.filter(item => item !== ' paymentdetail_set')
    if (productsChanged(originalOrder.paymentdetail_set, order.paymentdetail_set)) {
        changes.push(' Sección de productos')
    }
    if(originalAddress !== newAddress){
        changes.push(' Datos de envío')
    }
    let stringify = JSON.stringify(changes).replaceAll('"', '').replaceAll('[', '').replaceAll(']', '').trim()
    let result = stringify.replace('new_client', 'Cliente')
                            .replace('sweeper', 'Cazador 1')
                            .replace('hunter', 'Cazador 2')
                            .replace('closer', 'Closer')
                            .replace('advancepayment', 'Pago')
                            .replace('amount', 'Monto total')
                            .replace('currency', 'Moneda')
                            .replace('note', 'Observaciones')
                            .replace('with_order', '')
                            .replace('sweeper_name', '')
                            .replace('hunter_name', '')
                            .replace('closer_name', '')
                            .replace('origin', '')
                            .replace('last_update', '')
                            .replace('timestamp', '')
    return result
}

const productsChanged = (originalPD, newPD) => {
    if (originalPD.length !== newPD.length) {
        return true
    }
    for (let i = 0; i < originalPD.length; i++) {
        if (originalPD[i].article !== newPD[i].article ||
            originalPD[i].count !== newPD[i].count ||
            originalPD[i].rewarded !== newPD[i].rewarded) {
            return true
        }
    }
    return false
}


