export const setDistCentersStock = (distCenters, stockOrders) => {
    let centers = []
    distCenters?.forEach(dc => {
        centers.push({
            dist: dc.id,
            stock: []
        })
    })
    let elements = []
    stockOrders?.forEach(so => {
        so.ordersaleproductitem_set.forEach(ospi => {
            if (ospi.sale_product_item.status === 'inputs products assigned') {
                elements.push(ospi.sale_product_item)
            }
        })
    })
    elements.forEach(el => {
        distCenters.forEach(dc => {
            centers.forEach(c => {
                if (el.distribution_center === dc.id && dc.id === c.dist) {
                    c.stock.push(el)
                }
            })
        })
    })
    return centers
} 