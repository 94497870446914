import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import {
    errorTimeout,
    networkError,
    serverError,
    expiredSession,
    isBuilt
} from '../../Utilities/General/StatusHandler';

const CalBuildService = {

    getCalBuild: async id => {
        try {
            const { data } = await axios.get(
                id !== undefined ? GlobalUrls.urlCalBuild + `${id}/` : GlobalUrls.urlCalBuild,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewCalBuild: async newCalBuild => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlCalBuild,
                newCalBuild,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteCalBuild: async id => {
        try {
            const res = await axios.delete(GlobalUrls.urlCalBuild + `${id}/`, { headers: { Authorization: getToken() } }
            )
            return res
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('404')) return isBuilt()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    compileCalBuild: async id => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlBuildCalibration + `${id}/`,
                {},
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default CalBuildService