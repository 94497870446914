import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FcApproval, FcFullTrash, FcUndo } from 'react-icons/fc'
import { IoDocumentTextSharp } from "react-icons/io5";
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { useParams } from 'react-router-dom'

import PrincipalBar from '../../AppComponents/Commons/PrincipalBar'
import FirstTitle from '../../AppComponents/Commons/FirstTitle'
import ButtonBack from '../../AppComponents/Commons/ButtonBack'
import OrderContext from '../../Contexts/OrderContext'
import Loader from '../../AppComponents/Commons/Loader'
import ViewOrder from '../../AppComponents/Orders/ViewOrder'
import OrdersPipeline from '../../AppComponents/Orders/OrdersPipeline'
import OrderDataConfirm from '../../AppComponents/Orders/OrderDataConfirm'
import TableBackPaginated from '../../AppComponents/Orders/TableBackPaginated'
import useClientBillingData from '../../Hooks/Commercial/useClientBillingData'

import OrderService from '../../Services/Licenses/OrderService'
import PaymentService from '../../Services/Licenses/PaymentService'
import StockOrderService from '../../Services/Stock/StockOrderService'
import UsoundPaymentsService from '../../Services/UsoundPayments/UsoundPaymentsService'
import SaleProductService from '../../Services/Stock/SaleProductService'
import ClientService from '../../Services/Licenses/ClientService'
import { isAdmin, isFinancial, getToken, getUsername } from '../../Utilities/Auth/AuthPermissions'
import { showModal, hideModal, showTooltip, hideTooltip } from '../../Utilities/General/BootstrapApi'

import Delete from '../../Assets/Delete'

const Billing = () => {

  const {
    orders,
    setOrders,
    setNextPage,
    setPreviousPage,
    setTotalRegisters
  } = useContext(OrderContext)

  const [clientBillingData, setClientBillingData] = useClientBillingData()

  const [typeOrderStatus, setTypeOrderStatus] = useState([])
  const [countries, setCountries] = useState([])
  const [clients, setClients] = useState([])
  const [emailContacts, setEmailContacts] = useState([]);
  const [stockOrders, setStockOrders] = useState([])
  const [articles, setArticles] = useState([])
  const [uSoundPaymentOrders, setUsoundPaymentOrders] = useState([])
  const [usoudPaymentProducts, setUsoundPaymentProducts] = useState([])
  const [saleProductStock, setSaleProductStock] = useState([]);
  const [sellers, setSellers] = useState([])
  const [saleProducts, setSaleProducts] = useState([]);
  const [customers, setCustomers] = useState([])

  const [workOn, setWorkOn] = useState(null)
  const [toOrderStatus, setToOrderStatus] = useState(null);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [orderFromMail, setOrderFromMail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newFile, setNewFile] = useState()

  let params = useParams()
  let navigate = useNavigate()

  const cancelObsRef = useRef('')

  useEffect(() => {
    if (params.id !== undefined) {
      setOrderFromMail(true)
    } else {
      setOrderFromMail(false)
    }
  }, [])

  useEffect(() => {
    OrderService.getOrders({
      ordering: '-id',
      limit: 10
    }).then(data => {
      if (!data) return
      setTotalRegisters(data.count)
      setNextPage(data.next)
      setPreviousPage(data.previous)
      setOrders(data.results)
      setLoading(false)
      console.log(orders)
    })
    OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
    PaymentService.getCountries().then(data => setCountries(data))
    ClientService.getClients().then(data => setClients(data))
    PaymentService.getArticles()
      .then(data => setArticles(data?.sort((a, b) => {
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0
      })))
    ClientService.getEmailContacts().then(data => setEmailContacts(data))
    StockOrderService.getStockOrders().then(data => setStockOrders(data))
    UsoundPaymentsService.getOrders().then(data => setUsoundPaymentOrders(data))
    UsoundPaymentsService.getProducts().then(data => setUsoundPaymentProducts(data))
    UsoundPaymentsService.getCustomers().then(data => setCustomers(data))
    SaleProductService.getSaleProductStock().then(data => setSaleProductStock(data))
    SaleProductService.getSaleProducts().then(data => setSaleProducts(data))
    PaymentService.getSellers().then(data => setSellers(data))
  }, [])

  const handleFile = (e, id) => {
    setWorkOn(id)
    const [file] = e.target.files
    Object.defineProperties(file, {
      name: {
        value: `factura-aduanera-de-orden-${id}.pdf`
      }
    })
    setNewFile(file)
    handleFileSelects(id)
  }

  const handleFileSelects = id => {
    orders.sort((a, b) => b.id - a.id).forEach(order => {
      if (order.id === id) {
        document.getElementById('billActionsSection' + id).classList.remove('d-none')
      } else {
        let input = document.getElementById('fileInput' + order.id)
        if (input !== null) input.value = ''
        document.getElementById('billActionsSection' + order.id)?.classList.add('d-none')
      }
    })
  }

  const saveFile = id => {
    const type = newFile.type.split('/')[1]

    if (type !== 'pdf') {
      toast.error('Formato incorrecto. La extensión del archivo debe ser .pdf', {
        position: 'bottom-left',
        hideProgressBar: true,
        className: 'error-toast'
      })
      return
    }

    toast.warning(`Enviando archivo. Esto puede tardar un momento...`, {
      position: 'bottom-left',
      hideProgressBar: true,
      className: 'warning-toast',
      autoClose: false
    })

    OrderService.saveOrderBill(newFile)
      .then(() => {
        let [order] = orders.filter(item => item.id === workOn)
        let obd = order.orderbillingdata
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          obd.invoice_url = `https://ordenes-compra.s3.amazonaws.com/facturas/${newFile.name}`
        } else {
          obd.invoice_url = `https://ordenes-compra.s3.amazonaws.com/facturas-testing/${newFile.name}`
        }
        OrderService.updateOrderBillingData(obd.id, obd)
          .then(data => {
            setOrders([
              ...orders.filter(item => item.id !== order.id),
              {
                ...order,
                orderbillingdata: data
              }
            ])
            let newStatus = {
              order: data.order,
              user_name: getUsername(),
              type_order_status: typeOrderStatus.filter(tos => tos.name === 'invoiced')[0].id
            }
            // nuevo estado invoiced
            OrderService.saveNewOrderStatus(newStatus)
              .then(newOSResponse => {
                // actualizar contexto de ordenes con el nuevo estado
                let [order] = orders.filter(item => item.id === data.order)
                order.orderstatus_set = [...order.orderstatus_set, newOSResponse]
                setOrders([
                  ...orders.filter(item => item.id !== order.id),
                  order
                ])
                // mail estado invoiced
                OrderService.sendMailOrderStatus(data.order, 'invoiced', 'facturada')
                toast.dismiss()
                toast.success(`Factura guardada correctamente`, {
                  position: 'bottom-left',
                  hideProgressBar: true,
                  className: 'success-toast'
                })
              })
          })
      }).catch(() => document.getElementById('billActionsSection' + id).classList.remove('d-none'))
  }

  const deleteInvoiceUrl = orderId => {
    swal({
      title: "¿Borrar factura?",
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Confirmar']
    }).then(confirm => {
      if (confirm) {
        let [order] = orders.filter(item => item.id === workOn)
        let obd = order.orderbillingdata
        obd.invoice_url = null
        OrderService.updateOrderBillingData(obd.id, obd)
          .then(data => {
            setOrders([
              ...orders.filter(item => item.id !== order.id),
              {
                ...order,
                orderbillingdata: data
              }
            ])
            toast.success(`Factura eliminada correctamente`, {
              position: 'bottom-left',
              hideProgressBar: true,
              className: 'success-toast'
            })
          })
      }
    })
  }

  const handleCancel = (rowIndex) => {
    const id = rowIndex.id;
    let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'canceled')

    OrderService.checkCanceled(id, typeOrderStatus, getToken())
      .then(isCancelled => {
        if (isCancelled) {

          toast.error(`La orden #${id} ya se encuentra anulada. Refresque la página para actualizar estados.`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'error-toast'
          })

        } else {
          OrderService.saveNewOrderStatus({
            order: id,
            type_order_status: currentStatus.id,
            user_name: getUsername(),
            observation: cancelObsRef.current.value
          })
            .then(data => {
              let [order] = orders.filter(item => item.id === data.order)
              order.orderstatus_set = [...order.orderstatus_set, data]
              setOrders([...orders.filter(item => item.id !== order.id), order])
              OrderService.sendMailOrderStatus(id, 'canceled', 'anulada')
              hideModal('orderDataConfirm')
              document.getElementById('obsTextarea').value = ''
              toast.success(`Orden #${id} anulada correctamente`, {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'success-toast'
              })
              setLoadingChangeStatus(false)
            })
        }
      })
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ value }) => {
          return (
            <div className="cursor-pointer"
              id={'viewOrderDiv' + value}
              onMouseEnter={() => showTooltip('viewOrderDiv' + value, `Ver orden #${value}`)}
              onMouseLeave={() => hideTooltip('viewOrderDiv' + value)}
              onClick={() => {
                setWorkOn(parseInt(value));
                showModal('viewOrderModal')
              }}
            >
              <span>{value}</span>
            </div>
          )
        }
      },
      {
        Header: 'Cliente',
        accessor: order => {
          let [client] = clients.filter(c => c.id === order.new_client)
          let [customer] = customers.filter(c => c.id === parseInt(order.payments_service_customer_id))
          return client?.name || customer?.name
        }
      },
      {
        Header: 'Artículos',
        disableSortBy: true,
        accessor: order => {
          return <>
            {order.paymentdetail_set.map((item, idx) => {
              return (
                <p key={idx} className={idx >= 1 ? "d-block mt-4" : undefined}>
                  {'(' + item.count + ') ' + (item.article_alias !== null ? item.article_alias : item.article_name)}
                </p>
              )
            })}
          </>
        }
      },
      {
        Header: 'Dirección',
        accessor: order => {
          let osd = order.ordershippingdata
          let obd = order.orderbillingdata
          let [country] = countries.filter(item => item.id === osd?.address_country || item.id === obd?.address_country)
          if (osd !== null) return `${osd.address}, ${osd.address_province}, ${country?.name}`
          if (obd !== null) return `${obd.address}, ${obd.address_province}, ${country?.name}`
          if (order.shipping_address !== undefined) return order.shipping_address
          return 'No válido'
        }
      },
      {
        Header: 'Pago/Rep.',
        accessor: order => {
          let [paidStatus] = order.orderstatus_set.filter(
            item => (item.type_order_status_spanish_name === 'Pagada' ||
              item.type_order_status_spanish_name === 'Reposición'))
          return paidStatus?.timestamp
        },
        Cell: ({ value }) => {
          if (value !== undefined) {
            return format(new Date(value), "dd/MM/yyyy");
          }
          return 'No válido'
        }
      },
      {
        Header: 'Factura',
        disableSortBy: true,
        accessor: order => {
          let obd = order.orderbillingdata
          if (obd !== null && obd.invoice_url !== null) {
            return (
              <button
                type='button'
                onClick={() => window.open(obd.invoice_url, '_blank')}
                className="btn btn-sm text-white downloadButtons"
              >
                Descargar
              </button>
            )
          }
          return (
            <div className='d-flex align-items-center'>
              <div className=''>
                <input id={'fileInput' + order.id} type='file' className='form-control' onChange={e => handleFile(e, order.id)} />
              </div>
              <div
                id={'billActionsSection' + order.id}
                className='d-flex align-items-center justify-content-center fs-4 ms-2 d-none'
              >
                <span
                  id={'approveButton' + order.id}
                  className='actions'
                  onMouseEnter={() => showTooltip('approveButton' + order.id, 'Guardar')}
                  onMouseLeave={() => hideTooltip('approveButton' + order.id)}
                >
                  <FcApproval onClick={() => {
                    saveFile(order.id)
                    document.getElementById('billActionsSection' + order.id).classList.add('d-none')
                  }} />
                </span>
                <span
                  id={'undoButton' + order.id}
                  className='actions'
                  onMouseEnter={() => showTooltip('undoButton' + order.id, 'Deshacer')}
                  onMouseLeave={() => hideTooltip('undoButton' + order.id)}
                >
                  <FcUndo onClick={() => {
                    document.getElementById('fileInput' + order.id).value = ''
                    document.getElementById('billActionsSection' + order.id).classList.add('d-none')
                  }} />
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Estado',
        accessor: order => {
          let allStatus = order.orderstatus_set.sort((a, b) => b.id - a.id).filter(os => os.type_order_status_trunk)
          let display = allStatus[0]?.type_order_status_spanish_name || 'No válido'
          return [display, order.id].toString();
        },
        Cell: ({ value }) => {
          const [display, id] = value.split(',')
          return <button
            type="button"
            id={'pipelineButton' + id}
            className={
              (display === 'Pagada' || display === 'Reposición') ?
                "btn btn-sm paid-order text-white" :
                display === 'Aceptada' ?
                  "btn btn-sm accepted-order text-white" :
                  display === 'Armando' ?
                    "btn btn-sm assembling-order text-white" :
                    display === 'Enviada' ?
                      "btn btn-sm sended-order text-white" :
                      display === 'Recibida' ?
                        "btn btn-sm btn-primary text-white" :
                        display === 'Finalizada' ?
                          "btn btn-sm btn-success text-white" :
                          display === 'Anulada' ?
                            "btn btn-sm canceled-order text-white" :
                            "btn btn-sm btn-secondary text-white"
            }
            onMouseEnter={() => showTooltip('pipelineButton' + id, `Ver historial de orden #${id}`)}
            onMouseLeave={() => hideTooltip('pipelineButton' + id)}
            onClick={() => {
              setWorkOn(parseInt(id));
              showModal('orderPipeline')
            }}
          >
            {display}
          </button>
        }
      },
      {
        Header: 'Acciones',
        disableSortBy: true,
        accessor: order => {
          let obd = order.orderbillingdata
          let allStatus = order.orderstatus_set.filter(os => os.type_order_status_trunk).sort((a, b) => b.id - a.id)
          let [currentStatus] = typeOrderStatus.filter(tos => tos.id === allStatus[0].type_order_status)
          return (
            <div className='d-flex'>
              {(obd !== null && obd.invoice_url !== null && (isAdmin() || isFinancial())) &&
                <span
                  id={'deleteBillButton' + order.id}
                  className='d-flex align-items-center actions pt-1 fs-3'
                  onMouseEnter={() => showTooltip('deleteBillButton' + order.id, 'Borrar factura de orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('deleteBillButton' + order.id)}
                  onClick={() => deleteInvoiceUrl(order.id)}
                >
                  <FcFullTrash />
                </span>
              }
              {(currentStatus?.name !== 'canceled' && (isAdmin() || isFinancial())) &&
                <span
                  id={'cancelOrderButton' + order.id}
                  className="d-flex align-items-center actions pt-1'"
                  onMouseEnter={() => showTooltip('cancelOrderButton' + order.id, 'Anular orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('cancelOrderButton' + order.id)}
                  onClick={() => {
                    setToOrderStatus('canceled')
                    setWorkOn(parseInt(order.id))
                    showModal('orderDataConfirm')
                  }}
                >
                  <Delete />
                </span>
              }
              {(obd !== null && obd.invoice_autogenerated_url !== null) &&
                <span
                  id={'downloadInvoiceButton' + order.id}
                  className='actions fs-3 d-flex align-items-center'
                  onMouseEnter={() => showTooltip('downloadInvoiceButton' + order.id, 'Descargar factura autogenerada de orden #' + order.id)}
                  onMouseLeave={() => hideTooltip('downloadInvoiceButton' + order.id)}
                  onClick={() => {
                    window.open(obd.invoice_autogenerated_url, '_blank')
                  }}
                >
                  <IoDocumentTextSharp style={{ color: 'red' }}/>
                </span>
              }
            </div>
          )
        }
      },
    ],
    [countries, clients, typeOrderStatus, newFile, customers]
  )

  return (
    <div>
      <PrincipalBar />

      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
        <FirstTitle text="Facturación" />
        <ButtonBack destination="/inicio" text="VOLVER" />
      </div>

      {loading ?
        <div className='text-center'>
          <Loader />
          <strong>Cargando...</strong>
        </div> :
        <div className='mx-2'>
          <TableBackPaginated
            columns={columns}
            data={orderFromMail ?
              orders?.filter(order => order.id === parseInt(params.id)) :
              orders.filter(order => order.orderbillingdata !== null)}
            setData={setOrders}
          />
        </div>
      }

      {orderFromMail &&
        <button type="button" className="btn btn-info text-white d-block mx-auto"
          onClick={() => {
            setOrderFromMail(false)
            navigate('/facturacion')
          }}>
          Ver todas
        </button>
      }

      <OrdersPipeline
        orderId={workOn}
      />

      <div className="modal fade" id="viewOrderModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-info" id="staticBackdropLabel">Orden #{workOn}</h3>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => hideModal('viewOrderModal')}></button>
            </div>
            <div className="modal-body">
              <ViewOrder
                order={orders?.filter(item => item.id === workOn)[0]}
                clients={clients}
                emails={emailContacts}
                countries={countries}
                stockOrder={stockOrders?.filter(item => item.order_id === workOn)[0]}
                articles={articles}
                workOn={workOn}
                uSoundPaymentOrder={uSoundPaymentOrders?.filter(upo => parseInt(upo.order_id) === workOn)[0]}
                paymentServiceProducts={usoudPaymentProducts}
                saleProductStock={saleProductStock}
                customers={customers}
                typeOrderStatus={typeOrderStatus}
                clientBillingData={clientBillingData}
                setClients={setClients}
                setClientBillingData={setClientBillingData}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => hideModal('viewOrderModal')}>CERRAR</button>
            </div>
          </div>
        </div>
      </div>

      <OrderDataConfirm
        order={orders?.filter(item => item.id === workOn)[0]}
        stockOrder={stockOrders?.filter(item => item.order_id === workOn)[0]}
        stockOrders={stockOrders}
        clients={clients}
        emails={emailContacts}
        sellers={sellers}
        countries={countries}
        articles={articles}
        toOrderStatus={toOrderStatus}
        hideModal={hideModal}
        handleCancel={handleCancel}
        loadingChangeStatus={loadingChangeStatus}
        setLoadingChangeStatus={setLoadingChangeStatus}
        saleProducts={saleProducts}
        uSoundPaymentOrder={uSoundPaymentOrders?.filter(upo => parseInt(upo.order_id) === workOn)[0]}
        paymentServiceProducts={usoudPaymentProducts}
        customers={customers}
        cancelObsRef={cancelObsRef}
      />

    </div>
  )
}

export default Billing