import React from "react";

export default function Slice() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Grupo_1078" data-name="Grupo 1078" transform="translate(-298 -378)">
                    <g id="Grupo_1077" data-name="Grupo 1077" transform="translate(-303)">
                        <g id="Grupo_751" data-name="Grupo 751" transform="translate(601 378)">
                            <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#06486e" />
                        </g>
                    </g>
                    <path id="Trazado_646" data-name="Trazado 646" d="M.822-9.762a2.426,2.426,0,0,1,.706-1.8,2.511,2.511,0,0,1,1.851-.7,2.519,2.519,0,0,1,1.864.7,2.486,2.486,0,0,1,.7,1.839v.6a2.409,2.409,0,0,1-.7,1.785,2.505,2.505,0,0,1-1.847.7,2.585,2.585,0,0,1-1.847-.685A2.417,2.417,0,0,1,.822-9.172Zm1.411.636a1.331,1.331,0,0,0,.32.928,1.081,1.081,0,0,0,.843.354,1.059,1.059,0,0,0,.83-.354,1.392,1.392,0,0,0,.315-.961v-.608a1.347,1.347,0,0,0-.32-.932,1.075,1.075,0,0,0-.843-.358,1.045,1.045,0,0,0-.834.362,1.41,1.41,0,0,0-.311.953Zm4.4,6.163a2.376,2.376,0,0,1,.722-1.8A2.561,2.561,0,0,1,9.2-5.454a2.558,2.558,0,0,1,1.847.689,2.456,2.456,0,0,1,.718,1.859v.589a2.428,2.428,0,0,1-.693,1.781,2.491,2.491,0,0,1-1.855.7A2.564,2.564,0,0,1,7.342-.527a2.443,2.443,0,0,1-.71-1.83Zm1.411.639a1.324,1.324,0,0,0,.328.926,1.087,1.087,0,0,0,.843.361,1.053,1.053,0,0,0,.834-.344,1.416,1.416,0,0,0,.3-.967v-.623a1.312,1.312,0,0,0-.324-.93A1.082,1.082,0,0,0,9.2-4.25a1.079,1.079,0,0,0-.834.349,1.371,1.371,0,0,0-.32.963ZM3.81-.9,2.772-1.5l5.9-9.446,1.038.6Z" transform="translate(303.708 396.047)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}