import format from "date-fns/format"

export default function SearchResults({
    value,
    results,
    hideModal
}) {

    return (
        <div className="modal fade" id="searchResultsModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title text-info" id="staticBackdropLabel">
                            Resultados de búsqueda: <i>"{value}"</i>
                        </h3>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                            hideModal('searchResultsModal')
                        }}></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th>N° Mov.</th>
                                    <th>Artículo</th>
                                    <th>Cantidad</th>
                                    <th>Serie/lote</th>
                                    <th>Prov.</th>
                                    <th>Fab.</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Autor</th>
                                    <th>Obs.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map(res => {
                                    console.log(res)
                                    return (
                                        <tr key={res.id}>
                                            <td>{res.movement}</td>
                                            <td>{res.item_input_product_name}</td>
                                            <td>{res.quantity}</td>
                                            <td>{res.item_input_product_serial_number}</td>
                                            <td>{res.item_supplier_name}</td>
                                            <td>{res.item_input_product_manufacturer_name}</td>
                                            <td>{res.type}</td>
                                            <td>{format(new Date(res.timestamp), "dd/MM/yyyy HH:mm")}</td>
                                            <td>{res.user_name}</td>
                                            <td>{res.observation}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => {
                            hideModal('searchResultsModal')
                        }}>
                            CERRAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};
