import LicenseService from "../../Services/Licenses/LicenseService"
import Urls from "../General/Urls"

export const paginateLicenses = async url => {
    const data = await LicenseService.getLicensesWithUrl(url)
    return data
}

export const filterLicenses = async params => {
    const data = await LicenseService.getLicensesWithUrl(Urls.urlPayLicense + params)
    return data
}