import axios from "axios";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { errorTimeout, networkError, serverError } from '../../Utilities/General/StatusHandler';

const AuthService = {

    login: async form => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlPayLogin,
                form,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
        }
    },

    logout: async () => {
        try {
            await axios.post(
                GlobalUrls.urlPayLogout,
                {},
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
        }
    }

}

export default AuthService