import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Loader from '../../../AppComponents/Commons/Loader';

import { preventWheel } from '../../../Utilities/General/PreventWheel';

export default function CreatePreAssembledOrder({
    articles,
    saleProductStock,
    newPAO,
    setNewPAO,
    handleSubmit,
    hideModal,
    resetForm,
    loadingPAO,
    setLoadingPAO
}) {

    const [ospi, setOspi] = useState({});

    const handleChangeCount = (e, sku) => {
        setOspi({
            article: articles.find(art => art.sku === sku).id,
            count: parseInt(e.target.value)
        })
    }

    useEffect(() => {
        if (Object.keys(ospi).length > 0) {
            let flag = false
            for (let i = 0; i < newPAO.ordersaleproductitem_set.length; i++) {
                if (newPAO.ordersaleproductitem_set[i].article === ospi.article) {
                    flag = true
                }
            }
            if (flag) {
                // si flag esta en true, es porque el producto ya esta en el array
                // filtro los productos con article distinto y le agrego el nuevo objeto con la nueva cantidad
                setNewPAO({
                    ...newPAO,
                    ordersaleproductitem_set: [
                        ...newPAO.ordersaleproductitem_set.filter(item => item.article !== ospi.article),
                        ospi
                    ]
                })
            } else {
                // si flag esta en false, es porque el producto no esta en el array
                // simplemente lo agrego
                setNewPAO({
                    ...newPAO,
                    ordersaleproductitem_set: [
                        ...newPAO.ordersaleproductitem_set,
                        ospi
                    ]
                })
            }
        }
    }, [ospi])

    return (
        <>
            {saleProductStock === null ?
                <Loader /> :
                <>
                    <form id='createPAOForm'>
                        <table className="table table-hover text-center">
                            <thead>
                                <tr>
                                    <th className="col-4 ps-0">Detalle</th>
                                    <th className="col-2 text-center">Cantidad</th>
                                    <th className="col-3 text-center">Stock de producto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {saleProductStock?.map((sps, spsIndex) => {
                                    return (
                                        <tr key={sps.id}>
                                            <td>
                                                {sps.alias !== null ? sps.alias : sps.name}
                                            </td>
                                            <td>
                                                <input
                                                    id={'count' + spsIndex}
                                                    name="count"
                                                    type="number"
                                                    min={0}
                                                    step={1}
                                                    className="form-control"
                                                    defaultValue={0}
                                                    onChange={e => handleChangeCount(e, sps.sku)}
                                                    onWheel={() => preventWheel('count' + spsIndex)}
                                                />
                                            </td>
                                            <td>
                                                {sps.stock > 0 ?
                                                    <span className="bg-success p-1 rounded text-white mx-auto">
                                                        {sps.stock}
                                                    </span> :
                                                    <span className="bg-danger p-1 rounded text-white mx-auto">
                                                        {sps.stock}
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </form>

                    <div className='d-flex justify-content-end'>
                        {loadingPAO ?
                            <button
                                type='button'
                                className='btn btn-info text-white col-6 col-lg-2 me-2'
                                disabled
                            >
                                GUARDAR
                            </button> :
                            <button
                                type='button'
                                className='btn btn-info text-white col-6 col-lg-2 me-2'
                                onClick={() => {
                                    if (newPAO.ordersaleproductitem_set.length === 0) {
                                        toast.error('No se puede crear una orden sin productos.', {
                                            position: 'bottom-left',
                                            hideProgressBar: true,
                                            className: 'error-toast'
                                        })
                                        return
                                    }
                                    setLoadingPAO(true)
                                    handleSubmit()
                                }}
                            >
                                GUARDAR
                            </button>
                        }
                        <button
                            type='button'
                            className='btn btn-secondary text-white col-6 col-lg-2'
                            onClick={() => {
                                resetForm()
                                setNewPAO({
                                    ordersaleproductitem_set: []
                                })
                                hideModal('createPAOModal')
                            }}
                        >
                            CANCELAR
                        </button>
                    </div>
                </>
            }
        </>

    )
}



