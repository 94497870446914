import axios from "axios";
import { toast } from "react-toastify";

import Urls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions'
import { error, networkError, serverError, errorTimeout, expiredSession } from '../../Utilities/General/StatusHandler';

const UsoundPaymentsService = {

    getProducts: async () => {
        try {
            const { data } = await axios.get(
                Urls.urlUsoundPayProducts,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrders: async () => {
        try {
            const { data } = await axios.get(
                Urls.urlUsoundPayOrder,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateOrder: async order => {
        try {
            const { data } = await axios.put(
                Urls.urlUsoundPayOrder + `${order.id}/`,
                order,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteOrder: async order => {
        try {
            await axios.delete(
                Urls.urlUsoundPayOrder + `${order.id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewOrder: async newOrder => {
        try {
            const { data } = await axios.post(
                Urls.urlUsoundPayOrder,
                newOrder,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteOrderDetail: async id => {
        try {
            await axios.delete(
                Urls.urlUsoundPayOrderDetail + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getCustomers: async () => {
        try {
            const { data } = await axios.get(
                Urls.urlUsoundPayCustomer,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }
}

export default UsoundPaymentsService