import axios from 'axios';
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { toast } from 'react-toastify';

import Urls from '../../Utilities/General/Urls'
import { getToken, getUsername } from '../../Utilities/Auth/AuthPermissions';
import { errorTimeout, networkError, serverError, expiredSession, error } from '../../Utilities/General/StatusHandler';
import { s3 } from '../../Utilities/General/s3config';

const OrderService = {

    sendMailOrderStatus: async (id, status, message, detail = '') => {
        const data = {
            order_id: id,
            status,
            user_name: getUsername(),
            message,
            detail
        }
        try {
            const res = axios.post(
                Urls.urlPaySendMailOrderStatus,
                data,
                { headers: { Authorization: getToken() } })
            return res
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    sendOrder: (id, token) => {
        axios.post(Urls.urlPaySendOrder + `${id}/`, {}, { headers: { Authorization: token } })
    },

    checkCanceled: (id, typeOrderStatus, token) => {
        let flag = false
        return axios.get(Urls.urlPayOrder + id, { headers: { Authorization: token } })
            .then(res => {
                let canc = typeOrderStatus.find(tos => tos.name === 'canceled')
                let is_cancelled = res.data.orderstatus_set.find(os => os.type_order_status === canc.id)
                if (is_cancelled !== undefined) flag = true
                return flag
            })
    },

    getTypeOrderStatus: async () => {
        try {
            const { data } = await axios.get(Urls.urlPayTypeOrderStatus, { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrderStatus: async () => {
        try {
            const { data } = await axios.get(Urls.urlPayOrderStatus, { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewOrderStatus: async newOrderStatus => {
        try {
            const { data } = await axios.post
                (Urls.urlPayOrderStatus,
                    newOrderStatus,
                    { headers: { Authorization: getToken() } }
                )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrders: async (params = false) => {
        try {
            const { ordering, limit, offset } = params
            const { data } = await axios.get(
                !params ? Urls.urlPayOrder :
                    Urls.urlPayOrder +
                    `?ordering=${ordering}&limit=${limit}${offset ? '&offset=' + offset : ''}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrdersWithUrl: async (url, params = false) => {
        try {
            const { ordering, limit, offset } = params
            const { data } = await axios.get(
                !params ? url : url + `?ordering=${ordering}&limit=${limit}${offset ? '&offset=' + offset : ''}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrder: async id => {
        try {
            const { data } = await axios.get(
                Urls.urlPayOrder + `${id}/`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewOrder: async newOrder => {
        try {
            const { data } = await axios.post(
                Urls.urlPayOrder,
                newOrder,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateOrder: async (id, order) => {
        try {
            const { data } = await axios.put(
                Urls.urlPayOrder + `${id}/`,
                order,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getPaymentInstallment: async (params = false) => {
        try {
            const { ordering, limit, offset } = params
            const { data } = await axios.get(
                !params ? Urls.urlPayPaymentInstallment :
                    Urls.urlPayPaymentInstallment +
                    `?ordering=${ordering}&limit=${limit}${offset ? '&offset=' + offset : ''}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getPaymentInstallmentWithUrl: async url => {
        try {
            const { data } = await axios.get(
                url,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updatePaymentInstallmentData: async (id, newPaymentInstallment) => {
        try {
            const { data } = await axios.put(
                Urls.urlPayPaymentInstallment + `${id}/`,
                newPaymentInstallment,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrderShippingData: async () => {
        try {
            const { data } = await axios.get(Urls.urlPayOrderShippingData, { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewOrderShippingData: async newOrderShippingData => {
        try {
            const { data } = await axios.post(
                Urls.urlPayOrderShippingData,
                newOrderShippingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateOrderShippingData: async (id, newOrderShippingData) => {
        try {
            const { data } = await axios.put(
                Urls.urlPayOrderShippingData + `${id}/`,
                newOrderShippingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getOrderBillingData: async () => {
        try {
            const { data } = await axios.get(Urls.urlPayOrderBillingData, { headers: { Authorization: getToken() } })
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewOrderBillingData: async newOrderBillingData => {
        try {
            const { data } = await axios.post(
                Urls.urlPayOrderBillingData,
                newOrderBillingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateOrderBillingData: async (id, newOrderBillingData) => {
        try {
            const { data } = await axios.put(
                Urls.urlPayOrderBillingData + `${id}/`,
                newOrderBillingData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    autoAssocAudiometer: async id => {
        try {
            await axios.post(
                Urls.urlPayAutoAssocAudiometer,
                { order_id: id },
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    associatePreassembled: async (commercialId, spiList) => {
        try {
            await axios.post(
                Urls.urlPayAssociatePreassembled,
                { order_id: commercialId, sale_product_item_set: spiList },
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    sendOrderExcel: async id => {
        try {
            await axios.post(
                Urls.urlPaySendOrderExcel + `${id}/`,
                {},
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('400')) return error()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveOrderBill: async file => {
        const uploadParams = {
            Bucket: 'ordenes-compra',
            Key: `${process.env.REACT_APP_AWS_S3_DIRNAME}/${file.name}`,
            Body: file
        }
        try {
            const res = await s3.send(new PutObjectCommand(uploadParams));
            return res
        } catch (err) {
            console.log(err)
        }
    },

    saveShippingGuide: async file => {
        const uploadParams = {
            Bucket: 'ordenes-compra',
            Key: `${process.env.REACT_APP_AWS_S3_DIRNAME}/${process.env.REACT_APP_AWS_S3_SHIPPING_GUIDE_DIRNAME}/${file.name}`,
            Body: file
        }
        try {
            const res = await s3.send(new PutObjectCommand(uploadParams));
            return res
        } catch (err) {
            console.log(err)
        }
    },

    saveProofOfPayment: async file => {
        const uploadParams = {
            Bucket: 'ordenes-compra',
            Key: `${process.env.REACT_APP_AWS_S3_PROOF_OF_PAYMENT_DIRNAME}/${file.name}`,
            Body: file
        }
        try {
            const res = await s3.send(new PutObjectCommand(uploadParams));
            return res
        } catch (err) {
            console.log(err)
        }
    }
}

export default OrderService
