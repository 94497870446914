import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import swal from 'sweetalert';

import Login from './Login';

import { isAllowed, isAuth, getUsername } from '../../Utilities/Auth/AuthPermissions';
import { showModal, hideModal, collapseBar } from '../../Utilities/General/BootstrapApi'
import AuthService from '../../Services/Licenses/AuthService';
import { getVersion } from '../../Utilities/General/Version';

import Home from '../../Assets/Home';
import Logo from '../../Assets/dashboard_usound.png';

function PrincipalBar(props) {

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    let navigate = useNavigate()
    let location = useLocation()

    const handleLogout = () => {
        document.getElementById('logoutButton').setAttribute('disabled', '')
        AuthService.logout()
            .then(() => {
                setLoading(false);
                setStatus('logged out');
            })
            .catch(() => {
                setLoading(false);
                setStatus('logged out');
            })
    }

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/inicio')
        }
        if (!isAuth()) {
            if (props.fromCards) {
                navigate('/login')
            } else {
                showModal('loginModal')
            }
        } else {
            if ((!isAllowed(location.pathname.split('/')) && !props.fromError) || isAuth() === 'only django') {
                swal({
                    title: 'Acceso no permitido',
                    text: 'No tiene permiso para acceder a esta sección del sistema.',
                    icon: 'warning',
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    button: 'Aceptar'
                }).then(accept => {
                    if (accept) {
                        navigate('/inicio')
                    }
                })
            }
        }
    }, []);

    if (status === 'logged out') {
        navigate('/login')
        localStorage.clear();
    }

    return (
        <>

            <div className="modal fade backdrop-color" id="loginModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Login
                                fromOutside={'true'}
                                hideModal={hideModal}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-2 mb-1 rounded mx-2" id="principalBar">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button className="navbar-toggler" type="button" onClick={collapseBar}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div id='collapsableBar' className="collapse navbar-collapse d-flex flex-column">
                        <ul className="navbar-nav align-items-center p-1 pe-4 mx-auto w-100">
                            <li className="d-block ms-4">
                                <NavLink to="/inicio" className="nav-link">
                                    <img src={Logo} alt="Logo de uSound" id="logo" />
                                </NavLink>
                            </li>
                            {isAuth() &&
                                <>
                                    <li className="d-block mx-auto">
                                        <NavLink to="/inicio" className="nav-link">
                                            <div className="home-actions">
                                                <Home />
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className='d-flex align-items-center'>
                                        Bienvenido/a <strong className='ms-2'>{getUsername()}</strong>
                                        <div className='d-flex flex-column justify-content-center ms-2'>
                                            <button
                                                id='logoutButton'
                                                className="btn btn-info btn-sm text-white text-align-center w-100"
                                                onClick={handleLogout}
                                            >
                                                Cerrar sesión
                                            </button>
                                            {/* VER LA VERSION EN PRODUCCION Y TESTING */}
                                            {(process.env.REACT_APP_ENVIRONMENT === 'testing' ||
                                                process.env.REACT_APP_ENVIRONMENT === 'production') &&
                                                <span id='headerVersion' className='text-muted'>
                                                    {getVersion()}
                                                </span>
                                            }
                                        </div>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </nav>
            </div>

        </>

    );
}

export default PrincipalBar;