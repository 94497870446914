import React from "react";

import Loader from "../../../AppComponents/Commons/Loader";

export default function DivideValues({
    workOn,
    emissionData,
    displayValue,
    setDisplayValue,
    handleSubmit,
    loading
}) {

    const [value] = emissionData.filter(ed => ed.id === workOn)

    return (

        <>
            {loading ?
                <Loader /> :
                <>
                    <div className="text-center text-info mb-5">
                        <h2>Frecuencia: {value !== undefined ? value.frequency : 0}</h2>
                    </div>
                    <div className="d-flex flex-column col-8 mx-auto">
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="me-2 col-2">
                                Valor de corte:
                            </span>
                            <div className="col-2">
                                <input
                                    type="number"
                                    id="divideInput"
                                    min={value !== undefined ? value.dbhl_min_gain : 0}
                                    max={value !== undefined ? value.dbhl_max_gain : 0}
                                    step={0.1}
                                    defaultValue={0}
                                    className="form-control"
                                    onChange={e => {
                                        setDisplayValue(e.target.value)
                                        document.getElementById('divideRange').value = e.target.value
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-around mt-4">
                            <div className="text-center">
                                <span className="me-2">
                                    dBHL min gain:
                                </span>
                                <span className="border border-muted p-2 rounded">
                                    <strong>{value !== undefined && value.dbhl_min_gain}</strong>
                                </span>
                            </div>
                            <input
                                type="range"
                                id="divideRange"
                                min={value !== undefined ? value.dbhl_min_gain : 0}
                                max={value !== undefined ? value.dbhl_max_gain : 0}
                                step={0.1}
                                defaultValue={0}
                                className="cursor-pointer col-5"
                                onChange={e => {
                                    setDisplayValue(e.target.value)
                                    document.getElementById('divideInput').value = e.target.value
                                }}
                            />
                            <div className="text-center">
                                <span className="me-2">
                                    dBHL max gain:
                                </span>
                                <span className="border border-muted p-2 rounded">
                                    <strong>{value !== undefined && value.dbhl_max_gain}</strong>
                                </span>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-info btn-sm text-white col-2 mx-auto mt-5"
                            onClick={() => {
                                let step = parseFloat(document.getElementById('divideRange').getAttribute('step'))
                                let cutValue = parseFloat(displayValue)
                                let updatedData = {
                                    calibration_group: value.calibration_group,
                                    frequency: value.frequency,
                                    dbhl_max_gain: cutValue
                                }
                                let newData = {
                                    calibration_group: value.calibration_group,
                                    frequency: value.frequency,
                                    min_gain: value.min_gain,
                                    max_gain: value.max_gain,
                                    amplifier_gain: value.amplifier_gain,
                                    dbhl_min_gain: cutValue + step,
                                    dbhl_max_gain: value.dbhl_max_gain
                                }
                                handleSubmit(value, updatedData, newData)
                            }}
                        >
                            GUARDAR
                        </button>
                    </div>
                </>
            }
        </>
    )

}