import React from "react";

export default function Paid() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <g id="Grupo_1059" data-name="Grupo 1059" transform="translate(-601 -378)">
                    <g id="Grupo_751" data-name="Grupo 751" transform="translate(601 378)">
                        <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#00b784" />
                    </g>
                    <path id="Trazado_641" data-name="Trazado 641" d="M3260.886,2309.759c.521.359,1,.639,1.417.988a1,1,0,0,1,.16,1.5,1.04,1.04,0,0,1-1.485.074,3.434,3.434,0,0,0-.785-.482,1.028,1.028,0,0,0-1.355.757,1.045,1.045,0,0,0,.892,1.235c.255.038.517.037.769.092a3.1,3.1,0,0,1,2.44,2.631,3.03,3.03,0,0,1-1.61,3.1.69.69,0,0,0-.443.795,4.256,4.256,0,0,1,0,.569,1.035,1.035,0,1,1-2.067.018c-.012-.378,0-.757,0-.961-.676-.542-1.282-.965-1.809-1.47a.982.982,0,0,1-.01-1.476,1.059,1.059,0,0,1,1.523.076,3.8,3.8,0,0,0,1,.7,1.009,1.009,0,0,0,1.349-.757,1.044,1.044,0,0,0-.9-1.23c-.2-.029-.415-.018-.618-.055a3.1,3.1,0,0,1-.842-5.862c.092-.047.185-.093.309-.155,0-.384-.013-.78,0-1.175a1.031,1.031,0,1,1,2.062-.01C3260.9,2309.067,3260.886,2309.463,3260.886,2309.759Z" transform="translate(-2646.817 -1924.882)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}