import React from 'react';

export default function Warning() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.572" viewBox="0 0 24 20.572">
                <g id="Grupo_1032" data-name="Grupo 1032" transform="translate(0 0)">
                    <path id="Trazado_610" data-name="Trazado 610" d="M153.954,628.85H135.1a2.575,2.575,0,0,1-2.571-2.571,2.45,2.45,0,0,1,.4-1.37l9.408-15.4a2.559,2.559,0,0,1,4.385.014l9.42,15.415a2.408,2.408,0,0,1,.384,1.339,2.575,2.575,0,0,1-2.571,2.571Zm-9.429-18.857a.826.826,0,0,0-.716.4l-9.437,15.442a.764.764,0,0,0-.132.444.858.858,0,0,0,.857.857h18.857a.858.858,0,0,0,.857-.857.735.735,0,0,0-.111-.413l-9.449-15.46a.833.833,0,0,0-.725-.413Z" transform="translate(-132.525 -608.278)" fill="#fff" />
                    <path id="Trazado_611" data-name="Trazado 611" d="M153.848,627.029a.935.935,0,0,1-.934-.934v-7.472a.934.934,0,0,1,1.868,0v7.472A.935.935,0,0,1,153.848,627.029Z" transform="translate(-141.848 -612.875)" fill="#fff" />
                    <ellipse id="Elipse_10" data-name="Elipse 10" cx="0.934" cy="0.934" rx="0.934" ry="0.934" transform="translate(11.066 15.303)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}

