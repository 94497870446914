import React, { useState, useRef } from 'react';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

export default function CreateCalibration({
    buildId,
    smartphoneProp,
    earphoneProp,
    boneHeadProp,
    wireProp,
    suffixProp,
    calibrations,
    products,
    newCalibration,
    setNewCalibration,
    saveNewCalibration
}) {

    const smartphoneRef = useRef();
    const earphoneRef = useRef();
    const boneHeadRef = useRef();
    const wireRef = useRef();
    const copyDataRef = useRef();

    const preventWheel = field => {
        document.getElementById(field + 'Input').blur();
    }

    const handleChange = (e) => {
        e.preventDefault();
        setNewCalibration({
            ...newCalibration,
            [e.target.name]: parseInt(e.target.value),
            calibration_build: parseInt(buildId),
            calibration_origin: parseInt(copyDataRef.current.value)
        })
    }

    const setProductAndKitname = () => {
        var productList = [];
        var i = 0;
        while (i < products.length) {
            if (products[i].name === (smartphoneProp === 'all' ? smartphoneRef.current.value : smartphoneProp)) {
                productList[0] = products[i].id;
                var first = products[i].name + '_';
            }
            if (products[i].name === (earphoneProp === 'all' ? earphoneRef.current.value : earphoneProp)) {
                productList[1] = products[i].id;
                var second = products[i].name + '_';
            }
            if (products[i].name === (boneHeadProp === 'all' ? boneHeadRef.current.value : boneHeadProp)) {
                productList[2] = products[i].id;
                var third = products[i].name + '_';
            }
            if (products[i].name === (wireProp === 'all' ? wireRef.current.value : wireProp)) {
                productList[3] = products[i].id;
                var fourth = products[i].name;
            }
            i++
        }
        var kitname = first + second + third + fourth;
        if (suffixProp !== 'all') {
            kitname += '_' + suffixProp;
        }
        return [productList, kitname];
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        newCalibration.product = setProductAndKitname()[0];
        newCalibration.kitname = setProductAndKitname()[1];
        if (newCalibration.kitname.includes('undefined') ||
            newCalibration.kitname.includes('NaN') ||
            isNaN(newCalibration.mic_constant) ||
            isNaN(newCalibration.noise_constant) ||
            isNaN(newCalibration.noise_floor) ||
            isNaN(newCalibration.dbhl_floor) ||
            isNaN(newCalibration.dbhl_roof) ||
            newCalibration.mic_constant.toString().length > 5 ||
            newCalibration.noise_constant.toString().length > 5 ||
            newCalibration.noise_floor.toString().length > 5 ||
            newCalibration.dbhl_floor.toString().length > 5 ||
            newCalibration.dbhl_roof.toString().length > 5 ||
            isNaN(newCalibration.calibration_origin)) {
            swal(
                'Datos no válidos',
                `   - Debe seleccionar todos los tipos de accesorios.
                    - Los parámetros de ruido deben tener entre 1 y 5 caracteres.
                    - Debe seleccionar una calibración para copiar sus valores.
                `,
                'warning'
            )
            return
        }
        saveNewCalibration(newCalibration)
    }

    return (
        <>
            <form id="newCalibrationForm" onSubmit={(e) => handleSubmit(e)}>
                <div className=" d-flex justify-content-between border border-secondary rounded p-2 p-xl-3 my-3 flex-wrap">
                    <div className="form-group col-12 col-lg-2">
                        <label htmlFor="smartphone">Smartphone</label>
                        {smartphoneProp === 'all' ?
                            <select name="smartphone" className="form-select" ref={smartphoneRef}>
                                <option value="">Seleccione</option>
                                {products.map((item, idx) => {
                                    if (item.type === 'phone') {
                                        return <option key={idx} value={item.name}>{item.name}</option>
                                    }
                                })}
                            </select> :
                            <input type="text" name="smartphone" className="form-control" value={smartphoneProp} disabled />
                        }
                    </div>

                    <div className="form-group col-12 mt-2 col-lg-2 mt-lg-0">
                        <label htmlFor="earphone">Auricular</label>
                        {earphoneProp === 'all' ?
                            <select name="earphonephone" className="form-select" ref={earphoneRef}>
                                <option value="">Seleccione</option>
                                {products.map((item, idx) => {
                                    if (item.type === 'earphone') {
                                        return <option key={idx} value={item.name}>{item.name}</option>
                                    }
                                })}
                            </select> :
                            <input type="text" name="earphone" className="form-control" value={earphoneProp} disabled />
                        }
                    </div>

                    <div className="form-group col-12 my-2 col-lg-2 my-lg-0">
                        <label htmlFor="bone head">Vincha</label>
                        {boneHeadProp === 'all' ?
                            <select name="bone head" className="form-select" ref={boneHeadRef}>
                                <option value="">Seleccione</option>
                                {products.map((item, idx) => {
                                    if (item.type === 'bone head') {
                                        return <option key={idx} value={item.name}>{item.name}</option>
                                    }
                                })}
                            </select> :
                            <input type="text" name="bone head" className="form-control" value={boneHeadProp} disabled />
                        }
                    </div>

                    <div className="form-group col-12 mb-2 col-lg-2 mb-lg-0">
                        <label htmlFor="wire">Cable</label>
                        {wireProp === 'all' ?
                            <select name="wire" className="form-select" ref={wireRef}>
                                <option value="">Seleccione</option>
                                {products.map((item, idx) => {
                                    if (item.type === 'wire') {
                                        return <option key={idx} value={item.name}>{item.name}</option>
                                    }
                                })}
                            </select> :
                            <input type="text" name="wire" className="form-control" value={wireProp} disabled />
                        }
                    </div>

                    {(suffixProp !== 'all' && suffixProp !== 'no suffix') &&
                        <div className="form-group col-12 col-lg-2">
                            <label htmlFor="suffix">Sufijo</label>
                            <input type="text" name="suffix" className="form-control" value={suffixProp} disabled />
                        </div>
                    }
                </div>

                <div className="d-flex justify-content-between flex-wrap" onChange={(e) => handleChange(e)}>
                    <div className="form-group col-12 col-lg-2">
                        <label htmlFor="mic_constant">Mic constant</label>
                        <input id="mic_constantInput" type="number" step="0.0001" name="mic_constant" className="form-control"
                            onWheel={() => preventWheel('mic_constant')} />
                    </div>

                    <div className="form-group col-12 mt-2 col-lg-2 mt-lg-0">
                        <label htmlFor="noise_constant">Noise const.</label>
                        <input id="noise_constantInput" type="number" step="0.0001" name="noise_constant" className="form-control"
                            onWheel={() => preventWheel('noise_constant')} />
                    </div>

                    <div className="form-group col-12 my-2 col-lg-2 my-lg-0">
                        <label htmlFor="noise_floor">Noise floor</label>
                        <input id="noise_floorInput" type="number" step="0.0001" name="noise_floor" className="form-control"
                            onWheel={() => preventWheel('noise_floor')} />
                    </div>

                    <div className="form-group col-12 mb-2 col-lg-2 mb-lg-0">
                        <label htmlFor="dbhl_floor">dBHL floor</label>
                        <input id="dbhl_floorInput" type="number" step="0.0001" name="dbhl_floor" className="form-control"
                            onWheel={() => preventWheel('dbhl_floor')} />
                    </div>

                    <div className="form-group col-12 col-lg-2">
                        <label htmlFor="dbhl_roof">dBHL roof</label>
                        <input id="dbhl_roofInput" type="number" step="0.0001" name="dbhl_roof" className="form-control"
                            onWheel={() => preventWheel('dbhl_roof')} />
                    </div>
                </div>

                <div className="col-lg-4 mt-3" onChange={(e) => handleChange(e)}>
                    <label htmlFor="calibration_origin">Copiar valores de calibración</label>
                    <select name="calibration_origin" className="form-select" ref={copyDataRef}>
                        <option value="">Seleccione</option>
                        {calibrations.map((item, idx) => {
                            return <option key={idx} value={item.id}>{item.kitname}</option>
                        })}
                    </select>
                </div>

                <input
                    id='submit_new_calibration'
                    type="submit"
                    value="GUARDAR"
                    className="btn btn-info text-white d-block mx-auto col-lg-3 mt-4"
                />

            </form>
        </>
    )

}