import React, { useEffect } from 'react';

import CreateManufacturer from './CreateManufacturer';
import CreateTypeInputProduct from './CreateTypeInputProduct';
import { hideModal } from '../../../Utilities/General/BootstrapApi';

export default function EditInputProduct({
    inputProducts,
    workOn,
    newInputProduct,
    setNewInputProduct,
    val,
    willCreateManufacturer,
    setWillCreateManufacturer,
    saveNewManufacturer,
    newManufacturer,
    setNewManufacturer,
    forceUpdate,
    manufacturers,
    willCreateTypeInputProduct,
    setWillCreateTypeInputProduct,
    saveNewTypeInputProduct,
    newTypeInputProduct,
    setNewTypeInputProduct,
    typesInputProduct,
    handleSubmit,
    cancelButton,
    toggleValues
}) {

    useEffect(() => {
        if (workOn !== null) {
            setNewInputProduct(inputProducts.filter(item => item.id === parseInt(workOn))[0])
        }
    }, [workOn])

    useEffect(() => {
        if (newInputProduct !== undefined) {
            let manufacturer = document.getElementById('manufacturerInputIPedit')
            let tip = document.getElementById('type_input_productInputIPedit')
            let serializable = document.getElementById('is_serializableInputIPedit')
            manufacturer.value = newInputProduct.manufacturer
            tip.value = newInputProduct.type_input_product
            serializable.checked = newInputProduct.is_serializable ? true : false
        }
    }, [newInputProduct])

    const handleChange = e => {
        setNewInputProduct({
            ...newInputProduct,
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'is_serializable') {
            if (e.target.checked) {
                setNewInputProduct({
                    ...newInputProduct,
                    is_serializable: true
                })
            } else {
                setNewInputProduct({
                    ...newInputProduct,
                    is_serializable: false
                })
            }
        }
    }

    return (

        <>

            {newInputProduct !== undefined &&
                <div className="modal fade" id="editInputProduct" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                    setWillCreateTypeInputProduct(false)
                                    setWillCreateManufacturer(false)
                                    setNewTypeInputProduct({})
                                    setNewManufacturer({})
                                    document.getElementById('editInputProductForm').reset()
                                    hideModal('editInputProduct')
                                }}></button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-center">
                                    <form id="editInputProductForm" className="col-12 m-3">
                                        <div className="text-center bg-info p-1">
                                            <p className="text-white mb-1">Editar artículo</p>
                                        </div>
                                        <div className="p-3">
                                            <div className="form-group mb-2">
                                                <label htmlFor="sku">SKU</label>
                                                <input
                                                    type="text"
                                                    name="sku"
                                                    className="form-control"
                                                    defaultValue={newInputProduct.sku}
                                                    onChange={e => handleChange(e)}
                                                />
                                                {val.current.message('skuIPedit',
                                                    newInputProduct.sku,
                                                    'required|regex:[a-zA-Z0-9-/]|not_regex:[!¿¡?"@#$%&()=<>+]|max:50',
                                                    { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="name">Nombre</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    defaultValue={newInputProduct.name}
                                                    onChange={e => handleChange(e)}
                                                />
                                                {val.current.message('nameIPedit',
                                                    newInputProduct.name,
                                                    'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&/()=<>+-]|max:200',
                                                    { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="manufacturer">Fabricante</label>
                                                {!willCreateManufacturer ?
                                                    <span className="cursor-pointer text-info ms-1" onClick={() => setWillCreateManufacturer(true)}>
                                                        Nuevo
                                                    </span> :
                                                    <span className="d-block col-8 mx-auto">
                                                        <CreateManufacturer
                                                            willCreateManufacturer={willCreateManufacturer}
                                                            setWillCreateManufacturer={setWillCreateManufacturer}
                                                            cancelButton={'true'}
                                                            handleSubmit={saveNewManufacturer}
                                                            newManufacturer={newManufacturer}
                                                            setNewManufacturer={setNewManufacturer}
                                                            val={val}
                                                            forceUpdate={forceUpdate}
                                                        />
                                                    </span>
                                                }
                                                <select id='manufacturerInputIPedit' name="manufacturer" className="form-select"
                                                    onChange={e => handleChange(e)}>
                                                    <option value="">Seleccione</option>
                                                    {manufacturers?.map(elem => {
                                                        return <option key={elem.id} value={elem.id}>{elem.name}</option>
                                                    })}
                                                </select>
                                                {val.current.message('manufacturerIPedit',
                                                    newInputProduct.manufacturer, 'required',
                                                    { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="type_input_product">Tipo</label>
                                                {!willCreateTypeInputProduct ?
                                                    <span className="cursor-pointer text-info ms-1" onClick={() => setWillCreateTypeInputProduct(true)}>
                                                        Nuevo
                                                    </span> :
                                                    <span className="d-block col-8 mx-auto">
                                                        <CreateTypeInputProduct
                                                            willCreateTypeInputProduct={willCreateTypeInputProduct}
                                                            setWillCreateTypeInputProduct={setWillCreateTypeInputProduct}
                                                            cancelButton={'true'}
                                                            handleSubmit={saveNewTypeInputProduct}
                                                            newTypeInputProduct={newTypeInputProduct}
                                                            setNewTypeInputProduct={setNewTypeInputProduct}
                                                            val={val}
                                                            forceUpdate={forceUpdate}
                                                        />
                                                    </span>
                                                }
                                                <select id='type_input_productInputIPedit' name="type_input_product" className="form-select"
                                                    onChange={e => handleChange(e)} >
                                                    <option value="">Seleccione</option>
                                                    {typesInputProduct?.map(elem => {
                                                        return <option key={elem.id} value={elem.id}>{elem.name}</option>
                                                    })}
                                                </select>
                                                {val.current.message('type_input_productIPedit',
                                                    newInputProduct.type_input_product,
                                                    'required',
                                                    { className: 'text-danger' })}
                                            </div>
                                            <div className="form-group mb-3 d-flex align-items-center">
                                                <label htmlFor="is_serializable">Serializable</label>
                                                <input
                                                    id='is_serializableInputIPedit'
                                                    type="checkbox"
                                                    name="is_serializable"
                                                    className="checkbox form-check-input"
                                                    onChange={e => handleChange(e)}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-around">
                                                <button type="button" className="btn btn-info text-white" onClick={e => {
                                                    if (!val.current.fieldValid('skuIPedit') ||
                                                        !val.current.fieldValid('nameIPedit') ||
                                                        !val.current.fieldValid('manufacturerIPedit') ||
                                                        !val.current.fieldValid('type_input_productIPedit')) {
                                                        val.current.showMessages()
                                                        forceUpdate(1)
                                                    } else {
                                                        handleSubmit(e, newInputProduct)
                                                    }
                                                }}>
                                                    GUARDAR
                                                </button>
                                                {!willCreateManufacturer && !willCreateTypeInputProduct && cancelButton &&
                                                    <input type="button" value="CANCELAR" className="btn btn-info btn-sm text-info bg-transparent"
                                                        onClick={() => toggleValues()} />
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    setWillCreateTypeInputProduct(false)
                                    setWillCreateManufacturer(false)
                                    setNewTypeInputProduct({})
                                    setNewManufacturer({})
                                    document.getElementById('editInputProductForm').reset()
                                    hideModal('editInputProduct')
                                }}>CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}