import { useRef, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FcSearch } from 'react-icons/fc'
import { GrClose } from 'react-icons/gr'

import PaymentInstallmentContext from '../../../Contexts/PaymentInstallmentContext';
import OrderService from '../../../Services/Licenses/OrderService'
import { filterPaymentInstallment } from '../../../Utilities/PaymentInstallment/Pagination'

export default function TableOrdersHeader({
    setLoadingPage
}) {

    const {
        setPaymentsInstallments: setData,
        setNextPage,
        setPreviousPage,
        setTotalRegisters,
        setPageSize
    } = useContext(PaymentInstallmentContext)


    const navigate = useNavigate()
    const { pathname: path } = useLocation()

    const stringRef = useRef(null)
    const statusRef = useRef('')
    const countryRef = useRef('')


    const handleSearch = () => {
        let str = stringRef.current.value
        let fStr = str.trim().replaceAll(' ', '%20')
        let fInt = parseInt(str.trim())
        if (!isNaN(fInt) && str.length > 0) {
            setLoadingPage(true)
            filterPaymentInstallment(`?order=${fInt}`)
                .then(data => {
                    setTotalRegisters(data.length)
                    setNextPage(null)
                    setPreviousPage(null)
                    setData(data)
                    setPageSize(data.length)
                    setLoadingPage(false)
                })
            return
        }
    }

    // se comentó esta función porque no se está utilizando
    const handleChangeSearch = () => {
        let str = stringRef.current.value
        if (str.length === 0) {
            setLoadingPage(true)
            OrderService.getPaymentInstallment({
                ordering: '-id',
                limit: 10
            }).then(data => {
                if (!data) return
                setTotalRegisters(data.count)
                setNextPage(data.next)
                setPreviousPage(data.previous)
                setData(data.results)
                setPageSize(10)
                setLoadingPage(false)
            })
        }
    }

    const resetFilter = () => {
        let str = stringRef.current.value
        if (str.length > 0) {
            document.getElementById('searchInput').value = ''
            str = ''
            handleChangeSearch()
        }
    }

    return (
        <div className='my-4'>
            <div className="d-flex mx-1 mb-2 col-5">
                <div className="input-group me-2">
                    <input
                        id="searchInput"
                        type="text"
                        className="form-control"
                        placeholder="Buscar..."
                        aria-label="Buscar..."
                        aria-describedby="basic-addon2"
                        ref={stringRef}
                        // onChange={() => handleChangeSearch()}
                    />
                    <span
                        className="input-group-text only-cursor-pointer"
                        id="basic-addon2"
                        onClick={() => resetFilter()}
                    >
                        <GrClose />
                    </span>
                    <span
                        className="input-group-text only-cursor-pointer"
                        id="basic-addon2"
                        onClick={() => handleSearch()}
                    >
                        <FcSearch />
                    </span>
                </div>
            </div>
        </div>
    )
}