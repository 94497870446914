import React from "react";

export default function CreateSupplier({
    handleSubmit,
    newSupplier,
    handleChange,
    val,
    forceUpdate
}) {

    return (

        <form id="newSupplierForm" className="col-5">
            <div className="form-group mb-2">
                <label htmlFor="name">Nombre</label>
                <input type="text" name="name" className="form-control" onChange={e => handleChange(e)} />
                {val.current.message('nameSup', newSupplier.name, 'required|max:200|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&=<>+]', { className: 'text-danger' })}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="vat">CUIT/CUIL/RUT</label>
                <input type="text" name="vat" className="form-control" onChange={e => handleChange(e)} />
                {val.current.message('vat', newSupplier.vat, 'required|max:200|alpha_num_space', { className: 'text-danger' })}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="observation">Observaciones</label>
                <textarea
                    type="text"
                    name="observation"
                    className="form-control textarea"
                    onChange={e => handleChange(e)}
                ></textarea>
                {val.current.message('observation', newSupplier.observation, 'max:500', { className: 'text-danger' })}
            </div>
            <div className="d-flex justify-content-around">
                <button id="submit_supplier" type="button" className="btn btn-info text-white"
                    onClick={() => {
                        if (!val.current.fieldValid('nameSup') ||
                            !val.current.fieldValid('vat') ||
                            !val.current.fieldValid('observation')) {
                            val.current.showMessages()
                            forceUpdate(1)
                        } else {
                            handleSubmit('create')
                        }
                    }}>
                    GUARDAR
                </button>
            </div>
        </form>

    )

}