import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import swal from 'sweetalert';

import validator from '../../Utilities/General/Validator';
import Cripto from "../../Utilities/Auth/Crypto";
import { isAuth } from "../../Utilities/Auth/AuthPermissions";
import AuthService from "../../Services/Licenses/AuthService";

export default function Login({
  fromOutside,
  hideModal
}) {

  const [form, setForm] = useState({})

  const [loading, setLoading] = useState(null)
  const [status, setStatus] = useState(null)

  const val = validator.rules;

  let navigate = useNavigate()
  let location = useLocation()

  const handleChange = e => {
    setLoading(0);
    setStatus(null);
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleLogin = e => {
    e.preventDefault();

    if (val.fieldValid('username') &&
      val.fieldValid('password')) {
      setLoading(1);
      setStatus(null);

      AuthService.login(form)
        .then(data => {
          if (data !== undefined) {
            let flag = false
            for (let i = 0; i < data.user.groups.length; i++) {
              if (data.user.groups[i].name.includes('dashboard')) {
                flag = true
              }
            }

            if (flag) {
              let i = 0;
              let groups = [];
              while (i < data.user.groups.length) {
                groups.push(data.user.groups[i].name);
                i++;
              }

              let auth = {
                scopes: groups,
                username: data.user.username,
                person: data.user.first_name + ' ' + data.user.last_name,
                token: data.token,
              }

              let hash = Cripto.encrypt(JSON.stringify(auth));
              localStorage.setItem('usound', hash);

              if (fromOutside) {
                setStatus('logged from outside')
              } else {
                setStatus('success')
              }

            } else {
              swal({
                title: 'Acceso no permitido',
                text: 'No tiene permiso para acceder a este sistema.',
                icon: 'warning'
              }).then(accept => {
                if (accept) {
                  setLoading(null)
                }
              })
            }
          } else {
            setStatus('error')
          }
        })
    } else {
      val.showMessages();
    }
  }

  if (status === 'success') {
    navigate('/inicio')
  }

  if (status === 'logged from outside') {
    hideModal('loginModal')
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }

  if ((location.pathname === '/login' || location.pathname === '/') && isAuth()) {
    window.location.href = '/inicio'
  }

  return (

    <div className="login-container d-flex align-items-center w-100 vh-100">
      <div className="container col-lg-4 p-3 py-5 bg-white rounded">
        {!fromOutside &&
          <h1 className="text-align-center col-lg-8 mx-auto title-welcome">
            Bienvenidos
          </h1>
        }
        {fromOutside &&
          <h5 className="text-success col-lg-8 mx-auto text-center">
            Inicie sesión para acceder al sistema
          </h5>
        }
        <form className="col-lg-8 mx-auto mt-3" onSubmit={e => handleLogin(e)}>
          {status === 'error' &&
            <small className="text-white bg-danger p-1 rounded d-block mx-auto text-align-center mb-2">
              Usuario o contraseña incorrectos.
            </small>
          }
          <div className="form-group">
            <label htmlFor="username">Nombre de usuario</label>
            <input type="text" name="username" className="form-control mt-1" onChange={handleChange} />
            {val.message('username', form.username, 'required|alpha_num_space', { className: 'text-danger' })}
          </div>
          <div className="form-group mt-3">
            <label htmlFor="password">Contraseña</label>
            <input type="password" name="password" className="form-control mt-1" onChange={handleChange} />
            {val.message('password', form.password, 'required', { className: 'text-danger' })}
          </div>
          {loading === 1 && status !== 'error' && status !== 'network error' ?
            <button type="button" className="btn btn-info text-white mt-4 d-block w-100 mx-auto" disabled>
              Autenticando...
            </button> :
            <input
              id="submitButton"
              type="submit"
              className="btn btn-info text-white mt-4 d-block w-100 mx-auto"
              value="INICIAR SESIÓN"
            />
          }
        </form>
      </div>
    </div>

  );
}



