import React from "react";

export default function Assembling() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Grupo_1066" data-name="Grupo 1066" transform="translate(-363 -378)">
                    <g id="Grupo_1063" data-name="Grupo 1063" transform="translate(-109)">
                        <g id="Grupo_1061" data-name="Grupo 1061" transform="translate(-129)">
                            <g id="Grupo_751" data-name="Grupo 751" transform="translate(601 378)">
                                <circle id="Elipse_9" data-name="Elipse 9" cx="12" cy="12" r="12" fill="#0081cb" />
                            </g>
                        </g>
                    </g>
                    <g id="Grupo_1065" data-name="Grupo 1065" transform="translate(-5339.974 -2043.248)">
                        <path id="Trazado_644" data-name="Trazado 644" d="M5714.441,2426.44h.213c.039.018.076.04.116.055l6.653,2.5a.469.469,0,0,1,.364.519V2437a.459.459,0,0,1-.352.512q-3.327,1.248-6.652,2.5a.612.612,0,0,1-.487-.011q-2.88-1.239-5.763-2.469a.571.571,0,0,1-.374-.364v-7.852a.708.708,0,0,1,.372-.337q2.161-.92,4.318-1.848C5713.381,2426.9,5713.911,2426.67,5714.441,2426.44Zm6.494,3.6c-.047.016-.077.025-.1.036-.5.187-.994.376-1.493.557-.091.033-.107.079-.107.164,0,.825,0,1.65,0,2.476a.461.461,0,0,1-.358.521q-.771.291-1.542.579a.438.438,0,0,1-.655-.45q0-1.065,0-2.129v-.154c-.057.019-.1.031-.139.046-.481.18-.961.363-1.444.538a.159.159,0,0,0-.123.18q0,3.241,0,6.481c0,.042,0,.083.007.135.05-.017.088-.028.125-.042q2.861-1.073,5.723-2.143c.1-.036.11-.087.11-.175q0-3.241,0-6.481Zm-11.919.035c0,.037,0,.054,0,.072q0,3.279,0,6.558c0,.09.039.118.112.149q2.436,1.041,4.871,2.086c.039.017.078.03.13.049v-.167q0-3.206,0-6.412a.193.193,0,0,0-.138-.214q-2.419-1.029-4.835-2.068C5709.112,2430.11,5709.071,2430.1,5709.017,2430.074Zm.662-.652c.076.034.124.056.172.077,1.432.614,2.869,1.219,4.295,1.849a.918.918,0,0,0,.834.017c.3-.14.614-.238.945-.362-.063-.029-.1-.048-.142-.065-.446-.185-.895-.363-1.336-.557a.612.612,0,0,1-.267-.221.416.416,0,0,1,.255-.608c.521-.224,1.046-.439,1.564-.668a.59.59,0,0,1,.521.006q1.094.5,2.2.982a.292.292,0,0,0,.2.008c.371-.131.74-.272,1.108-.411.03-.011.058-.029.1-.052-.051-.022-.083-.036-.114-.048q-2.668-1-5.336-2a.293.293,0,0,0-.239.008q-2.3.989-4.6,1.974C5709.79,2429.368,5709.747,2429.39,5709.678,2429.422Zm8.7,1.572c-.169.063-.326.121-.482.18-.12.045-.286.062-.344.149s-.025.255-.025.386c0,.553,0,1.106,0,1.676.276-.1.541-.2.8-.3.027-.01.047-.07.047-.106,0-.226,0-.452,0-.677Zm-.7-.649c-.478-.214-.925-.414-1.373-.613a.131.131,0,0,0-.088-.012c-.183.074-.364.154-.57.242l1.417.582a.115.115,0,0,0,.075,0C5717.312,2430.484,5717.48,2430.42,5717.681,2430.345Z" transform="translate(0 0)" fill="#fff" />
                        <path id="Trazado_645" data-name="Trazado 645" d="M5759.721,2704.034a.727.727,0,0,1,.128.033c.567.239,1.134.478,1.7.722a.419.419,0,0,1,.256.451.424.424,0,0,1-.391.357.461.461,0,0,1-.206-.035q-.845-.354-1.687-.717a.416.416,0,0,1-.254-.486A.431.431,0,0,1,5759.721,2704.034Z" transform="translate(-49.39 -268.34)" fill="#fff" />
                    </g>
                </g>
            </svg>
        </div>
    )
}