import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { useParams } from "react-router";
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FcDocument, FcApproval, FcUndo } from 'react-icons/fc'
import { IoDocumentTextSharp } from "react-icons/io5";
import PrincipalBar from '../../AppComponents/Commons/PrincipalBar';
import OrdersPipeline from '../../AppComponents/Orders/OrdersPipeline';
import ViewOrder from '../../AppComponents/Orders/ViewOrder';
import OrderDataConfirm from '../../AppComponents/Orders/OrderDataConfirm';
import OrderContext from '../../Contexts/OrderContext';
import TableBackPaginated from '../../AppComponents/Orders/TableBackPaginated';
import Loader from '../../AppComponents/Commons/Loader';
import FirstTitle from '../../AppComponents/Commons/FirstTitle';
import useDistCenters from '../../Hooks/Stock/useDistCenters';
import useClientBillingData from '../../Hooks/Commercial/useClientBillingData'

import OrderService from '../../Services/Licenses/OrderService';
import PaymentService from '../../Services/Licenses/PaymentService'
import InputProductService from '../../Services/Stock/InputProductService'
import SaleProductService from '../../Services/Stock/SaleProductService'
import UsoundPaymentsService from '../../Services/UsoundPayments/UsoundPaymentsService';
import ClientService from '../../Services/Licenses/ClientService'
import StockOrderService from '../../Services/Stock/StockOrderService'
import MovementService from '../../Services/Stock/MovementService'
import { getToken, getUsername, isAdmin, isAuth, isCustomerSuccess, isManufacturer } from '../../Utilities/Auth/AuthPermissions';
import { hideTooltip, showTooltip, hideModal, showModal } from '../../Utilities/General/BootstrapApi';
import { toggleDisabled } from '../../Utilities/General/HandleClick'

import Send from '../../Assets/Send';
import Accept from '../../Assets/Accept';
import Assembling from '../../Assets/Assembling';
import Delete from '../../Assets/Delete';

export default function WorkOrders() {

    const {
        orders,
        setOrders,
        setNextPage,
        setPreviousPage,
        setTotalRegisters
    } = useContext(OrderContext)

    const [clientBillingData, setClientBillingData] = useClientBillingData()

    const [typesInputProduct, setTypesInputProduct] = useState([]);
    const [typeOrderStatus, setTypeOrderStatus] = useState([]);
    const [clients, setClients] = useState([]);
    const [countries, setCountries] = useState([]);
    const [articles, setArticles] = useState([])
    const [sellers, setSellers] = useState([])
    const [stockOrders, setStockOrders] = useState([])
    const [inputProducts, setInputProducts] = useState([]);
    const [saleProducts, setSaleProducts] = useState([]);
    const [saleProductStock, setSaleProductStock] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsStock, setItemsStock] = useState([]);
    const [emailContacts, setEmailContacts] = useState([]);
    const [uSoundPaymentOrders, setUsoundPaymentOrders] = useState([])
    const [usoudPaymentProducts, setUsoundPaymentProducts] = useState([])
    const [customers, setCustomers] = useState([])

    const [newPAO, setNewPAO] = useState({
        ordersaleproductitem_set: []
    })

    const [newMovement, setNewMovement] = useState({
        movementdetail_set: []
    });

    const [newFile, setNewFile] = useState()

    const [toOrderStatus, setToOrderStatus] = useState(null);
    const [workOn, setWorkOn] = useState(null);
    const [workOnParent, setWorkOnParent] = useState(null);
    const [workOnStockOrder, setWorkOnStockOrder] = useState(null);
    const [orderFromMail, setOrderFromMail] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingPAO, setLoadingPAO] = useState(false);
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

    let navigate = useNavigate()
    let params = useParams()

    const distCenters = useDistCenters()

    const cancelObsRef = useRef('')

    useEffect(() => {
        if (params.id !== undefined) {
            setOrderFromMail(true)
        } else {
            setOrderFromMail(false)
        }
    }, [])

    useEffect(() => {
        OrderService.getOrders({
            ordering: '-orderbillingdata__timestamp&no_orderbillingdata=False',
            limit: 10
        }).then(data => {
            if (!data) return
            setTotalRegisters(data.count)
            setNextPage(data.next)
            setPreviousPage(data.previous)
            setOrders(data.results)
        })
        PaymentService.getSellers().then(data => setSellers(data))
        PaymentService.getCountries().then(data => setCountries(data))
        PaymentService.getArticles()
            .then(data => setArticles(data?.sort((a, b) => {
                if (a.name < b.name) { return -1 }
                if (a.name > b.name) { return 1 }
                return 0
            })))
        InputProductService.getTypesInputProduct().then(data => setTypesInputProduct(data))
        InputProductService.getInputProducts().then(data => setInputProducts(data))
        OrderService.getTypeOrderStatus().then(data => setTypeOrderStatus(data))
        SaleProductService.getSaleProducts().then(data => setSaleProducts(data))
        SaleProductService.getSaleProductStock().then(data => setSaleProductStock(data))
        ClientService.getClients().then(data => setClients(data))
        ClientService.getEmailContacts().then(data => setEmailContacts(data))
        StockOrderService.getStockOrders().then(data => setStockOrders(data))
        MovementService.getItems().then(data => setItems(data))
        MovementService.getItemsStock().then(data => setItemsStock(data))
        UsoundPaymentsService.getOrders().then(data => {
            setUsoundPaymentOrders(data)
            setLoading(false)
        })
        UsoundPaymentsService.getProducts().then(data => setUsoundPaymentProducts(data))
        UsoundPaymentsService.getCustomers().then(data => setCustomers(data))
    }, []);

    // useEffect(() => {
    //     if (isAuth()) {
            
    //     }
    // }, [stockOrders])

    const columnsOrders = useMemo(
        () => [
            {
                Header: "#",
                accessor: "id",
                Cell: ({ value }) => {
                    return (
                        <div className="cursor-pointer"
                            id={'viewOrderDiv' + value}
                            onMouseEnter={() => showTooltip('viewOrderDiv' + value, `Ver orden #${value}`)}
                            onMouseLeave={() => hideTooltip('viewOrderDiv' + value)}
                            onClick={() => {
                                setWorkOn(parseInt(value));
                                showModal('viewOrderModal')
                            }}
                        >
                            <span>{value}</span>
                        </div>
                    )
                }
            },
            {
                Header: "Ingreso",
                accessor: order => {
                    let [paid] = order.orderstatus_set.filter(os => os.type_order_status_spanish_name === 'Pagada')
                    let [rep] = order.orderstatus_set.filter(os => os.type_order_status_spanish_name === 'Reposición')
                    return paid?.timestamp || rep?.timestamp
                },
                Cell: ({ value }) => {
                    if (value !== undefined) {
                        return format(new Date(value), "dd/MM/yyyy HH:mm");
                    }
                    return 'No válido'
                }
            },
            {
                Header: "Cliente",
                disableSortBy: true,
                accessor: order => {
                    let [client] = clients.filter(c => c.id === order.new_client)
                    let [customer] = customers.filter(c => c.id === parseInt(order.payments_service_customer_id))
                    return client?.name || customer?.name
                }
            },
            {
                Header: 'Artículos',
                disableSortBy: true,
                accessor: order => {
                    let freemiumCounter = 0
                    order.paymentdetail_set.forEach(item => {
                        if (item.article_name === 'Audiómetro Freemium') {
                            freemiumCounter++
                        }
                    })
                    return <>
                        {order.paymentdetail_set.sort((a, b) => {
                            if (a.article_name < b.article_name) { return -1; }
                            if (a.article_name > b.article_name) { return 1; }
                            return 0;
                        }).map((item, idx) => {
                            if (item.article_name === 'Audiómetro Freemium' &&
                                order.paymentdetail_set[idx - 1]?.article_name !== 'Audiómetro Freemium') {
                                return (
                                    <p key={idx} className={idx >= 1 ? "d-block mt-4" : undefined}>
                                        {'(' + freemiumCounter + ') ' + (item.article_alias !== null ? item.article_alias : item.article_name)}
                                    </p>
                                )
                            }
                            if (item.article_name !== 'Audiómetro Freemium') {
                                return (
                                    <p key={idx} className={idx >= 1 ? "d-block mt-4" : undefined}>
                                        {'(' + item.count + ') ' + (item.article_alias !== null ? item.article_alias : item.article_name)}
                                    </p>
                                )
                            }
                        })}
                    </>
                }
            },
            {
                Header: 'Dirección',
                disableSortBy: true,
                accessor: order => {
                    let osd = order.ordershippingdata
                    let obd = order.orderbillingdata
                    if (osd !== null) {
                        return `${osd.address_address} ${osd.address_number}, ${osd.address_city}`
                    }
                    if (obd !== null) {
                        return `${obd.address_address} ${obd.address_number}, ${obd.address_city}`
                    }
                    return order.shipping_address
                }
            },
            {
                Header: 'País',
                disableSortBy: true,
                accessor: order => {
                    let osd = order.ordershippingdata
                    let obd = order.orderbillingdata
                    if (osd !== null) {
                        let [country] = countries.filter(c => c.id === osd.address_country)
                        return country?.name
                    }
                    if (obd !== null) {
                        let [country] = countries.filter(c => c.id === obd.address_country)
                        return country.name
                    }
                    return countries.map(c => {
                        if (c.id === parseInt(order.shipping_address_country)) {
                            return c.name
                        }
                    })
                }
            },
            {
                Header: 'Guía de envío',
                disableSortBy: true,
                accessor: order => {
                    let osd = order.ordershippingdata
                    if (osd !== null && osd.shipping_guide_url !== null) {
                        return (
                            <button
                                type='button'
                                onClick={() => window.open(osd.shipping_guide_url, '_blank')}
                                className="btn btn-sm text-white downloadButtons"
                            >
                                Descargar
                            </button>
                        )
                    }
                    if (isCustomerSuccess()) return 'Sin cargar'
                    return (
                        <div className='d-flex align-items-center'>
                            <div className=''>
                                <input id={'fileInput' + order.id} type='file' className='form-control' onChange={e => handleFile(e, order.id)} />
                            </div>
                            <div
                                id={'shippingGuideSection' + order.id}
                                className='d-flex align-items-center justify-content-center fs-4 ms-2 d-none'
                            >
                                <span
                                    id={'approveButton' + order.id}
                                    className='actions'
                                    onMouseEnter={() => showTooltip('approveButton' + order.id, 'Guardar')}
                                    onMouseLeave={() => hideTooltip('approveButton' + order.id)}
                                >
                                    <FcApproval onClick={() => {
                                        saveFile(order.id)
                                        document.getElementById('shippingGuideSection' + order.id).classList.add('d-none')
                                    }} />
                                </span>
                                <span
                                    id={'undoButton' + order.id}
                                    className='actions'
                                    onMouseEnter={() => showTooltip('undoButton' + order.id, 'Deshacer')}
                                    onMouseLeave={() => hideTooltip('undoButton' + order.id)}
                                >
                                    <FcUndo onClick={() => {
                                        document.getElementById('fileInput' + order.id).value = ''
                                        document.getElementById('shippingGuideSection' + order.id).classList.add('d-none')
                                    }} />
                                </span>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: "Estado",
                accessor: order => {
                    let allStatus = order.orderstatus_set.sort((a, b) => b.id - a.id).filter(os => os.type_order_status_trunk)
                    let display = allStatus[0]?.type_order_status_spanish_name || 'No válido'
                    return [display, order.id].toString();
                },
                Cell: ({ value }) => {
                    const [display, id] = value.split(',')
                    return <button
                        type="button"
                        id={'pipelineButton' + id}
                        className={
                            (display === 'Pagada' || display === 'Reposición') ?
                                "btn btn-sm paid-order text-white" :
                                display === 'Aceptada' ?
                                    "btn btn-sm accepted-order text-white" :
                                    display === 'Armando' ?
                                        "btn btn-sm assembling-order text-white" :
                                        display === 'Enviada' ?
                                            "btn btn-sm sended-order text-white" :
                                            display === 'Recibida' ?
                                                "btn btn-sm btn-primary text-white" :
                                                display === 'Finalizada' ?
                                                    "btn btn-sm btn-success text-white" :
                                                    display === 'Anulada' ?
                                                        "btn btn-sm canceled-order text-white" :
                                                        "btn btn-sm btn-secondary text-white"
                        }
                        onMouseEnter={() => showTooltip('pipelineButton' + id, `Ver historial de orden #${id}`)}
                        onMouseLeave={() => hideTooltip('pipelineButton' + id)}
                        onClick={() => {
                            setWorkOn(parseInt(id));
                            showModal('orderPipeline')
                        }}
                    >
                        {display}
                    </button>
                }
            },
            {
                Header: "Acciones",
                disableSortBy: true,
                accessor: order => {
                    let obd = order.orderbillingdata
                    //TODO se podría reutilizar GetCurrentStatus.js para obtener el úlitmo status en este caso
                    let allStatus = order.orderstatus_set.filter(os => os.type_order_status_trunk).sort((a, b) => b.id - a.id)
                    let [currentStatus] = typeOrderStatus.filter(tos => tos.id === allStatus[0].type_order_status)
                    return (
                        <div className="d-flex justify-content-start">
                            {(((currentStatus.name === 'accepted' || currentStatus.name === 'paid' || currentStatus.name === 'assembling') && isManufacturer()) ||
                                (currentStatus.name !== 'canceled' && isAdmin())) &&
                                <span
                                    id={'deleteButton' + order.id}
                                    className="actions me-1"
                                    onMouseEnter={() => showTooltip('deleteButton' + order.id, 'Anular orden #' + order.id)}
                                    onMouseLeave={() => hideTooltip('deleteButton' + order.id)}
                                    onClick={() => {
                                        setToOrderStatus('canceled')
                                        setWorkOn(parseInt(order.id))
                                        showModal('orderDataConfirm')
                                    }}
                                >
                                    <Delete />
                                </span>
                            }
                            {((currentStatus.name === 'paid' || currentStatus.name === 'replacement') && (isManufacturer() || isAdmin())) &&
                                <span
                                    id={'acceptButton' + order.id}
                                    className="actions me-1"
                                    onMouseEnter={() => showTooltip('acceptButton' + order.id, 'Aceptar orden #' + order.id)}
                                    onMouseLeave={() => hideTooltip('acceptButton' + order.id)}
                                    onClick={() => {
                                        setToOrderStatus('accepted')
                                        setWorkOn(parseInt(order.id));
                                        showModal('orderDataConfirm')
                                    }}>
                                    <Accept />
                                </span>
                            }
                            {(currentStatus.name === 'accepted' && (isManufacturer() || isAdmin())) &&
                                <span
                                    id={'assembleButton' + order.id}
                                    className="actions me-1"
                                    onMouseEnter={() => showTooltip('assembleButton' + order.id, 'Armar orden #' + order.id)}
                                    onMouseLeave={() => hideTooltip('assembleButton' + order.id)}
                                    onClick={() => {
                                        setToOrderStatus('assembling')
                                        setWorkOn(parseInt(order.id));
                                        showModal('orderDataConfirm')
                                    }}>
                                    <Assembling />
                                </span>
                            }
                            {(currentStatus.name === 'assembling' && (isManufacturer() || isAdmin())) &&
                                <span
                                    id={'sendButton' + order.id}
                                    className="actions"
                                    onMouseEnter={() => showTooltip('sendButton' + order.id, 'Enviar orden #' + order.id)}
                                    onMouseLeave={() => hideTooltip('sendButton' + order.id)}
                                    onClick={() => {
                                        setToOrderStatus('sended')
                                        setWorkOn(parseInt(order.id));
                                        showModal('orderDataConfirm')
                                    }}>
                                    <Send />
                                </span>
                            }
                            {(obd !== null && obd.invoice_url !== null) &&
                                <span
                                    id={'downloadBillButton' + order.id}
                                    className='actions fs-3 d-flex align-items-center'
                                    onMouseEnter={() => showTooltip('downloadBillButton' + order.id, 'Descargar factura de orden #' + order.id)}
                                    onMouseLeave={() => hideTooltip('downloadBillButton' + order.id)}
                                    onClick={() => {
                                        window.open(obd.invoice_url, '_blank')
                                    }}
                                >
                                    <FcDocument />
                                </span>
                            }
                            {(obd !== null && obd.invoice_autogenerated_url !== null) &&
                                <span
                                id={'downloadInvoiceButton' + order.id}
                                className='actions fs-3 d-flex align-items-center'
                                onMouseEnter={() => showTooltip('downloadInvoiceButton' + order.id, 'Descargar factura autogenerada de orden #' + order.id)}
                                onMouseLeave={() => hideTooltip('downloadInvoiceButton' + order.id)}
                                onClick={() => {
                                    window.open(obd.invoice_autogenerated_url, '_blank')
                                }}
                                >
                                <IoDocumentTextSharp style={{ color: 'red' }}/>
                                </span>
                            }
                        </div>
                    );
                },
            }
        ],
        [clients, typeOrderStatus, customers, countries, newFile]
    );

    const handleFile = (e, id) => {
        setWorkOn(id)
        const [file] = e.target.files
        Object.defineProperties(file, {
            name: {
                value: `guia-de-envio-${id}.pdf`
            }
        })
        setNewFile(file)
        handleFileSelects(id)
    }

    const handleFileSelects = id => {
        orders.sort((a, b) => b.id - a.id).forEach(order => {
            if (order.id === id) {
                document.getElementById('shippingGuideSection' + id).classList.remove('d-none')
            } else {
                let input = document.getElementById('fileInput' + order.id)
                if (input !== null) input.value = ''
                document.getElementById('shippingGuideSection' + order.id)?.classList.add('d-none')
            }
        })
    }

    const saveFile = id => {

        const type = newFile.type.split('/')[1]

        if (type !== 'pdf') {
            toast.error('Formato incorrecto. La extensión del archivo debe ser .pdf', {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
            return
        }

        toast.warning(`Enviando archivo. Esto puede tardar un momento...`, {
            position: 'bottom-left',
            hideProgressBar: true,
            className: 'warning-toast',
            autoClose: false
        })

        OrderService.saveShippingGuide(newFile)
            .then(() => {
                let order = orders.find(o => o.id === workOn)
                let osd = order.ordershippingdata

                if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                    osd.shipping_guide_url = `https://ordenes-compra.s3.amazonaws.com/facturas/guiasenvio/${newFile.name}`
                } else {
                    osd.shipping_guide_url = `https://ordenes-compra.s3.amazonaws.com/facturas-testing/guiasenvio-testing/${newFile.name}`
                }

                OrderService.updateOrderShippingData(osd.id, osd)
                    .then(data => {
                        setOrders([
                            ...orders.filter(item => item.id !== order.id),
                            {
                                ...order,
                                ordershippingdata: data
                            }
                        ])
                        toast.dismiss()
                        toast.success(`Guía de envío guardada correctamente`, {
                            position: 'bottom-left',
                            hideProgressBar: true,
                            className: 'success-toast'
                        })
                    })
            }).catch(() => document.getElementById('shippingGuideSection' + id).classList.remove('d-none'))
    }

    const handleCancel = (rowIndex) => {
        const id = rowIndex.id;
        let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'canceled')

        OrderService.checkCanceled(id, typeOrderStatus, getToken())
            .then(isCancelled => {
                if (isCancelled) {

                    toast.error(`La orden #${id} ya se encuentra anulada. Refresque la página para actualizar estados.`, {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                    })

                } else {
                    OrderService.saveNewOrderStatus({
                        order: id,
                        type_order_status: currentStatus.id,
                        user_name: getUsername(),
                        observation: cancelObsRef.current.value
                    })
                        .then(data => {
                            let [order] = orders.filter(item => item.id === data.order)
                            order.orderstatus_set = [...order.orderstatus_set, data]
                            setOrders([...orders.filter(item => item.id !== order.id), order])
                            OrderService.sendMailOrderStatus(id, 'canceled', 'anulada')
                            hideModal('orderDataConfirm')
                            document.getElementById('obsTextarea').value = ''
                            toast.success(`Orden #${id} anulada correctamente`, {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                            setLoadingChangeStatus(false)
                        })
                }
            })
    };

    const handleAccept = order => {

        // pido de nuevo las ordenes del servicio de stock
        StockOrderService.getStockOrders()
            .then(data => {
                // actualizo las ordenes del servicio de stock
                setStockOrders(data)
                // veo si se creo una nueva orden de stock ligada a esta orden comercial
                let [currentStockOrder] = data.filter(so => so.order_id === order.id)

                // si no es asi, muestro el mensaje que faltan productos por asignar
                // INDICAR QUE PRODUCTOS FALTAN?
                if (currentStockOrder === undefined) {
                    toast.error('Existen productos sin una licencia asignada.', {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                    })
                    return
                }

                // chqueo que la orden no este ya anulada
                OrderService.checkCanceled(order.id, typeOrderStatus, getToken())
                    .then(isCancelled => {
                        if (isCancelled) {

                            toast.error(`La orden #${order.id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'error-toast'
                            })

                        } else {
                            let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'accepted')
                            OrderService.saveNewOrderStatus({
                                order: order.id,
                                type_order_status: currentStatus.id,
                                user_name: getUsername()
                            })
                                .then(data => {
                                    let [order] = orders.filter(item => item.id === data.order)
                                    order.orderstatus_set = [...order.orderstatus_set, data]
                                    setOrders([...orders.filter(item => item.id !== order.id), order])
                                    // setOrderStatus([...orderStatus, data]);
                                    OrderService.sendMailOrderStatus(order.id, 'accepted', 'aceptada')
                                    //mandar excel en estado aceptada
                                    //OrderService.sendOrderExcel(order.id)
                                    hideModal('orderDataConfirm')
                                    toast.success(`Orden #${order.id} aceptada correctamente`, {
                                        position: 'bottom-left',
                                        hideProgressBar: true,
                                        className: 'success-toast'
                                    })
                                    setLoadingChangeStatus(false)
                                })
                        }
                    })
            })
    }

    const handleAssembling = order => {
        OrderService.checkCanceled(order.id, typeOrderStatus, getToken())
            .then(isCancelled => {
                if (isCancelled) {

                    toast.error(`La orden #${order.id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                    })

                } else {
                    let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'assembling')
                    OrderService.saveNewOrderStatus({
                        order: order.id,
                        type_order_status: currentStatus.id,
                        user_name: getUsername()
                    })
                        .then(data => {
                            let [order] = orders.filter(item => item.id === data.order)
                            order.orderstatus_set = [...order.orderstatus_set, data]
                            setOrders([...orders.filter(item => item.id !== order.id), order])
                            // setOrderStatus([...orderStatus, data]);
                            OrderService.sendMailOrderStatus(order.id, 'assembling', 'armando')
                            hideModal('orderDataConfirm')
                            toast.success(`Se ha confirmado el armado de la orden #${order.id}`, {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                            setLoadingChangeStatus(false)
                        })
                }
            })
    }

    const handleSend = order => {

        let obd = order.orderbillingdata
        if (obd.invoice_url === null && order.new_client !== null) {
            toast.error(`La orden #${order.id} no tiene factura. Contacte al área de Operaciones y Finanzas.`, {
                position: 'bottom-left',
                hideProgressBar: true,
                className: 'error-toast'
            })
            setLoadingChangeStatus(false)
        }

        OrderService.checkCanceled(order.id, typeOrderStatus, getToken())
            .then(isCancelled => {
                if (isCancelled) {

                    toast.error(`La orden #${order.id} ya se encuentra anulada. Refresque la página para actualizar estados`, {
                        position: 'bottom-left',
                        hideProgressBar: true,
                        className: 'error-toast'
                    })

                } else {
                    let [currentStatus] = typeOrderStatus.filter(tos => tos.name === 'sended')
                    OrderService.saveNewOrderStatus({
                        order: order.id,
                        type_order_status: currentStatus.id,
                        user_name: getUsername()
                    })
                        .then(data => {
                            let [order] = orders.filter(item => item.id === data.order)
                            order.orderstatus_set = [...order.orderstatus_set, data]
                            setOrders([...orders.filter(item => item.id !== order.id), order])
                            // setOrderStatus([...orderStatus, data]);
                            OrderService.sendMailOrderStatus(order.id, 'sended', 'enviada')
                            hideModal('orderDataConfirm')
                            toast.success(`Orden #${order.id} enviada correctamente`, {
                                position: 'bottom-left',
                                hideProgressBar: true,
                                className: 'success-toast'
                            })
                            setLoadingChangeStatus(false)
                        })
                }
            })
    }



    

    return (
        <>

            <PrincipalBar />

{/*             <AudiometerConverter
                data={saleProductItemMovementsList?.filter(spiml =>
                    spiml.status === 'inputs products assigned' && spiml.sale_product_sku === 'NBK_AUD_DIAG_0001') || []}
                setStockOrders={setStockOrders}
            /> */}

            <OrdersPipeline
                orderId={workOn}
            />

            <OrderDataConfirm
                order={orders?.filter(item => item.id === workOn)[0]}
                stockOrder={stockOrders?.filter(item => item?.order_id === workOn)[0]}
                stockOrders={stockOrders}
                setStockOrders={setStockOrders}
                clients={clients}
                emails={emailContacts}
                sellers={sellers}
                countries={countries}
                articles={articles}
                toOrderStatus={toOrderStatus}
                hideModal={hideModal}
                handleAccept={handleAccept}
                handleAssembling={handleAssembling}
                handleSend={handleSend}
                handleCancel={handleCancel}
                loadingChangeStatus={loadingChangeStatus}
                setLoadingChangeStatus={setLoadingChangeStatus}
                saleProducts={saleProducts}
                uSoundPaymentOrder={uSoundPaymentOrders?.filter(upo => parseInt(upo.order_id) === workOn)[0]}
                paymentServiceProducts={usoudPaymentProducts}
                customers={customers}
                cancelObsRef={cancelObsRef}
            />

            <div className="modal fade" id="viewOrderModal" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-info" id="staticBackdropLabel">Orden #{workOn}</h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => hideModal('viewOrderModal')}></button>
                        </div>
                        <div className="modal-body">
                            <ViewOrder
                                order={orders?.filter(item => item.id === workOn)[0]}
                                clients={clients}
                                emails={emailContacts}
                                countries={countries}
                                stockOrder={stockOrders?.filter(item => item?.order_id === workOn)[0]}
                                articles={articles}
                                saleProductStock={saleProductStock}
                                workOn={workOn}
                                uSoundPaymentOrder={uSoundPaymentOrders?.filter(upo => parseInt(upo.order_id) === workOn)[0]}
                                paymentServiceProducts={usoudPaymentProducts}
                                customers={customers}
                                typeOrderStatus={typeOrderStatus}
                                clientBillingData={clientBillingData}
                                setClients={setClients}
                                setClientBillingData={setClientBillingData}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => hideModal('viewOrderModal')}>CERRAR</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mx-3 mb-5">
                <FirstTitle text="Órdenes de trabajo" />
                {loading ?
                    <div className='mt-5'>
                        <Loader />
                        <strong className='d-block text-center'>Cargando...</strong>
                    </div> :
                    <TableBackPaginated
                        columns={columnsOrders}
                        data={orderFromMail ?
                            orders?.filter(order => order.id === parseInt(params.id)) :
                            orders?.filter(order => order.orderbillingdata !== null)}
                        setData={setOrders}
                        paymentReplacement="true"
                    />
                }

                {orderFromMail &&
                    <button type="button" className="btn btn-info text-white d-block mx-auto"
                        onClick={() => {
                            setOrderFromMail(false)
                            navigate('/ordenes-trabajo')
                        }}>
                        Ver todas
                    </button>
                }
            </div>

        </>
    )

}