const Urls = {

    urlPayCurrency: process.env.REACT_APP_HOST_NAME + '/general/api/api/currency/',
    urlPayClient: process.env.REACT_APP_HOST_NAME + '/clients/api/api/clients/',
    urlPayClientShippingData: process.env.REACT_APP_HOST_NAME + '/clients/api/api/client-shipping-data/',
    urlPayClientBillingData: process.env.REACT_APP_HOST_NAME + '/clients/api/api/client-billing-data/',
    urlPayTypePhone: process.env.REACT_APP_HOST_NAME + '/clients/api/api/type-phone/',
    urlPayPhoneProvider: process.env.REACT_APP_HOST_NAME + '/clients/api/api/phone-provider/',
    urlPayPhoneContact: process.env.REACT_APP_HOST_NAME + '/clients/api/api/phone-contact/',
    urlPayEmailContact: process.env.REACT_APP_HOST_NAME + '/clients/api/api/email-contact/',
    urlPayTypeIdClient: process.env.REACT_APP_HOST_NAME + '/clients/api/api/type-id-client/',
    urlPaySeller: process.env.REACT_APP_HOST_NAME + '/payments/api/api/sellers/',
    urlPayArticle: process.env.REACT_APP_HOST_NAME + '/payments/api/api/articles/',
    urlPayOrder: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/',
    urlPayPaymentInstallment: process.env.REACT_APP_HOST_NAME + '/payments/api/api/payment-installment/',
    urlPayLogin: process.env.REACT_APP_HOST_NAME + '/general/api/login/',
    urlPayLogout: process.env.REACT_APP_HOST_NAME + '/general/api/logout/',
    urlPayCountry: process.env.REACT_APP_HOST_NAME + '/general/api/api/country/',
    urlPaySendOrder: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/send-order/',
    urlPayOrderStatus: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders-status/',
    urlPaySendMailOrderStatus: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/send-email-order-status/',
    urlPayTypeOrderStatus: process.env.REACT_APP_HOST_NAME + '/general/api/api/type-order-status/',
    urlPayOrderShippingData: process.env.REACT_APP_HOST_NAME + '/payments/api/api/order-shipping-data/',
    urlPayOrderBillingData: process.env.REACT_APP_HOST_NAME + '/payments/api/api/order-billing-data/',
    urlPayOffice: process.env.REACT_APP_HOST_NAME + '/general/api/api/office/',
    urlPayAutoAssocAudiometer: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/automatic-associate-audiometer/',
    urlPaySendOrderExcel: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/send-order-excel/',
    urlPayAssociatePreassembled: process.env.REACT_APP_HOST_NAME + '/payments/api/api/orders/associate-preassembled/',
    urlPayLicense: process.env.REACT_APP_HOST_NAME + '/license/api/api/license/',

    urlStockLogin: process.env.REACT_APP_HOST_STOCK + '/general/api/login/',
    urlStockLogout: process.env.REACT_APP_HOST_STOCK + '/general/api/logout/',
    urlStockMovement: process.env.REACT_APP_HOST_STOCK + '/inventory/api/movement/',
    urlStockMovementEntry: process.env.REACT_APP_HOST_STOCK + '/inventory/api/movement-entry/',
    urlStockInputProduct: process.env.REACT_APP_HOST_STOCK + '/inventory/api/input-product/',
    urlStockSupplier: process.env.REACT_APP_HOST_STOCK + '/inventory/api/supplier/',
    urlStockManufacturer: process.env.REACT_APP_HOST_STOCK + '/inventory/api/manufacturer/',
    urlStockTypeInputProduct: process.env.REACT_APP_HOST_STOCK + '/inventory/api/type-input-product/',
    urlStockItem: process.env.REACT_APP_HOST_STOCK + '/inventory/api/item/',
    urlStockItemStock: process.env.REACT_APP_HOST_STOCK + '/inventory/api/item-stock/',
    urlStockSaleProduct: process.env.REACT_APP_HOST_STOCK + '/inventory/api/sale-product/',
    urlStockInputProductStock: process.env.REACT_APP_HOST_STOCK + '/inventory/api/input-product-stock/',
    urlStockSaleProductStock: process.env.REACT_APP_HOST_STOCK + '/inventory/api/sale-product-stock/',
    urlStockOrder: process.env.REACT_APP_HOST_STOCK + '/inventory/api/order/',
    urlStockSaleProductItemMovementsList: process.env.REACT_APP_HOST_STOCK + '/inventory/api/sale-product-item-movements-list/',
    urlStockSaleProductItem: process.env.REACT_APP_HOST_STOCK + '/inventory/api/sale-product-item/',
    urlStockDistCenter: process.env.REACT_APP_HOST_STOCK + '/inventory/api/distribution-center/',
    urlStockAudiometerConverter: process.env.REACT_APP_HOST_STOCK + '/inventory/api/sale-product-item/audiometer-converter/',

    urlCalProduct: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/product/',
    urlCalLogin: process.env.REACT_APP_HOST_CALIBRATIONS + '/general/api/login/',
    urlCalLogout: process.env.REACT_APP_HOST_CALIBRATIONS + '/general/api/logout/',
    urlCalBuild: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration-build/',
    urlCal: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration/',
    urlCalGroup: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration-group-simple/',
    urlCalLogo: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration-logo/',
    urlCalPureTone: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration-puretone/',
    urlBuildCalibration: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/calibration-build/build-calibration/',
    urlCalSuffix: process.env.REACT_APP_HOST_CALIBRATIONS + '/calibrations/api/suffix/',

    urlUsoundPayProducts: process.env.REACT_APP_USOUND_PAYMENTS_SERVICE + '/products/api/api/products/',
    urlUsoundPayOrder: process.env.REACT_APP_USOUND_PAYMENTS_SERVICE + '/order/api/api/order/',
    urlUsoundPayOrderDetail: process.env.REACT_APP_USOUND_PAYMENTS_SERVICE + '/order/api/api/order-detail/',
    urlUsoundPayCustomer: process.env.REACT_APP_USOUND_PAYMENTS_SERVICE + '/stripe/api/api/customer/',

    urlKitProActivationFull: process.env.REACT_APP_KIT_PRO + '/general/api/api/activation-full/',
    urlKitProPasswordReset: process.env.REACT_APP_KIT_PRO + '/general/api/api/professional/password-reset/'

}

export default Urls