import axios from "axios";
import { toast } from "react-toastify";

import GlobalUrls from '../../Utilities/General/Urls'
import { getToken } from '../../Utilities/Auth/AuthPermissions';
import { errorTimeout, networkError, serverError, expiredSession } from '../../Utilities/General/StatusHandler';

const CalGroupService = {

    getCalGroups: async build => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlCalGroup + `?calibration__calibration_build=${build}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getCalPureTone: async group => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlCalPureTone + `?calibration_group=${group}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    saveNewCalPureTone: async newData => {
        try {
            const { data } = await axios.post(
                GlobalUrls.urlCalPureTone,
                newData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateCalPureTone: async (id, updatedData) => {
        try {
            const { data } = await axios.put(
                GlobalUrls.urlCalPureTone + `${id}`,
                updatedData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    deleteCalPureTone: async id => {
        try {
            await axios.delete(
                GlobalUrls.urlCalPureTone + `${id}`,
                { headers: { Authorization: getToken() } }
            )
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getCalLogo: async group => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlCalLogo + `?calibration_group=${group}`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    updateCalLogo: async (id, updatedData) => {
        try {
            const { data } = await axios.put(
                GlobalUrls.urlCalLogo + `${id}`,
                updatedData,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    },

    getNoiseParameters: async calibration => {
        try {
            const { data } = await axios.get(
                GlobalUrls.urlCal + `${calibration}/`,
                { headers: { Authorization: getToken() } }
            )
            return data
        } catch (err) {
            if (err.message.includes('Network Error')) return networkError()
            if (err.message.includes('500')) return serverError()
            if (err.message.includes('504')) return errorTimeout()
            if (err.message.includes('401') && !toast.isActive('expiredSessionToast')) return expiredSession()
        }
    }

}

export default CalGroupService