import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import ClientService from '../../../Services/Licenses/ClientService';
import { capitalizeEachWord } from '../../../Utilities/General/CapitalizeEachWord';
import { toggleDisabled } from '../../../Utilities/General/HandleClick';

export default function EditClient({
  client,
  setShowClient,
  hideModal,
  countries,
  typeIdClient,
  clients,
  setClients,
  val,
  clientBillingData,
  setClientBillingData
}) {

  const [newClient, setNewClient] = useState({});
  const [newClientBillingData, setNewClientBillingData] = useState({});

  const [, forceUpdate] = useState();

  const type_idRef = useRef();
  const id_clientRef = useRef();
  const nameRef = useRef();
  const countryRef = useRef();
  const noteRef = useRef();
  const razon_socialRef = useRef();
  const carnet_idRef = useRef()

  useEffect(() => {
    setNewClient(clients.filter(c => c.id === client)[0])
    setNewClientBillingData(clientBillingData.filter(cbd => cbd.client === client)[0])
  }, [client])

  useEffect(() => {
    if (newClientBillingData !== undefined && newClient !== undefined) {
      let razon_socialInput = document.getElementById('razon_socialInput')
      let iva = document.getElementById('ivaInput')
      let type = document.getElementById('typeInput')
      let person = document.getElementById('personInput')
      let typeIdInput = document.getElementById('type_idInput')
      let countryInput = document.getElementById('countryInput')
      razon_socialInput.value = newClientBillingData.name
      iva.value = newClientBillingData.iva
      type.value = newClientBillingData.type
      person.value = newClientBillingData.person
      typeIdInput.value = newClient.type_id
      countryInput.value = newClient.country
    }
  }, [newClient, newClientBillingData])

  const handleChange = () => {
    setNewClient({
      ...newClient,
      type_id: type_idRef.current.value,
      id_client: id_clientRef.current.value.trim(),
      name: nameRef.current.value.trim().charAt(0).toUpperCase() + nameRef.current.value.trim().slice(1),
      country: countryRef.current.value,
      note: noteRef.current.value
    });
  }

  const handleChangeClientBillingData = e => {
    setNewClientBillingData({
      ...newClientBillingData,
      client: newClientBillingData.client,
      name: razon_socialRef.current.value,
      carnet_id: carnet_idRef.current.value,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    var v = val;

    if (v.fieldValid('id_client') && v.fieldValid('name') &&
      v.fieldValid('note') && v.fieldValid('razon_social') && v.fieldValid('vat_number')) {

      // pongo en mayusculas la primera letra del nombre del cliente
      newClient.name = capitalizeEachWord(newClient.name)

      let [currentCountry] = countries.filter(c => c.iso_code === newClient.country)
      let [currentTypeId] = typeIdClient.filter(tic => tic.id === parseInt(newClient.type_id))

      // guardo el id del pais del cliente en los datos de facturacion
      newClientBillingData.address_country = currentCountry.id

      toggleDisabled('submit_edit_client')
      ClientService.updateClient(client, newClient, currentTypeId.name, currentCountry.name)
      .then(res => {
          if (res !== undefined && res.status !== 400) {
            setClients([
              ...clients.filter(c => c.id !== res.data.id),
              res.data
            ])
            setNewClient(res.data)
            ClientService.updateClientBillingData(newClientBillingData.id, newClientBillingData)
            .then(data => {
                hideModal('editClientModal')
                document.getElementById('editClientForm').reset();
                setNewClientBillingData(data)
                setClientBillingData([
                  ...clientBillingData.filter(cbd => cbd.id !== newClientBillingData.id),
                  data
                ])
                toggleDisabled('submit_edit_client')
                toast.success('Cliente editado correctamente', {
                  position: 'bottom-left',
                  hideProgressBar: true,
                  className: 'success-toast'
                })
              })
          }
        })
    } else {
      val.showMessages();
      forceUpdate(1)
    }
  }

  return (
    <>
      {(newClient !== undefined && newClientBillingData !== undefined) &&

        <div className="mx-2">

          <div className="row mt-3">

            <div className="row mx-auto">

              <form id="editClientForm" className="d-flex flex-wrap justify-content-around">

                <div className="border border-dark rounded p-3 mb-3 col-12 col-lg-5">

                  <h3 className="d-block mb-3 text-info">Datos personales</h3>
                  <hr />

                  <div className="form-group">
                    <label htmlFor="name" className="mb-2">Nombre y Apellido</label>
                    <input type="text" name="name" defaultValue={newClient.name} ref={nameRef} className="form-control" onChange={handleChange} />
                    {val.message('name', newClient.name, 'required|max:150|regex:[A-Za-zÑñÁáÉéÍíÓóÚúÜü]|not_regex:[0-9-&!"#$%/()=?¿]', { className: 'text-danger' })}
                  </div>

                  <div className="form-group my-3">
                    <label htmlFor="type_id" className="mb-2">Tipo de documento</label>
                    <select id="type_idInput" name="type_id" defaultValue={newClient.type_id} ref={type_idRef} className="form-select" onChange={handleChange}>
                      {typeIdClient.map((tyc, tycIndex) => {
                        return <option key={tycIndex} value={tyc.id}>{tyc.name}</option>
                      })}
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="id_client" className="mb-2">Núm. de documento</label>
                    <input type="text" name="id_client" defaultValue={newClient.id_client} ref={id_clientRef} className="form-control" onChange={handleChange} />
                    {val.message('id_client', newClient.id_client, 'required|regex:[A-Za-z0-9/_-]|not_regex:[¡¿?!"@#¢$%&/()+.]|min:1|max:100', { className: 'text-danger' })}
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="country" className="mb-2">País</label>
                    <select id="countryInput" defaultValue={newClient.country} name="country" ref={countryRef} className="form-select" onChange={handleChange}>
                      {
                        countries.map((country, idx) => {
                          return (
                            <option key={idx} value={country.iso_code}>{country.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="note" className="mb-2">Observaciones</label>
                    <textarea name="note" ref={noteRef} defaultValue={newClient.note} className="form-control textarea" onChange={handleChange}></textarea>
                    {val.message('note', newClient.note, 'max:250', { className: 'text-danger' })}
                  </div>

                </div>

                <div className="border border-dark rounded p-3 mb-3 col-12 col-lg-5">

                  <h3 className="d-block mb-3 text-info">Datos de facturación</h3>
                  <hr />

                  <div className="form-group">
                    <label htmlFor="carnet_id" className="mb-2">Matrícula</label>
                    <input
                      id="carnet_idInput"
                      defaultValue={newClientBillingData.carnet_id}
                      type="text"
                      name="carnet_id"
                      ref={carnet_idRef}
                      className="form-control"
                      onChange={e => handleChangeClientBillingData(e)}
                    />
                    {val.message('carnet_id', newClientBillingData.carnet_id, 'required|regex:[0-9A-Za-z- ]|not_regex:[!"#$%&()@]|max:100', { className: 'text-danger' })}
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="name" className="mb-2">Razón Social</label>
                    <input
                      id="razon_socialInput"
                      type="text"
                      name="razon_social"
                      ref={razon_socialRef}
                      className="form-control"
                      defaultValue={newClientBillingData.name}
                      onChange={e => handleChangeClientBillingData(e)}
                    />
                    {val.message('razon_social', newClientBillingData.name, 'required|max:150|regex:[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü]', { className: 'text-danger' })}
                  </div>

                  <div className="form-group my-3">
                    <label htmlFor="vat_number" className="mb-2">Cuit/Cuil/RUT</label>
                    <input
                      id="vat_numberInput"
                      type="text"
                      name="vat_number"
                      className="form-control"
                      defaultValue={newClientBillingData.vat_number}
                      onChange={e => handleChangeClientBillingData(e)}
                    />
                    {val.message('vat_number', newClientBillingData.vat_number, 'required|max:80', { className: 'text-danger' })}
                  </div>

                  <div className="form-group">
                    <label htmlFor="iva" className="mb-2">Condición Fiscal</label>
                    <select
                      id="ivaInput"
                      name="iva"
                      className="form-select"
                      onChange={e => handleChangeClientBillingData(e)}>
                      <option value="Consumidor Final">Consumidor Final</option>
                      <option value="Responsable Inscripto">Responsable Inscripto</option>
                      <option value="Exento">Exento</option>
                      <option value="Monotributista">Monotributista</option>
                    </select>
                  </div>

                  <div className="form-group my-3">
                    <label htmlFor="type" className="mb-2">Tipo de cliente</label>
                    <select id="typeInput" name="type" className="form-select" onChange={e => handleChangeClientBillingData(e)}>
                      <option value="Cliente Final">Cliente Final</option>
                      <option value="Distribuidor">Distribuidor</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="person" className="mb-2">Persona</label>
                    <select id="personInput" name="person" className="form-select" onChange={e => handleChangeClientBillingData(e)}>
                      <option value="Física">Física</option>
                      <option value="Jurídica">Jurídica</option>
                    </select>
                  </div>

                </div>

              </form>

              <div className="mt-3 mb-3 d-block mx-auto text-center">
                <button
                  type="button"
                  className="btn btn-secondary bg-transparent text-secondary col-5 me-2"
                  onClick={() => {
                    hideModal('editClientModal')
                    val.hideMessages();
                    forceUpdate(2)
                    setShowClient(null)
                  }}>
                  CANCELAR
                </button>
                <button
                  id='submit_edit_client'
                  type="button"
                  className="btn btn-info text-white col-5"
                  onClick={handleSubmit}>
                  GUARDAR
                </button>
              </div>

            </div>
          </div>
        </div>
      }
    </>
  );
}

