// import React, { useContext, useEffect } from "react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
// import OrderContext from "../../Contexts/OrderContext";

// import OrderService from "../../Services/Licenses/OrderService";
import { getToken, getUsername, isAdmin, isSellerEditor, isFinancial, isAuth, isCustomerSuccess } from "../../Utilities/Auth/AuthPermissions";

import ClientsCard from '../../Assets/ClientsCard';
import InventoryCard from '../../Assets/InventoryCard';
import OrdersCard from '../../Assets/OrdersCard';
import CalibrationsCard from '../../Assets/CalibrationsCard';
import WorkOrdersCard from '../../Assets/WorkOrdersCard';
import PreOrdersCard from '../../Assets/PreOrdersCard';
import BillingCard from "../../Assets/BillingCard";
import DistributionCentersCard from '../../Assets/DistributionCentersCard';

export default function Home() {

  // const {
  //   orders,
  //   setOrders,
  //   setOrderShippingData,
  //   setOrderBillingData,
  //   setNextPage,
  //   setPreviousPage,
  //   setPagesCount
  // } = useContext(OrderContext)

  useEffect(() => {
    var backs = document.getElementsByClassName('modal-backdrop');
    if (backs.length > 0) {
      window.location.reload();
    }
  }, [])

  // useEffect(() => {
  //   if (orders?.length === 0 && isAuth()) {
  //     OrderService.getOrders({
  //       ordering: '-id',
  //       limit: 3
  //     }).then(data => {
  //       console.log(data);
  //       setOrders(data)
  //     })
  //     OrderService.getOrderShippingData().then(data => setOrderShippingData(data))
  //     OrderService.getOrderBillingData().then(data => setOrderBillingData(data))
  //   }
  // }, [])

  return (

    <>

      <PrincipalBar fromCards={'true'} />

      <div id="cardsContainer" className="d-flex flex-wrap bg-light rounded min-vh-100 py-5 justify-content-center">

        <div className="m-3">
          <Link to="/clientes" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <ClientsCard />
              </div>
              <h3 className="text-info">Clientes</h3>
              <p className="text-secondary">
                Permite registrar, modificar y ver los datos de un cliente.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/ordenes-venta" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <OrdersCard />
              </div>
              <h3 className="text-info">Órdenes de venta</h3>
              <p className="text-secondary">
                Creación y edición de órdenes.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/comprobantes-de-pago" className="text-decoration-none">
            <div className="bg-white mainCards">
              <h3 className="text-info">Comprobantes de Pago</h3>
              <p className="text-secondary">
                Adjuntar comprobantes de pago.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/inventario" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <InventoryCard />
              </div>
              <h3 className="text-info">Inventario</h3>
              <p className="text-secondary">
                Administrar insumos, proveedores, fabricantes y productos.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/ordenes-trabajo" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <WorkOrdersCard />
              </div>
              <h3 className="text-info">Órdenes de trabajo</h3>
              <p className="text-secondary">
                Aceptación y envío de órdenes.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/ordenes-prearmado" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <PreOrdersCard />
              </div>
              <h3 className="text-info">Órdenes de Prearmado</h3>
              <p className="text-secondary">
                Creación y consulta de Prearmados.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/centros-de-distribucion" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <DistributionCentersCard />
              </div>
              <h3 className="text-info">Centros de Distribución</h3>
              <p className="text-secondary">
                Creación y consulta de Centros de Distribución.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/archivos-calibracion" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2">
                <CalibrationsCard />
              </div>
              <h3 className="text-info">Calibraciones</h3>
              <p className="text-secondary">
                Permite crear, consultar y gestionar versiones de calibraciones.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/facturacion" className="text-decoration-none">
            <div className="bg-white mainCards">
              <div className="m-3 ms-2" id="billingCard">
                <BillingCard />
              </div>
              <h3 className="text-info">Facturación</h3>
              <p className="text-secondary">
                Administrar datos de facturación de ventas.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <Link to="/panel-cs" className="text-decoration-none">
            <div className="bg-white mainCards">
              <h3 className="text-info">Panel Customer Success</h3>
              <p className="text-secondary">
                Administrar audiómetros en funcionamiento.
              </p>
            </div>
          </Link>
        </div>

        <div className="m-3">
          <a
            href="https://datastudio.google.com/u/0/reporting/194f2fdd-1675-4f1a-9cfd-e5adc11b0740/page/ceA5B"
            className="text-decoration-none"
            target="_blank" rel="noreferrer"
          >
            <div id="comercial" className="mainCards">
              <div className="bg-transparent border-0 py-4 d-flex align-items-center h-100">
                <h2 className="text-white fw-normal">Tablero comercial</h2>
              </div>
            </div>
          </a>
        </div>

        <div className="m-3">
          <a
            href="https://app.powerbi.com/view?r=eyJrIjoiYmYyZjZmN2QtYWQzNS00M2QyLTlmYzYtOWQzOTRlZDgwM2I0IiwidCI6IjdmYzA4YWQxLWNlMGUtNDRiNy04MGFmLTc4MTI2MWI1YmJhZiIsImMiOjR9"
            className="text-decoration-none"
            target="_blank" rel="noreferrer"
          >
            <div id="ranking" className="mainCards">
              <div className="bg-transparent border-0 py-4 d-flex align-items-center h-100">
                <h2 className="text-white fw-normal">Ranking de uso</h2>
              </div>
            </div>
          </a>
        </div>

        <div className="m-3">
          <a
            href="https://nsp.usound.co/es/dashboard-team/"
            className="text-decoration-none"
            target="_blank" rel="noreferrer"
          >
            <div id="ambassadors" className="mainCards">
              <div className="bg-transparent border-0 py-4 d-flex align-items-center h-100">
                <h2 className="text-white fw-normal">Mapa de embajadores</h2>
              </div>
            </div>
          </a>
        </div>

        <div className="m-3">
          <a
            href="https://app.powerbi.com/view?r=eyJrIjoiZmIzNDI3MTYtMWU3ZS00YzM1LWE3MTUtMWNhMWIyZjZkZTBjIiwidCI6IjdmYzA4YWQxLWNlMGUtNDRiNy04MGFmLTc4MTI2MWI1YmJhZiIsImMiOjR9"
            className="text-decoration-none"
            target="_blank" rel="noreferrer"
          >
            <div id="" className="mainCards bg-secondary">
              <div className="bg-transparent border-0 py-4 d-flex align-items-center h-100">
                <h2 className="text-white fw-normal">Frecuencia de uso</h2>
              </div>
            </div>
          </a>
        </div>

        {(isFinancial() || isAdmin() || isCustomerSuccess()) &&
          <div className="m-3">
            <a
              href="https://app.powerbi.com/view?r=eyJrIjoiZTdkODlhNjItMjIxZC00ODNjLTg0NmUtNjhhNzJlMWE3ZGMxIiwidCI6IjdmYzA4YWQxLWNlMGUtNDRiNy04MGFmLTc4MTI2MWI1YmJhZiIsImMiOjR9"
              className="text-decoration-none"
              target="_blank" rel="noreferrer"
            >
              <div id="" className="mainCards bg-secondary">
                <div className="bg-transparent border-0 py-4 d-flex align-items-center h-100">
                  <h2 className="text-white fw-normal">Panel de Licencias</h2>
                </div>
              </div>
            </a>
          </div>
        }

      </div>

    </>
  );
}