import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { format } from "date-fns";
import { toast } from "react-toastify";

import PrincipalBar from "../../AppComponents/Commons/PrincipalBar";
import FirstTitle from "../../AppComponents/Commons/FirstTitle";
import ButtonBack from '../../AppComponents/Commons/ButtonBack';
import Loader from "../../AppComponents/Commons/Loader";

import { hideTooltip, showTooltip } from '../../Utilities/General/BootstrapApi'
import { getUsername, isAdmin, isCalibrator, isAuth } from "../../Utilities/Auth/AuthPermissions";
import CalBuildService from "../../Services/Calibrations/CalBuildService";

import Watch from '../../Assets/Watch';
import Send from '../../Assets/Send';
import Delete from "../../Assets/Delete";

export default function ColumnsCalibrationBuilds() {

  const [calBuilds, setCalBuilds] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCompile, setLoadingCompile] = useState(false);
  const [allVersionsCompiled, setAllVersionsCompiled] = useState(true);

  const navigate = useNavigate()

  useEffect(() => {
    var backs = document.getElementsByClassName('modal-backdrop');
    if (backs.length > 0 && isAuth()) {
      window.location.reload();
    }
    if (isAuth()) {
      CalBuildService.getCalBuild()
        .then(data => {
          setCalBuilds(data?.reverse());
        })
    }
  }, []);

  useEffect(() => {
    var i = 0;
    while (i < calBuilds?.length) {
      if (!calBuilds[i].built) {
        setAllVersionsCompiled(false);
      }
      i++
    }
  }, [calBuilds, allVersionsCompiled])

  const handleWatch = id => {
    navigate('/calibraciones/' + id);
  }

  const handleCompile = id => {
    swal({
      title: "Confirmar compilación",
      text: 'Se va a compilar un archivo de calibración y no podrá ser modificado.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Confirmar']
    }).then(confirm => {
      if (confirm) {
        setLoading(true)
        setLoadingCompile(true);
        CalBuildService.compileCalBuild(id)
          .then(data => {
            if (data === undefined) return
            toast.success('Compilación realizada correctamente.', {
              position: 'bottom-left',
              hideProgressBar: true,
              className: 'success-toast'
            })
            CalBuildService.getCalBuild()
              .then(data => {
                setCalBuilds(data.reverse());
                setAllVersionsCompiled(true);
                setLoading(false);
                setLoadingCompile(false);
              })
          })
      }
    })
  }

  const handleDelete = id => {
    swal({
      title: "¿Borrar archivo de calibración?",
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Confirmar']
    }).then(confirm => {
      if (confirm) {
        CalBuildService.deleteCalBuild(id)
          .then(res => {
            if (res === undefined) return
            setCalBuilds([
              ...calBuilds.filter(i => i.id !== id)
            ])
            setAllVersionsCompiled(true);
            toast.success('Archivo de calibración borrado correctamente.', {
              position: 'bottom-left',
              hideProgressBar: true,
              className: 'success-toast'
            })
          })
      }
    })
  }

  const saveNewCalBuild = () => {
    setLoading(true);
    setLoadingCreate(true);
    let versionNumbers = calBuilds.map(build => {
      return build.id;
    })
    let sortedNumbers = versionNumbers.sort((a, b) => a - b);
    CalBuildService.saveNewCalBuild({
      version: sortedNumbers[sortedNumbers.length - 1],
      user: getUsername(),
      built: false
    })
      .then(data => {
        if (data === undefined) return
        toast.success('Archivo de calibración creado.', {
          position: 'bottom-left',
          hideProgressBar: true,
          className: 'success-toast'
        })
        CalBuildService.getCalBuild()
          .then(data => {
            setCalBuilds(data.reverse());
            setLoading(false);
            setLoadingCreate(false);
          })
      })
  }

  return (
    <>
      <PrincipalBar />

      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-between m-3">
        <FirstTitle text="Archivos de calibraciones" />
        <div className="mt-3 mt-sm-0">
          <ButtonBack text="VOLVER" destination="/inicio" />
        </div>
      </div>

      <div className="mx-3 text-center">
        {loadingCreate && <strong className="text-info">Creando nuevo archivo de calibraciones. Espere por favor...</strong>}
        {loadingCompile && <strong className="text-info">Compilando archivo de calibraciones. Espere por favor...</strong>}
      </div>

      {calBuilds?.length === 0 || loading ?
        <Loader /> :
        <div className="mx-3">

          <div className="my-4">
            <h4 className="text-info">Actual</h4>
            {allVersionsCompiled && (isCalibrator() || isAdmin()) ?
              <button type="button" className="btn btn-info text-white m-3" onClick={saveNewCalBuild}>
                CREAR NUEVO ARCHIVO DE CALIBRACIONES
              </button> :
              <div className="text-center md-overflow-scroll">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Autor</th>
                      <th>Versión</th>
                      <th>Última Act.</th>
                      <th>Creado el</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      calBuilds.map((build, idx) => {
                        if (!build.built) {
                          return (
                            <tr key={idx}>
                              <td>{build.id}</td>
                              <td>{build.user}</td>
                              <td>{build.version}</td>
                              <td>{format(new Date(build.last_update), "dd/MM/yyyy HH:mm")}</td>
                              <td>{format(new Date(build.timestamp), "dd/MM/yyyy HH:mm")}</td>
                              <td>
                                <div className="d-flex align-items-center justify-content-center">
                                  <span
                                    id={'watchButton' + build.id}
                                    onMouseEnter={() => showTooltip('watchButton' + build.id, `Ver archivo de calibración #${build.id}`)}
                                    onMouseLeave={() => hideTooltip('watchButton' + build.id)}
                                    onClick={() => {
                                      hideTooltip('watchButton' + build.id)
                                      handleWatch(build.id)
                                    }}
                                    className="actions"
                                  >
                                    <Watch />
                                  </span>
                                  {/* {(isCalibrator() || isAdmin()) &&
                                    <>
                                      <span
                                        id={'compileButton' + build.id}
                                        onMouseEnter={() => showTooltip('compileButton' + build.id, `Compilar archivo de calibración #${build.id}`)}
                                        onClick={() => {
                                          hideTooltip('compileButton' + build.id)
                                          handleCompile(build.id)
                                        }}
                                        className="actions ms-1"
                                      >
                                        <Send />
                                      </span>
                                      <span className="actions ms-1"
                                        id={'deleteButton' + build.id}
                                        onMouseEnter={() => showTooltip('deleteButton' + build.id, `Borrar archivo de calibración #${build.id}`)}
                                        onClick={() => {
                                          hideTooltip('deleteButton' + build.id)
                                          handleDelete(build.id)
                                        }}
                                      >
                                        <Delete />
                                      </span>
                                    </>
                                  } */}
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      })
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>

          <div>
            <h4 className="text-info">Historial</h4>
            <div className="text-center md-overflow-scroll">
              <table className="table table-hover text-center md-overflow-scroll">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Autor</th>
                    <th>Versión</th>
                    <th>Última Act.</th>
                    <th>Creado el</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    calBuilds?.sort((a, b) => b.id - a.id).map((build, idx) => {
                      if (build.built) {
                        return (
                          <tr key={idx}>
                            <td>{build.id}</td>
                            <td>{build.user}</td>
                            <td>{build.version}</td>
                            <td>{format(new Date(build.last_update), "dd/MM/yyyy HH:mm")}</td>
                            <td>{format(new Date(build.timestamp), "dd/MM/yyyy HH:mm")}</td>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                <span
                                  id={'watchButton' + build.id}
                                  onMouseEnter={() => showTooltip('watchButton' + build.id, `Ver archivo de calibración #${build.id}`)}
                                  onMouseLeave={() => hideTooltip('watchButton' + build.id)}
                                  onClick={() => {
                                    hideTooltip('watchButton' + build.id)
                                    handleWatch(build.id)
                                  }}
                                  className="actions"
                                >
                                  <Watch />
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    })
                  }
                </tbody>
              </table>
            </div>

          </div>

        </div>
      }

    </>
  );

}