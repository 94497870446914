import { useContext, useEffect, useState } from "react"
import { toast } from 'react-toastify';

import OrderContext from "../../../Contexts/OrderContext";
import OrderService from "../../../Services/Licenses/OrderService"
import { hideModal } from "../../../Utilities/General/BootstrapApi"

export default function LogisticData({
    id,
    ordershippingdata,
    data,
    setData
}) {

    const { orders, setOrders } = useContext(OrderContext)

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        let newData = {
            ...ordershippingdata,
            ...data
        }
        OrderService.updateOrderShippingData(ordershippingdata.id, newData)
            .then(() => {
                let order = orders.find(o => o.id === newData.order)
                setOrders([
                    ...orders.filter(item => item.id !== order.id),
                    {
                        ...order,
                        ordershippingdata: newData
                    }
                ])
                setData({})
                toast.success(`Datos de logística guardados correctamente`, {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    className: 'success-toast'
                })
                hideModal("logisticData" + id)
                document.getElementById('logisticDataForm').reset()
                document.getElementById('companySelect').value = ''
            })
    }

    return (
        <div className="modal fade" id={"logisticData" + id}>
            <div className="modal-dialog border border-dark shadow rounded">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-info">Datos de logística</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                hideModal("logisticData" + id)
                                setData({})
                                document.getElementById('logisticDataForm').reset()
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form id="logisticDataForm">
                            <div className="form-group">
                                <label htmlFor="logistic_company">Compañía logística</label>
                                <select
                                    id="companySelect"
                                    className="form-select"
                                    name="logistic_company"
                                    value={data?.logistic_company}
                                    onChange={e => handleChange(e)}
                                >
                                    <option value="">Seleccione</option>
                                    <option value="">Ninguna</option>
                                    <option value="Andreani">Andreani</option>
                                    <option value="DHL">DHL</option>
                                    <option value="Servientrega (Col)">Servientrega (Col)</option>
                                    <option value="Envios Chile (Ch)">Envios Chile (Ch)</option>
                                    <option value="Retiro por oficina">Retiro por oficina</option>
                                </select>
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="tracking_code">Código de seguimiento</label>
                                <input
                                    type="text"
                                    name="tracking_code"
                                    className="form-control"
                                    value={data?.tracking_code}
                                    onChange={e => handleChange(e)}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                hideModal("logisticData" + id)
                                setData({})
                                document.getElementById('logisticDataForm').reset()
                            }}
                        >
                            CANCELAR
                        </button>
                        <button
                            type="button"
                            className="btn btn-info text-white"
                            onClick={handleSubmit}
                        >
                            GUARDAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}