import React from 'react';

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        placeholder='Buscar...'
        className='form-control'
        value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
    </span>
  )
}


