import React from 'react';

export default function Home() {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <g id="Grupo_962" data-name="Grupo 962" transform="translate(-2335 -3203)">
                    <g id="Grupo_961" data-name="Grupo 961" transform="translate(1893 3190)">
                        <g id="Grupo_959" data-name="Grupo 959">
                            <g id="Grupo_751" data-name="Grupo 751" transform="translate(441.784 12.784)">
                                <circle id="Elipse_9" data-name="Elipse 9" cx="17.5" cy="17.5" r="17.5" transform="translate(0.216 0.216)" fill="#0DCAF0" />
                            </g>
                            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(448.25 20)">
                                <path id="Trazado_587" data-name="Trazado 587" d="M4.5,8.25,11.25,3,18,8.25V16.5A1.5,1.5,0,0,1,16.5,18H6a1.5,1.5,0,0,1-1.5-1.5Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Trazado_588" data-name="Trazado 588" d="M13.5,25.5V18H18v7.5" transform="translate(-4.5 -7.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );

}
