import React from 'react';

export default function WorkOrdersCard() {

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 23.998 21.141">
                <g id="Grupo_1057" data-name="Grupo 1057" transform="translate(-2466.876 -2234.316)">
                    <path id="Trazado_634" data-name="Trazado 634" d="M2490.875,2237.711v15.867a.149.149,0,0,1-.023.063,2.144,2.144,0,0,1-2.187,1.82q-9.785-.012-19.57-.008a2.3,2.3,0,0,1-.617-.07,2.111,2.111,0,0,1-1.594-2.187q-.012-7.594,0-15.187c0-.617.25-.867.875-.867h6.2v-.648a2.118,2.118,0,0,1,2.164-2.172q2.754,0,5.508.008a2.413,2.413,0,0,1,.969.227,2.048,2.048,0,0,1,1.2,1.938c.008.211,0,.43,0,.648h.313c1.953,0,3.914.008,5.859-.008A.8.8,0,0,1,2490.875,2237.711ZM2475.367,2247h-.281c-1.18,0-2.359.008-3.539,0a2.118,2.118,0,0,1-2.18-1.57c-.328-.977-.648-1.961-.977-2.93-.023-.062-.047-.117-.094-.219a.894.894,0,0,0-.008.117q-.012,5.438,0,10.891a.715.715,0,0,0,.813.758h19.563a.715.715,0,0,0,.8-.812v-10.75c0-.055,0-.1-.008-.2a.454.454,0,0,1-.047.086l-1.008,3.047a2.141,2.141,0,0,1-2.2,1.586h-3.8v.672a.7.7,0,0,1-.719.734q-2.8.012-5.6,0a.685.685,0,0,1-.711-.711C2475.359,2247.477,2475.367,2247.258,2475.367,2247Zm0-1.406c0-.25-.008-.461,0-.664a.7.7,0,0,1,.742-.742q2.766-.012,5.547,0a.708.708,0,0,1,.742.734v.672h3.742c.578,0,.758-.133.945-.672.648-1.961,1.3-3.922,1.953-5.875.055-.164.1-.312.156-.484H2468.57c.016.055.031.086.039.125q1.055,3.176,2.109,6.336a.731.731,0,0,0,.813.57h3.836Zm7.031-8.469v-.562a.735.735,0,0,0-.836-.836h-3.516c-.641,0-1.3,0-1.937.008a.679.679,0,0,0-.727.594,6.763,6.763,0,0,0-.008.8Zm-5.617,9.859h4.188v-1.375h-4.187Z" transform="translate(0 -0.005)" fill="#43eba2" />
                </g>
            </svg>
        </div>
    );

}
