import React, { useEffect, useRef } from "react";
import swal from 'sweetalert';

import { hideModal } from "../../../Utilities/General/BootstrapApi";

export default function CreateSaleProduct({
    val,
    newSaleProduct,
    setNewSaleProduct,
    handleSubmit,
    typesInputProduct,
    requirements,
    setRequirements,
    forceUpdate,
    notAllowed,
    setNotAllowed
}) {

    const skuRef = useRef();
    const nameSaleProductRef = useRef();
    const suffixRef = useRef();
    const newRequirementIdRef = useRef();
    const newRequirementQuantityRef = useRef();

    const handleChange = e => {
        setNewSaleProduct({
            sku: skuRef.current.value,
            name: nameSaleProductRef.current.value,
            suffix: suffixRef.current.value
        })
    }

    useEffect(() => {
        let selId = document.getElementById('inputId')
        let selQ = document.getElementById('inputQuantity')
        if (selId !== null && selQ !== null) {
            selId.value = ""
            selQ.value = ""
        }
    }, [requirements])

    return (

        <>

            {newSaleProduct !== undefined &&

                <div className="modal fade" id="newSaleProduct" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                    document.getElementById('newSaleProductForm').reset()
                                    setRequirements([])
                                    hideModal('newSaleProduct')
                                }}></button>
                            </div>
                            <div className="modal-body">

                                <form id="newSaleProductForm" onChange={handleChange}>
                                    <div className="text-center bg-info p-1 mb-2">
                                        <p className="text-white mb-1">Alta de producto</p>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="sku">SKU</label>
                                        <input type="text" name="sku" ref={skuRef} className="form-control" />
                                        {val.current.message('sku', newSaleProduct.sku, 'required|regex:[A-Z0-9_]|max:50', { className: 'text-danger' })}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="nameSaleProduct">Nombre</label>
                                        <input type="text" name="nameSaleProduct" ref={nameSaleProductRef} className="form-control" />
                                        {val.current.message('nameSaleProduct', newSaleProduct.name, 'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ]|not_regex:[!¿¡?"@#$%&/()=<>+-]|max:200', { className: 'text-danger' })}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="nameSaleProduct">Sufijo</label>
                                        <input
                                            type="text"
                                            name="suffix"
                                            ref={suffixRef}
                                            className="form-control"
                                            placeholder="Ejemplo: NBK_AUD"
                                        />
                                        {val.current.message('suffixSaleProduct', newSaleProduct.suffix, 'required|regex:[A-Za-z0-9áéíóúÁÉÍÓÚÜüñÑ_]|not_regex:[!¿¡?"@#$%&/()=<>+-]|max:200', { className: 'text-danger' })}
                                    </div>
                                </form>

                                <div className="mb-3 text-center">
                                    <label htmlFor="newRequirement">Accesorios</label>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tipo</th>
                                                <th>Cantidad</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requirements.length === 0 ?
                                                <tr>
                                                    <td colSpan="3"><strong>No hay accesorios agregados.</strong></td>
                                                </tr> :
                                                requirements.map((item, idx) => {
                                                    return <tr key={idx}>
                                                        <td>
                                                            {idx + 1}
                                                        </td>
                                                        <td >
                                                            {typesInputProduct.map(type => {
                                                                if (item.type_input_product === type.id) {
                                                                    return type.name
                                                                }
                                                            })}
                                                        </td>
                                                        <td>
                                                            {item.quantity}
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-danger btn-sm text-white" onClick={() => {
                                                                setRequirements(requirements.filter(req => {
                                                                    return req.type_input_product !== item.type_input_product;
                                                                }))
                                                            }}>
                                                                <strong>X</strong>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                })}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-between">
                                        {requirements.length === typesInputProduct?.length ?
                                            <select className="form-select" disabled></select> :
                                            <select id="inputId" name="newRequirementId" ref={newRequirementIdRef} className="form-select">
                                                <option value="">Seleccione</option>
                                                {typesInputProduct?.map((item, idx) => {
                                                    let flag = 0;
                                                    requirements.map(json => {
                                                        if (json.type_input_product === item.id) {
                                                            flag = 1
                                                        }
                                                    })
                                                    if (flag === 0) {
                                                        return <option key={idx} value={item.id}>{item.name}</option>
                                                    }
                                                })}
                                            </select>}
                                        {requirements.length === typesInputProduct?.length ?
                                            <input type="number" className="form-control mx-3" value="" disabled /> :
                                            <input id="inputQuantity" type="number" min="1" name="newRequirementQuantity"
                                                ref={newRequirementQuantityRef}
                                                placeholder="Cantidad" className="form-control mx-3"
                                            />
                                        }
                                        {requirements.length === typesInputProduct?.length ?
                                            <button type="button" className="btn btn-info btn-sm text-white" disabled>Agregar</button> :
                                            <button type="button" className="btn btn-info btn-sm text-white" onClick={() => {
                                                let selId = document.getElementById('inputId').value
                                                let selQ = document.getElementById('inputQuantity').value
                                                if (selId.length === 0 || selQ === "" || parseInt(selQ) < 1 || selQ > 100) {
                                                    setNotAllowed(true)
                                                } else {
                                                    setNotAllowed(false)
                                                    setRequirements([...requirements, {
                                                        type_input_product: parseInt(newRequirementIdRef.current.value),
                                                        quantity: parseInt(newRequirementQuantityRef.current.value)
                                                    }]);
                                                }
                                            }}>
                                                Agregar
                                            </button>
                                        }
                                    </div>
                                    {notAllowed &&
                                        <small className="text-danger d-block mt-3">
                                            * Verifique datos: <br />
                                            - El tipo es requerido. <br />
                                            - La cantidad es requerida. <br />
                                            - Ingrese una cantidad entre 1 y 100.
                                        </small>
                                    }
                                </div>

                                <div className="d-flex justify-content-around">
                                    <button type="button" className="btn btn-info text-white" onClick={e => {
                                        newSaleProduct.saleproductrequirement_set = requirements
                                        if (newSaleProduct.saleproductrequirement_set.length === 0) {
                                            swal(
                                                'No hay accesorios agregados',
                                                'El producto debe tener al menos un artículo como accesorio.',
                                                'warning'
                                            )
                                        } else {
                                            if (val.current.fieldValid('nameSaleProduct') &&
                                                val.current.fieldValid('sku')) {
                                                handleSubmit(e, newSaleProduct)
                                            } else {
                                                val.current.showMessages()
                                                forceUpdate(1)
                                            }
                                        }
                                    }}>
                                        GUARDAR
                                    </button>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    document.getElementById('newSaleProductForm').reset()
                                    setRequirements([])
                                    hideModal('newSaleProduct')
                                }}>CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>

            }

        </>

    )
}