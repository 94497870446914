import React from 'react';
import { NavLink } from "react-router-dom";

export default function ButtonBack({
    destination,
    text
}) {

    return (
        <div className="d-flex text-right">
            <NavLink to={destination} className="col-12 col-sm-6 col-md-1 mt-3 mt-lg-0">
                <button className="btn border border-info text-info">{text}</button>
            </NavLink>
        </div>
    );

}