import OrderService from "../../Services/Licenses/OrderService"
import Urls from "../General/Urls"

export const paginateOrders = async url => {
    const data = await OrderService.getOrdersWithUrl(url)
    return data
}

export const filterOrders = async params => {
    const data = await OrderService.getOrdersWithUrl(Urls.urlPayOrder + params)
    return data
}